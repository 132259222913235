import { DeliveryTotalNumber } from '../../../common/delivery-table-search-uwi-contract/models/delivery-total-number.model';
import { TabInfo } from '../../../common/self-service-to-validation-table/models/self-service-to-validation.model';
import { WellInformation } from '../../../core/models/well-information.model';
import { User } from '../../admin-settings/user-table/user.model';
import {
  AdditionalInformation,
  ContractInformation,
  Operator,
  ProjectInformation,
  ServiceProvider,
  TechnicalInformation,
  TransportationInformation,
} from '../../reception/models/reception.model';
import { SampleDetailInformation } from './sample-details.model';

export interface VerificationSampleInformation {
  verified?: boolean;
  sampleTypeId?: string;
  id?: string | null;
  title?: string;
  numberOfSet?: number | null;
  objectiveSetId?: string;
  boxTypeId?: string;
  boxNumber?: number | null;
  boxTopDepth?: number | null;
  length?: number | null;
  boxBottomDepth?: number | null;
  numberSamplesPerBox?: number | null;
  depthUnitId?: string;
  packagingTypeId?: string;
  tubesPerBox?: number | null;
  numberOfCores?: number | null;
  sectionTypeId?: string;
  latitude?: number | null;
  longitude?: number | null;
  north?: number | null;
  east?: number | null;
  origin?: string | null;
  formation?: string | null;
  diameter?: number | null;
  recoveryPercent?: number | null;
  typeOfPackaging?: string;
  technicalInfoId?: string;
  mediumDeliveryInfoId?: string;
  lengthUnitId?: string;
  samplesPerBox?: number | null;
  endCupFractionId?: string;
  extractionPositionId?: string;
  weight?: number | null;
  weightUnitId?: string;
  fluidTypeId?: string;
  numberOfBottles?: number | null;
  samplingInterval?: number | null;
  samplingSite?: string;
  formationGeology?: string;
  formationOperation?: string;
  samplingMethodId?: string;
  samplingMethod?: string;
  analysisPerformed?: string;
  sampleOriginId?: string;
  stain?: string;
  sampleUnitId?: string;
  locality?: string;
  sampleName?: string;
  penetrationLength?: number | null;
  recoveryLength?: number | null;
  location?: string;
  section?: string;
  shotDate?: Date | null;
  laboratory?: string;
  bottleNumber?: number | null;
  minCps?: number | null;
  maxCps?: number | null;
  locationId?: string | null;
  containerId?: string | null;
  deliveryId?: string;
  note?: string;
  notes?: string;
  createdAt?: string;
  createdBy?: string;
  modifiedAt?: string;
  modifiedBy?: string;
  zhZsDatSamples?: SampleDetailInformation[];
  coreSamples?: SampleDetailInformation[];
  preservedSamples?: SampleDetailInformation[];
  plugSamples?: SampleDetailInformation[];
  swcSamples?: SampleDetailInformation[];
  fluidSamples?: SampleDetailInformation[];
  residueSamples?: SampleDetailInformation[];
  petrographicPlateSamples?: SampleDetailInformation[];
  biostratigraphicPlateSamples?: SampleDetailInformation[];
  surfaceSamples?: SampleDetailInformation[];
  pistonCoreSamples?: SampleDetailInformation[];
  crudeSamples?: SampleDetailInformation[];
}

export interface Verification {
  contractInformation: ContractInformation;
  additionalInformation: AdditionalInformation;
  operator: Operator;
  projectInformation: ProjectInformation;
  sampleInformation: Record<string, VerificationSampleInformation[]>;
  serviceProvider: ServiceProvider;
  transportationInformation: TransportationInformation;
  wellInformation: WellInformation | any;
  technicalInformation: TechnicalInformation;
  verifyingTechnicalInformation: TechnicalInformation;
  validationTechnicalInformation: TechnicalInformation;
}

export interface VerificationShape {
  sampleTypeId?: string;
  contractId: string;
  searchSample?: string;
  contractNumber: string;
  yearOfContract: string;
  operatorId: string | null;
  operatorContactName: string | null;
  operatorContactNumber: string | null;
  operatorEmail: string | null;
  serviceProviderId: string | null;
  serviceProviderContactName: string | null;
  serviceProviderContactNumber: string | null;
  serviceProviderEmail: string | null;
  transportationProviderId: string | null;
  wellInformation: WellInformation;
  upi: string;
  projectName: string;
  uwiUpiCreatedInEpis: boolean;
  notes: string;
  receivingTechnicianId: string;
  verificationTechnicianId: string;
  validationTechnicianId?: string;
  zhZsDatBoxes: VerificationSampleInformation[];
  coreBoxes: VerificationSampleInformation[];
  preservedBoxes: VerificationSampleInformation[];
  plugBoxes: VerificationSampleInformation[];
  swcBoxes: VerificationSampleInformation[];
  fluidBoxes: VerificationSampleInformation[];
  residueBoxes: VerificationSampleInformation[];
  petrographicPlatesBoxes: VerificationSampleInformation[];
  biostratigraphicBoxes: VerificationSampleInformation[];
  surfaceSampleBoxes: VerificationSampleInformation[];
  pistonCoreBoxes: VerificationSampleInformation[];
  crudeBoxes: VerificationSampleInformation[];
  deliveryId?: string;
  [key: string]: any;
}

export class VerificationShapeImpl implements VerificationShape {
  deliveryId: string;
  sampleTypeId?: string;
  contractId: string;
  searchSample?: string;
  contractNumber: string;
  yearOfContract: string;
  operatorId: string | null;
  operatorContactName: string | null;
  operatorContactNumber: string | null;
  operatorEmail: string | null;
  serviceProviderId: string | null;
  serviceProviderContactName: string | null;
  serviceProviderContactNumber: string | null;
  serviceProviderEmail: string | null;
  transportationProviderId: string | null;
  wellInformation: WellInformation | any;
  uwiUpiCreatedInEpis: boolean;
  upi: string;
  projectName: string;
  notes: string;
  receivingTechnicianId: string;
  verificationTechnicianId: string;
  totalNumberOfBoxes: number;
  totalNumberOfSamples: number;
  zhZsDatBoxes: VerificationSampleInformation[];
  coreBoxes: VerificationSampleInformation[];
  preservedBoxes: VerificationSampleInformation[];
  plugBoxes: VerificationSampleInformation[];
  swcBoxes: VerificationSampleInformation[];
  fluidBoxes: VerificationSampleInformation[];
  residueBoxes: VerificationSampleInformation[];
  petrographicPlatesBoxes: VerificationSampleInformation[];
  biostratigraphicBoxes: VerificationSampleInformation[];
  surfaceSampleBoxes: VerificationSampleInformation[];
  pistonCoreBoxes: VerificationSampleInformation[];
  crudeBoxes: VerificationSampleInformation[];

  constructor(
    deliveryId: string,
    contractId: string,
    userOption: User[],
    totalNumber: DeliveryTotalNumber,
    ss?: Verification,
    table?: TabInfo[],
  ) {
    this.deliveryId = deliveryId;
    this.contractId = contractId;
    this.searchSample = '';
    this.contractNumber = ss?.contractInformation?.contractNumber ?? '';
    this.yearOfContract = ss!.contractInformation?.contractYear;
    this.operatorId = ss?.operator?.companyName ?? null;
    this.operatorContactName = ss?.operator?.contactName ?? null;
    this.operatorContactNumber = ss?.operator?.contactNumber ?? null;
    this.operatorEmail = ss?.operator?.email ?? null;
    this.serviceProviderId = ss?.serviceProvider?.companyName ?? null;
    this.serviceProviderContactName = ss?.serviceProvider?.contactName ?? null;
    this.serviceProviderContactNumber = ss?.serviceProvider?.contactNumber ?? null;
    this.serviceProviderEmail = ss?.serviceProvider?.email ?? null;
    this.transportationProviderId = ss?.transportationInformation?.companyName ?? null;
    this.wellInformation = {
      id: ss?.wellInformation?.id || null,
      basinId: ss?.wellInformation?.basin || null,
      uwi: ss?.wellInformation?.uwi ?? '',
      wellName: ss?.wellInformation?.wellName ?? '',
      wellTypeId: ss?.wellInformation?.wellType || null,
      latitude: ss?.wellInformation?.latitude ?? null,
      longitude: ss?.wellInformation?.longitude ?? null,
      north: ss?.wellInformation?.north ?? null,
      east: ss?.wellInformation?.east ?? null,
      origin: ss?.wellInformation?.origin ?? '',
      uwiUpiCreatedInEpis: ss?.wellInformation.uwiUpiCreatedInEpis ?? false,
    } as any;
    this.upi = ss?.projectInformation?.upi ?? '';
    this.projectName = ss?.projectInformation?.projectName ?? '';
    this.uwiUpiCreatedInEpis = ss?.projectInformation.uwiUpiCreatedInEpis ?? false;
    this.notes = ss?.additionalInformation?.notes ?? '';
    this.receivingTechnicianId = ss?.technicalInformation.name ?? '';
    this.verificationTechnicianId = ss?.verifyingTechnicalInformation.name ?? '';
    this.totalNumberOfBoxes = totalNumber.totalCount;
    this.totalNumberOfSamples = totalNumber.sumOfValue;
    this.zhZsDatBoxes = table![0].data ?? [];
    this.coreBoxes = table![1].data ?? [];
    this.preservedBoxes = table![2].data ?? [];
    this.plugBoxes = table![3].data ?? [];
    this.swcBoxes = table![4].data ?? [];
    this.fluidBoxes = table![5].data ?? [];
    this.residueBoxes = table![6].data ?? [];
    this.petrographicPlatesBoxes = table![7].data ?? [];
    this.biostratigraphicBoxes = table![8].data ?? [];
    this.surfaceSampleBoxes = table![9].data ?? [];
    this.pistonCoreBoxes = table![10].data ?? [];
    this.crudeBoxes = table![11].data ?? [];
  }
}
