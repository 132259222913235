import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DropDownOption } from 'src/app/core/models/drop-down-option.model';

import { User } from '../../features/admin-settings/user-table/user.model';
import { DropdownEditableComponent } from '../dropdown-editable/dropdown-editable.component';

@Component({
  selector: 'app-technical-information-form',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AccordionModule,
    CalendarModule,
    DropdownEditableComponent,
    DropdownModule,
  ],
  templateUrl: './technical-information-form.component.html',
  styleUrls: ['./technical-information-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechnicalInformationFormComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() formName = 'technicalInformation';
  @Input() options: User[] = [];
  control = new FormControl();
  dropdownOptions = new Array<DropDownOption>();

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setUpControl();
    this.initializeOptions();
  }

  checkFormError(controlPath: string, fieldName: string): boolean | undefined {
    return (
      this.formGroup.get(`${controlPath}.${fieldName} `)?.invalid &&
      this.formGroup.get(`${controlPath}.${fieldName} `)?.dirty
    );
  }

  private initializeOptions(): void {
    this.dropdownOptions = this.options.map(
      (user: User) =>
        ({
          id: user.id,
          name: `${user.fullName} ${user.email || ''} ${user.contactNumber || ''}`,
        } as DropDownOption),
    );
  }

  private setUpControl(): void {
    const technicalInformationFormGroup = this.formGroup.get(this.formName) as FormGroup;
    technicalInformationFormGroup.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
  }
}
