<div class="popup-content">
  <button class="link-btn" (click)="viewMore()">
    {{ 'dataAccess.map.buttons.navigate' | translate }}
  </button>
  <p-accordion>
    <p-accordionTab *ngFor="let delivery of well.deliveries">
      <ng-template pTemplate="header">
        {{ delivery.contractNumber }}-{{ delivery.uwi }}-{{ delivery.wellName }}
      </ng-template>
      <span *ngFor="let sample of delivery.samples" class="samples-content"
        >{{ sample.type }}-{{ sample.topDepth }}-{{ sample.bottomDepth }}</span
      >
    </p-accordionTab>
  </p-accordion>
</div>
