<ng-container [formGroup]="parentGroup">
  <p-accordionTab
    header="{{ 'additionalInformation.additionalInformation' | translate }}"
    formGroupName="additionalInformation"
    [selected]="isExpanded"
  >
    <div class="row mt-2">
      <span class="p-float-label">
        <textarea
          id="textarea"
          pInputTextarea
          class="w-full"
          formControlName="notes"
          [class.p-filled]="parentGroup.get('additionalInformation')?.get('notes')?.value"
        ></textarea>
        <label for="textarea">{{ noteLabel | translate }}</label>
        <small *ngIf="checkFormError('additionalInformation.notes')" class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsTooLong' | translate }}
        </small>
      </span>
    </div>
  </p-accordionTab>
</ng-container>
