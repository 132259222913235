<div class="content">
  <div class="flex">
    <div class="type">
      {{ notificationTypeTitleKeyMap[notification.notificationType] | translate }}
    </div>
    <p-button icon="pi pi-times" (onClick)="onDelete(notification.id)" styleClass="p-button-danger">
    </p-button>
  </div>
  <div class="title">
    <span>{{ 'dashboard.notifications.title' | translate }}:</span>
    {{ notification.title }}
  </div>

  <div
    class="note cursor-pointer"
    (click)="attachment(notification.message)"
    *ngIf="hasAttachments(notification.message); else noAttachments"
  >
    <span>{{ 'dashboard.notifications.note' | translate }}:</span>
    {{ diplayMsg(notification.message) }}
    <span style="color: blue">{{ extractFileInformation(notification.message)['fileName'] }}</span>
  </div>
  <ng-template #noAttachments>
    <div class="note" (click)="attachment(notification.message)">
      <span>{{ 'dashboard.notifications.note' | translate }}:</span>
      {{ notification.message }}
    </div>
  </ng-template>

  <div class="date">
    <span>{{ 'dashboard.notifications.date' | translate }}:</span>
    {{ notification.createdAt | appDefaultDateFormat }}
  </div>
  <div *ngIf="notification.createdBy" class="sender">
    <span>{{ 'dashboard.notifications.sender' | translate }}:</span>
    {{ notification.createdBy }}
  </div>
</div>
