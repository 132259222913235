import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SearchWellProjectPopupComponent } from 'src/app/common/search-well-project-popup/search-well-project-popup.component';

import { DropdownSearchPopupComponent } from '../../../../common/dropdown-search-popup/dropdown-search-popup.component';
import { LocationModel } from '../../../../core/models/location.model';
import { PalletStorageModel } from '../../models/pallet-search.model';

@Component({
  selector: 'app-pallet-search-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    TranslateModule,
    ButtonModule,
    SearchWellProjectPopupComponent,
    DialogModule,
    DropdownSearchPopupComponent,
  ],
  templateUrl: './pallet-search-form.component.html',
  styleUrls: ['./pallet-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PalletSearchFormComponent {
  @Input() formGroup!: FormGroup;
  @Input() pallets!: PalletStorageModel[];
  @Input() location!: LocationModel[];
  @Input() searchValue = 'UWI/UPI';
  @Output() searchEvent = new EventEmitter<string>();
  @ViewChild('dropdownSearchPopupComponent')
  dropdownSearchPopupComponent!: DropdownSearchPopupComponent;

  visiblePalletPopup = false;
  visibleLocationPopup = false;
  private currentValue = '';

  valueChanged(value: string): void {
    this.currentValue = value;
  }

  search(): void {
    this.searchEvent.emit(this.currentValue);
  }

  onClick(): void {
    if (this.formGroup.get('filter')?.value === 'LocationLabel') this.visibleLocationPopup = true;
    else this.visiblePalletPopup = true;
  }

  onSelectedDropDownOption(selectedDropDownOption: PalletStorageModel): void {
    if (this.dropdownSearchPopupComponent) this.dropdownSearchPopupComponent.resetPage();
    this.formGroup.patchValue({
      search: selectedDropDownOption.palletLabel || selectedDropDownOption.locationLabel,
    });
    this.visiblePalletPopup = false;
    this.visibleLocationPopup = false;
  }
}
