export const TableSummaryLabel = [
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalTubesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalTubesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalBottlesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalPistonCoresAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalBottlesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
  },
];

export const TableSummaryVerifiedLabel = [
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.verifiedBoxes',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalTubesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.verifiedBoxes',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalTubesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.verifiedBoxes',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.verifiedBoxes',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.verifiedBoxes',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalBottlesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.verifiedBoxes',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.verifiedBoxes',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.verifiedBoxes',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.verifiedBoxes',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.verifiedBoxes',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalPistonCoresAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.verifiedBoxes',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalBottlesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.verifiedBoxes',
  },
];

export const TableSummaryValidatedLabel = [
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.validatedSamples',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalTubesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.validatedSamples',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalTubesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.validatedSamples',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.validatedSamples',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.validatedSamples',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalBottlesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.validatedSamples',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.validatedSamples',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.validatedSamples',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.validatedSamples',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalSamplesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.validatedSamples',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalPistonCoresAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.validatedSamples',
  },
  {
    label1: 'selfServices.sampleInformationTable.totalBottlesAutomatic',
    label2: 'selfServices.sampleInformationTable.totalBoxesAutomatic',
    label3: 'selfServices.sampleInformationTable.validatedSamples',
  },
];
