<div>
  <div class="margin-bottom-20">
    <label #upl for="plot-data-upload">
      <button pButton pRipple (click)="this.upl.click()" class="btn btn-secondary custom-upload">
        Upload .txt files<i class="pi pi-upload"></i>
      </button>
    </label>
    <input type="file" accept=".txt" (change)="onTxtFileSelected($event)" id="plot-data-upload" />
  </div>

  <div *ngIf="txtContent">
    <div>
      <div class="margin-bottom-20 flex-container">
        <p>cross plot control</p>
        <button pButton pRipple class="btn btn-secondary" (click)="onAddPlot()">Add plot</button>
        <div style="flex: 0.01"></div>
        <button pButton pRipple class="btn btn-secondary" (click)="onClearPlot()">
          Clear plot
        </button>
        <div style="flex: 0.01"></div>
      </div>

      <div class="flex-container" *ngIf="txtContent">
        <span class="p-float-label">
          <p-dropdown
            [options]="plot_column_list"
            [(ngModel)]="plot_x_select"
            id="x-axis"
            placeholder="X Axis"
          ></p-dropdown>
          <label for="x-axis">X Axis</label>
        </span>
        <div style="flex: 0.1"></div>
        <span class="p-float-label">
          <p-dropdown
            [options]="plot_column_list"
            [(ngModel)]="plot_y_select"
            id="y-axis"
            placeholder="Y Axis"
          ></p-dropdown>
          <label for="y-axis">Y Axis</label>
        </span>
      </div>
    </div>
    <div class="container" *ngIf="txtContent">
      <ng-container *ngFor="let pD of plotChartDatas; let i = index">
        <div class="chart" *ngIf="plot_show[i]">
          <canvas baseChart [data]="pD" [options]="plotOptions[i]" [type]="plotChartType"></canvas>
        </div>
      </ng-container>
    </div>
    <div>
      <div class="flex-container margin-bottom-20">
        <p>Scatter Plot Control</p>
        <button pButton pRipple class="btn btn-secondary" (click)="onAddScatterPlot()">
          Add scatter plot
        </button>
        <div style="flex: 0.01"></div>
        <button pButton pRipple class="btn btn-secondary" (click)="onClearScatterPlot()">
          Clear scatter plot
        </button>
        <div style="flex: 0.01"></div>
      </div>

      <div class="flex-container" *ngIf="txtContent">
        <span class="p-float-label">
          <p-dropdown
            [options]="plot_column_list"
            [(ngModel)]="plot_x_select_2"
            id="x-axis-2"
            placeholder="X Axis"
          ></p-dropdown>
          <label for="x-axis-2">X Axis</label>
        </span>
        <div style="flex: 0.1"></div>
        <span class="p-float-label">
          <p-dropdown
            [options]="plot_column_list"
            [(ngModel)]="plot_y_select_2"
            id="y-axis-2"
            placeholder="Y Axis"
          ></p-dropdown>
          <label for="y-axis-2">Y Axis</label>
        </span>
      </div>
    </div>
    <div class="container" *ngIf="txtContent">
      <ng-container *ngFor="let pD of plotChartDatas_2; let i = index">
        <div class="scatterChart" *ngIf="plot_show_2[i]">
          <canvas
            baseChart
            [data]="pD"
            [options]="plotOptions_2[i]"
            [type]="plotChartType_2"
          ></canvas>
        </div>
      </ng-container>
    </div>
  </div>
</div>
