import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PASS_ERROR_HEADER, SGC_API } from '../../core/api.consts';
import { PaginationResponse } from '../../core/models/pagination-response.model';
import { WellData } from '../../features/data-access/gis-map/models/well-data.model';

@Injectable({
  providedIn: 'root',
})
export class GisService {
  private url = `${SGC_API}/Gis`;

  constructor(private httpClient: HttpClient) {}

  getGisBy(
    page: number,
    pageSize: number,
    wellName: string,
  ): Observable<PaginationResponse<WellData>> {
    const params = new HttpParams({
      fromObject: { page, pageSize, wellName },
    });

    return this.httpClient.get<PaginationResponse<WellData>>(this.url, {
      params,
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }
}
