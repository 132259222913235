<div class="header">
  <h1 class="title">{{ 'sidebar.assignment' | translate }}</h1>
  <div class="actions">
    <button
      pButton
      type="button"
      class="btn btn-primary"
      label="{{ 'button.cancel' | translate }}"
      (click)="clearAssignmentTable()"
    ></button>

    <button
      pButton
      class="btn btn-secondary"
      type="button"
      label="{{ 'button.save' | translate }}"
      (click)="submitForm()"
      [disabled]="!foundDeliveryForAssignments.length"
    ></button>
  </div>
</div>

<div class="content">
  <form [formGroup]="assignmentFormGroup">
    <p-accordion [multiple]="true">
      <p-accordionTab
        header="{{ 'assignment.tabs.well-information' | translate }}"
        [selected]="true"
      >
        <div class="row input-group-50 mb-3">
          <span class="p-input-icon-left p-float-label">
            <i class="pi pi-search"></i>
            <input
              class="search-input"
              id="search-template-input"
              type="text"
              pInputText
              autocomplete="off"
              [readonly]="true"
              (click)="openSearchAssignmentPopup()"
            />
            <label for="search-template-input">{{ 'button.search' | translate }}</label>
          </span>
        </div>
      </p-accordionTab>
    </p-accordion>

    <div class="content-table" formGroupName="assignmentTable">
      <p-table
        [columns]="assignmentTableLabels"
        [value]="getAssignmentTableFormControl().controls"
        [rows]="initialTableRowCount"
        [totalRecords]="getAssignmentTableFormControl().controls.length"
        [resizableColumns]="true"
        styleClass="p-datatable-gridlines basicTable"
        dataKey="value.deliveryId"
        [scrollable]="true"
        [paginator]="true"
        scrollHeight="500px"
        rowExpandMode="single"
      >
        <ng-template let-columns pTemplate="header">
          <tr>
            <ng-container *ngFor="let header of headers">
              <th class="white-space-nowrap">
                {{ header | translate }}
              </th>
            </ng-container>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <ng-container [formArrayName]="'table'">
            <tr [formGroupName]="rowIndex" class="vertical-align-middle">
              <ng-container
                *ngFor="
                  let col of columns[0] | keyvalue : orderOriginal;
                  let i = index;
                  let last = last
                "
              >
                <td
                  class="px-1 py-1"
                  *ngIf="
                    !assignmentTableDropdownOption[col.key] &&
                    col.key !== 'dateAssigned' &&
                    col.key !== 'note'
                  "
                >
                  <span class="white-space-pre">{{
                    col.key === 'dateCompleted'
                      ? (getCellValue(rowIndex, col.key) | appDefaultDateFormat)
                      : getCellValue(rowIndex, col.key)
                  }}</span>
                </td>

                <td *ngIf="assignmentTableDropdownOption[col.key]">
                  <app-dropdown-editable
                    [filter]="col.key === 'technician'"
                    [filterBy]="col.key === 'technician' ? 'name' : ''"
                    [type]="col.key"
                    [tableFormGroup]="assignmentTableFormGroup"
                    [formControlName]="col.key"
                    [options]="this.assignmentTableDropdownOption[col.key]"
                    (ngModelChange)="onDropdownValueChanged(rowIndex, col.key)"
                  >
                  </app-dropdown-editable>
                </td>

                <td *ngIf="col.key === 'dateAssigned'">
                  <p-calendar
                    appDefaultCalendarDateFormat
                    appendTo="body"
                    [readonlyInput]="true"
                    id="dateAssigned"
                    [placeholder]="(getCellValue(rowIndex, col.key) | appDefaultDateFormat) || ''"
                    [formControl]="getTableFormControlBy(rowIndex, col.key)"
                  ></p-calendar>
                </td>

                <td class="px-1 py-1" *ngIf="col.key === 'note'">
                  <input
                    #note
                    [pTooltip]="note.value"
                    [formControlName]="col.key"
                    [tooltipDisabled]="note.value.length < noteMaxLengthBeforeTooltip"
                    class="p-inputtext p-component p-element"
                    type="text"
                  />
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td [colSpan]="11" class="empty-message">
              {{ 'storage.table.empty-cells' | translate }}
            </td>
          </tr>
        </ng-template></p-table
      >
    </div>
  </form>
</div>

<app-search-assignment-popup
  [isVisible]="isSearchAssignmentPopupVisible"
  (cancelClicked)="closeSearchAssignmentPopup()"
  (selectClicked)="buildTableData($event)"
></app-search-assignment-popup>

<div *ngIf="spinnerShow" class="card spinner">
  <p-progressSpinner></p-progressSpinner>
</div>
