import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PASS_ERROR_HEADER, SGC_API } from '../../core/api.consts';
import { Role } from '../../core/models/role.model';
import { User } from '../../features/admin-settings/user-table/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url = `${SGC_API}/Users`;

  constructor(private httpClient: HttpClient) {}

  getCurrentUser(): Observable<User> {
    return this.httpClient.get<User>(`${this.url}/currentUser`, {
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }

  getUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(this.url);
  }

  createUser(user: User): Observable<null> {
    return this.httpClient.post<null>(this.url, user);
  }

  editUser(id: string, user: User): Observable<null> {
    return this.httpClient.put<null>(`${this.url}/${id}`, user);
  }

  getRoles(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(`${this.url}/roles`);
  }
}
