import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { catchError, debounceTime, EMPTY, finalize, Subject, switchMap } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { CheckboxChangeEvent } from 'src/app/core/models/checkbox-change-event.model';

import { ContractInformationComponent } from '../../../common/contract-information/contract-information.component';
import { OperatorComponent } from '../../../common/operator/operator.component';
import { ProjectInformationComponent } from '../../../common/project-information/project-information.component';
import { ServiceProviderComponent } from '../../../common/service-provider/service-provider.component';
import { WellInformationComponent } from '../../../common/well-information/well-information.component';
import { DeliveryTemplate } from '../../../core/models/delivery-template.model';
import { DeliveryTemplateTableRow } from '../../../core/models/delivery-template-table-row.model';
import { PageEvent } from '../../../core/models/page-event.model';
import { PaginatorOptions } from '../../../core/models/paginator-options.model';
import { Destroyable } from '../../../core/utils/mixins/destroyable.mixin';
import { DeliveryService } from '../../../services/api/delivery.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-well-management',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    TranslateModule,
    ReactiveFormsModule,
    ButtonModule,
    ConfirmPopupModule,
    DialogModule,
    SharedModule,
    TableModule,
    PaginatorModule,
    AccordionModule,
    ContractInformationComponent,
    OperatorComponent,
    ProjectInformationComponent,
    ServiceProviderComponent,
    WellInformationComponent,
    ProgressSpinnerModule,
    CheckboxModule,
  ],
  templateUrl: './well-management.component.html',
  styleUrls: ['./well-management.component.scss'],
  providers: [ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WellManagementComponent extends Destroyable(Object) implements OnInit {
  readonly tableHeaderKeysPrefix = 'adminSettings.wellManagement.tableHeaders.';

  paginatorOptions: PaginatorOptions = {
    page: 0,
    pageSize: 10,
    count: 0,
  };

  deliveryTemplateFormGroup!: FormGroup;
  searchFormControl = new FormControl<string>('');
  isCreatePopupVisible = false;
  spinnerShow = false;
  deliveryTemplates: DeliveryTemplateTableRow[] = [];
  projectInformationList = ['projectName', 'uwiUpiCreatedInEpis'];
  wellInformationList = [
    'basin',
    'wellName',
    'wellType',
    'latitude',
    'longitude',
    'north',
    'east',
    'origin',
    'uwiUpiCreatedInEpis',
  ];

  private loadSubject = new Subject<void>();

  constructor(
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private deliveryService: DeliveryService,
    private toastService: ToastService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initDeliveryTemplateForm();
    this.setupSearchControl();
    this.setupDeliveryTemplatesLoading();
    this.loadDeliveryTemplates();
    this.initControlToggel();
  }

  onSave(): void {
    if (this.deliveryTemplateFormGroup.invalid) {
      this.deliveryTemplateFormGroup.updateValueAndValidity();
      this.deliveryTemplateFormGroup.markAllAsTouched();
      this.validateAllFormFields(this.deliveryTemplateFormGroup);
      this.toastService.toastError(this.getFirstMessage());
    } else {
      this.showSpinner();
      const formValue = this.deliveryTemplateFormGroup.getRawValue();

      const payload: DeliveryTemplate = {
        isTemplate: true,
        contractNumber: formValue.contractInformation.contractNumber,
        contractYear: formValue.contractInformation.contractYear
          ? formValue.contractInformation.contractYear.getFullYear()
          : null,
        uwi: formValue.wellInformation.uwi,
        wellName: formValue.wellInformation.wellName,
        wellTypeId: formValue.wellInformation.wellType,
        basinId: formValue.wellInformation.basin,
        latitude: formValue.wellInformation.latitude,
        longitude: formValue.wellInformation.longitude,
        north: formValue.wellInformation.north,
        east: formValue.wellInformation.east,
        origin: formValue.wellInformation.origin,
        uwiUpiCreatedInEpis:
          formValue.wellInformation.uwiUpiCreatedInEpis ??
          formValue.projectInformation.uwiUpiCreatedInEpis,
        upi: formValue.projectInformation.upi,
        projectName: formValue.projectInformation.projectName,
        operatorId: formValue.operator.companyName,
        operatorContactName: formValue.operator.contactName,
        operatorContactNumber: formValue.operator.contactNumber,
        operatorEmail: formValue.operator.email,
        serviceProviderId: formValue.serviceProvider.companyName,
        serviceProviderContactName: formValue.serviceProvider.contactName,
        serviceProviderContactNumber: formValue.serviceProvider.contactNumber,
        serviceProviderEmail: formValue.serviceProvider.email,
      };

      this.deliveryService
        .createDeliveryTemplate(payload)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: () => {
            this.toastService.toastSuccess(
              this.translate.instant('adminSettings.wellManagement.messages.successfullyCreated', {
                deliveryTemplateContractNumber: payload.contractNumber,
              }),
            );
            this.resetDeliveryTemplateForm();
            this.hideModal();
            this.loadDeliveryTemplates();
          },
          error: () => {
            this.hideSpinner();
          },
        });
    }
  }

  showModal(): void {
    this.isCreatePopupVisible = true;
  }

  onCancelModal(): void {
    this.resetDeliveryTemplateForm();
    this.hideModal();
  }

  confirmDelete(event: Event, deliveryTemplate: DeliveryTemplateTableRow): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('confirmation.message'),
      acceptButtonStyleClass: 'btn-secondary',
      rejectButtonStyleClass: 'btn-primary',
      acceptLabel: this.translate.instant('confirmation.confirm_btn'),
      rejectLabel: this.translate.instant('confirmation.cancel_btn'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDelete(deliveryTemplate);
      },
    });
  }

  confirmCheckboxChanges(
    event: CheckboxChangeEvent,
    deliveryTemplate: DeliveryTemplateTableRow,
  ): void {
    const targetElement = (event.originalEvent.target as Element).closest(
      '.checkbox-container',
    ) as EventTarget;

    this.confirmationService.confirm({
      target: targetElement,
      message: this.translate.instant('confirmation.checkboxMessage'),
      acceptButtonStyleClass: 'btn-secondary',
      rejectButtonStyleClass: 'btn-primary',
      acceptLabel: this.translate.instant('confirmation.confirm_btn'),
      rejectLabel: this.translate.instant('confirmation.cancel_btn'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deliveryTemplate.uwiUpiCreatedInEpis = event.checked;
        this.onEpisStateChanges(deliveryTemplate);
      },
      reject: () => {
        deliveryTemplate.uwiUpiCreatedInEpis = !event.checked;
      },
    });
  }

  onPageChanged(pageEvent: PageEvent): void {
    this.paginatorOptions = {
      ...this.paginatorOptions,
      page: pageEvent.page,
      pageSize: pageEvent.rows,
    };

    this.loadDeliveryTemplates();
  }

  private onEpisStateChanges(deliveryTemplate: DeliveryTemplateTableRow): void {
    this.deliveryService
      .updateDeliveryTemplateEpis(deliveryTemplate)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.toastService.toastSuccess(
            this.translate.instant(
              'adminSettings.wellManagement.messages.episStateChangedSuccessfully',
              {
                deliveryTemplateContractNumber: deliveryTemplate.contractNumber,
              },
            ),
          );
          this.loadDeliveryTemplates();
        },
        error: () => {
          this.hideSpinner();
        },
      });
  }

  private setupSearchControl(): void {
    this.searchFormControl.valueChanges
      .pipe(debounceTime(450), this.takeUntilDestroyed())
      .subscribe(() => {
        this.loadDeliveryTemplates();
      });
  }

  private setupDeliveryTemplatesLoading(): void {
    this.loadSubject
      .asObservable()
      .pipe(
        switchMap(() => {
          this.showSpinner();

          return this.deliveryService
            .getDeliveryTemplatesBy(
              this.paginatorOptions.page + 1,
              this.paginatorOptions.pageSize,
              this.searchFormControl.value!,
            )
            .pipe(
              finalize(() => {
                this.hideSpinner();
              }),
              catchError(() => EMPTY),
            );
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe((resp) => {
        this.deliveryTemplates = resp.data;
        this.paginatorOptions.count = resp.count;
        this.cd.markForCheck();
      });
  }

  private loadDeliveryTemplates(): void {
    this.loadSubject.next();
  }

  private onDelete(deliveryTemplate: DeliveryTemplateTableRow): void {
    this.showSpinner();
    this.deliveryService
      .deleteDelivery(deliveryTemplate.id)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.toastService.toastSuccess(
            this.translate.instant('adminSettings.wellManagement.messages.successfullyDeleted', {
              deliveryTemplateContractNumber: deliveryTemplate.contractNumber,
            }),
          );
          this.loadDeliveryTemplates();
        },
        error: () => {
          this.hideSpinner();
        },
      });
  }

  private initDeliveryTemplateForm(): void {
    this.deliveryTemplateFormGroup = this.fb.group(
      {
        contractInformation: this.fb.group({
          contractNumber: [null, [Validators.required]],
          contractYear: [null],
        }),
        operator: this.fb.group({
          companyName: [null],
          contactName: [{ value: null, disabled: true }],
          contactNumber: [{ value: null, disabled: true }],
          email: [{ value: null, disabled: true }],
        }),
        serviceProvider: this.fb.group({
          companyName: [null],
          contactName: [{ value: null, disabled: true }],
          contactNumber: [{ value: null, disabled: true }],
          email: [{ value: null, disabled: true }],
        }),
        wellInformation: this.fb.group({
          basin: [{ value: null, disabled: true }],
          wellName: [{ value: null, disabled: true }],
          wellType: [{ value: null, disabled: true }],
          uwi: [null],
          latitude: [{ value: null, disabled: true }],
          longitude: [{ value: null, disabled: true }],
          north: [{ value: null, disabled: true }],
          east: [{ value: null, disabled: true }],
          origin: [{ value: null, disabled: true }],
          uwiUpiCreatedInEpis: [{ value: null, disabled: true }],
        }),
        projectInformation: this.fb.group({
          upi: [null],
          projectName: [{ value: null, disabled: true }],
          uwiUpiCreatedInEpis: [{ value: null, disabled: true }],
        }),
      },
      { validators: this.atLeastOneFieldRequiredValidator() } as AbstractControlOptions,
    );
  }

  private initControlToggel(): void {
    this.deliveryTemplateFormGroup
      .get('wellInformation.uwi')
      ?.valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe((_v) => {
        this.checkWellinformationControl();
      });

    this.deliveryTemplateFormGroup
      .get('projectInformation.upi')
      ?.valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe((_v) => {
        this.checkProjectInformationControl();
      });
  }

  private checkWellinformationControl(): void {
    const wellInformationGroup = this.deliveryTemplateFormGroup.get('wellInformation') as FormGroup;

    const uwiValue = this.deliveryTemplateFormGroup.get('wellInformation.uwi')?.value;

    if (uwiValue) {
      this.enableControls(this.wellInformationList, wellInformationGroup);
    } else {
      this.disableControls(this.wellInformationList, wellInformationGroup);
    }
  }

  private checkProjectInformationControl(): void {
    const projectInformationGroup = this.deliveryTemplateFormGroup.get(
      'projectInformation',
    ) as FormGroup;

    const upiValue = this.deliveryTemplateFormGroup.get('projectInformation.upi')?.value;

    if (upiValue) {
      this.enableControls(this.projectInformationList, projectInformationGroup);
    } else {
      this.disableControls(this.projectInformationList, projectInformationGroup);
    }
  }

  private enableControls(controlList: string[], group: FormGroup): void {
    controlList.forEach((controlName) => {
      group.get(controlName)?.enable();
    });
  }

  private disableControls(controlList: string[], group: FormGroup): void {
    controlList.forEach((controlName) => {
      group.get(controlName)?.disable();
    });
  }

  private atLeastOneFieldRequiredValidator(): ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      const wellInformation = formGroup.get('wellInformation') as FormGroup;
      const projectInformation = formGroup.get('projectInformation') as FormGroup;

      const wellInformationValid =
        wellInformation.get('uwi')?.value !== '' && wellInformation.get('uwi')?.value !== null;
      const projectInformationValid =
        projectInformation.get('upi')?.value !== '' &&
        projectInformation.get('upi')?.value !== null;

      if (!wellInformationValid && !projectInformationValid) {
        return { atLeastOneFieldRequired: true };
      }

      return null;
    };
  }

  private resetDeliveryTemplateForm(): void {
    this.deliveryTemplateFormGroup.reset(undefined, { emitEvent: false });
  }

  private hideModal(): void {
    this.isCreatePopupVisible = false;
    this.checkWellinformationControl();
    this.checkProjectInformationControl();
  }

  private getFirstMessage(): string {
    const array: ValidationErrors[] | null = [];
    const controlWithError = this.getErrorControl(this.deliveryTemplateFormGroup, array).shift();
    const controlErrors = controlWithError?.[1].errors;

    if (controlErrors?.['required']) {
      return `${this.translate.instant(
        `reception.message.error.${controlWithError?.[0]}`,
      )} ${this.translate.instant('errorMessage.shouldNotBeEmpty')}.`;
    }

    if (this.deliveryTemplateFormGroup.errors?.['atLeastOneFieldRequired']) {
      return `${this.translate.instant('errorMessage.atLeastOneOfTheFieldsUwiOrUpiIsRequired')}`;
    }

    if (controlErrors?.['atLeastOneFieldRequired']) {
      return `${this.translate.instant(
        `reception.message.error.${controlWithError?.[0]}`,
      )} ${this.translate.instant('errorMessage.shouldNotBeEmpty')}.`;
    }

    if (controlErrors?.['maxlength']) {
      return `${this.translate.instant(
        `reception.message.error.${controlWithError?.[0]}`,
      )} ${this.translate.instant('errorMessage.shouldHaveValidLength')}.`;
    }

    return `${this.translate.instant('errorMessage.unhandledValidationError')}`;
  }

  private getErrorControl(formGroup: FormGroup, array: ValidationErrors[]): ValidationErrors[] {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl && control.errors) {
        array.push([field, control]);
      } else if (control instanceof FormGroup) {
        this.getErrorControl(control, array);
      }
    });
    return array;
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private showSpinner(): void {
    this.spinnerShow = true;
    this.cd.markForCheck();
  }

  private hideSpinner(): void {
    this.spinnerShow = false;
    this.cd.markForCheck();
  }
}
