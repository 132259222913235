import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appControlDisabled]',
  standalone: true,
})
export class ControlDisabledDirective {
  @Input() set appControlDisabled(isDisabled: boolean) {
    setTimeout(() => {
      if (isDisabled) {
        this.ngControl.control?.disable({ emitEvent: false });
      } else {
        this.ngControl.control?.enable({ emitEvent: false });
      }
    });
  }

  constructor(private ngControl: NgControl) {}
}
