import { Verification } from '../../features/verification/models/verification-sample-information.model';
import { PrintDeliveryTable } from './print-delivery-table.model';

export class PrintDelivery {
  org_name: string;
  uuid: string;
  barcode_id: string;
  company_name: string;
  records: PrintDeliveryTable[];
  wellname: string;
  project_name: string;
  basin: string;
  email: string;
  phone: string;
  responsible_person_name: string;
  contract_number: string;
  date_of_receipt: string;
  latitude: string;
  longitude: string;
  coordinate_n: string;
  coordinate_e: string;
  origin: string;
  depth_unit: string;
  note: string;

  constructor(
    contractNumber: string,
    barcodeId: string,
    basin: string,
    operatorProvider: string,
    serviceProvider: string,
    e?: Verification,
  ) {
    this.org_name = 'SGC';
    this.barcode_id = barcodeId;
    this.uuid = '';
    this.company_name = (operatorProvider || serviceProvider) ?? '';
    this.records = [];
    this.wellname = e?.wellInformation.wellName ?? '';
    this.project_name = e?.projectInformation.projectName ?? '';
    this.basin = basin ?? '';
    this.email = '';
    this.phone = '';
    this.responsible_person_name = '';
    this.contract_number = e?.contractInformation.contractNumber ?? '';
    this.date_of_receipt = '';
    this.latitude = e?.wellInformation.latitude ? e?.wellInformation.latitude!.toString() : '';
    this.longitude = e?.wellInformation.longitude ? e?.wellInformation.longitude!.toString() : '';
    this.coordinate_n = e?.wellInformation.north ? e?.wellInformation.north!.toString() : '';
    this.coordinate_e = e?.wellInformation.east ? e?.wellInformation.east!.toString() : '';
    this.origin = e?.wellInformation.origin ?? '';
    this.depth_unit = '';
    this.note = e?.additionalInformation.notes ?? '';
  }
}
