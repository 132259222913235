import { Injectable } from '@angular/core';

import { DEFAULT_LOCALE } from '../core/consts';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private currentLocale = DEFAULT_LOCALE;

  getDefaultLocale(): string {
    return this.currentLocale;
  }

  setLocale(locale: string): void {
    this.currentLocale = locale;
  }
}
