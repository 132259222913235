import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { UserManagementService } from '../../services/user-management.service';

@Injectable({
  providedIn: 'root',
})
export class AdminOrTechnicianGuard implements CanActivate {
  constructor(private userManagementService: UserManagementService, private router: Router) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!(this.userManagementService.isAdmin || this.userManagementService.isTechnician)) {
      this.router.navigate(['self-service']);

      return false;
    }

    return true;
  }
}
