<div class="header">
  <h1 class="title">{{ 'reception.titles.reception' | translate }}</h1>
  <div class="actions">
    <button
      pButton
      type="button"
      class="btn btn-primary"
      label="{{ 'button.clear' | translate }}"
      (click)="resetForm(true)"
    ></button>
    <p-confirmPopup></p-confirmPopup>
    <button
      pButton
      type="button"
      class="btn btn-secondary"
      [disabled]="!this.selectedDeliveryId"
      (click)="submitForm($event)"
      label="{{ 'button.save' | translate }}"
    ></button>
  </div>
</div>
<div class="content">
  <p-accordion [multiple]="true">
    <div class="row input-group-50 mb-3">
      <span class="p-input-icon-left p-float-label">
        <i class="pi pi-search"></i>
        <input
          class="search-input"
          id="float-input"
          type="text"
          pInputText
          autocomplete="off"
          [readonly]="true"
          [formGroup]="receptionSearchFormGroup"
          (click)="openPopup()"
        />
        <label for="float-input">{{ 'button.search' | translate }}</label>
      </span>
    </div>
    <div *ngIf="spinnerShow || spinnerShowForSearch" class="card spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <form
      [formGroup]="receptionFormGroup"
      *ngIf="receptionFormGroup && selectedDeliveryId"
      class="h-full"
    >
      <app-contract-information [parentGroup]="receptionFormGroup"></app-contract-information>

      <app-operator [required]="false" [parentGroup]="receptionFormGroup"></app-operator>

      <app-service-provider
        [required]="false"
        [parentGroup]="receptionFormGroup"
      ></app-service-provider>

      <app-well-information
        [required]="false"
        [parentGroup]="receptionFormGroup"
      ></app-well-information>

      <app-project-information [parentGroup]="receptionFormGroup"></app-project-information>

      <p-accordionTab
        header="{{ 'reception.titles.receivingTechnicianInformation' | translate }}"
        formGroupName="operator"
        [selected]="isExpanded"
      >
        <app-technical-information-form
          [formGroup]="receptionFormGroup"
          [options]="userInformationDropdownOption"
        ></app-technical-information-form>
      </p-accordionTab>

      <p-accordionTab
        [selected]="isExpanded"
        header="{{ 'selfServices.sampleInformation' | translate }}"
      >
        <div class="row">
          <app-self-service-to-validation-table
            #selfServiceToValidationTable
            [formGroup]="receptionFormGroup"
          ></app-self-service-to-validation-table>
        </div>
      </p-accordionTab>

      <app-transportation-information
        [parentGroup]="receptionFormGroup"
        [required]="true"
      ></app-transportation-information>

      <app-additional-information [parentGroup]="receptionFormGroup"></app-additional-information>
    </form>
  </p-accordion>
</div>

<div *ngIf="selectedDeliveryId" class="cursor-pointer w-max" (click)="printDelivery()">
  <i class="pi pi-print mb-4 mt-4" style="color: green"></i>
  <span class="text-green-800 ml-1">{{ 'button.printDeliveryInformation' | translate }}</span>
</div>

<p-dialog
  *ngIf="deliveryTableData.length"
  header="{{ 'deliveryTable.search' | translate }}"
  [closable]="true"
  [modal]="true"
  [(visible)]="visiblePopup"
  [style]="{ width: '90vw', height: '90vh' }"
>
  <app-delivery-table-search-uwi-contract
    [tableData]="deliveryTableData"
    (selectedDeliveryId)="onSelectedDeliveryId($event)"
  ></app-delivery-table-search-uwi-contract>
</p-dialog>
