<ng-container [formGroup]="formGroup">
  <div class="row input-group-50">
    <span class="p-float-label">
      <p-dropdown
        id="float-input"
        formControlName="storeToLocation"
        [options]="locations"
        optionLabel="locationLabel"
        placeholder="{{ 'storage.placeholder.select-location' | translate }}"
        [showClear]="true"
        [editable]="false"
        [readonly]="true"
        (click)="displayPopUp()"
      >
      </p-dropdown>
      <label for="float-input">{{ 'storage.table.location-label' | translate }}*</label>
      <small *ngIf="checkFormError('storeToLocation')" class="p-error p-invalid"
        >{{ 'errorMessage.fieldIsRequired' | translate }}
      </small>
    </span>
  </div>
</ng-container>

<p-dialog
  *ngIf="this.locations"
  header="Location"
  [closable]="true"
  [modal]="true"
  [(visible)]="visibleLocationPopup"
  [styleClass]="'dialog-popup'"
>
  <app-dropdown-search-popup
    #dropdownSearchPopupComponent
    [tableHeader]="'Location label'"
    [tableData]="locations"
    [showLocationOccupied]="true"
    (selectedDropDownOption)="onSelectedDropDownOption($event)"
  ></app-dropdown-search-popup>
</p-dialog>
