import { SampleType } from '../enums/sample-type.enum';

export function generateSampleLabel(
  uwi: string,
  sampleType: SampleType,
  unitNumber: string,
  depthFullValue: string,
): string {
  return `${uwi}${sampleType}${unitNumber}${depthFullValue.split(' - ')[0]}`;
}
