import { Pipe, PipeTransform } from '@angular/core';

import { logTypeDisplayName } from '../consts/log-type-display-name.const';
import { LogTypeEnum } from '../enums/log-type.enum';

@Pipe({
  name: 'appLogTypeDisplay',
  standalone: true,
})
export class LogTypeDisplayPipe implements PipeTransform {
  transform(logType: LogTypeEnum): string {
    return logTypeDisplayName[logType];
  }
}
