import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfigProvider } from './app/core/providers/app-config.provider';

fetch('assets/config/app.config.json').then(async (response) => {
  const appConfigProvider = new AppConfigProvider(await response.json());

  platformBrowserDynamic([{ provide: AppConfigProvider, useValue: appConfigProvider }])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
