<form [formGroup]="serviceFormGroup" class="h-full">
  <div class="header">
    <h2 class="title">{{ title | translate }}</h2>

    <div class="actions">
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'button.cancel' | translate }}"
        (click)="resetForm()"
        class="btn btn-primary"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'button.save' | translate }}"
        class="btn btn-secondary"
        [disabled]="!this.searchSelectedList.length"
        (click)="save()"
      ></button>
    </div>
  </div>

  <div *ngIf="showSpinner" class="card spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <div class="content">
    <div class="row input-group-50">
      <div class="mb-4 font-bold">{{ 'service.information' | translate }}</div>
      <span class="p-float-label">
        <p-calendar
          appDefaultCalendarDateFormat
          id="application-date"
          [readonlyInput]="true"
          [showIcon]="true"
          formControlName="applicationDate"
        ></p-calendar>
        <label for="application-date">{{ 'service.applicationDate' | translate }}</label>
        <small *ngIf="checkFormError('applicationDate')" class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>
    </div>
    <div class="row input-group-50">
      <span class="p-float-label">
        <p-dropdown
          class="company"
          id="company-type"
          formControlName="companyNameType"
          [options]="companyTypeDropdown"
          placeholder="{{ 'service.selectType' | translate }}"
          optionLabel="name"
          optionValue="name"
        ></p-dropdown>
        <label for="company-type">{{ 'service.providerType' | translate }}*</label>
      </span>
      <span class="p-float-label">
        <p-dropdown
          class="company"
          id="company-provider"
          formControlName="providerCompanyName"
          [options]="formProvidersNamesDropdown"
          placeholder="{{ 'service.selectProvider' | translate }}"
          optionLabel="companyName"
          optionValue="companyName"
        ></p-dropdown>
        <label for="company-provider">{{ 'service.companyName' | translate }}*</label>
      </span>
    </div>
    <div class="row mt-2">
      <span class="p-float-label">
        <textarea
          id="description-input"
          rows="3"
          [autoResize]="true"
          formControlName="description"
          pInputTextarea
        ></textarea>
        <label for="description-input">{{ 'service.description' | translate }}</label>
      </span>
    </div>

    <div class="row mt-4" [formGroup]="serviceSchedulingFormGroup">
      <p class="text-lg font-bold mb-3">{{ 'service.serviceScheduling' | translate }}</p>
      <p-table
        [columns]="[serviceTableLabel]"
        [value]="getSampleTableFormControl().controls"
        [rows]="5"
        [totalRecords]="getSampleTableFormControl().controls.length"
        [paginator]="true"
        [resizableColumns]="true"
        styleClass="p-datatable-gridlines basicTable"
        [scrollable]="true"
        scrollHeight="500px"
        scrollDirection="vertical"
      >
        <ng-template let-columns pTemplate="header">
          <tr>
            <ng-container
              *ngFor="let header of serviceTableLabel | keyvalue : orderOriginal; let i = index"
            >
              <th class="white-space-nowrap">
                {{ header.value | translate }}
              </th>
            </ng-container>
            <th></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <ng-container [formArrayName]="'table'">
            <tr [formGroupName]="rowIndex">
              <ng-container *ngFor="let col of columns[0] | keyvalue : orderOriginal">
                <td
                  (keydown)="onKeyDown($event, col.key)"
                  (click)="openSearchPopup(col.key)"
                  class="px-1 py-1"
                  *ngIf="
                    col.key !== 'serviceAttachments' &&
                    !serviceDropDownoption[col.key] &&
                    !integerInput(col.key) &&
                    !dateInput(col.key)
                  "
                >
                  <input
                    [readonly]="col.key !== 'note'"
                    [formControlName]="col.key"
                    [ngClass]="{ 'note-input': col.key === 'note' }"
                    class="p-inputtext p-component p-element"
                    type="text"
                    style="width: auto"
                  />
                </td>

                <td
                  (click)="openSearchPopup(col.key)"
                  *ngIf="col.key !== 'serviceAttachments' && serviceDropDownoption[col.key]"
                >
                  <p-dropdown
                    [formControlName]="col.key"
                    [filter]="col.key === 'technicianId'"
                    [filterBy]="col.key === 'technicianId' ? 'name' : ''"
                    placeholder=" "
                    optionLabel="name"
                    [options]="serviceDropDownoption[col.key]"
                    [showClear]="false"
                    appendTo="body"
                    [editable]="false"
                    optionValue="id"
                  ></p-dropdown>
                  <small
                    *ngIf="
                      col.key === 'technicianId' &&
                      tableFormGroup.get('table')?.get(rowIndex.toString())?.get('technicianId')
                        ?.invalid &&
                      tableFormGroup.get('table')?.get(rowIndex.toString())?.get('technicianId')
                        ?.touched
                    "
                    class="p-error p-invalid"
                    >{{ 'errorMessage.fieldIsRequired' | translate }}
                  </small>
                </td>

                <td
                  (keydown)="onKeyDown($event, col.key)"
                  (click)="openSearchPopup(col.key)"
                  *ngIf="col.key !== 'serviceAttachments' && integerInput(col.key)"
                >
                  <p-inputNumber
                    [readonly]="true"
                    inputId="integeronly"
                    [formControlName]="col.key"
                    [locale]="defaultLocale"
                  >
                  </p-inputNumber>
                </td>

                <td
                  class="table_date"
                  *ngIf="col.key !== 'serviceAttachments' && dateInput(col.key)"
                >
                  <p-calendar
                    appDefaultCalendarDateFormat
                    [showButtonBar]="true"
                    [readonlyInput]="true"
                    appendTo="body"
                    placeholder=""
                    id="date"
                    [showIcon]="true"
                    [formControlName]="col.key"
                  ></p-calendar>
                </td>
                <td class="attachment-td" *ngIf="col.key === 'serviceAttachments'">
                  <div #scrollMe class="attachment-body">
                    <div class="attachment-container">
                      <div class="attachment" *ngIf="!attachments[rowIndex]?.attachmentId">
                        <label #upl for="attachment-upload">
                          <button
                            pButton
                            (click)="saveUploadIndex(rowIndex); this.upl.click()"
                            class="btn btn-secondary attachment"
                          >
                            <i class="pi pi-upload"></i>
                          </button>
                        </label>
                        <input
                          type="file"
                          (change)="onUpload($event)"
                          accept=".txt, .doc, .docx, .xls, .xlsx, .pdf, .csv, .ppt, .pptx,
                         .png, .jpeg, .bmp, .gif, .jpg, .zip"
                          id="attachment-upload"
                        />
                      </div>
                      <div class="file-name" *ngIf="attachments[rowIndex]?.attachmentId">
                        <div
                          class="text-overflow-ellipsis"
                          title="{{ attachments[rowIndex].fileName }}"
                        >
                          {{ attachments[rowIndex].fileName }}
                        </div>
                        <i class="pi pi-times" (click)="deleteAttachment(rowIndex)"></i>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <td *ngIf="this.searchSelectedList[rowIndex]" (click)="deleteRow(rowIndex)">
                <div class="text-center"><i class="pi pi-trash"></i></div>
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
    </div>
  </div>
</form>

<app-search-service
  [depthUnitOption]="serviceDropDownoption['depthUnitId']"
  [visible]="visible"
  (dataEvent)="onServiceDataReceived($event)"
  (cancelClick)="onCancelClick($event)"
></app-search-service>
