import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { combineLatest } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DropDownOption } from '../../core/models/drop-down-option.model';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { WellInfoService } from '../../services/api/well-info.service';
import { LocaleService } from '../../services/locale.service';
import { TranslationComponent } from '../translation/translation.component';

@Component({
  selector: 'app-well-information',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationComponent,
    TranslateModule,
    DropdownModule,
    InputSwitchModule,
    InputNumberModule,
    InputMaskModule,
    CheckboxModule,
  ],
  templateUrl: './well-information.component.html',
  styleUrls: ['./well-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WellInformationComponent extends Destroyable(Object) implements OnInit {
  @Input() parentGroup!: FormGroup;
  @Input() isExpanded = true;
  @Input() required = true;

  control = new FormControl();
  wellType!: DropDownOption[];
  basinType!: DropDownOption[];

  get defaultLocale(): string {
    return this.localeService.getDefaultLocale();
  }

  constructor(
    private localeService: LocaleService,
    private cd: ChangeDetectorRef,
    private wellInfoService: WellInfoService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initList();
    this.setUpControl();
  }

  onFieldInput(): void {
    this.parentGroup.updateValueAndValidity();
    this.parentGroup.markAllAsTouched();
  }

  checkFormError(controlPath: string): boolean | undefined {
    return this.parentGroup.get(controlPath)?.invalid && this.parentGroup.get(controlPath)?.dirty;
  }

  private initList(): void {
    combineLatest([this.wellInfoService.getWellTypes(), this.wellInfoService.getBasinTypes()])
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        [this.wellType, this.basinType] = data;
        this.cd.markForCheck();
      });
  }

  private setUpControl(): void {
    const wellInformationFormGroup = this.parentGroup.get('wellInformation') as FormGroup;
    wellInformationFormGroup.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
  }
}
