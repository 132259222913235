import { Pipe, PipeTransform } from '@angular/core';

import { SEPARATOR_BETWEEN_PALLET_LABEL_AND_CONTAINER_COUNT } from '../../../core/consts';
import { PalletStorageModel } from '../models/pallet-search.model';

@Pipe({
  name: 'appFormatPalletLabel',
  standalone: true,
})
export class FormatPalletLabelPipe implements PipeTransform {
  transform(palletLabel: string, pallets: PalletStorageModel[]): string {
    const pallet = pallets.find((p) => p.originalPalletLabel === palletLabel)!;

    return `${palletLabel}${SEPARATOR_BETWEEN_PALLET_LABEL_AND_CONTAINER_COUNT}${pallet.containerCount}`;
  }
}
