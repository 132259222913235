<div class="header">
  <div class="title">{{ title }}</div>
</div>

<p-table
  [value]="entities"
  [paginator]="true"
  [rows]="5"
  [showCurrentPageReport]="true"
  [tableStyle]="{ 'min-width': '250px' }"
  currentPageReportTemplate=""
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 75%">{{ 'adminSettings.titles.table.name' | translate }}</th>
      <th style="width: 25%"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-entity>
    <tr>
      <td>{{ entity.name }}</td>
      <td>
        <p-confirmPopup></p-confirmPopup>
        <button
          pButton
          type="button"
          class="delete-btn btn"
          icon="pi pi-trash"
          (click)="confirm($event, entity)"
        ></button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <p-button
      type="button"
      icon="pi pi-plus"
      (click)="showDialog()"
      styleClass="btn-secondary"
    ></p-button>

    <p-dialog
      header="Header"
      [(visible)]="isAddModalVisible"
      [modal]="true"
      [style]="{ width: 'auto' }"
      [draggable]="false"
      [resizable]="false"
      [closable]="false"
    >
      <ng-template pTemplate="header">
        <span class="text-xl font-bold"
          >{{ 'adminSettings.titles.actions.new' | translate }} {{ title }}</span
        >
      </ng-template>
      <input
        type="text"
        pInputText
        [(ngModel)]="newEntityName"
        [maxlength]="title === 'Volume unit' ? 32 : null"
      />
      <ng-template pTemplate="footer">
        <p-button (click)="onCancelModal()" label="Cancel" styleClass="btn-primary"></p-button>
        <p-button
          [disabled]="!newEntityName.length"
          (onClick)="onSave()"
          label="Save"
          styleClass="btn-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>
  </ng-template>
</p-table>
