import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { combineLatest } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { DropdownEditableComponent } from 'src/app/common/dropdown-editable/dropdown-editable.component';
import { sortAlphabetically } from 'src/app/core/utils/alpahabetical-order.util';
import { ProjectOptionsService } from 'src/app/services/api/project-options.service';
import { ToastService } from 'src/app/services/toast.service';

import { AdditionalInformationComponent } from '../../common/additional-information/additional-information.component';
import { ContractInformationComponent } from '../../common/contract-information/contract-information.component';
import { DeliveryTableSearchUwiContractComponent } from '../../common/delivery-table-search-uwi-contract/delivery-table-search-uwi-contract.component';
import { ShortDelivery } from '../../common/delivery-table-search-uwi-contract/models/short-delivery.model';
import { DropdownSearchPopupComponent } from '../../common/dropdown-search-popup/dropdown-search-popup.component';
import { OperatorComponent } from '../../common/operator/operator.component';
import { ProjectInformationComponent } from '../../common/project-information/project-information.component';
import { SelfServiceToValidationTableComponent } from '../../common/self-service-to-validation-table/self-service-to-validation-table.component';
import { ServiceProviderComponent } from '../../common/service-provider/service-provider.component';
import { TechnicalInformationFormComponent } from '../../common/technical-information-form/technical-information-form.component';
import { TransportationInformationComponent } from '../../common/transportation-information/transportation-information.component';
import { WellInformationComponent } from '../../common/well-information/well-information.component';
import { DeliveryFull } from '../../core/models/delivery-full.model';
import { DropDownOption } from '../../core/models/drop-down-option.model';
import { ProviderType } from '../../core/models/provider.model';
import { SAMPLE_INFO_TABLE_DROPDOWN_OPTION } from '../../core/table-consts';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { DeliveryService } from '../../services/api/delivery.service';
import { PackagingTypeService } from '../../services/api/packaging-type.service';
import { PrintDeliveryDocService } from '../../services/api/print-delivery-doc.service';
import { ProviderService } from '../../services/api/provider.service';
import { UserService } from '../../services/api/user.service';
import { WellInfoService } from '../../services/api/well-info.service';
import { SelfServiceToValidationTableValuesMapperService } from '../../services/self-service-to-validation-table-values-mapper.service';
import { User } from '../admin-settings/user-table/user.model';
import { WellSearchFormComponent } from '../storage/forms/well-search-form/well-search-form.component';
import { StorageService } from '../storage/services/storage.service';
import { ReceptionShape } from './models/reception.model';
import { ReceptionShapeImpl } from './models/reception-shape.model';

@Component({
  selector: 'app-reception',
  standalone: true,
  providers: [ConfirmationService],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    ContractInformationComponent,
    OperatorComponent,
    ServiceProviderComponent,
    WellInformationComponent,
    ProjectInformationComponent,
    WellSearchFormComponent,
    TransportationInformationComponent,
    AdditionalInformationComponent,
    TabViewModule,
    TableModule,
    CheckboxModule,
    DropdownEditableComponent,
    MenuModule,
    TechnicalInformationFormComponent,
    InputNumberModule,
    ProgressSpinnerModule,
    CalendarModule,
    SelfServiceToValidationTableComponent,
    InputTextModule,
    DialogModule,
    DeliveryTableSearchUwiContractComponent,
    DropdownSearchPopupComponent,
    ConfirmPopupModule,
  ],
  templateUrl: './reception.component.html',
  styleUrls: ['./reception.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceptionComponent extends Destroyable(Object) implements OnInit {
  @ViewChild(SelfServiceToValidationTableComponent)
  selfServiceToValidationTable!: SelfServiceToValidationTableComponent;

  @ViewChild(DeliveryTableSearchUwiContractComponent)
  deliveryTableSearchUwiContractComponent!: DeliveryTableSearchUwiContractComponent;

  receptionFormGroup!: FormGroup;
  receptionSearchFormGroup!: FormGroup;
  sampleInformationFormGroup!: FormGroup;
  successMessage!: string;
  isExpanded = true;
  selectedDeliveryId = '';
  spinnerShow = false;
  spinnerShowForSearch = false;
  contractId!: string;
  barcodeId!: string;

  barQrCodeOptions = [
    {
      label: 'Print QR code',
      qrCode: true,
    },
    {
      label: 'Print barcode',
      qrCode: false,
    },
  ];

  sampleInfoTableDropdownOption = SAMPLE_INFO_TABLE_DROPDOWN_OPTION;
  userInformationDropdownOption: (User & { fullName?: string })[] = [];

  visiblePopup = false;
  deliveryTableData: ShortDelivery[] = [];

  wellType!: DropDownOption[];
  basinType!: DropDownOption[];
  operator!: DropDownOption[];
  serviceProvider!: DropDownOption[];

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private translate: TranslateService,
    private userService: UserService,
    private deliveryService: DeliveryService,
    private projectOptionsService: ProjectOptionsService,
    private selfServiceToValidationTableValuesMapperService: SelfServiceToValidationTableValuesMapperService,
    private storageService: StorageService,
    private providerService: ProviderService,
    private wellInfoService: WellInfoService,
    private printDeliveryDocService: PrintDeliveryDocService,
    private packagingTypeService: PackagingTypeService,
    private confirmationService: ConfirmationService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initList();
    this.wellInformation();
    this.receptionSearchFormGroup = this.fb.group({ search: [''] });
    this.initForm();
    this.getSuccessMessage();
    this.technicianInformationUpdate(this.receptionFormGroup, 'technicalInformation');
  }

  submitForm(event: Event): void {
    if (
      this.receptionFormGroup.valid &&
      this.selfServiceToValidationTable.mainTableValidationStatus.isValid
    ) {
      if (this.selfServiceToValidationTable.bulkUploadCompleted) this.deleteConfirm(event);
      else this.saveDeliveries();
    } else {
      this.handleInvalidForm();
    }
  }

  private handleInvalidForm(): void {
    if (!this.selfServiceToValidationTable.mainTableValidationStatus.isValid) {
      this.toastService.toastError(
        `${this.getTranslationText('errorMessage.bottomDepthGreaterOrEqualTopDepth')} (${
          this.selfServiceToValidationTable.mainTableValidationStatus.fieldName
        })`,
      );
    } else if (!this.selfServiceToValidationTable.childTableValidationStatus.isValid) {
      this.toastService.toastError(
        `${this.getTranslationText('errorMessage.childBottomDepthGreaterOrEqualTopDepth')} (${
          this.selfServiceToValidationTable.childTableValidationStatus.fieldName
        })`,
      );
    } else {
      this.receptionFormGroup.updateValueAndValidity();
      this.receptionFormGroup.markAllAsTouched();
      this.validateAllFormFields(this.receptionFormGroup);
      this.toastService.toastError(this.getFirstMessage());
    }
  }

  private deleteConfirm(event: Event): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.getTranslationText('confirmation.bulkUpload_box_confirm'),
      acceptButtonStyleClass: 'btn-secondary',
      rejectButtonStyleClass: 'btn-primary',
      acceptLabel: this.getTranslationText('confirmation.confirm_btn'),
      rejectLabel: this.getTranslationText('confirmation.cancel_btn'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteBoxesAndSamples();
      },
    });
  }

  private deleteBoxesAndSamples(): void {
    this.spinnerShow = true;
    this.deliveryService
      .deleteBoxesAndSamples(this.selectedDeliveryId)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_) => {
        this.saveDeliveries();
      });
  }

  private saveDeliveries(): void {
    const receptionData = new ReceptionShapeImpl(
      this.selectedDeliveryId,
      this.contractId,
      this.userInformationDropdownOption,
      this.selfServiceToValidationTable.totalNumber,
      this.receptionFormGroup.getRawValue(),
      this.selfServiceToValidationTable.removeEmptyRowsFromAllTabs(),
    ) as unknown as DeliveryFull;
    this.spinnerShow = true;

    this.deliveryService
      .updateDelivery(receptionData, 'reception')
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (shortDelivery) => {
          this.toastService.toastSuccess(this.successMessage);
          this.deliveryTableSearchUwiContractComponent.updateRow(shortDelivery);
          this.resetForm(true);
          this.spinnerShow = false;
          this.selfServiceToValidationTable.bulkUploadCompleted = true;
          this.cd.detectChanges();
        },
        error: () => {
          this.spinnerShow = false;
          this.cd.detectChanges();
        },
        complete: () => {
          this.spinnerShow = false;
          this.cd.detectChanges();
        },
      });
  }

  onSelectedDeliveryId(shortDelivery: ShortDelivery): void {
    this.visiblePopup = false;
    this.selectedDeliveryId = shortDelivery.deliveryId;
    this.barcodeId = shortDelivery.barcodeId;
    this.applySearch(this.selectedDeliveryId);
  }

  openPopup(): void {
    this.visiblePopup = true;
  }

  printDelivery(): void {
    this.spinnerShow = true;

    const data = this.receptionFormGroup.getRawValue();
    const basin = this.basinType.find((obj) => obj.id === data.wellInformation.basin)?.name;
    const operatorProvider = this.operator.find(
      (obj) => obj.id === data.operator.companyName,
    )?.name;
    const serviceProvider = this.serviceProvider.find(
      (obj) => obj.id === data.serviceProvider.companyName,
    )?.name;

    const tableData = this.selfServiceToValidationTable.removeEmptyRowsFromAllTabs(true);

    this.printDeliveryDocService.downloadDoc(
      this.contractId,
      this.barcodeId,
      basin!,
      operatorProvider!,
      serviceProvider!,
      data,
      tableData,
    );

    this.spinnerShow = false;
  }

  private getTableData(tableData: DeliveryFull): void {
    this.contractId = tableData.contractId!;
    this.selfServiceToValidationTable.updateTableData(tableData);
  }

  private applySearch(deliveryId: string): void {
    this.spinnerShowForSearch = true;
    this.deliveryService
      .getDeliveryById(deliveryId)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (data) => {
          this.resetForm();
          this.getTableData(data);
          this.resetFormValue(data);

          this.cd.detectChanges();
          this.spinnerShowForSearch = false;
        },
        error: () => {
          this.spinnerShowForSearch = false;
          this.cd.detectChanges();
        },
        complete: () => {
          this.spinnerShowForSearch = false;
          this.cd.detectChanges();
        },
      });
  }

  private initList(): void {
    this.spinnerShow = true;
    combineLatest([
      this.projectOptionsService.getZhZsDatObjectiveSets(),
      this.projectOptionsService.getBoxTypes(),
      this.packagingTypeService.getAllPackagingTypes(),
      this.packagingTypeService.getAllPackagingTypes(),
      this.projectOptionsService.getDepthUnits(),
      this.projectOptionsService.getLengthUnits(),
      this.projectOptionsService.getWeightUnits(),
      this.projectOptionsService.getSectionTypes(),
      this.projectOptionsService.getTechnicalInfoses(),
      this.projectOptionsService.getMediumDeliveryInfos(),
      this.projectOptionsService.getFluidTypes(),
      this.projectOptionsService.getEndCupFractionInfos(),
      this.projectOptionsService.getExtractionPositionInfos(),
      this.selfServiceToValidationTableValuesMapperService.getMappedZhZsDatSampleTypes(),
      this.projectOptionsService.getSamplingMethods(),
      this.projectOptionsService.getSampleOrigins(),
      this.storageService.getAllContainers(),
      this.storageService.getAllPallets(),
      this.storageService.getAllLocations(),
      this.userService.getUsers(),
      this.deliveryService.getAllShortDeliveries(),
    ])
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        [
          this.sampleInfoTableDropdownOption['objectiveSetId'],
          this.sampleInfoTableDropdownOption['boxTypeId'],
          this.sampleInfoTableDropdownOption['packagingTypeId'],
          this.sampleInfoTableDropdownOption['typeOfPackaging'],
          this.sampleInfoTableDropdownOption['depthUnitId'],
          this.sampleInfoTableDropdownOption['lengthUnitId'],
          this.sampleInfoTableDropdownOption['weightUnitId'],
          this.sampleInfoTableDropdownOption['sectionTypeId'],
          this.sampleInfoTableDropdownOption['technicalInfoId'],
          this.sampleInfoTableDropdownOption['mediumDeliveryInfoId'],
          this.sampleInfoTableDropdownOption['fluidTypeId'],
          this.sampleInfoTableDropdownOption['endCupFractionId'],
          this.sampleInfoTableDropdownOption['extractionPositionId'],
          this.sampleInfoTableDropdownOption['sampleTypeId'],
          this.sampleInfoTableDropdownOption['samplingMethodId'],
          this.sampleInfoTableDropdownOption['sampleOriginId'],
          this.sampleInfoTableDropdownOption['containerId'] as any[],
          this.sampleInfoTableDropdownOption['palletId'] as any[],
          this.sampleInfoTableDropdownOption['locationId'] as any[],
          this.userInformationDropdownOption,
          this.deliveryTableData,
        ] = data;

        this.sampleInfoTableDropdownOption['locationId'] = this.sampleInfoTableDropdownOption[
          'locationId'
        ].map((d) => {
          return { ...d, name: d.locationLabel };
        });
        this.sampleInfoTableDropdownOption['palletId'] = this.sampleInfoTableDropdownOption[
          'palletId'
        ].map((d) => {
          return { ...d, name: d.palletLabel };
        });
        this.sampleInfoTableDropdownOption['containerId'] = this.sampleInfoTableDropdownOption[
          'containerId'
        ].map((d) => {
          return { ...d, name: d.containerLabel };
        });
        this.userInformationDropdownOption = this.userInformationDropdownOption
          .filter((u) => u.firstName && u.lastName)
          .map((d) => {
            d.fullName = `${d.firstName} ${d.lastName}`;
            return d;
          });

        this.providerService.getProviders(ProviderType.operator).subscribe((operator) => {
          this.operator = operator.map((value) => {
            return {
              id: value.id!,
              name: value.companyName,
            };
          });
        });
        this.providerService.getProviders(ProviderType.serviceProvider).subscribe((service) => {
          this.serviceProvider = service.map((value) => {
            return {
              id: value.id!,
              name: value.companyName,
            };
          });
        });
        this.sampleInfoTableDropdownOption['typeOfPackaging'] = sortAlphabetically(
          this.sampleInfoTableDropdownOption['typeOfPackaging'],
        );

        this.spinnerShow = false;
        this.cd.markForCheck();
      });
  }

  private technicianInformationUpdate(formGroup: FormGroup, controlName: string): void {
    const technicianInformation = formGroup.controls[controlName];
    technicianInformation
      .get('name')!
      .valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe((id) => {
        const selectedUser = this.userInformationDropdownOption.filter((user) => user.id === id)[0];
        technicianInformation.get('email')?.setValue(selectedUser?.email);
        technicianInformation.get('phoneNumber')?.setValue(selectedUser?.contactNumber);
      });
  }

  private wellInformation(): void {
    combineLatest([this.wellInfoService.getWellTypes(), this.wellInfoService.getBasinTypes()])
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        [this.wellType, this.basinType] = data;
        this.cd.markForCheck();
      });
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private getErrorControl(formGroup: FormGroup, array: ValidationErrors[]): ValidationErrors[] {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl && control.errors) {
        array.push([field, control]);
      } else if (control instanceof FormGroup) {
        this.getErrorControl(control, array);
      }
    });
    return array;
  }

  protected resetForm(buttonReset = false): void {
    this.receptionFormGroup.markAllAsTouched();
    this.receptionFormGroup.reset(undefined, { emitEvent: true });
    this.selfServiceToValidationTable.resetTable();
    if (buttonReset) this.selectedDeliveryId = '';
    this.receptionFormGroup.markAsUntouched();
    this.receptionFormGroup.markAsPristine();
  }

  private initForm(formData?: ReceptionShape): void {
    this.receptionFormGroup = this.fb.group(
      {
        contractInformation: this.fb.group({
          contractNumber: [
            { value: formData?.contractNumber, disabled: true },
            [Validators.required],
          ],
          contractYear: [{ value: String(formData?.yearOfContract), disabled: true }],
        }),
        operator: this.fb.group({
          companyName: [{ value: formData?.operatorId, disabled: true }],
          contactName: [{ value: formData?.['operatorContactName'], disabled: true }],
          contactNumber: [{ value: formData?.['operatorContactNumber'], disabled: true }],
          email: [{ value: formData?.['operatorEmail'], disabled: true }],
        }),
        serviceProvider: this.fb.group({
          companyName: [{ value: formData?.serviceProviderId, disabled: true }],
          contactName: [{ value: formData?.['serviceProviderContactName'], disabled: true }],
          contactNumber: [{ value: formData?.['serviceProviderContactNumber'], disabled: true }],
          email: [{ value: formData?.['serviceProviderEmail'], disabled: true }],
        }),
        wellInformation: this.fb.group({
          basin: [{ value: formData?.wellInformation.basinId, disabled: true }],
          id: [{ value: formData?.wellInformation.id, disabled: true }],
          wellName: [{ value: formData?.wellInformation.wellName, disabled: true }],
          wellType: [{ value: formData?.wellInformation.wellTypeId, disabled: true }],
          uwi: [{ value: formData?.wellInformation.uwi, disabled: true }],
          latitude: [{ value: formData?.wellInformation.latitude, disabled: true }],
          longitude: [{ value: formData?.wellInformation.longitude, disabled: true }],
          north: [{ value: formData?.wellInformation.north, disabled: true }],
          east: [{ value: formData?.wellInformation.east, disabled: true }],
          origin: [{ value: formData?.wellInformation.origin, disabled: true }],
          uwiUpiCreatedInEpis: [
            { value: formData?.wellInformation.uwiUpiCreatedInEpis, disabled: true },
          ],
        }),
        projectInformation: this.fb.group({
          upi: [{ value: formData?.upi, disabled: true }],
          projectName: [{ value: formData?.projectName, disabled: true }],
          uwiUpiCreatedInEpis: [{ value: formData?.uwiUpiCreatedInEpis, disabled: true }],
        }),
        technicalInformation: this.fb.group({
          name: [formData?.receivingTechnicianId, [Validators.required]],
        }),
        transportationInformation: this.fb.group({
          companyName: [formData?.transportationProviderId, [Validators.required]],
        }),
        additionalInformation: this.fb.group({
          notes: [formData?.notes, [Validators.maxLength(512)]],
        }),
      },
      { validators: this.atLeastOneFieldRequiredValidator() },
    );
  }

  resetFormValue(formData: DeliveryFull): void {
    this.receptionFormGroup.setControl('sampleInformation', this.sampleInformationFormGroup);
    this.receptionFormGroup.patchValue({
      contractInformation: {
        contractNumber: formData?.contractNumber,
        contractYear: formData?.yearOfContract ? formData?.yearOfContract.toString() : null,
      },
      operator: {
        companyName: formData?.operatorId,
        contactName: formData?.operatorContactName,
        contactNumber: formData?.operatorContactNumber,
        email: formData?.operatorEmail,
      },
      serviceProvider: {
        companyName: formData?.serviceProviderId,
        contactName: formData?.serviceProviderContactName,
        contactNumber: formData?.serviceProviderContactNumber,
        email: formData?.serviceProviderEmail,
      },
      wellInformation: {
        id: formData?.wellInformation?.id ?? null,
        basin: formData?.wellInformation?.basinId ?? null,
        wellName: formData?.wellInformation?.wellName ?? null,
        wellType: formData?.wellInformation?.wellTypeId ?? null,
        uwi: formData?.wellInformation?.uwi ?? null,
        latitude: formData?.wellInformation?.latitude ?? null,
        longitude: formData?.wellInformation?.longitude ?? null,
        north: formData?.wellInformation?.north ?? null,
        east: formData?.wellInformation?.east ?? null,
        origin: formData?.wellInformation?.origin ?? '',
        uwiUpiCreatedInEpis: formData?.wellInformation?.uwiUpiCreatedInEpis ?? null,
      },
      projectInformation: {
        upi: formData?.upi ?? null,
        projectName: formData?.projectName ?? null,
        uwiUpiCreatedInEpis: formData?.uwiUpiCreatedInEpis ?? null,
      },
      technicalInformation: {
        name: this.getTechnicalInformation(formData?.receivingTechnicianId),
      },
      transportationInformation: {
        companyName: formData?.transportationProviderId,
      },
      additionalInformation: {
        notes: formData?.notes ? decodeURIComponent(formData?.notes) : null,
      },
    });
  }

  private atLeastOneFieldRequiredValidator(): ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      const wellInformation = formGroup.get('wellInformation') as FormGroup;
      const projectInformation = formGroup.get('projectInformation') as FormGroup;

      const wellInformationValid =
        wellInformation.get('uwi')?.value !== '' && wellInformation.get('uwi')?.value !== null;
      const projectInformationValid =
        projectInformation.get('upi')?.value !== '' &&
        projectInformation.get('upi')?.value !== null;

      if (!wellInformationValid && !projectInformationValid) {
        return { atLeastOneFieldRequired: true };
      }

      return null;
    };
  }

  private getTechnicalInformation(data: string | undefined): string | null {
    let technicianId = null;

    if (data) {
      const isValueInArray = this.checkValueInArray(this.userInformationDropdownOption, data);

      if (isValueInArray) {
        technicianId = data;
      }
    }

    return technicianId;
  }

  private checkValueInArray(array: any[], value: string): boolean {
    return array.some((item) => item.id === value);
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }

  private getSuccessMessage(): void {
    this.translate
      .stream('reception.message.success')
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        this.successMessage = msg;
      });
  }

  private getFirstMessage(): string {
    const array: ValidationErrors[] | null = [];
    const controlWithError = this.getErrorControl(this.receptionFormGroup, array).shift();
    const controlErrors = controlWithError?.[1].errors;

    if (controlErrors?.['required']) {
      return `${this.getTranslationText(
        `reception.message.error.${controlWithError?.[0]}`,
      )} ${this.getTranslationText('errorMessage.shouldNotBeEmpty')}.`;
    }

    if (this.receptionFormGroup.errors?.['atLeastOneFieldRequired']) {
      return `${this.getTranslationText('errorMessage.atLeastOneOfTheFieldsUwiOrUpiIsRequired')}`;
    }

    if (controlErrors?.['maxlength']) {
      return `${this.getTranslationText(
        `reception.message.error.${controlWithError?.[0]}`,
      )} ${this.getTranslationText('errorMessage.shouldHaveValidLength')}.`;
    }

    if (controlErrors?.['pattern']) {
      return `${this.getTranslationText(
        `reception.message.error.${controlWithError?.[0]}`,
      )} ${this.getTranslationText('errorMessage.shouldBeInTheCorrectEmailFormat')}.`;
    }
    return `${this.getTranslationText('errorMessage.unhandledValidationError')}`;
  }
}
