import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabViewModule } from 'primeng/tabview';
import { combineLatest, finalize } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { LocationModel } from 'src/app/core/models/location.model';

import { SEPARATOR_BETWEEN_PALLET_LABEL_AND_CONTAINER_COUNT } from '../../core/consts';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { CacheService } from '../../services/cache.service';
import { ToastService } from '../../services/toast.service';
import { PALLET_OPTION, SAMPLE_OPTION } from './consts/storage-filter.const';
import { DepalletizeTabComponent } from './depalletize-tab/depalletize-tab.component';
import { Localize } from './models/localize.model';
import { PalletStorageModel } from './models/pallet-search.model';
import { Palletize } from './models/palletize.model';
import { SampleModel } from './models/sample.model';
import { PalletizeTabComponent } from './palletize-tab/palletize-tab.component';
import { SearchTabComponent } from './search-tab/search-tab.component';
import { StorageService } from './services/storage.service';
import { TableRowService } from './services/table-row.service';
import { StoreTabComponent } from './store-tab/store-tab.component';

@Component({
  selector: 'app-storage',
  standalone: true,
  imports: [
    CommonModule,
    TabViewModule,
    ButtonModule,
    DepalletizeTabComponent,
    PalletizeTabComponent,
    SearchTabComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    StoreTabComponent,
    ProgressSpinnerModule,
  ],
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageComponent extends Destroyable(Object) implements OnInit {
  readonly amountOfFirstTabsRelatedToSamples = 2;

  storageFormGroup!: FormGroup;
  activeTabIndex!: number;
  activeFormGroup!: FormGroup;
  technicalInformation!: FormGroup;
  activeTabBtnLabel = 'Save';
  locations!: LocationModel[];
  pallets!: PalletStorageModel[];
  selectedSamples: SampleModel[] = [];
  samples: SampleModel[] = [];
  searchedPallets: PalletStorageModel[] = [];
  selectedPallets: PalletStorageModel[] = [];
  spinnerShow = false;
  sampleOption = SAMPLE_OPTION;
  palletOption = PALLET_OPTION;

  @ViewChild('searchTabComponent') searchTabComponent!: SearchTabComponent;
  @ViewChild('palletizeTabComponent') palletizeTabComponent!: PalletizeTabComponent;

  get searchFormGroup(): FormGroup {
    return this.storageFormGroup.get('searchFormGroup') as FormGroup;
  }

  get palletizeFormGroup(): FormGroup {
    return this.storageFormGroup.get('palletizeFormGroup') as FormGroup;
  }

  get depalletizeFormGroup(): FormGroup {
    return this.storageFormGroup.get('depalletizeFormGroup') as FormGroup;
  }

  get storeFormGroup(): FormGroup {
    return this.storageFormGroup.get('storeFormGroup') as FormGroup;
  }

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private translate: TranslateService,
    private storageService: StorageService,
    private cacheService: CacheService,
    private tableRowService: TableRowService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getOptions();
    this.initMainForm();
    this.getActiveTabIndex({ index: 0 }, false);
    this.tableRowService.childEventListener().subscribe((data) => {
      if (this.isSampleModel(data)) {
        this.selectedSamples = data as SampleModel[];
      } else {
        this.selectedPallets = (data as PalletStorageModel[]).map((pallet) => {
          return {
            ...pallet,
            palletLabel: pallet!['palletLabel'].split(
              SEPARATOR_BETWEEN_PALLET_LABEL_AND_CONTAINER_COUNT,
            )[0],
          };
        });
      }
    });
  }

  initMainForm(): void {
    this.storageFormGroup = this.fb.group({
      searchFormGroup: this.fb.group({
        search: [''],
        filter: [this.sampleOption[0].id],
      }),
      palletizeFormGroup: this.fb.group({
        search: [''],
        palletCodeSearch: ['', Validators.required],
        filter: [this.sampleOption[0].id],
      }),
      depalletizeFormGroup: this.fb.group({
        search: [''],
        filter: [this.palletOption[0].id],
      }),
      storeFormGroup: this.fb.group({
        search: [''],
        storeToLocation: ['', Validators.required],
        filter: [this.palletOption[0].id],
      }),
    });
  }

  submitForm(): void {
    let sentData: Palletize | Localize;

    if (
      this.activeFormGroup.valid &&
      (this.selectedPallets.length > 0 || this.selectedSamples.length > 0)
    ) {
      switch (true) {
        case this.activeTabIndex === 1:
          sentData = {
            palletCode: (
              this.activeFormGroup.get('palletCodeSearch')?.value?.palletLabel || ''
            ).split(SEPARATOR_BETWEEN_PALLET_LABEL_AND_CONTAINER_COUNT)[0],
            containerCodes: this.selectedSamples.map((sample) => sample.containerLabel),
          };

          this.storageService
            .palletize(sentData)
            .pipe(this.takeUntilDestroyed())
            .subscribe((_) => {
              this.toastService.toastSuccess(
                this.getTranslationText('storage.successMessage.palletize'),
              );
              this.samples = [];
              this.resetForm();
              this.cd.detectChanges();
            });
          break;
        case this.activeTabIndex === 2:
          sentData = {
            palletCode: this.selectedPallets[0].palletLabel,
            containerCodes: [],
          };

          this.storageService
            .depalletize(sentData)
            .pipe(this.takeUntilDestroyed())
            .subscribe((_) => {
              this.toastService.toastSuccess(
                this.getTranslationText('storage.successMessage.depalletize'),
              );
              this.searchedPallets = [];
              this.resetForm();
              this.cd.detectChanges();
            });
          break;
        case this.activeTabIndex === 3:
          sentData = {
            location: this.activeFormGroup.get('storeToLocation')?.value?.locationLabel || '',
            palletCodes: [this.selectedPallets[0].palletLabel],
          };

          this.storageService
            .localize(sentData)
            .pipe(this.takeUntilDestroyed())
            .subscribe((_) => {
              this.toastService.toastSuccess(
                this.getTranslationText('storage.successMessage.store'),
              );
              this.searchedPallets = [];
              this.resetForm();
              this.cd.detectChanges();
            });
          break;
        default:
          break;
      }
    } else {
      this.activeFormGroup.updateValueAndValidity();
      this.activeFormGroup.markAllAsTouched();
      this.validateAllFormFields(this.activeFormGroup);
      this.toastService.toastError(this.getFirstMessage());
    }
  }

  getActiveTabIndex(event: { index: number }, resetForm = true): void {
    this.activeTabIndex = event.index;

    if (this.searchTabComponent?.isSearchPerformed) {
      this.searchTabComponent.isSearchPerformed = false;
    }

    if (this.palletizeTabComponent?.isSearchPerformed) {
      this.palletizeTabComponent.isSearchPerformed = false;
    }

    switch (true) {
      case this.activeTabIndex === 0:
        this.activeFormGroup = this.searchFormGroup;
        this.samples = [];
        if (resetForm) this.resetForm();
        break;
      case this.activeTabIndex === 1:
        this.activeFormGroup = this.palletizeFormGroup;
        this.samples = [];
        this.resetForm();
        this.activeTabBtnLabel = 'storage.button.palletize';
        break;
      case this.activeTabIndex === 2:
        this.activeFormGroup = this.depalletizeFormGroup;
        this.searchedPallets = [];
        this.resetForm();
        this.activeTabBtnLabel = 'storage.button.depalletize';
        break;
      case this.activeTabIndex === 3:
        this.activeFormGroup = this.storeFormGroup;
        this.searchedPallets = [];
        this.resetForm();
        this.activeTabBtnLabel = 'storage.button.store';
        break;
      default:
        break;
    }
  }

  search(term: string): void {
    const { filter } = this.activeFormGroup.getRawValue();

    if (term && this.activeTabIndex < this.amountOfFirstTabsRelatedToSamples) {
      this.spinnerShow = true;
      this.storageService
        .searchProjects(term, filter)
        .pipe(
          finalize(() => {
            this.spinnerShow = false;
            this.cd.markForCheck();
          }),
          this.takeUntilDestroyed(),
        )
        .subscribe((data) => {
          this.samples = data;
          this.cd.detectChanges();
        });
    } else {
      this.spinnerShow = true;
      this.storageService
        .searchPallets(term, filter)
        .pipe(
          finalize(() => {
            this.spinnerShow = false;
            this.cd.markForCheck();
          }),
          this.takeUntilDestroyed(),
        )
        .subscribe((data) => {
          this.searchedPallets = data;
          this.cd.detectChanges();
        });
    }
  }

  protected resetForm(): void {
    this.activeFormGroup.reset();
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private getOptions(): void {
    combineLatest([this.storageService.getAllLocations(), this.storageService.getAllPallets()])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([locations, pallets]) => {
        this.locations = locations;
        this.pallets = pallets;
        this.cd.markForCheck();
      });
  }

  private isSampleModel(obj: Array<SampleModel | PalletStorageModel>): obj is Array<SampleModel> {
    return obj.length
      ? 'containerLabel' in obj[0]
      : this.activeTabIndex < this.amountOfFirstTabsRelatedToSamples;
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }

  private getErrorControl(formGroup: FormGroup, array: ValidationErrors[]): ValidationErrors[] {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl && control.errors) {
        array.push([field, control]);
      } else if (control instanceof FormGroup) {
        this.getErrorControl(control, array);
      }
    });
    return array;
  }

  private getFirstMessage(): string {
    const array: ValidationErrors[] | null = [];
    const controlWithError = this.getErrorControl(this.activeFormGroup, array).shift();
    const controlErrors = controlWithError?.[1].errors;

    if (controlErrors?.['required']) {
      return `${this.getTranslationText(
        `storage.message.error.${controlWithError?.[0]}`,
      )} ${this.getTranslationText('errorMessage.shouldNotBeEmpty')}.`;
    }

    if (this.activeTabIndex < 2 && !this.selectedSamples.length) {
      return `${this.getTranslationText('storage.message.error.emptySamples')}`;
    }

    if (this.activeTabIndex >= 2 && !this.selectedPallets.length) {
      return `${this.getTranslationText('storage.message.error.emptyPallets')}`;
    }

    return `${this.getTranslationText('errorMessage.unhandledValidationError')}`;
  }
}
