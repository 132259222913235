import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from '../../core/api.consts';
import { SampleInformation } from '../../core/models/delivery-full.model';

@Injectable({
  providedIn: 'root',
})
export class BulkDataUploadService {
  private url = `${SGC_API}/BulkDataUpload`;
  private bulkUploadBoxUrl = `${this.url}/box`;
  private bulkUploadSampleUrl = `${this.url}/sample`;
  private downloadTemplateBoxUrl = `${this.url}/box/template`;
  private downloadTemplateSampleUrl = `${this.url}/sample/template`;

  constructor(private httpClient: HttpClient) {}

  bulkUploadBox(file: File): Observable<SampleInformation> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', file.type);

    return this.httpClient.post<SampleInformation>(`${this.bulkUploadBoxUrl}`, formData);
  }

  bulkUploadSample(file: File): Observable<SampleInformation> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', file.type);

    return this.httpClient.post<SampleInformation>(`${this.bulkUploadSampleUrl}`, formData);
  }

  downloadTemplateBox(): Observable<ArrayBuffer> {
    return this.httpClient.get(`${this.downloadTemplateBoxUrl}`, {
      responseType: 'arraybuffer',
    });
  }

  downloadTemplateSample(): Observable<ArrayBuffer> {
    return this.httpClient.get(`${this.downloadTemplateSampleUrl}`, {
      responseType: 'arraybuffer',
    });
  }
}
