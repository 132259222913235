import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { catchError, combineLatest, debounceTime, EMPTY, finalize, Subject, switchMap } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { AdditionalInformationComponent } from 'src/app/common/additional-information/additional-information.component';
import { ContractInformationComponent } from 'src/app/common/contract-information/contract-information.component';
import { OperatorComponent } from 'src/app/common/operator/operator.component';
import { ProjectInformationComponent } from 'src/app/common/project-information/project-information.component';
import { ServiceProviderComponent } from 'src/app/common/service-provider/service-provider.component';
import { TranslationComponent } from 'src/app/common/translation/translation.component';
import { TransportationInformationComponent } from 'src/app/common/transportation-information/transportation-information.component';
import { WellInformationComponent } from 'src/app/common/well-information/well-information.component';
import { sortAlphabetically } from 'src/app/core/utils/alpahabetical-order.util';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';
import { ProjectOptionsService } from 'src/app/services/api/project-options.service';
import { ToastService } from 'src/app/services/toast.service';

import { DropdownEditableComponent } from '../../common/dropdown-editable/dropdown-editable.component';
import { SelfServiceToValidationTableComponent } from '../../common/self-service-to-validation-table/self-service-to-validation-table.component';
import { DeliveryFull } from '../../core/models/delivery-full.model';
import { DeliveryTemplateTableRow } from '../../core/models/delivery-template-table-row.model';
import { DropDownOption } from '../../core/models/drop-down-option.model';
import { PageEvent } from '../../core/models/page-event.model';
import { PaginatorOptions } from '../../core/models/paginator-options.model';
import { ProviderType } from '../../core/models/provider.model';
import { RecipientTableRow } from '../../core/models/recipient-table-row.model';
import { Responsibility } from '../../core/models/responsibility.model';
import { Role } from '../../core/models/role.model';
import { SAMPLE_INFO_TABLE_DROPDOWN_OPTION } from '../../core/table-consts';
import { DeliveryService } from '../../services/api/delivery.service';
import { PackagingTypeService } from '../../services/api/packaging-type.service';
import { PrintDeliveryDocService } from '../../services/api/print-delivery-doc.service';
import { ProviderService } from '../../services/api/provider.service';
import { ResponsibilityService } from '../../services/api/responsibility.service';
import { SelfServiceService } from '../../services/api/self-service.service';
import { UserService } from '../../services/api/user.service';
import { UserNotificationsService } from '../../services/api/user-notifications.service';
import { WellInfoService } from '../../services/api/well-info.service';
import { SelfServiceToValidationTableValuesMapperService } from '../../services/self-service-to-validation-table-values-mapper.service';
import { UserManagementService } from '../../services/user-management.service';
import { User } from '../admin-settings/user-table/user.model';
import {
  SelfServices,
  SelfServicesSampleInformationImpl,
} from './models/self-services-sample-information.model';

@Component({
  selector: 'app-self-service',
  standalone: true,
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    AccordionModule,
    InputTextModule,
    DropdownModule,
    CalendarModule,
    InputTextareaModule,
    TabViewModule,
    ButtonModule,
    TableModule,
    DialogModule,
    MessagesModule,
    MessageModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationComponent,
    TranslateModule,
    ContractInformationComponent,
    OperatorComponent,
    ServiceProviderComponent,
    WellInformationComponent,
    ProjectInformationComponent,
    TransportationInformationComponent,
    AdditionalInformationComponent,
    ConfirmDialogModule,
    DropdownEditableComponent,
    PaginatorModule,
    SelfServiceToValidationTableComponent,
    ConfirmPopupModule,
    ProgressSpinnerModule,
    RippleModule,
    MultiSelectModule,
    CheckboxModule,
    PasswordModule,
    TagModule,
    TooltipModule,
  ],
})
export class SelfServiceComponent extends Destroyable(Object) implements OnInit {
  readonly tableHeaderKeysPrefix = 'adminSettings.wellManagement.tableHeaders.';

  @ViewChild(SelfServiceToValidationTableComponent)
  selfServiceToValidationTable!: SelfServiceToValidationTableComponent;

  title = 'selfServices.selfService';
  notDisplayField = ['minCps', 'maxCps', 'containerId', 'locationId', 'palletId'];
  visiblePopup = false;
  visibleRequestWellPopup = false;
  visibleRequestWellRecipientsPopup = false;
  spinnerShow = false;
  deliveryTemplateSearchSpinner = false;
  sendWellRequestSpinner = false;
  paginatorOptions: PaginatorOptions = {
    page: 0,
    pageSize: 10,
    count: 0,
  };

  searchRecipientsFormControl = new FormControl<string>('');
  selectedRecipients: RecipientTableRow[] = [];
  wellRequestFormGroup!: FormGroup<{
    title: FormControl<string>;
    recipientIds: FormControl<string[]>;
    message: FormControl<string>;
  }>;

  selectedRow: DeliveryTemplateTableRow | null = null;
  selectedDeliveryId = '';
  searchFormControl = new FormControl<string>('');
  isExpanded = true;
  selfServiceData!: SelfServices;
  selfServicesFormGroup!: FormGroup;
  barcodeId!: string;

  sampleInfoTableDropdownOption = SAMPLE_INFO_TABLE_DROPDOWN_OPTION;
  deliveryTemplates: DeliveryTemplateTableRow[] = [];
  basinType!: DropDownOption[];
  operator!: DropDownOption[];
  serviceProvider!: DropDownOption[];
  recipients: RecipientTableRow[] = [];
  filteredRecipients: RecipientTableRow[] = [];

  private loadSubject = new Subject<void>();

  get isSendWellRequestBtnDisabled(): boolean {
    const wellRequestFormGroupValue = this.wellRequestFormGroup.getRawValue();

    return (
      !wellRequestFormGroupValue.title ||
      !wellRequestFormGroupValue.recipientIds.length ||
      !wellRequestFormGroupValue.message
    );
  }

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private translate: TranslateService,
    private selfServices: SelfServiceService,
    private projectOptionsServices: ProjectOptionsService,
    private selfServiceToValidationTableValuesMapperService: SelfServiceToValidationTableValuesMapperService,
    private providerService: ProviderService,
    private wellInfoService: WellInfoService,
    private packagingTypeService: PackagingTypeService,
    private printDeliveryDocService: PrintDeliveryDocService,
    private userNotificationsService: UserNotificationsService,
    private userService: UserService,
    private responsibilityService: ResponsibilityService,
    private deliveryService: DeliveryService,
    private userManagementService: UserManagementService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initList();
    this.initForm(this.selfServiceData);
    this.initWellRequestForm();
    this.setupSearchControl();
    this.setupSearchRecipientControl();
    this.setupDeliveryTemplatesLoading();
  }

  clearTemplatesIfNothingSelected(): void {
    if (!this.selectedDeliveryId) {
      this.deliveryTemplates = [];
    }
  }

  saveAndPrint(): void {
    this.save(true);
  }

  save(print = false): void {
    if (this.selfServicesFormGroup.invalid) {
      this.selfServicesFormGroup.updateValueAndValidity();
      this.selfServicesFormGroup.markAllAsTouched();
      this.validateAllFormFields(this.selfServicesFormGroup);
      this.toastService.toastError(this.getFirstMessage());
    } else if (!this.selfServiceToValidationTable.mainTableValidationStatus.isValid) {
      this.toastService.toastError(
        `${this.getTranslationText('errorMessage.bottomDepthGreaterOrEqualTopDepth')} (${
          this.selfServiceToValidationTable.mainTableValidationStatus.fieldName
        })`,
      );
    } else if (!this.selfServiceToValidationTable.childTableValidationStatus.isValid) {
      this.toastService.toastError(
        `${this.getTranslationText('errorMessage.childBottomDepthGreaterOrEqualTopDepth')} (${
          this.selfServiceToValidationTable.childTableValidationStatus.fieldName
        })`,
      );
    } else {
      const selfServiceFormData = new SelfServicesSampleInformationImpl(
        this.selfServiceToValidationTable.totalNumber,
        this.selfServicesFormGroup.getRawValue(),
        this.selfServiceToValidationTable.removeEmptyRowsFromAllTabs(),
      );
      this.spinnerShow = true;
      this.selfServices
        .create(selfServiceFormData)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: (result) => {
            this.toastService.toastSuccess(this.getTranslationText('selfServices.message.success'));
            if (print) {
              this.barcodeId = result.barcodeId || '';
              this.printDelivery();
            }

            this.resetForm(true);
            this.spinnerShow = false;
            this.cd.detectChanges();
          },
          error: (d: any) => {
            this.spinnerShow = false;
            this.cd.detectChanges();
            if (d.error.message) {
              this.toastService.toastError(d.error.message);
            }
          },
          complete: () => {
            this.spinnerShow = false;
            this.cd.detectChanges();
          },
        });
    }
  }

  onClearTemplateDeliverySearch(): void {
    if (this.searchFormControl.value || this.selectedRow) {
      this.resetTemplateDeliverySearch();
      this.loadDeliveryTemplates();
    }
  }

  onRowSelect(row: DeliveryTemplateTableRow): void {
    this.selectedRow = row;
  }

  onPageChanged(pageEvent: PageEvent): void {
    this.paginatorOptions = {
      ...this.paginatorOptions,
      page: pageEvent.page,
      pageSize: pageEvent.rows,
    };

    this.loadDeliveryTemplates();
  }

  onSelectedDeliveryId(deliveryId: string): void {
    this.visiblePopup = false;
    this.selectedDeliveryId = deliveryId;
    this.applySearch(this.selectedDeliveryId);
  }

  openPopup(): void {
    this.visiblePopup = true;

    if (!this.selectedDeliveryId) {
      this.loadDeliveryTemplates();
    }
  }

  onRequestWellBtnClicked(): void {
    this.resetWellRequestForm();
    this.visibleRequestWellPopup = true;
  }

  onClearRequestWellBtnClicked(): void {
    this.resetWellRequestForm();
    this.searchRecipientsFormControl.reset('');
  }

  onRequestWellToFieldClicked(): void {
    this.visibleRequestWellRecipientsPopup = true;
  }

  onClearRequestWellRecipientsSearchBtnClicked(): void {
    this.searchRecipientsFormControl.reset('');
  }

  onSelectRequestWellRecipientsClicked(): void {
    const recipientIds = this.selectedRecipients.map((recipient) => recipient.id);
    this.wellRequestFormGroup.get('recipientIds')!.setValue(recipientIds);
    this.visibleRequestWellRecipientsPopup = false;
  }

  onSendRequestWellClicked(): void {
    this.showSendWellRequestSpinner();
    this.userNotificationsService
      .postNotification(this.wellRequestFormGroup.getRawValue())
      .pipe(
        finalize(() => {
          this.hideSendWellRequestSpinner();
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.toastService.toastSuccess(
          this.translate.instant('selfServices.requestWell.wellRequestedNotification'),
        );
        this.visibleRequestWellPopup = false;
      });
  }

  protected resetForm(resetSelectedTemplate: boolean): void {
    this.selfServicesFormGroup.markAllAsTouched();
    this.selfServicesFormGroup.reset(undefined, { emitEvent: false });
    this.selfServiceToValidationTable.resetTable();
    this.selfServicesFormGroup.markAsUntouched();
    this.selfServicesFormGroup.markAsPristine();

    if (resetSelectedTemplate) {
      this.resetTemplateDeliverySearch();
      this.selectedDeliveryId = '';
    }
  }

  private initWellRequestForm(): void {
    this.wellRequestFormGroup = new UntypedFormGroup({
      title: new FormControl<string>(
        this.translate.instant('selfServices.requestWell.defaultRequestWellTitleValue'),
      ),
      recipientIds: new FormControl<string[]>([]),
      message: new FormControl<string>(''),
    });
  }

  private resetWellRequestForm(): void {
    this.wellRequestFormGroup.reset({
      title: this.translate.instant('selfServices.requestWell.defaultRequestWellTitleValue'),
      recipientIds: [],
      message: '',
    });
  }

  private applySearch(deliveryId: string): void {
    this.showSpinner();
    this.deliveryService
      .getDeliveryById(deliveryId)
      .pipe(
        finalize(() => {
          this.hideSpinner();
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe({
        next: (templateDelivery) => {
          this.resetForm(false);
          this.fillTemplateDeliveryData(templateDelivery);
        },
      });
  }

  private resetTemplateDeliverySearch(): void {
    this.searchFormControl.setValue('', { emitEvent: false });
    this.paginatorOptions = {
      page: 0,
      pageSize: 10,
      count: 0,
    };
    this.selectedRow = null;
    this.deliveryTemplates = [];
  }

  private fillTemplateDeliveryData(templateDelivery: DeliveryFull): void {
    this.selfServicesFormGroup.patchValue({
      contractInformation: {
        contractNumber: templateDelivery.contractNumber,
        contractYear: templateDelivery.yearOfContract
          ? templateDelivery.yearOfContract.toString()
          : null,
      },
      operator: {
        companyName: templateDelivery.operatorId,
        contactName: templateDelivery.operatorContactName,
        contactNumber: templateDelivery.operatorContactNumber,
        email: templateDelivery.operatorEmail,
      },
      serviceProvider: {
        companyName: templateDelivery.serviceProviderId,
        contactName: templateDelivery.serviceProviderContactName,
        contactNumber: templateDelivery.serviceProviderContactNumber,
        email: templateDelivery.serviceProviderEmail,
      },
      wellInformation: {
        basin: templateDelivery.wellInformation?.basinId,
        wellName: templateDelivery.wellInformation?.wellName,
        wellType: templateDelivery.wellInformation?.wellTypeId,
        uwi: templateDelivery.wellInformation?.uwi,
        latitude: templateDelivery.wellInformation?.latitude,
        longitude: templateDelivery.wellInformation?.longitude,
        north: templateDelivery.wellInformation?.north,
        east: templateDelivery.wellInformation?.east,
        origin: templateDelivery.wellInformation?.origin,
        uwiUpiCreatedInEpis: templateDelivery.wellInformation?.uwiUpiCreatedInEpis,
      },
      projectInformation: {
        upi: templateDelivery.upi,
        projectName: templateDelivery.projectName,
        uwiUpiCreatedInEpis: templateDelivery?.uwiUpiCreatedInEpis,
      },
    });
  }

  private setupSearchControl(): void {
    this.searchFormControl.valueChanges
      .pipe(debounceTime(450), this.takeUntilDestroyed())
      .subscribe(() => {
        this.loadDeliveryTemplates();
      });
  }

  private setupSearchRecipientControl(): void {
    this.searchRecipientsFormControl.valueChanges
      .pipe(debounceTime(450), this.takeUntilDestroyed())
      .subscribe((searchValue) => {
        const lowerCasedSearchValue = (searchValue ?? '').toLowerCase();
        this.filteredRecipients = this.recipients.filter((recipient) => {
          return (
            (recipient.fullName ?? '').toLowerCase().includes(lowerCasedSearchValue) ||
            (recipient.organizationName ?? '').toLowerCase().includes(lowerCasedSearchValue) ||
            (recipient.responsibilityNames ?? '').toLowerCase().includes(lowerCasedSearchValue) ||
            (recipient.roleName.toLowerCase() ?? '').includes(lowerCasedSearchValue) ||
            (recipient.email.toLowerCase() ?? '').includes(lowerCasedSearchValue)
          );
        });
        this.selectedRecipients = [];
        this.cd.markForCheck();
      });
  }

  private setupDeliveryTemplatesLoading(): void {
    this.loadSubject
      .asObservable()
      .pipe(
        switchMap(() => {
          this.showDeliveryTemplateSearchSpinner();

          return this.deliveryService
            .getDeliveryTemplatesBy(
              this.paginatorOptions.page + 1,
              this.paginatorOptions.pageSize,
              this.searchFormControl.value!,
            )
            .pipe(
              finalize(() => {
                this.hideDeliveryTemplateSearchSpinner();
              }),
              catchError(() => EMPTY),
            );
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe((resp) => {
        this.deliveryTemplates = resp.data;
        this.paginatorOptions.count = resp.count;
        this.cd.markForCheck();
      });
  }

  private loadDeliveryTemplates(): void {
    this.loadSubject.next();
  }

  private printDelivery(): void {
    const data = this.selfServicesFormGroup.getRawValue();
    const basin = this.basinType.find((obj) => obj.id === data.wellInformation.basin)?.name;
    const operatorProvider = this.operator.find(
      (obj) => obj.id === data.operator.companyName,
    )?.name;
    const serviceProvider = this.serviceProvider.find(
      (obj) => obj.id === data.serviceProvider.companyName,
    )?.name;

    const tableData = this.selfServiceToValidationTable.removeEmptyRowsFromAllTabs(true);

    this.printDeliveryDocService.downloadDoc(
      data.contractNumber,
      this.barcodeId,
      basin!,
      operatorProvider!,
      serviceProvider!,
      data,
      tableData,
    );
  }

  private initList(): void {
    combineLatest([
      this.projectOptionsServices.getZhZsDatObjectiveSets(),
      this.projectOptionsServices.getBoxTypes(),
      this.packagingTypeService.getAllPackagingTypes(),
      this.packagingTypeService.getAllPackagingTypes(),
      this.projectOptionsServices.getDepthUnits(),
      this.projectOptionsServices.getLengthUnits(),
      this.projectOptionsServices.getWeightUnits(),
      this.projectOptionsServices.getSectionTypes(),
      this.projectOptionsServices.getTechnicalInfoses(),
      this.projectOptionsServices.getMediumDeliveryInfos(),
      this.projectOptionsServices.getFluidTypes(),
      this.projectOptionsServices.getEndCupFractionInfos(),
      this.projectOptionsServices.getExtractionPositionInfos(),
      this.selfServiceToValidationTableValuesMapperService.getMappedZhZsDatSampleTypes(),
      this.projectOptionsServices.getSamplingMethods(),
      this.projectOptionsServices.getSampleOrigins(),
      this.userService.getUsers(),
      this.userService.getRoles(),
      this.responsibilityService.getAll(),
    ])
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        let users: User[] = [];
        let roles: Role[] = [];
        let responsibilities: Responsibility[] = [];

        [
          this.sampleInfoTableDropdownOption['objectiveSetId'],
          this.sampleInfoTableDropdownOption['boxTypeId'],
          this.sampleInfoTableDropdownOption['packagingTypeId'],
          this.sampleInfoTableDropdownOption['typeOfPackaging'],
          this.sampleInfoTableDropdownOption['depthUnitId'],
          this.sampleInfoTableDropdownOption['lengthUnitId'],
          this.sampleInfoTableDropdownOption['weightUnitId'],
          this.sampleInfoTableDropdownOption['sectionTypeId'],
          this.sampleInfoTableDropdownOption['technicalInfoId'],
          this.sampleInfoTableDropdownOption['mediumDeliveryInfoId'],
          this.sampleInfoTableDropdownOption['fluidTypeId'],
          this.sampleInfoTableDropdownOption['endCupFractionId'],
          this.sampleInfoTableDropdownOption['extractionPositionId'],
          this.sampleInfoTableDropdownOption['sampleTypeId'],
          this.sampleInfoTableDropdownOption['samplingMethodId'],
          this.sampleInfoTableDropdownOption['sampleOriginId'],
          users,
          roles,
          responsibilities,
        ] = data;

        this.recipients = users
          .filter(
            (user) => user.isActive && user.id !== this.userManagementService.getUserInfo()!.id,
          )
          .map((user) => new RecipientTableRow(user, roles, responsibilities));
        this.filteredRecipients = [...this.recipients];
        this.sampleInfoTableDropdownOption['typeOfPackaging'] = sortAlphabetically(
          this.sampleInfoTableDropdownOption['typeOfPackaging'],
        );

        this.cd.markForCheck();
      });

    this.wellInfoService
      .getBasinTypes()
      .pipe(this.takeUntilDestroyed())
      .subscribe((basinType) => {
        this.basinType = basinType;
      });
    this.providerService.getProviders(ProviderType.operator).subscribe((operator) => {
      this.operator = operator.map((value) => {
        return {
          id: value.id!,
          name: value.companyName,
        };
      });
    });
    this.providerService.getProviders(ProviderType.serviceProvider).subscribe((service) => {
      this.serviceProvider = service.map((value) => {
        return {
          id: value.id!,
          name: value.companyName,
        };
      });
    });
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private getErrorControl(formGroup: FormGroup, array: ValidationErrors[]): ValidationErrors[] {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl && control.errors) {
        array.push([field, control]);
      } else if (control instanceof FormGroup) {
        this.getErrorControl(control, array);
      }
    });
    return array;
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }

  private initForm(data: SelfServices) {
    this.selfServicesFormGroup = this.fb.group(
      {
        contractInformation: this.fb.group({
          contractNumber: [
            { value: data?.contractInformation.contractNumber, disabled: true },
            [Validators.required],
          ],
          contractYear: [{ value: data?.contractInformation.contractYear, disabled: true }],
        }),
        operator: this.fb.group({
          companyName: [{ value: data?.operator.companyName, disabled: true }],
          contactName: [{ value: data?.operator.contactName, disabled: true }],
          contactNumber: [{ value: data?.operator.contactNumber, disabled: true }],
          email: [{ value: data?.operator.email, disabled: true }],
        }),
        serviceProvider: this.fb.group({
          companyName: [{ value: data?.serviceProvider.companyName, disabled: true }],
          contactName: [{ value: data?.serviceProvider.contactName, disabled: true }],
          contactNumber: [{ value: data?.serviceProvider.contactNumber, disabled: true }],
          email: [{ value: data?.serviceProvider.email, disabled: true }],
        }),
        wellInformation: this.fb.group({
          basin: [{ value: data?.wellInformation.basinId, disabled: true }],
          wellName: [{ value: data?.wellInformation.wellName, disabled: true }],
          wellType: [{ value: data?.wellInformation.wellTypeId, disabled: true }],
          uwi: [{ value: data?.wellInformation.uwi, disabled: true }],
          latitude: [{ value: data?.wellInformation.latitude, disabled: true }],
          longitude: [{ value: data?.wellInformation.longitude, disabled: true }],
          north: [{ value: data?.wellInformation.north, disabled: true }],
          east: [{ value: data?.wellInformation.east, disabled: true }],
          origin: [{ value: data?.wellInformation.origin, disabled: true }],
          uwiUpiCreatedInEpis: [
            { value: data?.wellInformation.uwiUpiCreatedInEpis, disabled: true },
          ],
        }),
        projectInformation: this.fb.group({
          upi: [{ value: data?.projectInformation.upi, disabled: true }],
          projectName: [{ value: data?.projectInformation.projectName, disabled: true }],
          uwiUpiCreatedInEpis: [
            { value: data?.projectInformation.uwiUpiCreatedInEpis, disabled: true },
          ],
        }),
        transportationInformation: this.fb.group({
          companyName: [data?.transportationInformation.companyName, [Validators.required]],
        }),
        additionalInformation: this.fb.group({
          notes: [data?.additionalInformation.notes, [Validators.maxLength(512)]],
        }),
      },
      { validators: this.atLeastOneFieldRequiredValidator() },
    );
  }

  private atLeastOneFieldRequiredValidator(): ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      const wellInformation = formGroup.get('wellInformation') as FormGroup;
      const projectInformation = formGroup.get('projectInformation') as FormGroup;

      const wellInformationValid =
        wellInformation.get('uwi')?.value !== '' && wellInformation.get('uwi')?.value !== null;
      const projectInformationValid =
        projectInformation.get('upi')?.value !== '' &&
        projectInformation.get('upi')?.value !== null;

      if (!wellInformationValid && !projectInformationValid) {
        return { atLeastOneFieldRequired: true };
      }

      return null;
    };
  }

  private getFirstMessage(): string {
    const array: ValidationErrors[] | null = [];
    const controlWithError = this.getErrorControl(this.selfServicesFormGroup, array).shift();
    const controlErrors = controlWithError?.[1].errors;

    if (controlErrors?.['required']) {
      return `${this.getTranslationText(
        `reception.message.error.${controlWithError?.[0]}`,
      )} ${this.getTranslationText('errorMessage.shouldNotBeEmpty')}.`;
    }

    if (this.selfServicesFormGroup.errors?.['atLeastOneFieldRequired']) {
      return `${this.getTranslationText('errorMessage.atLeastOneOfTheFieldsUwiOrUpiIsRequired')}`;
    }

    if (controlErrors?.['atLeastOneFieldRequired']) {
      return `${this.getTranslationText(
        `reception.message.error.${controlWithError?.[0]}`,
      )} ${this.getTranslationText('errorMessage.shouldNotBeEmpty')}.`;
    }

    if (controlErrors?.['maxlength']) {
      return `${this.getTranslationText(
        `reception.message.error.${controlWithError?.[0]}`,
      )} ${this.getTranslationText('errorMessage.shouldHaveValidLength')}.`;
    }

    if (controlErrors?.['pattern']) {
      return `${controlWithError?.[0]} ${this.getTranslationText(
        'errorMessage.shouldBeInTheCorrectEmailFormat',
      )}.`;
    }
    return `${this.getTranslationText('errorMessage.unhandledValidationError')}`;
  }

  private showSpinner(): void {
    this.spinnerShow = true;
    this.cd.markForCheck();
  }

  private hideSpinner(): void {
    this.spinnerShow = false;
    this.cd.markForCheck();
  }

  private showDeliveryTemplateSearchSpinner(): void {
    this.deliveryTemplateSearchSpinner = true;
    this.cd.markForCheck();
  }

  private hideDeliveryTemplateSearchSpinner(): void {
    this.deliveryTemplateSearchSpinner = false;
    this.cd.markForCheck();
  }

  private showSendWellRequestSpinner(): void {
    this.sendWellRequestSpinner = true;
    this.cd.markForCheck();
  }

  private hideSendWellRequestSpinner(): void {
    this.sendWellRequestSpinner = false;
    this.cd.markForCheck();
  }
}
