import { BehaviorSubject, Observable, shareReplay, switchMap, tap } from 'rxjs';

import { Injectable } from '@angular/core';

import { EntityCacheKey } from '../core/enums/entity-cache-key.enum';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private _cache: Map<
    EntityCacheKey,
    {
      updater: BehaviorSubject<null>;
      valueObservable: Observable<any>;
    }
  > = new Map();

  getCachedObservable<T>(key: EntityCacheKey, sourceObservable: Observable<T>): Observable<T> {
    if (!this._cache.has(key)) {
      const updater = new BehaviorSubject<null>(null);

      this._cache.set(key, {
        updater,
        valueObservable: updater.asObservable().pipe(
          switchMap(() => sourceObservable),
          shareReplay(1),
        ),
      });
    }

    return this._cache.get(key)!.valueObservable as Observable<T>;
  }

  updateCacheAfterActionFor<T>(
    key: EntityCacheKey,
    sourceObservable: Observable<T>,
  ): Observable<T> {
    return sourceObservable.pipe(
      tap(() => {
        this.updateCacheFor(key);
      }),
    );
  }

  private updateCacheFor(key: EntityCacheKey): void {
    this._cache.get(key)?.updater.next(null);
  }
}
