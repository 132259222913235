import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DefaultDateFormatPipe } from '../pipes/default-date-format.pipe';
import { ShortDelivery } from './models/short-delivery.model';

@Component({
  selector: 'app-delivery-table-search-uwi-contract',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    TranslateModule,
    FormsModule,
    InputTextModule,
    CheckboxModule,
    ButtonModule,
    RippleModule,
    DefaultDateFormatPipe,
  ],
  templateUrl: './delivery-table-search-uwi-contract.component.html',
  styleUrls: ['./delivery-table-search-uwi-contract.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryTableSearchUwiContractComponent implements OnInit {
  @Input() tableData: ShortDelivery[] = [];
  @Output() selectedDeliveryId: EventEmitter<any> = new EventEmitter<any>();
  tableHeaders: string[] = [
    'deliveryTable.contractNumber',
    'deliveryTable.transmittalNumber',
    'deliveryTable.uwi',
    'deliveryTable.wellName',
    'deliveryTable.upi',
    'deliveryTable.projectName',
    'deliveryTable.sampleType',
    'deliveryTable.totalBoxes',
    'deliveryTable.totalSamples',
    'deliveryTable.companyName',
    'deliveryTable.topDepth',
    'deliveryTable.bottomDepth',
    'deliveryTable.formation',
    'deliveryTable.basin',
    'deliveryTable.notes',
    'deliveryTable.deliveryDate',
  ];

  rows: ShortDelivery[] = [];

  selectedRow: ShortDelivery | null = null;
  searchText!: string;

  private readonly fieldsToExclude = [
    'deliveryId',
    'sampleType',
    'totalBoxes',
    'totalSamples',
    'topDepth',
    'bottomDepth',
  ];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.filterTable();
    this.cd.markForCheck();
  }

  onRowSelect(row: ShortDelivery): void {
    this.selectedRow = row;
  }

  resetPage(): void {
    this.searchText = '';
    this.selectedRow = null;
    this.rows = this.tableData;
  }

  select(): void {
    this.selectedDeliveryId.emit(this.selectedRow);
  }

  filterTable(): void {
    const tableDataFiltered = this.tableData.filter((x) => !!x.uwi || !!x.upi);

    if (!this.searchText) {
      this.rows = tableDataFiltered;
    } else {
      this.rows = tableDataFiltered.filter((row) => {
        const values = Object.keys(row)
          .filter((key) => !this.fieldsToExclude.includes(key))
          .map((key) =>
            row[key as keyof ShortDelivery]
              ? row[key as keyof ShortDelivery].toString().toLowerCase()
              : '',
          );

        return values.some((value) => value && value.includes(this.searchText.toLowerCase()));
      });
    }

    this.rows.reverse();
  }

  updateRow(shortDelivery: ShortDelivery): void {
    const entryIndexToUpdate = this.tableData.findIndex(
      (entry) => entry.deliveryId === shortDelivery.deliveryId,
    )!;
    this.tableData[entryIndexToUpdate] = shortDelivery;

    if (this.selectedRow?.deliveryId === shortDelivery.deliveryId) {
      this.selectedRow = shortDelivery;
    }

    this.filterTable();
    this.cd.markForCheck();
  }
}
