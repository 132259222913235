<p-selectButton
  [options]="langOptions"
  [(ngModel)]="lang"
  (ngModelChange)="changeLang($event)"
  optionLabel="icon"
  optionValue="code"
>
  <ng-template let-item>
    <span [class]="item.icon" [pTooltip]="item.tooltip" tooltipPosition="bottom"></span>
  </ng-template>
</p-selectButton>
