import OktaAuth from '@okta/okta-auth-js';

import { environment } from '../../environments/environment';

export const oktaAuth = new OktaAuth(environment.oktaConfig);
export const oktaStubAuth = new OktaAuth({
  issuer: 'https://your-okta-issuer-url',
  clientId: 'your-okta-client-id',
  redirectUri: 'https://your-app-callback-url',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
});

export const SGC_API = '/api';

export const API_SERVER_ERROR_MESSAGE = 'Internal server error';
export const API_AUTHENTICATION_ERROR_MESSAGE = 'Wrong authentication data';
export const USER_NOT_CREATED_ERROR_MESSAGE = 'User is not created. Please, ask admin to create it';
export const API_UNKNOWN_ERROR_MESSAGE = 'Something went wrong. Please try again later';

export const X_USER_NOT_CREATED_HEADER_NAME = 'X-User-Not-Created';
export const TOKEN_EXPIRED_HEADER_NAME = 'Token-Expired';
export const PASS_ERROR_HEADER = 'PassError';
export const IS_FILE_SERVICE_HEADER = 'IsFileService';
