import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { finalize } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { PageEvent } from '../../core/models/page-event.model';
import { PaginatorOptions } from '../../core/models/paginator-options.model';
import { Responsibility } from '../../core/models/responsibility.model';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { ResponsibilityService } from '../../services/api/responsibility.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-options-table-responsibility',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    InputTextModule,
    PaginatorModule,
    SharedModule,
    TableModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  templateUrl: './options-table-responsibility.component.html',
  styleUrls: ['./options-table-responsibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsTableResponsibilityComponent extends Destroyable(Object) {
  @Input() title = '';

  isModalVisible = false;
  isSavingInProgress = false;
  isEdit = false;
  responsibilities: Responsibility[] = [];
  responsibilityId: string | undefined;
  responsibilityFormControl = new FormControl('', [Validators.required, Validators.maxLength(64)]);

  paginatorOptions: PaginatorOptions = {
    page: 0,
    pageSize: 5,
    count: 0,
  };

  get modeKey(): string {
    return this.isEdit ? 'adminSettings.titles.actions.edit' : 'adminSettings.titles.actions.new';
  }

  private get responsibility(): Responsibility {
    return { id: this.responsibilityId, name: this.responsibilityFormControl.value! };
  }

  constructor(
    private cd: ChangeDetectorRef,
    private responsibilityService: ResponsibilityService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getResponsibilities();
  }

  onCreate(): void {
    this.isEdit = false;
    this.openDialog();
  }

  onEdit(rowData: Responsibility): void {
    this.isEdit = true;
    this.responsibilityId = rowData.id;
    this.responsibilityFormControl.setValue(rowData.name);
    this.openDialog();
  }

  onCancelModal(): void {
    this.clearEntityData();
    this.closeDialog();
  }

  onSave(): void {
    this.isSavingInProgress = true;

    if (this.isEdit) {
      this.responsibilityService
        .edit(this.responsibility)
        .pipe(
          this.takeUntilDestroyed(),
          finalize(() => {
            this.isSavingInProgress = false;
            this.cd.markForCheck();
          }),
        )
        .subscribe(() => {
          this.toastService.toastSuccess(
            `${this.title} ${this.responsibilityFormControl.value!} ${this.translate.instant(
              'adminSettings.messages.hasBeenUpdated',
            )}`,
          );
          this.getResponsibilities();
          this.clearEntityData();
          this.closeDialog();
        });
    } else {
      this.responsibilityService
        .create(this.responsibility)
        .pipe(
          this.takeUntilDestroyed(),
          finalize(() => {
            this.isSavingInProgress = false;
            this.cd.markForCheck();
          }),
        )
        .subscribe(() => {
          this.toastService.toastSuccess(
            `${this.title} ${this.responsibility.name} ${this.translate.instant(
              'adminSettings.messages.hasBeenCreated',
            )}`,
          );
          this.getResponsibilities();
          this.clearEntityData();
          this.closeDialog();
        });
    }
  }

  onPageChanged(pageEvent: PageEvent): void {
    this.paginatorOptions = {
      ...this.paginatorOptions,
      page: pageEvent.page,
    };

    this.getResponsibilities();
  }

  private getResponsibilities(): void {
    this.responsibilityService
      .getBy(this.paginatorOptions.page + 1, this.paginatorOptions.pageSize)
      .pipe(this.takeUntilDestroyed())
      .subscribe((resp) => {
        this.responsibilities = resp.data;
        this.paginatorOptions.count = resp.count;
        this.cd.markForCheck();
      });
  }

  private clearEntityData(): void {
    this.responsibilityId = undefined;
    this.responsibilityFormControl.setValue('');
  }

  private openDialog(): void {
    this.isModalVisible = true;
  }

  private closeDialog(): void {
    this.isModalVisible = false;
  }
}
