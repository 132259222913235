<form [formGroup]="storageFormGroup" *ngIf="storageFormGroup" class="h-full">
  <div class="header">
    <h1 class="title">{{ 'storage.titles.storage' | translate }}</h1>
    <div class="actions" *ngIf="activeTabIndex !== 0">
      <button
        pButton
        type="button"
        class="btn btn-primary"
        label="{{ 'button.clear' | translate }}"
        (click)="resetForm()"
      ></button>
      <button
        pButton
        type="button"
        class="btn btn-secondary"
        (click)="submitForm()"
        label="{{ activeTabBtnLabel | translate }}"
      ></button>
    </div>
  </div>
  <div class="content">
    <p-tabView
      class="tab-header"
      styleClass="tabview-custom"
      (onChange)="getActiveTabIndex($event)"
    >
      <p-tabPanel header="{{ 'storage.titles.search' | translate }}">
        <app-search-tab
          #searchTabComponent
          [formGroup]="searchFormGroup"
          [samples]="samples"
          [pallets]="pallets"
          [filterOption]="sampleOption"
          (searchEvent)="search($event)"
        ></app-search-tab
      ></p-tabPanel>
      <p-tabPanel header="{{ 'storage.titles.palletize' | translate }}"
        ><app-palletize-tab
          #palletizeTabComponent
          [formGroup]="palletizeFormGroup"
          [samples]="samples"
          [pallets]="pallets"
          [filterOption]="sampleOption"
          (searchEvent)="search($event)"
        ></app-palletize-tab
      ></p-tabPanel>
      <p-tabPanel header="{{ 'storage.titles.depalletize' | translate }}">
        <app-depalletize-tab
          [formGroup]="depalletizeFormGroup"
          [pallets]="searchedPallets"
          [allPallets]="pallets"
          [locations]="locations"
          [filterOption]="palletOption"
          (searchEvent)="search($event)"
        ></app-depalletize-tab>
      </p-tabPanel>
      <p-tabPanel header="{{ 'storage.titles.store' | translate }}">
        <app-store-tab
          [formGroup]="storeFormGroup"
          [pallets]="searchedPallets"
          [allPallets]="pallets"
          [locations]="locations"
          [filterOption]="palletOption"
          (searchEvent)="search($event)"
        ></app-store-tab>
      </p-tabPanel>
    </p-tabView>
  </div>
</form>

<div *ngIf="spinnerShow" class="card spinner">
  <p-progressSpinner></p-progressSpinner>
</div>
