import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from '../../core/api.consts';
import { SelfServicesShape } from '../../features/self-service/models/self-services-sample-information.model';

@Injectable({
  providedIn: 'root',
})
export class SelfServiceService {
  private url = `${SGC_API}/SelfService`;

  constructor(private httpClient: HttpClient) {}

  create(selfServiceFormData: SelfServicesShape): Observable<SelfServicesShape> {
    return this.httpClient.post<SelfServicesShape>(this.url, selfServiceFormData, {
      headers: { PassError: '' },
    });
  }
}
