<ng-container [formGroup]="parentGroup">
  <p-accordionTab
    header="{{ 'selfServices.wellInformation' | translate }}"
    formGroupName="wellInformation"
    [selected]="isExpanded"
  >
    <div class="row input-group-50">
      <span class="p-float-label">
        <input
          maxlength="256"
          (input)="onFieldInput()"
          [ngClass]="{
            'ng-invalid':
              parentGroup.get('wellInformation.uwi')!.touched &&
              parentGroup.getError('atLeastOneFieldRequired')
          }"
          id="uwi-input"
          type="text"
          pInputText
          formControlName="uwi"
        />
        <label for="uwi-input">{{ 'selfServices.uwi' | translate }}</label>
      </span>

      <span class="p-float-label">
        <input
          maxlength="256"
          id="wellName-input"
          type="text"
          pInputText
          formControlName="wellName"
        />
        <label for="wellName-input"
          >{{ 'selfServices.wellName' | translate }}<span *ngIf="required">*</span></label
        >
        <small *ngIf="checkFormError('wellInformation.wellName')" class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>
    </div>

    <div class="row input-group-50">
      <span class="p-float-label">
        <p-dropdown
          id="well-type-dropdown"
          [filter]="true"
          filterBy="name"
          formControlName="wellType"
          [options]="wellType"
          optionValue="id"
          placeholder="Select a WellType"
          optionLabel="name"
          [showClear]="true"
          [styleClass]="'w-full'"
        ></p-dropdown>
        <label for="well-type-dropdown">{{ 'selfServices.wellType' | translate }}</label>
        <small *ngIf="checkFormError('wellInformation.wellType')" class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>

      <span class="p-float-label">
        <p-dropdown
          id="basin-dropdown"
          [filter]="true"
          filterBy="name"
          formControlName="basin"
          [options]="basinType"
          optionValue="id"
          placeholder="Select a Basin"
          optionLabel="name"
          [showClear]="true"
          [styleClass]="'w-full'"
        ></p-dropdown>
        <label for="basin-dropdown">{{ 'selfServices.basin' | translate }}</label>
        <small *ngIf="checkFormError('wellInformation.basin')" class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>
    </div>

    <h2>{{ 'selfServices.coordinate' | translate }}</h2>

    <div class="row input-group-flex">
      <span class="p-float-label">
        <p-inputNumber
          id="latitude-input"
          formControlName="latitude"
          mode="decimal"
          [minFractionDigits]="0"
          [maxFractionDigits]="10"
          [locale]="defaultLocale"
        >
        </p-inputNumber>
        <label for="latitude-input">{{ 'selfServices.latitude' | translate }}</label>
      </span>
      <span class="p-float-label">
        <p-inputNumber
          id="longitude-input"
          formControlName="longitude"
          mode="decimal"
          [minFractionDigits]="0"
          [maxFractionDigits]="10"
          [locale]="defaultLocale"
        >
        </p-inputNumber>
        <label for="longitude-input">{{ 'selfServices.longitude' | translate }}</label>
      </span>
      <span class="p-float-label">
        <p-inputNumber
          id="north-input"
          formControlName="north"
          mode="decimal"
          [minFractionDigits]="0"
          [maxFractionDigits]="10"
          [locale]="defaultLocale"
        >
        </p-inputNumber>
        <label for="north-input">{{ 'selfServices.north' | translate }}</label>
      </span>
      <span class="p-float-label">
        <p-inputNumber
          id="east-input"
          formControlName="east"
          mode="decimal"
          [minFractionDigits]="0"
          [maxFractionDigits]="10"
          [locale]="defaultLocale"
        >
        </p-inputNumber>
        <label for="east-input">{{ 'selfServices.east' | translate }}</label>
      </span>
      <span class="p-float-label">
        <input id="origin-input" type="text" pInputText formControlName="origin" />
        <label for="origin-input">{{ 'selfServices.origin' | translate }}</label>
      </span>
    </div>
    <div class="row input-group-50">
      <span class="input-switch">
        <label for="epis-checkbox">{{ 'selfServices.epis' | translate }}</label>
        <p-inputSwitch id="epis-checkbox" formControlName="uwiUpiCreatedInEpis"></p-inputSwitch>
      </span>
    </div>
  </p-accordionTab>
</ng-container>
