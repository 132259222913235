import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SearchWellProjectPopupComponent } from 'src/app/common/search-well-project-popup/search-well-project-popup.component';

@Component({
  selector: 'app-well-search-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    TranslateModule,
    ButtonModule,
    SearchWellProjectPopupComponent,
  ],
  templateUrl: './well-search-form.component.html',
  styleUrls: ['./well-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WellSearchFormComponent {
  @Input() formGroup!: FormGroup;
  @Input() searchValue = 'UWI/UPI';
  @Output() searchEvent = new EventEmitter<string>();

  private currentValue = '';

  onEnterClicked(): void {
    this.searchEvent.emit(this.currentValue);
  }

  valueChanged(value: string): void {
    this.currentValue = value;
  }

  search(): void {
    this.searchEvent.emit(this.currentValue);
  }
}
