<p-table
  [resizableColumns]="true"
  styleClass="p-datatable-gridlines basicTable"
  [columns]="tab.childTable.columns"
  [value]="rowData[childTableName[tabIndex]]"
  [sortField]="'boxTopDepth'"
  [sortOrder]="1"
  (sortFunction)="customSort($event)"
  [customSort]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <ng-container *ngFor="let col of tab.childTable.columns">
        <th *ngIf="validationTable && col.field === 'validated'">
          {{ 'verification.table.headers.validated' | translate }}
        </th>
        <th *ngIf="col.header">
          {{ col.header | translate }}
        </th>
        <th *ngIf="col.field === 'printSample'"></th
      ></ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-childRowData let-childColumns="columns" let-rowIndex="rowIndex">
    <tr>
      <ng-container *ngFor="let col of childColumns">
        <ng-container *ngIf="col.header">
          <td
            class="px-1 py-1"
            *ngIf="
              !sampleDetailTableDropdownOption[col.field] &&
              !typeOf(col.field) &&
              !floatInput(col.field) &&
              !tableCheckbox[col.field]
            "
          >
            <div
              *ngIf="col.field === 'sampleLabel'"
              class="flex justify-content-between align-items-center top-0"
            >
              <input
                [(ngModel)]="childRowData[col.field]"
                (focusout)="onValidatedClicked($event)"
                class="p-inputtext p-component p-element w-full"
                type="text"
              />
              <i
                class="pi pi-wrench action-icon cursor-pointer"
                pTooltip="{{ 'tooltip.generateSampleLabel' | translate }}"
                (click)="generateAndFillSampleLabel(childRowData)"
              ></i>
            </div>

            <ng-container *ngIf="col.field !== 'sampleLabel'">
              <input
                [(ngModel)]="childRowData[col.field]"
                (focusout)="onValidatedClicked($event)"
                class="p-inputtext p-component p-element w-full"
                type="text"
              />
            </ng-container>
          </td>

          <td
            class="px-1 py-1"
            *ngIf="
              !sampleDetailTableDropdownOption[col.field] &&
              typeOf(col.field) &&
              !tableCheckbox[col.field]
            "
          >
            <p-inputNumber
              [locale]="defaultLocale"
              [(ngModel)]="childRowData[col.field]"
              inputId="integeronly"
            >
            </p-inputNumber>
          </td>

          <td
            class="px-1 py-1"
            *ngIf="
              !sampleDetailTableDropdownOption[col.field] &&
              floatInput(col.field) &&
              !tableCheckbox[col.field] &&
              sampleDepthFieldsName.includes(col.field)
            "
          >
            <p-inputNumber
              [(ngModel)]="childRowData[col.field]"
              mode="decimal"
              pTooltip="{{ 'tooltip.bottomDepthShouldBeGreaterOrEqualToTopDepth' | translate }}"
              tooltipPosition="right"
              (focusout)="onValidatedClicked($event)"
              [minFractionDigits]="0"
              [maxFractionDigits]="10"
              [locale]="defaultLocale"
            >
            </p-inputNumber>
          </td>

          <td
            class="px-1 py-1"
            *ngIf="
              !sampleDetailTableDropdownOption[col.field] &&
              floatInput(col.field) &&
              !tableCheckbox[col.field] &&
              sampleWeightFieldsName.includes(col.field)
            "
          >
            <p-inputNumber
              [(ngModel)]="childRowData[col.field]"
              mode="decimal"
              pTooltip="{{
                'tooltip.remainingWeightShouldBeGreaterOrEqualToMinimumWeight' | translate
              }}"
              tooltipPosition="right"
              (focusout)="onValidatedClicked($event)"
              [minFractionDigits]="0"
              [maxFractionDigits]="10"
              [locale]="defaultLocale"
            >
            </p-inputNumber>
          </td>

          <td
            class="px-1 py-1"
            *ngIf="
              !sampleDetailTableDropdownOption[col.field] &&
              floatInput(col.field) &&
              !tableCheckbox[col.field] &&
              !sampleWeightFieldsName.includes(col.field) &&
              !sampleDepthFieldsName.includes(col.field)
            "
          >
            <p-inputNumber
              [(ngModel)]="childRowData[col.field]"
              mode="decimal"
              (focusout)="onValidatedClicked($event)"
              [minFractionDigits]="0"
              [maxFractionDigits]="10"
              [locale]="defaultLocale"
            >
            </p-inputNumber>
          </td>

          <td *ngIf="sampleDetailTableDropdownOption[col.field]">
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'packagingTypeId'">
                <app-dropdown-packaging-type
                  (focusOut)="onValidatedClicked($event)"
                  [(packagingType)]="childRowData[col.field]"
                  [options]="this.sampleDetailTableDropdownOption[col.field]"
                ></app-dropdown-packaging-type>
              </ng-container>

              <div *ngSwitchCase="'containerId'" class="dropdown-with-icon">
                <i class="pi pi-print print-icon" (click)="printContainer(containerDropDown)"></i>
                <p-dropdown
                  #containerDropDown
                  [(ngModel)]="childRowData[col.field]"
                  (focusout)="onValidatedClicked($event)"
                  placeholder=" "
                  optionLabel="name"
                  [options]="this.sampleDetailTableDropdownOption[col.field]"
                  [showClear]="true"
                  appendTo="body"
                  [editable]="false"
                  optionValue="id"
                  [readonly]="col.field === 'containerId' || col.field === 'locationId'"
                  (click)="showDialog(rowIndex, col.field)"
                ></p-dropdown>
              </div>

              <ng-container *ngSwitchDefault>
                <p-dropdown
                  [(ngModel)]="childRowData[col.field]"
                  (focusout)="onValidatedClicked($event)"
                  placeholder=" "
                  optionLabel="name"
                  [options]="this.sampleDetailTableDropdownOption[col.field]"
                  [showClear]="true"
                  appendTo="body"
                  [editable]="false"
                  optionValue="id"
                  [readonly]="col.field === 'containerId' || col.field === 'locationId'"
                  (click)="showDialog(rowIndex, col.field)"
                ></p-dropdown>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>

        <td
          *ngIf="col.field === 'printSample'"
          class="text-center cursor-pointer print-icon"
          pTooltip="{{ 'tooltip.printSampleBarcode' | translate }}"
          (click)="printSampleLabel(childRowData)"
        >
          <i class="pi pi-print"></i>
        </td>

        <td
          *ngIf="
            (validationTable && tableCheckbox[col.field]) ||
            (col.field === 'endCupExists' && tableCheckbox[col.field])
          "
        >
          <div class="p-field-checkbox text-center">
            <p-checkbox
              [(ngModel)]="childRowData[col.field]"
              (onChange)="onValidatedClicked($event)"
              [binary]="true"
              id="checkbox"
            ></p-checkbox>
          </div>
        </td>
      </ng-container>
    </tr>
  </ng-template>
</p-table>

<p-dialog
  *ngIf="selectedDropDownColumnName && sampleDetailTableDropdownOption['containerId'].length"
  header="Container"
  [closable]="true"
  [modal]="true"
  [(visible)]="visibleContainerPopup"
  [style]="{ width: '40vw', height: '50vw' }"
>
  <app-dropdown-search-popup
    #dropdownSearchPopupComponent
    [tableHeader]="'Container code'"
    [isContainerSearchPopup]="true"
    [tableData]="this.sampleDetailTableDropdownOption['containerId']"
    (selectedDropDownOption)="onSelectedDropDownOption($event)"
  ></app-dropdown-search-popup>
</p-dialog>
