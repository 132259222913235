import { TranslateService } from '@ngx-translate/core';

import { Injectable } from '@angular/core';

import { Destroyable } from '../core/utils/mixins/destroyable.mixin';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class DateValidationService extends Destroyable(Object) {
  constructor(private translate: TranslateService, private toastService: ToastService) {
    super();
  }

  validateDate(startDate: Date, endDate: Date): void {
    if (startDate && endDate && endDate < startDate)
      this.toastService.toastError(this.translate.instant('errorMessage.enterTheCorrectDate'));
  }

  isEndDateBeforeStartDate(startDate: Date, endDate: Date): boolean {
    return startDate && endDate && endDate < startDate;
  }
}
