import { SampleType } from '../enums/sample-type.enum';
import { generateSampleLabel } from '../utils/generate-sample-label.util';

export class SampleLabelPayload {
  org_name = 'SGC';
  barcode_value: string;
  well_name: string;
  uwi: string;
  sample_type: SampleType;
  unit_number: string;
  depth: string;

  constructor(
    sampleLabel: string,
    wellName: string,
    uwi: string,
    sampleType: SampleType,
    unitNumber: string,
    depth: string,
  ) {
    this.barcode_value = sampleLabel || generateSampleLabel(uwi, sampleType, unitNumber, depth);
    this.well_name = wellName;
    this.uwi = uwi;
    this.sample_type = sampleType;
    this.unit_number = unitNumber;
    this.depth = depth;
  }
}
