import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { combineLatest, finalize } from 'rxjs';

import { CommonModule, KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { DropdownEditableComponent } from 'src/app/common/dropdown-editable/dropdown-editable.component';
import { DropDownOption } from 'src/app/core/models/drop-down-option.model';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';
import { ToastService } from 'src/app/services/toast.service';

import { DefaultCalendarDateFormatDirective } from '../../common/directives/default-calendar-date-format.directive';
import { DefaultDateFormatPipe } from '../../common/pipes/default-date-format.pipe';
import { ProjectState } from '../../core/models/project-state.model';
import { DeliveryService } from '../../services/api/delivery.service';
import { ProjectStateService } from '../../services/api/project-state.service';
import { UserService } from '../../services/api/user.service';
import { User } from '../admin-settings/user-table/user.model';
import { WellSearchFormComponent } from '../storage/forms/well-search-form/well-search-form.component';
import { GetDeliveryForAssignment } from './models/get-delivery-for-assignment.model';
import { UpdateDeliveryAssignment } from './models/update-delivery-assignment.model';
import { SearchAssignmentPopupComponent } from './search-assignment-popup/search-assignment-popup.component';
import { AssignmentTableInformation } from './table-values/assignment-table-information.model';
import { ASSIGNMENT_TABLE_HEADERS } from './table-values/table-headers.const';

@Component({
  selector: 'app-assignment',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AccordionModule,
    ReactiveFormsModule,
    WellSearchFormComponent,
    ButtonModule,
    TableModule,
    TooltipModule,
    DropdownEditableComponent,
    InputTextModule,
    TranslateModule,
    CalendarModule,
    CheckboxModule,
    SearchAssignmentPopupComponent,
    ProgressSpinnerModule,
    DefaultCalendarDateFormatDirective,
    DefaultDateFormatPipe,
  ],
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignmentComponent extends Destroyable(Object) implements OnInit {
  readonly assignmentTableLabels = ASSIGNMENT_TABLE_HEADERS;
  readonly initialTableRowCount = 7;
  readonly noteMaxLengthBeforeTooltip = 22;

  assignmentFormGroup!: FormGroup;
  spinnerShow = false;
  isSearchAssignmentPopupVisible = false;
  assignmentTableFormGroup!: FormGroup;
  headers!: string[];

  assignmentTableDropdownOption: { [key: string]: DropDownOption[] } = {
    state: [],
    technician: [],
  };

  foundDeliveryForAssignments: GetDeliveryForAssignment[] = [];

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private deliveryService: DeliveryService,
    private userService: UserService,
    private projectStateService: ProjectStateService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getTableHeaderLabel();
    this.getOptions();
    this.initForm();
  }

  getCellValue(rowIndex: number, colKey: string): string {
    return this.assignmentTableFormGroup.get(['table', rowIndex.toString(), colKey])!.value;
  }

  openSearchAssignmentPopup(): void {
    this.isSearchAssignmentPopupVisible = true;
  }

  closeSearchAssignmentPopup(): void {
    this.isSearchAssignmentPopupVisible = false;
  }

  buildTableData(foundDeliveryForAssignments: GetDeliveryForAssignment[]): void {
    this.initForm();
    this.foundDeliveryForAssignments = foundDeliveryForAssignments;
    this.addEmptyRows();
    this.closeSearchAssignmentPopup();
    this.cd.markForCheck();
  }

  getTableFormControlBy(rowIndex: number, controlName: string): FormControl {
    return this.assignmentFormGroup
      ?.get('assignmentTable')
      ?.get('table')
      ?.get([rowIndex.toString()])
      ?.get(controlName) as FormControl;
  }

  getAssignmentTableFormControl(): FormArray {
    return this.assignmentTableFormGroup.get('table') as FormArray;
  }

  orderOriginal = (_a: KeyValue<string, string>, _b: KeyValue<string, string>): number => {
    return 0;
  };

  getTableHeaderLabel(): void {
    this.headers = Object.values(this.assignmentTableLabels[0]);
  }

  addEmptyRows(): void {
    this.foundDeliveryForAssignments.forEach((foundDeliveryForAssignment) => {
      const { projectStateRelatedInfos } = foundDeliveryForAssignment;
      const currentProjectStateRelatedInfo = projectStateRelatedInfos.find(
        (info) => info.projectStateId === foundDeliveryForAssignment.projectStateId,
      )!;

      const data: AssignmentTableInformation = {
        deliveryId: foundDeliveryForAssignment.id,
        state: foundDeliveryForAssignment?.projectStateId ?? '',
        type: foundDeliveryForAssignment?.type ?? '',
        uwiupi: foundDeliveryForAssignment?.uwiOrUpi ?? '',
        name: foundDeliveryForAssignment?.name ?? '',
        sampleTypeAndTotalBoxes: foundDeliveryForAssignment?.sampleBoxInfos
          .map((info) => `${info.sampleType}: ${info.numberOfBoxes}`)
          .join('\n'),
        companyName: foundDeliveryForAssignment.companyName ?? '',
        contractNumber: foundDeliveryForAssignment?.contractNumber ?? '',
        technician: currentProjectStateRelatedInfo.technicianId ?? '',
        dateAssigned: currentProjectStateRelatedInfo.dateAssigned ?? '',
        dateCompleted: currentProjectStateRelatedInfo.dateCompleted ?? '',
        note: currentProjectStateRelatedInfo.note ?? '',
      };

      this.addRow(data);
    });
  }

  onDropdownValueChanged(rowIndex: number, controlKey: string): void {
    const rowFormGroup = this.assignmentTableFormGroup.get('table')?.get(rowIndex.toString());
    if (controlKey === 'state') {
      const { projectStateRelatedInfos } = this.foundDeliveryForAssignments[rowIndex];
      const currentProjectStateRelatedInfo = projectStateRelatedInfos.find(
        (info) => info.projectStateId === rowFormGroup?.get('state')!.getRawValue(),
      )!;
      const currentProjectStateRelatedInfoFormValue: Partial<AssignmentTableInformation> = {
        technician: currentProjectStateRelatedInfo.technicianId ?? '',
        dateAssigned: currentProjectStateRelatedInfo.dateAssigned ?? '',
        dateCompleted: currentProjectStateRelatedInfo.dateCompleted ?? '',
        note: currentProjectStateRelatedInfo.note ?? '',
      };
      rowFormGroup?.patchValue(currentProjectStateRelatedInfoFormValue);
    } else {
      rowFormGroup?.get('dateAssigned')?.setValue(null);
      rowFormGroup?.get('dateCompleted')?.setValue(null);
      rowFormGroup?.get('note')?.setValue('');
    }
  }

  clearAssignmentTable(): void {
    this.initForm();
    this.foundDeliveryForAssignments = [];
    this.addEmptyRows();
  }

  submitForm(): void {
    if (this.assignmentFormGroup?.valid) {
      let errorMessage = '';

      const formArrayValue: AssignmentTableInformation[] = this.assignmentTableFormGroup
        .get('table')
        ?.getRawValue();

      const updateDeliveryData: UpdateDeliveryAssignment[] = formArrayValue.map(
        (item: AssignmentTableInformation) => {
          const projectStateId = item.state ?? '';
          const dateAssigned = item.dateAssigned ?? '';
          const technicianId = item.technician ?? '';
          const note = item.note ?? '';
          const { deliveryId } = item;

          return { projectStateId, dateAssigned, technicianId, note, deliveryId };
        },
      );

      const propertiesToCheck: (keyof UpdateDeliveryAssignment)[] = [
        'projectStateId',
        'technicianId',
        'dateAssigned',
      ];

      propertiesToCheck.some((prop) => {
        const hasInvalidValue = updateDeliveryData.some(
          (obj) => obj[prop] === null || obj[prop] === '',
        );
        if (hasInvalidValue) {
          errorMessage = `assignment.validation.${prop}`;
          return errorMessage;
        }
        return false;
      });

      if (errorMessage) {
        this.toastService.toastError(this.getTranslationText(errorMessage));
      } else {
        this.spinnerShow = true;

        this.deliveryService
          .updateDeliveryAssignment(updateDeliveryData)
          .pipe(
            finalize(() => {
              this.spinnerShow = false;
              this.cd.markForCheck();
            }),
            this.takeUntilDestroyed(),
          )
          .subscribe(() => {
            this.toastService.toastSuccess(
              this.getTranslationText('assignment.validation.success'),
            );
            this.clearTableValue();
            this.cd.detectChanges();
          });
      }
    } else {
      this.toastService.toastError(this.getTranslationText('assignment.validation.unknownError'));
    }
  }

  private addRow(data: AssignmentTableInformation) {
    const control = this.assignmentTableFormGroup.get(`table`) as FormArray;
    control.push(this.initiateAssignmentTableForm(data));
    this.cd.detectChanges();
  }

  private initiateAssignmentTableForm(data: AssignmentTableInformation): FormGroup {
    const currentTable = data;
    const formData: { [key: string]: string[] } = {};
    Object.entries(currentTable).forEach(([key, value]) => {
      formData[key] = [value];
    });
    return this.fb.group(formData);
  }

  private initForm(): void {
    const tableFormGroup: { [key: string]: FormArray } = {};

    this.assignmentTableLabels.forEach((_) => {
      const key = 'table';
      tableFormGroup[key] = this.fb.array([]);
    });

    this.assignmentTableFormGroup = this.fb.group(tableFormGroup);

    this.assignmentFormGroup = this.fb.group({
      search: [''],
      assignmentTable: this.assignmentTableFormGroup,
    });
  }

  private clearTableValue(): void {
    const assignmentTableFormArray = this.getAssignmentTableFormControl();
    assignmentTableFormArray.clear();
  }

  private getOptions(): void {
    combineLatest([this.userService.getUsers(), this.projectStateService.getProjectStates()])
      .pipe(this.takeUntilDestroyed())
      .subscribe((data: [User[], ProjectState[]]) => {
        const [users, projectStates] = data;
        this.assignmentTableDropdownOption['state'] = projectStates.filter(
          (projectState) => projectState.isAssignmentCompatible,
        );
        this.assignmentTableDropdownOption['technician'] = users.map((user) => ({
          id: user.id!,
          name: `${user.firstName ?? ''} ${user.lastName ?? ''} ${user.email ?? ''} ${
            user.contactNumber ?? ''
          }`,
        }));
        this.cd.markForCheck();
      });
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg: string) => {
        translationText = msg;
      });
    return translationText;
  }
}
