import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ShortDelivery } from '../../common/delivery-table-search-uwi-contract/models/short-delivery.model';
import { PASS_ERROR_HEADER, SGC_API } from '../../core/api.consts';
import { EntityCacheKey } from '../../core/enums/entity-cache-key.enum';
import { DeliveryFull } from '../../core/models/delivery-full.model';
import { DeliveryTemplate } from '../../core/models/delivery-template.model';
import { DeliveryTemplateTableRow } from '../../core/models/delivery-template-table-row.model';
import { PaginationResponse } from '../../core/models/pagination-response.model';
import { GetDeliveryForAssignment } from '../../features/assignment/models/get-delivery-for-assignment.model';
import { UpdateDeliveryAssignment } from '../../features/assignment/models/update-delivery-assignment.model';
import { DataAccessServiceModel } from '../../features/data-access/models/data-access.model';
import { CacheService } from '../cache.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  private url = `${SGC_API}/Deliveries`;
  private deliveriesByFilterUrl = `${this.url}/DeliveriesByFilter`;
  private assignmentsByFilterUrl = `${this.url}/AssignmentsByFilter`;
  private templatesUrl = `${this.url}/Templates`;
  private shortDeliveriesUrl = `${this.url}/ShortDeliveries`;
  private searchForAssignmentUrl = `${this.url}/SearchForAssignment`;
  private updateAssignmentUrl = `${this.url}/UpdateAssignment`;
  private updateEpisUrl = `${this.url}/Template/Epis`;

  constructor(private httpClient: HttpClient, private cacheService: CacheService) {}

  getDeliveryTemplatesBy(
    page: number,
    pageSize: number,
    searchText: string,
  ): Observable<PaginationResponse<DeliveryTemplateTableRow>> {
    const params = new HttpParams({
      fromObject: { page, pageSize, searchText },
    });

    return this.httpClient.get<PaginationResponse<DeliveryTemplateTableRow>>(this.templatesUrl, {
      params,
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }

  createDeliveryTemplate(deliveryTemplate: DeliveryTemplate): Observable<null> {
    return this.httpClient.post<null>(this.url, deliveryTemplate, {
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }

  deleteDelivery(deliveryId: string): Observable<null> {
    return this.httpClient.delete<null>(`${this.url}/${deliveryId}`, {
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }

  updateDeliveryTemplateEpis(deliveryTemplate: DeliveryTemplateTableRow): Observable<null> {
    const params = new HttpParams()
      .set('DeliveryId', deliveryTemplate.id)
      .set('UwiUpiCreatedInEpis', deliveryTemplate.uwiUpiCreatedInEpis);

    return this.httpClient.put<null>(`${this.updateEpisUrl}`, deliveryTemplate, {
      params,
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }

  getAllShortDeliveries(): Observable<ShortDelivery[]> {
    return this.httpClient.get<ShortDelivery[]>(this.shortDeliveriesUrl);
  }

  getDeliveriesBy(upiOrUwi: string): Observable<GetDeliveryForAssignment[]> {
    const params = new HttpParams().set('searchText', upiOrUwi);

    return this.httpClient.get<GetDeliveryForAssignment[]>(this.searchForAssignmentUrl, {
      params,
    });
  }

  updateDeliveryAssignment(formData: UpdateDeliveryAssignment[]): Observable<null> {
    return this.httpClient.post<null>(this.updateAssignmentUrl, formData);
  }

  getDeliveryById(deliveryId: string): Observable<DeliveryFull> {
    return this.httpClient.get<DeliveryFull>(`${this.url}/${deliveryId}`, {
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }

  updateDelivery(
    receptionData: DeliveryFull,
    stage: 'reception' | 'validation' | 'verification',
  ): Observable<ShortDelivery> {
    const params = new HttpParams({
      fromObject: { stage },
    });

    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.CONTAINERS,
      this.httpClient.put<ShortDelivery>(this.url, receptionData, { params }),
    );
  }

  getDeliveryByFilter(params: HttpParams): Observable<DataAccessServiceModel> {
    return this.httpClient.get<DataAccessServiceModel>(this.deliveriesByFilterUrl, {
      params,
    });
  }

  getAssignmentByFilter(params: HttpParams): Observable<DataAccessServiceModel> {
    return this.httpClient.get<DataAccessServiceModel>(this.assignmentsByFilterUrl, {
      params,
    });
  }

  deleteBoxesAndSamples(deliveryId: string): Observable<null> {
    return this.httpClient.delete<null>(`${this.url}/${deliveryId}/boxesAndSamples`, {
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }

  deleteSamples(deliveryId: string): Observable<null> {
    return this.httpClient.delete<null>(`${this.url}/${deliveryId}/samples`, {
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }
}
