import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CapitalizeStringPipe } from 'src/app/common/pipes/capitalize-string.pipe';
import { IdName } from 'src/app/core/models/id-name.model';

import { DefaultCalendarDateFormatDirective } from '../../../../common/directives/default-calendar-date-format.directive';
import { DefaultDateFormatPipe } from '../../../../common/pipes/default-date-format.pipe';
import { Destroyable } from '../../../../core/utils/mixins/destroyable.mixin';
import { DateValidationService } from '../../../../services/date-validation.service';
import { SampleTypesChartFilters } from './models/sample-types-chart-filters.model';

@Component({
  selector: 'app-sample-types-chart-filter',
  templateUrl: './sample-types-chart-filter.component.html',
  styleUrls: ['./sample-types-chart-filter.component.scss'],
  standalone: true,
  imports: [
    DialogModule,
    CheckboxModule,
    CommonModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    MultiSelectModule,
    TranslateModule,
    CapitalizeStringPipe,
    DefaultCalendarDateFormatDirective,
    DefaultDateFormatPipe,
  ],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleTypesChartFilterComponent extends Destroyable(Object) implements OnInit {
  dataObject!: SampleTypesChartFilters;
  sampleTypeFormGroup!: FormGroup;
  statusFormGroup!: FormGroup;
  displayFormGroup!: FormGroup;
  teamsFormGroup!: FormGroup;
  durabilityFormGroup!: FormGroup;
  displayOptions!: IdName[];
  teamsOptions!: IdName[];
  sampleTypesOptions: IdName[] = [
    { id: '1', name: 'BiostratigraphicBox' },
    { id: '2', name: 'CoreBox' },
    { id: '3', name: 'FluidBox' },
    { id: '4', name: 'PetrographicPlateBox' },
    { id: '5', name: 'PistonCoreBox' },
    { id: '6', name: 'PlugBox' },
    { id: '7', name: 'PreservedBox' },
    { id: '8', name: 'ResidueBox' },
    { id: '9', name: 'SwcBox' },
    { id: '10', name: 'SurfaceSampleBox' },
    { id: '11', name: 'ZhZsDatBox' },
  ];

  sampleStatusOptions: IdName[] = [];
  pickedDisplay = 'week';
  isStartDateGreaterThanEndDate = false;

  private readonly sampleStatusLabels: Record<string, string> = {
    checkedIn: 'Checked-in',
    checkedOut: 'Checked-out',
  };

  private readonly displayLabels: Record<string, string> = {
    day: 'Day',
    week: 'Week',
    month: 'Month',
  };

  get sampleTypeFormArray(): FormGroup {
    return this.sampleTypeFormGroup.get('sampleType') as FormGroup;
  }

  constructor(
    public dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private dateValidationService: DateValidationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initData();
  }

  initOptions(): void {
    this.displayOptions = Object.keys(this.dataObject.display).map(
      (id) => ({ id, name: this.displayLabels[id] } as IdName),
    );

    this.sampleStatusOptions =
      Object.keys(this.dataObject.sampleStatus).map((id) => ({
        id,
        name: this.sampleStatusLabels[id],
      })) ?? [];

    this.pickedDisplay =
      (Object.keys(this.dataObject.display) as (keyof typeof this.dataObject.display)[]).find(
        (key) => this.dataObject.display[key],
      ) ?? '';
  }

  apply(): void {
    const sampleTypeFormGroupValue = this.sampleTypeFormArray.getRawValue();

    const sampleTypesIds = Object.entries(sampleTypeFormGroupValue)
      .filter(([key, value]) => value === true)
      .map(([key]) => {
        return this.sampleTypesOptions[Number(key) - 1].name;
      });

    const newDataObject: SampleTypesChartFilters = {
      sampleTypesIds,
      teamsIds: this.teamsFormGroup.value['teams'],
      sampleStatus: { ...this.statusFormGroup.value },
      display: { ...this.dataObject.display },
      duration: { ...this.durabilityFormGroup.value },
    };
    Object.keys(newDataObject.display).forEach((key) => {
      newDataObject.display[key as keyof typeof newDataObject.display] = false;
    });

    newDataObject.display[
      this.displayFormGroup.get('display')?.value as keyof typeof newDataObject.display
    ] = true;

    this.dialogRef.close(newDataObject);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  private initData(): void {
    this.dataObject = this.dialogConfig.data;
    this.initOptions();
    this.initSampleTypeFormGroup();
    this.initTeamsGroup();
    this.initStatusGroup();
    this.initDisplayGroup();
    this.initDurabilityGroup();
  }

  private initSampleTypeFormGroup(): void {
    const selectedSampleTypes = this.dataObject.sampleTypesIds
      ? [...this.dataObject.sampleTypesIds]
      : [];

    const formControls = new FormGroup(
      this.sampleTypesOptions.reduce((acc, item) => {
        return { ...acc, [item.id]: new FormControl(selectedSampleTypes.includes(item.name)) };
      }, {}),
    );

    this.sampleTypeFormGroup = new FormGroup({
      sampleType: formControls,
    });
  }

  private initTeamsGroup(): void {
    this.teamsFormGroup = new FormGroup({
      teams: new FormControl(this.dataObject.teamsIds ? [...this.dataObject.teamsIds] : []),
    });
  }

  private initStatusGroup(): void {
    const sampleStatusKeyValuePairs = Object.entries(this.dataObject.sampleStatus);
    const controls = Object.fromEntries(
      sampleStatusKeyValuePairs.map(([key, val]) => [key, new FormControl(val)]),
    );

    this.statusFormGroup = new FormGroup(controls);
  }

  private initDisplayGroup(): void {
    this.displayFormGroup = new FormGroup({
      display: new FormControl(this.pickedDisplay),
    });
  }

  private initDurabilityGroup(): void {
    this.durabilityFormGroup = new FormGroup({
      startDate: new FormControl(this.dataObject.duration.startDate),
      endDate: new FormControl(this.dataObject.duration.endDate),
    });

    this.durabilityFormGroup.controls['startDate'].valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((date) => {
        if (date && this.durabilityFormGroup.controls['endDate'].value) {
          const startDate = new Date(date);
          const endDate = new Date(this.durabilityFormGroup.controls['endDate'].value);
          this.isStartDateGreaterThanEndDate = this.dateValidationService.isEndDateBeforeStartDate(
            startDate,
            endDate,
          );
        } else {
          this.isStartDateGreaterThanEndDate = false;
        }
      });

    this.durabilityFormGroup.controls['endDate'].valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((date) => {
        if (this.durabilityFormGroup.controls['startDate'].value && date) {
          const startDate = new Date(this.durabilityFormGroup.controls['startDate'].value);
          const endDate = new Date(date);
          this.isStartDateGreaterThanEndDate = this.dateValidationService.isEndDateBeforeStartDate(
            startDate,
            endDate,
          );
        } else {
          this.isStartDateGreaterThanEndDate = false;
        }
      });
  }
}
