<p-dropdown
  #packagingTypeDropdownRef
  [(ngModel)]="packagingType"
  (focusout)="focusOutEmitter()"
  placeholder=" "
  optionLabel="name"
  [options]="options"
  [showClear]="true"
  (onChange)="onDropdownChange($event)"
  appendTo="body"
  optionValue="id"
  [panelStyleClass]="'packaging-type-overlay-panel'"
>
  <ng-template let-packType pTemplate="item">
    <div class="item" (click)="onPackageTypeClicked($event, packType, packagingTypeDropdownRef)">
      {{ packType.name }} - {{ packType.count || 0 }}
    </div>
  </ng-template>
</p-dropdown>

<p-dialog
  header="{{ 'packagingType.popup.header' | translate }}"
  [(visible)]="isDialogVisible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
>
  <ng-template *ngIf="this.dialogInfo.isStockAvailable; then inStock; else outOfStock">
  </ng-template>
  <ng-template #inStock>
    <p class="m-0 content-block">{{ 'packagingType.popup.useFromStockOrAsIsTitle' | translate }}</p>
    <p class="m-0 actions-block">
      <p-button
        label="{{ 'packagingType.popup.useFromStockButton' | translate }}"
        (onClick)="onUseStock()"
      ></p-button>
      <p-button
        label="{{ 'packagingType.popup.useAsIsButton' | translate }}"
        (onClick)="onUseAsIs()"
      ></p-button>
    </p>
  </ng-template>

  <ng-template #outOfStock>
    <p class="m-0 content-block">{{ 'packagingType.popup.outOfStockTitle' | translate }}</p>
    <p class="m-0 actions-block">
      <p-button
        label="{{ 'packagingType.popup.okButton' | translate }}"
        (onClick)="onUseAsIs()"
      ></p-button>
    </p>
  </ng-template>
</p-dialog>
