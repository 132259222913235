import { AssignmentTableInformation } from './assignment-table-information.model';

export const ASSIGNMENT_TABLE_HEADERS: AssignmentTableInformation[] = [
  {
    state: 'assignment.table-headers.state',
    type: 'assignment.table-headers.type',
    uwiupi: 'assignment.table-headers.uwiupi',
    name: 'assignment.table-headers.name',
    sampleTypeAndTotalBoxes: 'assignment.table-headers.sampleTypeAndTotalBoxes',
    companyName: 'assignment.table-headers.companyName',
    contractNumber: 'assignment.table-headers.contractNumber',
    technician: 'assignment.table-headers.technician',
    dateAssigned: 'assignment.table-headers.dateAssigned',
    dateCompleted: 'assignment.table-headers.dateCompleted',
    note: 'assignment.table-headers.note',
  },
];
