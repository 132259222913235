import { Pipe, PipeTransform } from '@angular/core';

import { PrintLabelType } from '../../core/enums/print-label-type.enum';
import { PalletStorageModel } from '../../features/storage/models/pallet-search.model';
import { SampleModel } from '../../features/storage/models/sample.model';

@Pipe({
  name: 'printDisabled',
  standalone: true,
  pure: true,
})
export class PrintDisabledPipe implements PipeTransform {
  transform(
    selection: Array<SampleModel | PalletStorageModel>,
    labelType: PrintLabelType,
  ): boolean {
    let hasAllEmptyLabels = true;
    switch (labelType) {
      case PrintLabelType.sample:
        hasAllEmptyLabels = selection.every((entity) => {
          return !(entity as SampleModel).sampleLabel;
        });
        break;
      case PrintLabelType.container:
        hasAllEmptyLabels = selection.every((entity) => !(entity as SampleModel).containerLabel);
        break;
      case PrintLabelType.pallet:
        hasAllEmptyLabels = selection.every((entity) => !entity.palletLabel);
        break;
      case PrintLabelType.location:
        hasAllEmptyLabels = selection.every((entity) => !(entity as SampleModel).locationLabel);
        break;
      default:
        break;
    }
    return selection.length <= 1 || hasAllEmptyLabels;
  }
}
