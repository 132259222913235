import { User } from '../features/admin-settings/user-table/user.model';
import {
  TableSummaryLabel,
  TableSummaryValidatedLabel,
  TableSummaryVerifiedLabel,
} from '../features/self-service/self-service-labels';
import { SampleType } from './enums/sample-type.enum';
import { DropDownOption } from './models/drop-down-option.model';

export const TABLE_SUMMARY = {
  labels: TableSummaryLabel,
  validatedLabels: TableSummaryValidatedLabel,
  verifiedLabels: TableSummaryVerifiedLabel,
  key: [
    'numberSamplesPerBox',
    'tubesPerBox',
    'tubesPerBox',
    'samplesPerBox',
    'samplesPerBox',
    'numberOfBottles',
    'samplesPerBox',
    'samplesPerBox',
    'samplesPerBox',
    'samplesPerBox',
    'samplesPerBox',
    'bottleNumber',
  ],
  value: [
    { sumOfValue: 0, totalCount: 0, verifiedCount: 0, validatedCount: 0, samplesTotal: 0 },
    { sumOfValue: 0, totalCount: 0, verifiedCount: 0, validatedCount: 0, samplesTotal: 0 },
    { sumOfValue: 0, totalCount: 0, verifiedCount: 0, validatedCount: 0, samplesTotal: 0 },
    { sumOfValue: 0, totalCount: 0, verifiedCount: 0, validatedCount: 0, samplesTotal: 0 },
    { sumOfValue: 0, totalCount: 0, verifiedCount: 0, validatedCount: 0, samplesTotal: 0 },
    { sumOfValue: 0, totalCount: 0, verifiedCount: 0, validatedCount: 0, samplesTotal: 0 },
    { sumOfValue: 0, totalCount: 0, verifiedCount: 0, validatedCount: 0, samplesTotal: 0 },
    { sumOfValue: 0, totalCount: 0, verifiedCount: 0, validatedCount: 0, samplesTotal: 0 },
    { sumOfValue: 0, totalCount: 0, verifiedCount: 0, validatedCount: 0, samplesTotal: 0 },
    { sumOfValue: 0, totalCount: 0, verifiedCount: 0, validatedCount: 0, samplesTotal: 0 },
    { sumOfValue: 0, totalCount: 0, verifiedCount: 0, validatedCount: 0, samplesTotal: 0 },
    { sumOfValue: 0, totalCount: 0, verifiedCount: 0, validatedCount: 0, samplesTotal: 0 },
  ],
};

export const INPUT_FIELDS_NUMBER = [
  'numberOfSet',
  'boxNumber',
  'numberSamplesPerBox',
  'tubesPerBox',
  'numberOfCores',
  'recoveryPercent',
  'samplesPerBox',
  'weight',
  'numberOfBottles',
  'samplingInterval',
  'penetrationLength',
  'recoveryLength',
  'bottleNumber',
  'length',
];

export const INPUT_FIELDS_FLOAT = [
  'boxTopDepth',
  'boxBottomDepth',
  'diameter',
  'minCps',
  'maxCps',
  'latitude',
  'longitude',
  'north',
  'east',
];

export const CHILD_TABLE_INPUT_FIELDS_FLOAT = [
  'sampleTopDepth',
  'interval',
  'weight',
  'remainingWeight',
  'minimumWeight',
  'sampleBottomDepth',
  'topPreservedDepth',
  'botomPreservedDepth',
  'topMissingDepth',
  'bottomMissinDepth',
  'length',
  'depth',
  'api',
  'bsw',
  'ph',
  'salinity',
  'penetrationLength',
  'recoveredSedimentLength',
  'diameter',
  'samplingDepth',
  'fluidVolume',
];

export const CHILD_TABLE_INPUT_FIELDS_NUMBER = [
  'coreNumber',
  'sampleAtSameDepth',
  'samplesAtSameDepth',
];

export const TABLE_DATE_FIELDS = ['shotDate'];

export const SAMPLE_INFO_TABLE_DROPDOWN_OPTION: {
  [key: string]: DropDownOption[];
} = {
  objectiveSetId: [],
  boxTypeId: [],
  packagingTypeId: [],
  typeOfPackaging: [],
  depthUnitId: [],
  sectionTypeId: [],
  technicalInfoId: [],
  mediumDeliveryInfoId: [],
  lengthUnitId: [],
  weightUnitId: [],
  fluidTypeId: [],
  endCupFractionId: [],
  extractionPositionId: [],
  sampleTypeId: [],
  samplingMethodId: [],
  sampleStatusId: [],
  plateTypeId: [],
  sampleOriginId: [],
  containerId: [],
  palletId: [],
  locationId: [],
  physicalStatusId: [],
  volumeUnitId: [],
};

export const TABLE_CHECKBOX: { [key: string]: boolean } = { endCupExists: true, validated: true };

export const TABLE_NAME = [
  'zhZsDatBoxes',
  'coreBoxes',
  'preservedBoxes',
  'plugBoxes',
  'swcBoxes',
  'fluidBoxes',
  'residueBoxes',
  'petrographicPlatesBoxes',
  'biostratigraphicBoxes',
  'surfaceSampleBoxes',
  'pistonCoreBoxes',
  'crudeBoxes',
];

export const CHILD_TABLE_NAME = [
  'zhZsDatSamples',
  'coreSamples',
  'preservedSamples',
  'plugSamples',
  'swcSamples',
  'fluidSamples',
  'residueSamples',
  'petrographicPlateSamples',
  'biostratigraphicPlateSamples',
  'surfaceSamples',
  'pistonCoreSamples',
  'crudeSamples',
];

export const TableNameType: { [key: string]: string } = {
  zhZsDatBoxes: SampleType.ZH_ZS_DAT,
  coreBoxes: SampleType.CORE,
  preservedBoxes: SampleType.PRESERVED,
  plugBoxes: SampleType.PLUG,
  swcBoxes: SampleType.SWC,
  fluidBoxes: SampleType.FLUID,
  residueBoxes: SampleType.RESIDUE,
  petrographicPlatesBoxes: SampleType.PETROGRAPHIC_PLATE,
  biostratigraphicBoxes: SampleType.BIOSTRATIGRAPHIC,
  surfaceSampleBoxes: SampleType.SURFACE_SAMPLE,
  pistonCoreBoxes: SampleType.PISTON_CORE,
  crudeBoxes: SampleType.CRUDE,
};

export function getUserNameUsingOption(userOption: User[], id = ''): string {
  const name = userOption.find((obj) => obj.id === id)?.lastName ?? '';
  return name;
}

export function downloadDoc(data: Blob, filename: string, mimeType: string) {
  const blobData = new Blob([data], { type: mimeType });
  const url = window.URL.createObjectURL(blobData);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
}
