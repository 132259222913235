import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LocationModel } from 'src/app/core/models/location.model';

import { DropdownSearchPopupComponent } from '../../../../common/dropdown-search-popup/dropdown-search-popup.component';

@Component({
  selector: 'app-store-to-location-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    TranslateModule,
    DialogModule,
    DropdownSearchPopupComponent,
  ],
  templateUrl: './store-to-location-form.component.html',
  styleUrls: ['./store-to-location-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreToLocationFormComponent implements OnInit {
  @ViewChild('dropdownSearchPopupComponent')
  dropdownSearchPopupComponent!: DropdownSearchPopupComponent;

  @Input() formGroup!: FormGroup;
  @Input() locations!: LocationModel[];
  control = new FormControl();
  visibleLocationPopup = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setUpControl();
  }

  checkFormError(controlPath: string): boolean | undefined {
    return this.formGroup.get(controlPath)?.invalid && this.formGroup.get(controlPath)?.dirty;
  }

  onSelectedDropDownOption(selectedDropDownOption: LocationModel): void {
    if (this.dropdownSearchPopupComponent) this.dropdownSearchPopupComponent.resetPage();
    this.formGroup.patchValue({ storeToLocation: selectedDropDownOption });
    this.visibleLocationPopup = false;
  }

  displayPopUp(): void {
    this.visibleLocationPopup = true;
  }

  private setUpControl(): void {
    const storeLocationFormGroup = this.formGroup.get('storeToLocation') as FormGroup;
    storeLocationFormGroup.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
  }
}
