import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { Table, TableModule } from 'primeng/table';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DropDownOption } from '../../../core/models/drop-down-option.model';
import { Destroyable } from '../../../core/utils/mixins/destroyable.mixin';
import { SearchServiceService } from '../../../services/api/search-service.service';
import { WellSearchFormComponent } from '../../storage/forms/well-search-form/well-search-form.component';
import { ServiceSearch } from '../models/service.model';

@Component({
  selector: 'app-search-service',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RippleModule,
    ButtonModule,
    SharedModule,
    WellSearchFormComponent,
    TableModule,
    ProgressSpinnerModule,
    DropdownModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
  ],
  templateUrl: './search-service.component.html',
  styleUrls: ['./search-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchServiceComponent extends Destroyable(Object) implements OnInit {
  @Input() depthUnitOption: DropDownOption[] = [];
  @Input() visible = false;
  @Output() dataEvent = new EventEmitter<any>();
  @Output() cancelClick = new EventEmitter<boolean>();
  @ViewChild('dataTable') dataTable!: Table;

  title = 'service.serviceScheduler';
  serviceSearchFormGroup!: FormGroup;
  searchValues: Array<ServiceSearch> = [];
  selectedItems: Array<ServiceSearch> = [];
  searchUwiUpi = '';
  spinnerShow = false;

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private searchServiceService: SearchServiceService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.serviceSearchFormGroup = this.fb.group({ search: [''] });
  }

  resetForm(): void {
    this.serviceSearchFormGroup.markAllAsTouched();
    this.serviceSearchFormGroup.reset(undefined, { emitEvent: false });
    this.serviceSearchFormGroup.markAsUntouched();
    this.serviceSearchFormGroup.markAsPristine();
    this.searchValues = [];
    this.selectedItems = [];
    this.dataTable.selection = [];
    this.dataTable.selectionKeys = {};
    this.cancelClick.emit(false);
  }

  select(): void {
    this.dataEvent.emit(this.removeDataKeys(this.selectedItems));
    if (this.selectedItems.length > 0) this.resetForm();
  }

  onSearch(term: string): void {
    this.searchUwiUpi = term.trim();
    this.applySearch(this.searchUwiUpi);
  }

  private applySearch(upiUwi: string): void {
    this.spinnerShow = true;
    this.searchServiceService
      .getAllBy(upiUwi)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (data) => {
          this.selectedItems = [];
          this.searchValues = this.addDataKeys(data);
          this.spinnerShow = false;
        },
        error: () => {
          this.spinnerShow = false;
          this.cd.detectChanges();
        },
        complete: () => {
          this.spinnerShow = false;
          this.cd.detectChanges();
        },
      });
  }

  private addDataKeys(data: ServiceSearch[]): ServiceSearch[] {
    return data.map((item) => {
      return { ...item, dataKey: [item.deliveryId, item.sampleType].join(',') };
    });
  }

  private removeDataKeys(data: ServiceSearch[]): ServiceSearch[] {
    return data.map((item) => {
      const { dataKey, ...serviceSearchItem } = item;
      return serviceSearchItem;
    });
  }
}
