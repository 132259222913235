import {TabInfo} from "../models/self-service-to-validation.model";
import { SampleInformation } from "../../../core/models/delivery-full.model";

export const selfServiceToValidationTable: TabInfo[] = [
  {
    header: 'selfServices.sampleInformationTable.zHzSdAT',
    tableName: 'zhZsDatBoxes',
    columns: [
      { field: 'id', header: '' },
      { field: 'verified', header: ''},
      { field: 'numberOfSet', header: 'selfServices.sampleInformationTable.set', required: true },
      { field: 'sampleTypeId', header: 'selfServices.sampleInformationTable.sampleType'},
      { field: 'objectiveSetId', header: 'selfServices.sampleInformationTable.objectiveSet'},
      { field: 'boxTypeId', header: 'selfServices.sampleInformationTable.boxType'},
      { field: 'boxNumber', header: 'selfServices.sampleInformationTable.boxNumber'},
      { field: 'boxTopDepth', header: 'selfServices.sampleInformationTable.depthTop'},
      { field: 'boxBottomDepth', header: 'selfServices.sampleInformationTable.depthBottom'},
      { field: 'formation', header: 'selfServices.sampleInformationTable.formation'},
      { field: 'numberSamplesPerBox', header: 'selfServices.sampleInformationTable.samplesPerBox'},
      { field: 'packagingTypeId', header: 'selfServices.sampleInformationTable.packagingType'},
      { field: 'depthUnitId', header: 'selfServices.sampleInformationTable.depthUnit'},
      { field: 'minCps', header: 'reception.sampleInformationTable.minCps'},
      { field: 'maxCps', header: 'reception.sampleInformationTable.maxCps'},
      { field: 'containerId', header: 'reception.sampleInformationTable.containerCode'},
      { field: 'palletId', header: 'reception.sampleInformationTable.pallet'},
      { field: 'locationId', header: 'reception.sampleInformationTable.location'},
      { field: 'sampleUnitId', header: ''},
      { field: 'createdAt', header: ''},
    ],
    data: [],
    childTable:{
      columns:[
        { field: 'validated', header: ''},
        { field: 'sampleTopDepth', header: 'verification.table.headers.sampleTop' },
        { field: 'sampleBottomDepth', header: 'verification.table.headers.sampleBottom'},
        { field: 'interval', header: 'verification.table.headers.interval'},
        { field: 'weight', header: 'verification.table.headers.originalWeight'},
        { field: 'minimumWeight', header: 'verification.table.headers.minimumWeight'},
        { field: 'remainingWeight', header: 'verification.table.headers.remainingWeight'},
        { field: 'sampleLabel', header: 'verification.table.headers.sampleLabel'},
        { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
        { field: 'containerId', header: 'verification.table.headers.containerCode'},
        { field: 'depthUnitId', header: 'verification.table.headers.depthUnit'},
        { field: 'weightUnitId', header: 'verification.table.headers.weightUnit'},
        { field: 'printSample', header: ''},
      ]
    }
  },
  {
    header: 'selfServices.sampleInformationTable.core',
    tableName: 'coreBoxes',
    columns: [
      { field: 'id', header: '' },
      { field: 'verified', header: ''},
      { field: 'boxTypeId', header: 'selfServices.sampleInformationTable.boxType'},
      { field: 'boxNumber', header: 'selfServices.sampleInformationTable.boxNumber'},
      { field: 'boxTopDepth', header: 'selfServices.sampleInformationTable.depthTop'},
      { field: 'boxBottomDepth', header: 'selfServices.sampleInformationTable.depthBottom'},
      { field: 'tubesPerBox', header: 'selfServices.sampleInformationTable.tubesPerBox'},
      { field: 'numberOfCores', header: 'selfServices.sampleInformationTable.numberOfCores'},
      { field: 'sectionTypeId', header: 'selfServices.sampleInformationTable.sectionTypes'},
      { field: 'formation', header: 'selfServices.sampleInformationTable.formation'},
      { field: 'diameter', header: 'selfServices.sampleInformationTable.diameter'},
      { field: 'recoveryPercent', header: 'selfServices.sampleInformationTable.netInterval'},
      { field: 'packagingTypeId', header: 'selfServices.sampleInformationTable.typeOfPackaging'},
      { field: 'technicalInfoId', header: 'selfServices.sampleInformationTable.technicalInformation'},
      { field: 'mediumDeliveryInfoId', header: 'selfServices.sampleInformationTable.mediumDelivery'},
      { field: 'depthUnitId', header: 'selfServices.sampleInformationTable.depthUnit'},
      { field: 'lengthUnitId', header: 'selfServices.sampleInformationTable.lengthUnit'},
      { field: 'minCps', header: 'reception.sampleInformationTable.minCps'},
      { field: 'maxCps', header: 'reception.sampleInformationTable.maxCps'},
      { field: 'containerId', header: 'reception.sampleInformationTable.containerCode'},
      { field: 'palletId', header: 'reception.sampleInformationTable.pallet'},
      { field: 'locationId', header: 'reception.sampleInformationTable.location'},
      { field: 'createdAt', header: ''},
    ],
    data: [],
    childTable:{
      columns:[
        { field: 'validated', header: ''},
        { field: 'sampleTopDepth', header: 'verification.table.headers.depthTop' },
        { field: 'sampleBottomDepth', header: 'verification.table.headers.depthBottom'},
        { field: 'interval', header: 'verification.table.headers.interval'},
        { field: 'coreNumber', header: 'verification.table.headers.coreNumber'},
        { field: 'topPreservedDepth', header: 'verification.table.headers.topPreservedDepth'},
        { field: 'botomPreservedDepth', header: 'verification.table.headers.bottomPreservedDepth'},
        { field: 'topMissingDepth', header: 'verification.table.headers.topMissingDepth'},
        { field: 'bottomMissinDepth', header: 'verification.table.headers.bottomMissingDepth'},
        { field: 'physicalStatusId', header: 'verification.table.headers.physicalStatus'},
        { field: 'sampleLabel', header: 'verification.table.headers.sampleLabel'},
        { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
        { field: 'containerId', header: 'verification.table.headers.containerCode'},
        { field: 'depthUnitId', header: 'verification.table.headers.depthUnit'},
        { field: 'printSample', header: ''},
      ]
    }
  },
  {
    header: 'selfServices.sampleInformationTable.preserved',
    tableName: 'preservedBoxes',
    columns: [
      { field: 'id', header: '' },
      { field: 'verified', header: ''},
      { field: 'boxTypeId', header: 'selfServices.sampleInformationTable.boxType'},
      { field: 'boxNumber', header: 'selfServices.sampleInformationTable.boxNumber'},
      { field: 'boxTopDepth', header: 'selfServices.sampleInformationTable.depthTop'},
      { field: 'boxBottomDepth', header: 'selfServices.sampleInformationTable.depthBottom'},
      { field: 'tubesPerBox', header: 'selfServices.sampleInformationTable.tubesPerBox'},
      { field: 'numberOfCores', header: 'selfServices.sampleInformationTable.numberOfCores'},
      { field: 'sectionTypeId', header: 'selfServices.sampleInformationTable.sectionTypes'},
      { field: 'formation', header: 'selfServices.sampleInformationTable.formation'},
      { field: 'diameter', header: 'selfServices.sampleInformationTable.diameter'},
      { field: 'packagingTypeId', header: 'selfServices.sampleInformationTable.typeOfPackaging'},
      { field: 'mediumDeliveryInfoId', header: 'selfServices.sampleInformationTable.mediumDelivery'},
      { field: 'lengthUnitId', header: 'selfServices.sampleInformationTable.lengthUnit'},
      { field: 'depthUnitId', header: 'selfServices.sampleInformationTable.depthUnit'},
      { field: 'minCps', header: 'reception.sampleInformationTable.minCps'},
      { field: 'maxCps', header: 'reception.sampleInformationTable.maxCps'},
      { field: 'containerId', header: 'reception.sampleInformationTable.containerCode'},
      { field: 'palletId', header: 'reception.sampleInformationTable.pallet'},
      { field: 'locationId', header: 'reception.sampleInformationTable.location'},
      { field: 'createdAt', header: ''},
    ],
    data: [],
    childTable:{
      columns:[
        { field: 'validated', header: ''},
        { field: 'coreNumber', header: 'verification.table.headers.coreNumber' },
        { field: 'topPreservedDepth', header: 'verification.table.headers.preservedTop'},
        { field: 'botomPreservedDepth', header: 'verification.table.headers.preservedBottom'},
        { field: 'sampleLabel', header: 'verification.table.headers.sampleLabel'},
        { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
        { field: 'containerId', header: 'verification.table.headers.containerCode'},
        { field: 'depthUnitId', header: 'verification.table.headers.depthUnit'},
        { field: 'printSample', header: ''},
      ]
    }
  },
  {
    header: 'selfServices.sampleInformationTable.plug',
    tableName: 'plugBoxes',
    columns: [
      { field: 'id', header: '' },
      { field: 'verified', header: ''},
      { field: 'deliveryId', header: '' },
      { field: 'boxTypeId', header: 'selfServices.sampleInformationTable.boxType'},
      { field: 'boxNumber', header: 'selfServices.sampleInformationTable.boxNumber'},
      { field: 'boxTopDepth', header: 'selfServices.sampleInformationTable.depthTop'},
      { field: 'boxBottomDepth', header: 'selfServices.sampleInformationTable.depthBottom'},
      { field: 'samplesPerBox', header: 'selfServices.sampleInformationTable.tubesPerBox'},
      { field: 'length', header: 'selfServices.sampleInformationTable.numberOfCores'},
      { field: 'diameter', header: 'selfServices.sampleInformationTable.diameter'},
      { field: 'endCupFractionId', header: 'selfServices.sampleInformationTable.endCupFraction'},
      { field: 'extractionPositionId', header: 'selfServices.sampleInformationTable.extractionPosition'},
      { field: 'formation', header: 'selfServices.sampleInformationTable.formation'},
      { field: 'packagingTypeId', header: 'selfServices.sampleInformationTable.typeOfPackaging'},
      { field: 'lengthUnitId', header: 'selfServices.sampleInformationTable.lengthUnit'},
      { field: 'depthUnitId', header: 'selfServices.sampleInformationTable.depthUnit'},
      { field: 'minCps', header: 'reception.sampleInformationTable.minCps'},
      { field: 'maxCps', header: 'reception.sampleInformationTable.maxCps'},
      { field: 'containerId', header: 'reception.sampleInformationTable.containerCode'},
      { field: 'palletId', header: 'reception.sampleInformationTable.pallet'},
      { field: 'locationId', header: 'reception.sampleInformationTable.location'},
      { field: 'createdAt', header: ''},
    ],
    data: [],
    childTable:{
      columns:[
        { field: 'validated', header: ''},
        { field: 'sampleName', header: 'verification.table.headers.sampleName' },
        { field: 'locality', header: 'verification.table.headers.locality'},
        { field: 'section', header: 'verification.table.headers.section'},
        { field: 'depth', header: 'verification.table.headers.depth'},
        { field: 'weight', header: 'verification.table.headers.weight'},
        { field: 'diameter', header: 'verification.table.headers.diameter'},
        { field: 'length', header: 'verification.table.headers.length'},
        { field: 'sampleStatusId', header: 'verification.table.headers.sampleStatus' },
        { field: 'extractionPositionId', header: 'verification.table.headers.extractionPosition'},
        { field: 'endCupExists', header: 'verification.table.headers.endCupExists'},
        { field: 'laboratoryCode', header: 'verification.table.headers.laboratory'},
        { field: 'sampleLabel', header: 'verification.table.headers.sampleLabel'},
        { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
        { field: 'containerId', header: 'verification.table.headers.containerCode'},
        { field: 'depthUnitId', header: 'verification.table.headers.depthUnit' },
        { field: 'lengthUnitId', header: 'verification.table.headers.lengthUnit'},
        { field: 'weightUnitId', header: 'verification.table.headers.weightUnit'},
        { field: 'printSample', header: ''},
      ]
    }
  },
  {
    header: 'selfServices.sampleInformationTable.swc',
    tableName: 'swcBoxes',
    columns: [
      { field: 'id', header: '' },
      { field: 'verified', header: ''},
      { field: 'boxTypeId', header: 'selfServices.sampleInformationTable.boxType'},
      { field: 'boxNumber', header: 'selfServices.sampleInformationTable.boxNumber'},
      { field: 'boxTopDepth', header: 'selfServices.sampleInformationTable.depthTop'},
      { field: 'boxBottomDepth', header: 'selfServices.sampleInformationTable.depthBottom'},
      { field: 'samplesPerBox', header: 'selfServices.sampleInformationTable.tubesPerBox'},
      { field: 'diameter', header: 'selfServices.sampleInformationTable.diameter'},
      { field: 'weight', header: 'selfServices.sampleInformationTable.weight'},
      { field: 'formation', header: 'selfServices.sampleInformationTable.formation'},
      { field: 'packagingTypeId', header: 'selfServices.sampleInformationTable.typeOfPackaging'},
      { field: 'depthUnitId', header: 'selfServices.sampleInformationTable.depthUnit'},
      { field: 'lengthUnitId', header: 'selfServices.sampleInformationTable.lengthUnit'},
      { field: 'weightUnitId', header: 'selfServices.sampleInformationTable.weightUnit'},
      { field: 'minCps', header: 'reception.sampleInformationTable.minCps'},
      { field: 'maxCps', header: 'reception.sampleInformationTable.maxCps'},
      { field: 'containerId', header: 'reception.sampleInformationTable.containerCode'},
      { field: 'palletId', header: 'reception.sampleInformationTable.pallet'},
      { field: 'locationId', header: 'reception.sampleInformationTable.location'},
      { field: 'createdAt', header: ''},
    ],
    data: [],
    childTable:{
      columns:[
        { field: 'validated', header: ''},
        { field: 'depth', header: 'verification.table.headers.depth' },
        { field: 'weight', header: 'verification.table.headers.weight'},
        { field: 'diameter', header: 'verification.table.headers.diameter'},
        { field: 'sampleAtSameDepth', header: 'verification.table.headers.samplesAtSameDepth'},
        { field: 'sampleLabel', header: 'verification.table.headers.sampleLabel'},
        { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
        { field: 'containerId', header: 'verification.table.headers.containerCode'},
        { field: 'depthUnitId', header: 'verification.table.headers.depthUnit' },
        { field: 'weightUnitId', header: 'verification.table.headers.weightUnit'},
        { field: 'printSample', header: ''},
      ]
    }
  },
  {
    header: 'selfServices.sampleInformationTable.fluid',
    tableName: 'fluidBoxes',
    columns: [
      { field: 'id', header: '' },
      { field: 'verified', header: ''},
      { field: 'fluidTypeId', header: 'selfServices.sampleInformationTable.fluidTypes'},
      { field: 'boxTypeId', header: 'selfServices.sampleInformationTable.boxType'},
      { field: 'boxNumber', header: 'selfServices.sampleInformationTable.boxNumber'},
      { field: 'boxTopDepth', header: 'selfServices.sampleInformationTable.depthTop'},
      { field: 'boxBottomDepth', header: 'selfServices.sampleInformationTable.depthBottom'},
      { field: 'numberOfBottles', header: 'selfServices.sampleInformationTable.numberOfBottles'},
      { field: 'samplingInterval', header: 'selfServices.sampleInformationTable.samplingInterval'},
      { field: 'samplingSite', header: 'selfServices.sampleInformationTable.samplingSite'},
      { field: 'formation', header: 'selfServices.sampleInformationTable.formation'},
      { field: 'formationOperation', header: 'selfServices.sampleInformationTable.formationOperation'},
      { field: 'samplingMethodId', header: 'selfServices.sampleInformationTable.samplingMethod'},
      { field: 'packagingTypeId', header: 'selfServices.sampleInformationTable.typeOfPackaging'},
      { field: 'depthUnitId', header: 'selfServices.sampleInformationTable.depthUnit'},
      { field: 'minCps', header: 'reception.sampleInformationTable.minCps'},
      { field: 'maxCps', header: 'reception.sampleInformationTable.maxCps'},
      { field: 'containerId', header: 'reception.sampleInformationTable.containerCode'},
      { field: 'palletId', header: 'reception.sampleInformationTable.pallet'},
      { field: 'locationId', header: 'reception.sampleInformationTable.location'},
      { field: 'createdAt', header: ''},
    ],
    data: [],
    childTable:{
      columns:[
        { field: 'validated', header: ''},
        { field: 'sampleNumber', header: 'verification.table.headers.sampleNumber' },
        { field: 'sampleTopDepth', header: 'verification.table.headers.depthTop'},
        { field: 'sampleBottomDepth', header: 'verification.table.headers.bottomDepth'},
        { field: 'interval', header: 'verification.table.headers.interval'},
        { field: 'fluidVolume', header: 'verification.table.headers.fluidVolume'},
        { field: 'api', header: 'verification.table.headers.api'},
        { field: 'bsw', header: 'verification.table.headers.bsw'},
        { field: 'ph', header: 'verification.table.headers.ph'},
        { field: 'salinity', header: 'verification.table.headers.salinity' },
        { field: 'sampleAtSameDepth', header: 'verification.table.headers.samplesAtSameDepth'},
        { field: 'sampleLabel', header: 'verification.table.headers.sampleLabel'},
        { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
        { field: 'containerId', header: 'verification.table.headers.containerCode' },
        { field: 'depthUnitId', header: 'verification.table.headers.depthUnit'},
        { field: 'volumeUnitId', header: 'verification.table.headers.volumeUnit'},
        { field: 'printSample', header: ''},
      ]
    }
  },
  {
    header: 'selfServices.sampleInformationTable.residue',
    tableName: 'residueBoxes',
    columns: [
      { field: 'id', header: '' },
      { field: 'verified', header: ''},
      { field: 'boxTypeId', header: 'selfServices.sampleInformationTable.boxType'},
      { field: 'boxNumber', header: 'selfServices.sampleInformationTable.boxNumber'},
      { field: 'boxTopDepth', header: 'selfServices.sampleInformationTable.depthTop'},
      { field: 'boxBottomDepth', header: 'selfServices.sampleInformationTable.depthBottom'},
      { field: 'samplesPerBox', header: 'selfServices.sampleInformationTable.samplesPerBox'},
      { field: 'analysisPerformed', header: 'selfServices.sampleInformationTable.analysisPerformance'},
      { field: 'laboratory', header: 'selfServices.sampleInformationTable.laboratory'},
      { field: 'weight', header: 'selfServices.sampleInformationTable.weight'},
      { field: 'formation', header: 'selfServices.sampleInformationTable.formation'},
      { field: 'packagingTypeId', header: 'selfServices.sampleInformationTable.typeOfPackaging'},
      { field: 'weightUnitId', header: 'selfServices.sampleInformationTable.weightUnit'},
      { field: 'depthUnitId', header: 'selfServices.sampleInformationTable.depthUnit'},
      { field: 'minCps', header: 'reception.sampleInformationTable.minCps'},
      { field: 'maxCps', header: 'reception.sampleInformationTable.maxCps'},
      { field: 'containerId', header: 'reception.sampleInformationTable.containerCode'},
      { field: 'palletId', header: 'reception.sampleInformationTable.pallet'},
      { field: 'locationId', header: 'reception.sampleInformationTable.location'},
      { field: 'createdAt', header: ''},
    ],
    data: [],
    childTable:{
      columns:[
        { field: 'validated', header: ''},
        { field: 'sampleName', header: 'verification.table.headers.sampleName' },
        { field: 'sampleTopDepth', header: 'verification.table.headers.depthTop'},
        { field: 'sampleBottomDepth', header: 'verification.table.headers.bottomDepth'},
        { field: 'interval', header: 'verification.table.headers.interval'},
        { field: 'weight', header: 'verification.table.headers.weight'},
        { field: 'section', header: 'verification.table.headers.section'},
        { field: 'locality', header: 'verification.table.headers.locality'},
        { field: 'station', header: 'verification.table.headers.station' },
        { field: 'analysisPerformed', header: 'verification.table.headers.analysisPerformed'},
        { field: 'laboratory', header: 'verification.table.headers.laboratory'},
        { field: 'samplesAtSameDepth', header: 'verification.table.headers.samplesAtSameDepth'},
        { field: 'sampleLabel', header: 'verification.table.headers.sampleLabel' },
        { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
        { field: 'containerId', header: 'verification.table.headers.containerCode'},
        { field: 'depthUnitId', header: 'verification.table.headers.depthUnit' },
        { field: 'weightUnitId', header: 'verification.table.headers.weightUnit'},
        { field: 'printSample', header: ''},
      ]
    }
  },
  {
    header: 'selfServices.sampleInformationTable.petrographic',
    tableName: 'petrographicPlatesBoxes',
    columns: [
      { field: 'id', header: '' },
      { field: 'verified', header: ''},
      { field: 'deliveryId', header: ''},
      { field: 'boxTypeId', header: 'selfServices.sampleInformationTable.boxType'},
      { field: 'boxNumber', header: 'selfServices.sampleInformationTable.boxNumber'},
      { field: 'boxTopDepth', header: 'selfServices.sampleInformationTable.depthTop'},
      { field: 'boxBottomDepth', header: 'selfServices.sampleInformationTable.depthBottom'},
      { field: 'samplesPerBox', header: 'selfServices.sampleInformationTable.samplesPerBox'},
      { field: 'sampleOriginId', header: 'selfServices.sampleInformationTable.sampleOrigin'},
      { field: 'stain', header: 'selfServices.sampleInformationTable.stain'},
      { field: 'analysisPerformed', header: 'selfServices.sampleInformationTable.analysisPerformed'},
      { field: 'laboratory', header: 'selfServices.sampleInformationTable.laboratory'},
      { field: 'formation', header: 'selfServices.sampleInformationTable.formation'},
      { field: 'packagingTypeId', header: 'selfServices.sampleInformationTable.typeOfPackaging'},
      { field: 'depthUnitId', header: 'selfServices.sampleInformationTable.depthUnit'},
      { field: 'minCps', header: 'reception.sampleInformationTable.minCps'},
      { field: 'maxCps', header: 'reception.sampleInformationTable.maxCps'},
      { field: 'containerId', header: 'reception.sampleInformationTable.containerCode'},
      { field: 'palletId', header: 'reception.sampleInformationTable.pallet'},
      { field: 'locationId', header: 'reception.sampleInformationTable.location'},
      { field: 'createdAt', header: ''},
    ],
    data: [],
    childTable:{
      columns:[
        { field: 'validated', header: ''},
        { field: 'sampleName', header: 'verification.table.headers.sampleName' },
        { field: 'plateTypeId', header: 'verification.table.headers.plateType' },
        { field: 'sampleTopDepth', header: 'verification.table.headers.depthTop'},
        { field: 'sampleBottomDepth', header: 'verification.table.headers.bottomDepth'},
        { field: 'lithology', header: 'verification.table.headers.lithology'},
        { field: 'analysisPerformed', header: 'verification.table.headers.analysisPerformed'},
        { field: 'preparationType', header: 'verification.table.headers.preparationType'},
        { field: 'laboratory', header: 'verification.table.headers.laboratory'},
        { field: 'sampleLabel', header: 'verification.table.headers.sampleLabel' },
        { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
        { field: 'containerId', header: 'verification.table.headers.containerCode'},
        { field: 'depthUnitId', header: 'verification.table.headers.depthUnit' },
        { field: 'weightUnitId', header: 'verification.table.headers.weightUnit'},
        { field: 'printSample', header: ''},
      ]
    }
  },
  {
    header: 'selfServices.sampleInformationTable.biostratigraphic',
    tableName: 'biostratigraphicBoxes',
    columns: [
      { field: 'id', header: '' },
      { field: 'verified', header: ''},
      { field: 'deliveryId', header: ''},
      { field: 'boxTypeId', header: 'selfServices.sampleInformationTable.boxType'},
      { field: 'boxNumber', header: 'selfServices.sampleInformationTable.boxNumber'},
      { field: 'boxTopDepth', header: 'selfServices.sampleInformationTable.depthTop'},
      { field: 'boxBottomDepth', header: 'selfServices.sampleInformationTable.depthBottom'},
      { field: 'samplesPerBox', header: 'selfServices.sampleInformationTable.samplesPerBox'},
      { field: 'sampleOriginId', header: 'selfServices.sampleInformationTable.sampleOrigin'},
      { field: 'analysisPerformed', header: 'selfServices.sampleInformationTable.analysisPerformed'},
      { field: 'laboratory', header: 'selfServices.sampleInformationTable.laboratory'},
      { field: 'formation', header: 'selfServices.sampleInformationTable.formation'},
      { field: 'packagingTypeId', header: 'selfServices.sampleInformationTable.typeOfPackaging'},
      { field: 'depthUnitId', header: 'selfServices.sampleInformationTable.depthUnit'},
      { field: 'minCps', header: 'reception.sampleInformationTable.minCps'},
      { field: 'maxCps', header: 'reception.sampleInformationTable.maxCps'},
      { field: 'containerId', header: 'reception.sampleInformationTable.containerCode'},
      { field: 'palletId', header: 'reception.sampleInformationTable.pallet'},
      { field: 'locationId', header: 'reception.sampleInformationTable.location'},
      { field: 'createdAt', header: ''},
    ],
    data: [],
    childTable:{
      columns:[
        { field: 'validated', header: ''},
        { field: 'sampleName', header: 'verification.table.headers.sampleName' },
        { field: 'plateTypeId', header: 'verification.table.headers.plateType' },
        { field: 'sampleTopDepth', header: 'verification.table.headers.depthTop'},
        { field: 'sampleBottomDepth', header: 'verification.table.headers.bottomDepth'},
        { field: 'lithology', header: 'verification.table.headers.lithology'},
        { field: 'analysisPerformed', header: 'verification.table.headers.analysisPerformed'},
        { field: 'preparationType', header: 'verification.table.headers.preparationType'},
        { field: 'laboratory', header: 'verification.table.headers.laboratory'},
        { field: 'sampleLabel', header: 'verification.table.headers.sampleLabel' },
        { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
        { field: 'containerId', header: 'verification.table.headers.containerCode'},
        { field: 'depthUnitId', header: 'verification.table.headers.depthUnit' },
        { field: 'printSample', header: ''},
      ]
    }
  },
  {
    header: 'selfServices.sampleInformationTable.surfaceSamples',
    tableName: 'surfaceSampleBoxes',
    columns: [
      { field: 'verified', header: ''},
      { field: 'id', header: 'ID' },
      { field: 'boxTypeId', header: 'selfServices.sampleInformationTable.boxType'},
      { field: 'locality', header: 'selfServices.sampleInformationTable.locality'},
      { field: 'section', header: 'selfServices.sampleInformationTable.section'},
      { field: 'boxNumber', header: 'selfServices.sampleInformationTable.boxNumber'},
      { field: 'boxTopDepth', header: 'selfServices.sampleInformationTable.depthTop'},
      { field: 'boxBottomDepth', header: 'selfServices.sampleInformationTable.depthBottom'},
      { field: 'samplesPerBox', header: 'selfServices.sampleInformationTable.samplesPerBox'},
      { field: 'formation', header: 'selfServices.sampleInformationTable.formation'},
      { field: 'basin', header: 'selfServices.sampleInformationTable.basin'},
      { field: 'latitude', header: 'selfServices.sampleInformationTable.latitude'},
      { field: 'longitude', header: 'selfServices.sampleInformationTable.longitude'},
      { field: 'north', header: 'selfServices.sampleInformationTable.north'},
      { field: 'east', header: 'selfServices.sampleInformationTable.east'},
      { field: 'origin', header: 'selfServices.sampleInformationTable.origin'},
      { field: 'packagingTypeId', header: 'selfServices.sampleInformationTable.typeOfPackaging'},
      { field: 'depthUnitId', header: 'selfServices.sampleInformationTable.depthUnit'},
      { field: 'minCps', header: 'reception.sampleInformationTable.minCps'},
      { field: 'maxCps', header: 'reception.sampleInformationTable.maxCps'},
      { field: 'containerId', header: 'reception.sampleInformationTable.containerCode'},
      { field: 'palletId', header: 'reception.sampleInformationTable.pallet'},
      { field: 'locationId', header: 'reception.sampleInformationTable.location'},
      { field: 'createdAt', header: ''},
    ],
    data: [],
    childTable:{
      columns:[
        { field: 'validated', header: ''},
        { field: 'sampleName', header: 'verification.table.headers.sampleName' },
        { field: 'plateTypeId', header: 'verification.table.headers.plateType' },
        { field: 'sampleTopDepth', header: 'verification.table.headers.depthTop'},
        { field: 'sampleBottomDepth', header: 'verification.table.headers.depthBottom'},
        { field: 'plate', header: 'verification.table.headers.plate'},
        { field: 'weight', header: 'verification.table.headers.weight'},
        { field: 'samplingDepth', header: 'verification.table.headers.samplingDepth'},
        { field: 'sampleAtSameDepth', header: 'verification.table.headers.samplesAtSameDepth'},
        { field: 'sampleLabel', header: 'verification.table.headers.sampleLabel' },
        { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
        { field: 'containerId', header: 'verification.table.headers.containerCode'},
        { field: 'weightUnitId', header: 'verification.table.headers.weightUnit'},
        { field: 'depthUnitId', header: 'verification.table.headers.depthUnit' },
        { field: 'printSample', header: ''},
      ]
    }
  },
  {
    header: 'selfServices.sampleInformationTable.pistonCore',
    tableName: 'pistonCoreBoxes',
    columns: [
      { field: 'id', header: '' },
      { field: 'verified', header: ''},
      { field: 'boxTypeId', header: 'selfServices.sampleInformationTable.boxType'},
      { field: 'boxTopDepth', header: 'selfServices.sampleInformationTable.depthTop'},
      { field: 'boxBottomDepth', header: 'selfServices.sampleInformationTable.depthBottom'},
      { field: 'formation', header: 'selfServices.sampleInformationTable.formation'},
      { field: 'boxNumber', header: 'selfServices.sampleInformationTable.boxNumber'},
      { field: 'location', header: 'selfServices.sampleInformationTable.location'},
      { field: 'section', header: 'selfServices.sampleInformationTable.section'},
      { field: 'sampleName', header: 'selfServices.sampleInformationTable.sampleName'},
      { field: 'diameter', header: 'selfServices.sampleInformationTable.diameter'},
      { field: 'penetrationLength', header: 'selfServices.sampleInformationTable.penetrationLength'},
      { field: 'recoveryLength', header: 'selfServices.sampleInformationTable.recoveredLength'},
      { field: 'weight', header: 'selfServices.sampleInformationTable.weight'},
      { field: 'shotDate', header: 'selfServices.sampleInformationTable.shotDate'},
      { field: 'samplesPerBox', header: 'selfServices.sampleInformationTable.samplesPerBox'},
      { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
      { field: 'depthUnitId', header: 'selfServices.sampleInformationTable.depthUnit'},
      { field: 'lengthUnitId', header: 'selfServices.sampleInformationTable.lengthUnit'},
      { field: 'weightUnitId', header: 'selfServices.sampleInformationTable.weightUnit'},
      { field: 'minCps', header: 'reception.sampleInformationTable.minCps'},
      { field: 'maxCps', header: 'reception.sampleInformationTable.maxCps'},
      { field: 'containerId', header: 'reception.sampleInformationTable.containerCode'},
      { field: 'palletId', header: 'reception.sampleInformationTable.pallet'},
      { field: 'locationId', header: 'reception.sampleInformationTable.location'},
      { field: 'createdAt', header: ''},
    ],
    data: [],
    childTable:{
      columns:[
        { field: 'validated', header: ''},
        { field: 'sampleName', header: 'verification.table.headers.sampleName' },
        { field: 'weight', header: 'verification.table.headers.weight'},
        { field: 'diameter', header: 'verification.table.headers.diameter'},
        { field: 'penetrationLength', header: 'verification.table.headers.penetrationLength'},
        { field: 'recoveredSedimentLength', header: 'verification.table.headers.recoveredSedimentLength'},
        { field: 'sampleAtSameDepth', header: 'verification.table.headers.samplesAtSameDepth'},
        { field: 'sampleLabel', header: 'verification.table.headers.sampleLabel' },
        { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
        { field: 'containerId', header: 'verification.table.headers.containerCode'},
        { field: 'weightUnitId', header: 'verification.table.headers.weightUnit'},
        { field: 'depthUnitId', header: 'verification.table.headers.depthUnit' },
        { field: 'lengthUnitId', header: 'verification.table.headers.lengthUnit' },
        { field: 'printSample', header: ''},
      ]
    }
  },
  {
    header: 'selfServices.sampleInformationTable.crude',
    tableName: 'crudeBoxes',
    columns: [
      { field: 'id', header: '' },
      { field: 'verified', header: ''},
      { field: 'deliveryId', header: ''},
      { field: 'boxTypeId', header: 'selfServices.sampleInformationTable.boxType'},
      { field: 'boxTopDepth', header: 'selfServices.sampleInformationTable.depthTop'},
      { field: 'boxBottomDepth', header: 'selfServices.sampleInformationTable.depthBottom'},
      { field: 'bottleNumber', header: 'selfServices.sampleInformationTable.numberOfBottles'},
      { field: 'samplingSite', header: 'selfServices.sampleInformationTable.samplingSite'},
      { field: 'formation', header: 'selfServices.sampleInformationTable.formation'},
      { field: 'samplingMethod', header: 'selfServices.sampleInformationTable.samplingMethod'},
      { field: 'depthUnitId', header: 'selfServices.sampleInformationTable.depthUnit'},
      { field: 'minCps', header: 'reception.sampleInformationTable.minCps'},
      { field: 'maxCps', header: 'reception.sampleInformationTable.maxCps'},
      { field: 'containerId', header: 'reception.sampleInformationTable.containerCode'},
      { field: 'palletId', header: 'reception.sampleInformationTable.pallet'},
      { field: 'locationId', header: 'reception.sampleInformationTable.location'},
      { field: 'createdAt', header: ''},
    ],
    data: [],
    childTable:{
      columns:[
        { field: 'validated', header: ''},
        { field: 'sampleNumber', header: 'verification.table.headers.sampleNumber' },
        { field: 'sampleName', header: 'verification.table.headers.sampleName' },
        { field: 'sampleTopDepth', header: 'verification.table.headers.depthTop'},
        { field: 'sampleBottomDepth', header: 'verification.table.headers.depthBottom'},
        { field: 'interval', header: 'verification.table.headers.interval'},
        { field: 'api', header: 'verification.table.headers.api'},
        { field: 'bsw', header: 'verification.table.headers.bsw'},
        { field: 'sampleAtSameDepth', header: 'verification.table.headers.samplesAtSameDepth'},
        { field: 'sampleLabel', header: 'verification.table.headers.sampleLabel' },
        { field: 'packagingTypeId', header: 'verification.table.headers.packagingType'},
        { field: 'containerId', header: 'verification.table.headers.containerCode'},
        { field: 'printSample', header: ''},
      ]
    }
  },
];
