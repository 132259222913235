import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';

import { DropDownOption } from 'src/app/core/models/drop-down-option.model';
import { ToastService } from 'src/app/services/toast.service';

import { TranslationComponent } from '../translation/translation.component';

@Component({
  selector: 'app-dropdown-editable',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationComponent,
    TranslateModule,
    DropdownModule,
  ],
  templateUrl: './dropdown-editable.component.html',
  styleUrls: ['./dropdown-editable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownEditableComponent implements ControlValueAccessor, OnInit {
  @Input() tableFormGroup!: FormGroup;
  @Input() options: DropDownOption[] = [];
  @Input() type!: string;
  @Input() filter = false;
  @Input() filterBy = '';
  @Input() set isDisabled(value: boolean) {
    if (value) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  control = new UntypedFormControl();
  onFocusValue!: string;
  value: any;

  onTouched!: (value: any) => void;
  onChange!: (value: any) => void;

  constructor(
    private ngControl: NgControl,
    private primeNGConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private cd: ChangeDetectorRef,
  ) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.setUpNgControl();
  }

  writeValue(val: string): void {
    this.control.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onFocusFun(value: string): void {
    this.onFocusValue = value;
  }

  onBlurFun(value: string, type: string, option: DropDownOption[]): void {
    if (value && value !== this.onFocusValue) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if (this.chekUniqObject(option, value) < 0) {
            option.push({ id: value, name: value });
            this.toastService.toastSuccess(`New ${type} type successfully added`);
          } else {
            this.toastService.toastWarn(`This ${type} type already added`);
            this.ngControl.control!.reset();
          }
        },
        reject: () => {
          this.toastService.toastWarn('You have rejected');
          this.ngControl.control!.reset();
        },
      });
    }
  }

  chekUniqObject(array: DropDownOption[], value: string): number {
    const result = array.findIndex(
      (item: DropDownOption) =>
        item.id.toLowerCase().replace(/\s/g, '') === value.toLowerCase().replace(/\s/g, ''),
    );

    return result;
  }

  private setUpNgControl(): void {
    this.ngControl.control!.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
    this.ngControl.control!.reset = (value, options) => {
      this.control.reset(value, options);
    };
  }
}
