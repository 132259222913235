<div class="search-container">
  <span class="p-input-icon-left p-float-label">
    <i class="pi pi-search"></i>
    <input
      id="float-input"
      type="text"
      pInputText
      [formControl]="searchMessageControl"
      [placeholder]="'adminSettings.logs.searchLabel' | translate"
      (keyup.enter)="onEnterPressed()"
    />
    <label for="float-input">{{ 'adminSettings.logs.searchLabel' | translate }}</label>

    <button
      pButton
      type="button"
      class="btn btn-secondary btn-search"
      (click)="onSearchBtnClicked()"
      label="{{ 'button.search' | translate }}"
    ></button>
  </span>

  <button
    pButton
    type="button"
    class="btn btn-primary"
    label="{{ 'button.filter' | translate }}"
    (click)="onFilterBtnClicked()"
  ></button>
</div>

<p-table
  [value]="logs"
  [paginator]="false"
  [rows]="10"
  [showCurrentPageReport]="true"
  currentPageReportTemplate=""
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'dateTime' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'message' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'type' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'service' | translate }}</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-log>
    <tr>
      <td>{{ log.dateTime | appDefaultDateFormat : true }}</td>
      <td>{{ log.message }}</td>
      <td>{{ log.type | appLogTypeDisplay }}</td>
      <td>{{ log.service | appLogServiceDisplay }}</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="100">{{ 'adminSettings.logs.emptyData' | translate }}</td>
    </tr>
  </ng-template>
</p-table>

<p-paginator
  (onPageChange)="onPageChanged($event)"
  [rows]="paginatorOptions.pageSize"
  [totalRecords]="paginatorOptions.count"
  [rowsPerPageOptions]="[10, 20, 30]"
  [dropdownAppendTo]="'body'"
></p-paginator>

<p-dialog
  header="{{ 'adminSettings.logs.filterDialog.title' | translate }}"
  [style]="{ width: '700px' }"
  [(visible)]="isFilterLogsPopupVisible"
  [modal]="true"
  [closable]="true"
  [draggable]="false"
  [formGroup]="filterFormGroup"
>
  <div class="filter-row">
    <span class="p-float-label">
      <p-calendar
        appDefaultCalendarDateFormat
        id="log-filter-start-date"
        appendTo="body"
        formControlName="startDate"
        [showTime]="false"
        [showIcon]="true"
        [styleClass]="'w-full'"
      ></p-calendar>
      <label for="log-filter-start-date">{{
        'adminSettings.logs.filterDialog.startDate' | translate
      }}</label>
    </span>

    <span class="p-float-label p-fluid">
      <p-calendar
        appDefaultCalendarDateFormat
        id="log-filter-end-date"
        appendTo="body"
        formControlName="endDate"
        [showTime]="false"
        [showIcon]="true"
        [styleClass]="'w-full'"
      ></p-calendar>
      <label for="log-filter-end-date">{{
        'adminSettings.logs.filterDialog.endDate' | translate
      }}</label>
    </span>
  </div>

  <div class="filter-row">
    <span class="p-float-label">
      <p-multiSelect
        inputId="log-types"
        appendTo="body"
        formControlName="types"
        optionLabel="name"
        optionValue="id"
        [options]="logTypes"
        [styleClass]="'w-full'"
      ></p-multiSelect>
      <label for="log-types">{{ 'adminSettings.logs.filterDialog.type' | translate }}</label>
    </span>

    <span class="p-float-label">
      <p-multiSelect
        inputId="log-services"
        appendTo="body"
        formControlName="services"
        optionLabel="name"
        optionValue="id"
        [options]="logServices"
        [styleClass]="'w-full'"
      ></p-multiSelect>
      <label for="log-services">{{ 'adminSettings.logs.filterDialog.service' | translate }}</label>
    </span>
  </div>

  <ng-template pTemplate="footer">
    <span class="error-message" *ngIf="isStartDateGreaterThanEndDate">
      {{ 'dataAccess.filter.validation.startDateGreaterThanEndDateMessage' | translate }}
    </span>
    <button
      pButton
      pRipple
      type="button"
      label="{{ 'button.clear' | translate }}"
      (click)="onClearBtnClicked()"
      class="btn btn-primary"
    ></button>

    <button
      pButton
      pRipple
      type="button"
      label="{{ 'button.apply' | translate }}"
      class="btn btn-secondary"
      [disabled]="isStartDateGreaterThanEndDate"
      (click)="onApplyBtnClicked()"
    ></button>
  </ng-template>
</p-dialog>

<div *ngIf="isLogsLoading" class="card spinner">
  <p-progressSpinner></p-progressSpinner>
</div>
