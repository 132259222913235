import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from 'src/app/core/api.consts';

import { DashboardFilterValue } from '../../sample-types-widget/sample-types-chart-filter/models/dashboard-filter-value.model';
import { WarehouseFilterModel } from '../warehouse-chart-filter/models/warehouse-filters.model';

@Injectable({
  providedIn: 'root',
})
export class WarehouseWidgetService {
  private serviceFilterUrl = `${SGC_API}/dashboards/warehouse`;
  private areasUrl = `${SGC_API}/dashboards/areas`;
  constructor(private http: HttpClient) {}

  getFilteredWarehouses(filteredData: WarehouseFilterModel): Observable<DashboardFilterValue[]> {
    const parameters = new HttpParams().appendAll(filteredData.area);

    return this.http.get<Array<DashboardFilterValue>>(`${this.serviceFilterUrl}`, {
      params: parameters,
    });
  }

  getAreas(): Observable<Array<string>> {
    return this.http.get<Array<string>>(this.areasUrl);
  }
}
