export interface ServicesInformation {
  applicationDate: Date;
  companyName: string;
  description: string;
}

export interface ServiceSearch {
  id?: string;
  deliveryId: string;
  type: string;
  uwiOrUpi: string;
  name: string;
  sampleType: string;
  totalBoxes: number;
  totalSamples: number;
  topDepth: number;
  bottomDepth: number;
  depthUnitId: string;
  sampleLocations?: string;
  boxLocations?: string;
  wellId: string;
  projectId: string | null;
  [key: string]: any;
}

export interface ServiceScheduling {
  id?: string;
  deliveryId?: string;
  serviceId?: string;
  type?: string;
  uwiOrUpi?: string;
  name?: string;
  sampleType?: string;
  totalBoxes?: number;
  totalSamples?: number;
  topDepth?: number;
  bottomDepth?: number;
  depthUnitId?: string;
  sampleLocations?: string | null;
  boxLocations?: string | null;
  dateOfService: Date | null;
  technicianId?: string | null;
  note?: string;
  wellId?: string | null;
  projectId?: string | null;
  serviceAttachments?: { attachmentId: string; note: string }[] | null;
  [key: string]: any;
}

export interface ServicesShape {
  service: ServicesInformation;
  serviceSchedules: [ServiceScheduling];
}
export interface ServicesTable {
  table: [ServiceScheduling];
}

export interface RowServiceSelectEvent {
  originalEvent: PointerEvent;
  index: number;
  data: ServiceSearch;
  type: string;
}

export class ServiceSchedulingShapeImpl implements ServiceScheduling {
  id?: string;
  deliveryId?: string;
  serviceId?: string;
  type?: string;
  uwiOrUpi?: string;
  name?: string;
  sampleType?: string;
  totalBoxes?: number;
  totalSamples?: number;
  topDepth?: number;
  bottomDepth?: number;
  depthUnitId?: string;
  sampleLocations?: string | null;
  boxLocations?: string | null;
  dateOfService: Date | null;
  technicianId?: string | null;
  note?: string;
  wellId?: string | null;
  projectId?: string | null;
  serviceAttachments?: { attachmentId: string; note: string }[];

  constructor(s: ServiceSearch) {
    this.deliveryId = s.deliveryId;
    this.type = s.type;
    this.uwiOrUpi = s.uwiOrUpi;
    this.name = s.name;
    this.sampleType = s.sampleType;
    this.totalBoxes = s.totalBoxes;
    this.totalSamples = s.totalSamples;
    this.topDepth = s.topDepth;
    this.bottomDepth = s.bottomDepth;
    this.depthUnitId = s.depthUnitId;
    this.sampleLocations = s.sampleLocations;
    this.boxLocations = s.boxLocations;
    this.dateOfService = null;
    this.technicianId = null;
    this.note = '';
    this.wellId = s.wellId;
    this.projectId = s.projectId;
    this.serviceAttachments = [{ attachmentId: '', note: '' }];
  }
}
