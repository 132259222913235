import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { RippleModule } from 'primeng/ripple';
import { merge } from 'rxjs';

import { CommonModule, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';

import { DropDownOption } from '../../../core/models/drop-down-option.model';
import { DateValidationService } from '../../../services/date-validation.service';
import { LocaleService } from '../../../services/locale.service';
import { DefaultCalendarDateFormatDirective } from '../../directives/default-calendar-date-format.directive';

@Component({
  selector: 'app-filter-option',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    TranslateModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    InputNumberModule,
    ButtonModule,
    RippleModule,
    CalendarModule,
    MultiSelectModule,
    CheckboxModule,
    DefaultCalendarDateFormatDirective,
  ],
  providers: [DatePipe],
  templateUrl: './filter-option.component.html',
  styleUrls: ['./filter-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterOptionComponent extends Destroyable(Object) implements OnInit {
  @Input() sampleTypes!: string[];
  @Input() stateOption!: DropDownOption[];
  @Input() filterOptionList!: { [key: string]: boolean };
  @Input() techniciansOption!: DropDownOption[];
  @Output() dialogSubmit: EventEmitter<any> = new EventEmitter<any>();

  isMaxDepthHigherThanMinDepth = false;
  isEndDatehHigherThanStartDate = false;
  value: boolean | null = null;
  formGroup!: FormGroup;
  statusList: string[] = ['receptionStatus', 'verificationStatus', 'validationStatus'];

  get defaultLocale(): string {
    return this.localeService.getDefaultLocale();
  }

  constructor(
    private localeService: LocaleService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dateValidationService: DateValidationService,
    private cd: ChangeDetectorRef,
  ) {
    super();
    this.initForm();
  }

  ngOnInit(): void {
    this.updateFormControl();
    this.validateDepth();
    this.validateDate();
  }

  resetForm(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.reset(undefined, { emitEvent: true });
    this.formGroup.markAsUntouched();
    this.formGroup.markAsPristine();
  }

  filterSelect(): void {
    const rawValue = this.formGroup.getRawValue();
    const startDateProperties = [
      'startDateOfDeliveryCreated',
      'receptionStartDate',
      'minDateOfService',
      'minDateOfAssignment',
    ];
    const endDateProperties = [
      'endDateOfDeliveryCreated',
      'receptionEndDate',
      'maxDateOfService',
      'maxDateOfAssignment',
    ];

    this.statusList.forEach((statusName) => {
      delete rawValue[`${statusName}Complete`];
      delete rawValue[`${statusName}Incomplete`];
    });

    startDateProperties
      .filter((prop) => !!rawValue[prop])
      .forEach((prop) => {
        const startDate = new Date(rawValue[prop]);
        rawValue[prop] = startDate.toISOString();
      });

    endDateProperties
      .filter((prop) => !!rawValue[prop])
      .forEach((prop) => {
        const endDate = new Date(rawValue[prop]);
        endDate.setDate(endDate.getDate() + 1);
        rawValue[prop] = endDate.toISOString();
      });

    this.dialogSubmit.emit(rawValue);
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      sampleType: null,
      minDepth: null,
      maxDepth: null,
    });
  }

  private updateFormControl(): void {
    if (this.filterOptionList['status']) {
      this.addControls('receptionStatus', null);
      this.addControls('verificationStatus', null);
      this.addControls('validationStatus', null);

      this.statusList.forEach((statusName) => {
        this.addControls(`${statusName}Complete`, false);
        this.addControls(`${statusName}Incomplete`, false);
      });

      this.initStatus();
    }

    if (this.filterOptionList['state']) {
      this.addControls('state', null);
    }

    if (this.filterOptionList['date']) {
      this.addControls('minDateOfAssignment', null);
      this.addControls('maxDateOfAssignment', null);
    }

    if (this.filterOptionList['technicianIds']) {
      this.addControls('technicianIds', null);
    }

    if (this.filterOptionList['deliveryCreated']) {
      this.addControls('startDateOfDeliveryCreated', null);
      this.addControls('endDateOfDeliveryCreated', null);
    }

    if (this.filterOptionList['receptionDate']) {
      this.addControls('receptionStartDate', null);
      this.addControls('receptionEndDate', null);
    }

    if (this.filterOptionList['dateService']) {
      this.addControls('minDateOfService', null);
      this.addControls('maxDateOfService', null);
    }
  }

  private addControls(newControlName: string, value: string | boolean | null): void {
    this.formGroup.addControl(newControlName, new FormControl(value));
  }

  private initStatus(): void {
    this.statusList.forEach((statusName) => {
      const completeControl = this.formGroup.get(`${statusName}Complete`);
      const incompleteControl = this.formGroup.get(`${statusName}Incomplete`);

      if (completeControl && incompleteControl) {
        merge(completeControl.valueChanges, incompleteControl.valueChanges)
          .pipe(this.takeUntilDestroyed())
          .subscribe(() => {
            this.updateStatus(statusName);
          });
      }
    });
  }

  private updateStatus(status: string): void {
    const completed = this.formGroup.get(`${status}Complete`)?.value;
    const incomplete = this.formGroup.get(`${status}Incomplete`)?.value;
    const statusControl = this.formGroup.get(`${status}`);

    if (completed && incomplete) {
      statusControl?.setValue(null);
    } else if (completed) {
      statusControl?.setValue(true);
    } else if (incomplete) {
      statusControl?.setValue(false);
    } else {
      statusControl?.setValue(null);
    }
  }

  private validateDepth(): void {
    this.formGroup.controls['minDepth'].valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((minDepthValue) => {
        const maxDepthValue = this.formGroup.controls['maxDepth'].value;
        this.isMaxDepthHigherThanMinDepth =
          minDepthValue !== undefined && maxDepthValue !== undefined
            ? minDepthValue > maxDepthValue || (minDepthValue !== 0 && maxDepthValue === 0)
            : false;
      });

    this.formGroup.controls['maxDepth'].valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((maxDepthValue) => {
        const minDepthValue = this.formGroup.controls['minDepth'].value;
        this.isMaxDepthHigherThanMinDepth =
          minDepthValue !== undefined && maxDepthValue !== undefined
            ? minDepthValue > maxDepthValue || (minDepthValue !== 0 && maxDepthValue === 0)
            : false;
      });
  }

  private validateDate(): void {
    const dateControls: [string, string, string][] = [
      ['receptionDate', 'receptionStartDate', 'receptionEndDate'],
      ['date', 'minDateOfAssignment', 'maxDateOfAssignment'],
      ['dateService', 'minDateOfService', 'maxDateOfService'],
    ];

    const currentControls = this.getCurrentDateControlName(dateControls);
    const startDateControlName = currentControls[1];
    const endDateControlName = currentControls[2];

    this.formGroup.controls[startDateControlName].valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((date) => {
        const startDateValue = new Date(date);
        const endDateValue = new Date(this.formGroup.controls[endDateControlName].value);
        this.isEndDatehHigherThanStartDate = this.dateValidationService.isEndDateBeforeStartDate(
          startDateValue,
          endDateValue,
        );
      });

    this.formGroup.controls[endDateControlName].valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((date) => {
        const startDateValue = new Date(this.formGroup.controls[startDateControlName].value);
        const endDateValue = new Date(date);
        this.isEndDatehHigherThanStartDate = this.dateValidationService.isEndDateBeforeStartDate(
          startDateValue,
          endDateValue,
        );
      });
  }

  private getCurrentDateControlName(
    dateArray: [string, string, string][],
  ): [string, string, string] {
    const controls = dateArray.find(([controlName]) => this.filterOptionList[controlName] === true);
    return controls || ['', '', ''];
  }
}
