import { Observable, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { User } from '../../features/admin-settings/user-table/user.model';
import { UserService } from '../../services/api/user.service';
import { UserManagementService } from '../../services/user-management.service';

@Injectable({
  providedIn: 'root',
})
export class AppLevelInfoResolver implements Resolve<User> {
  constructor(
    private userService: UserService,
    private userManagementService: UserManagementService,
  ) {}

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<User> {
    return this.userService.getCurrentUser().pipe(
      tap((userInfo) => {
        this.userManagementService.addUserInfo(userInfo);
      }),
    );
  }
}
