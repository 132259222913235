import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from '../../core/api.consts';
import { PrintDelivery } from '../../core/models/print-delivery.model';

@Injectable({
  providedIn: 'root',
})
export class PrintDeliveryService {
  private url = SGC_API;
  public deliveryUrl = `${this.url}/LabelGenerator/delivery`;
  constructor(private httpClient: HttpClient) {}

  printDelivery(printDelivery: PrintDelivery): Observable<Blob> {
    return this.httpClient.post<Blob>(this.deliveryUrl, printDelivery, {
      responseType: 'blob' as 'json',
    });
  }
}
