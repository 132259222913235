import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { map, Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { TokenStorageService } from '../../services/api/token-storage.service';
import { USE_OKTA_AUTH_TOKEN } from '../tokens/use-okta-auth.token';

@Injectable({
  providedIn: 'root',
})
export class PreventLoginAccessGuard implements CanActivate {
  constructor(
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private router: Router,
    private tokenStorageService: TokenStorageService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAuthenticatedObservable = this.useOktaAuth
      ? fromPromise(this.oktaAuth.isAuthenticated())
      : of(!!this.tokenStorageService.getAccessToken());

    return isAuthenticatedObservable.pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          return true;
        }

        this.router.navigate(['']);
        return false;
      }),
    );
  }
}
