import { MenuItem } from './models/menu-item.model';
import { RoutePath } from './route-paths.enum';

export const ROUTES: Array<MenuItem> = [
  {
    labelKey: 'sidebar.dashboard',
    icon: 'pi-table',
    url: RoutePath.DASHBOARD,
    isActive: true,
  },
  {
    labelKey: 'sidebar.dataAccess',
    icon: 'pi-search',
    url: RoutePath.DATA_ACCESS,
    hide: false,
  },
  {
    labelKey: 'sidebar.calendar',
    icon: 'pi-calendar',
    url: RoutePath.CALENDAR,
  },
  {
    labelKey: 'sidebar.selfService',
    icon: 'pi-download',
    url: RoutePath.SELF_SERVICE,
  },
  {
    labelKey: 'sidebar.reception',
    icon: 'pi-user',
    url: RoutePath.RECEPTION,
  },
  {
    labelKey: 'sidebar.verification',
    icon: 'pi-user',
    url: RoutePath.VERIFICATION,
  },
  {
    labelKey: 'sidebar.validation',
    icon: 'pi-user',
    url: RoutePath.VALIDATION,
  },
  {
    labelKey: 'sidebar.storage',
    icon: 'pi-user',
    url: RoutePath.STORAGE,
  },
  {
    labelKey: 'sidebar.assignment',
    icon: 'pi-user',
    url: RoutePath.ASSIGNMENT,
  },
  {
    labelKey: 'sidebar.service',
    icon: 'pi-user',
    url: RoutePath.SERVICE,
  },
  {
    labelKey: 'sidebar.sampleManagement',
    icon: 'pi-download',
    url: RoutePath.SAMPLE_MANAGEMENT,
    hide: true,
  },
  {
    labelKey: 'sidebar.visualization',
    icon: 'pi-desktop',
    url: RoutePath.VISUALIZATION,
  },
  {
    labelKey: 'sidebar.accountSettings',
    icon: 'pi-user',
    url: RoutePath.ACCOUNT_SETTINGS,
  },
  {
    labelKey: 'sidebar.adminSettings',
    icon: 'pi-user',
    url: RoutePath.ADMIN_SETTINGS,
  },
];
