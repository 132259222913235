import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import { AppComponent } from './app.component';
import { AppRoutingModule, oktaCallBackRoute, routes } from './app-routing.module';
import { oktaAuth, oktaStubAuth } from './core/api.consts';
import { HttpLoaderFactory } from './core/factories/http-loader.factory';
import { ApiErrorHandlerInterceptor } from './core/interceptors/api-error-handler.interceptor';
import { AuthInterceptor } from './core/interceptors/auth-interceptor';
import { BaseApiUrlInterceptor } from './core/interceptors/base-api-url.interceptor';
import { OktaAuthInterceptor } from './core/interceptors/okta-auth.interceptor';
import { AppConfigProvider } from './core/providers/app-config.provider';
import { API_FILE_SERVER_URL_TOKEN } from './core/tokens/api-file-server-url.token';
import { API_SERVER_URL_TOKEN } from './core/tokens/api-server-url.token';
import { USE_OKTA_AUTH_TOKEN } from './core/tokens/use-okta-auth.token';
import { isOktaAuth } from './core/utils/is-okta-auth.util';
import { LayoutComponent } from './layout/layout.component';
import { AuthenticationService } from './services/api/authentication.service';
import { TokenStorageService } from './services/api/token-storage.service';
import { UserManagementService } from './services/user-management.service';

function onAuthRequired(_oktaAuth: OktaAuth, injector: Injector) {
  const router = injector.get(Router);
  router.navigate(['/login']);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ConfirmDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    SelectButtonModule,
    TooltipModule,
    ToastModule,
    LayoutComponent,
    OktaAuthModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
  ],
  providers: [
    MessageService,
    ConfirmationService,
    {
      provide: OKTA_CONFIG,
      deps: [AppConfigProvider],
      useFactory: (appConfigProvider: AppConfigProvider) => ({
        oktaAuth: isOktaAuth(appConfigProvider) ? oktaAuth : oktaStubAuth,
        onAuthRequired,
      }),
    },
    {
      provide: API_SERVER_URL_TOKEN,
      deps: [AppConfigProvider],
      useFactory: (appConfigProvider: AppConfigProvider) =>
        appConfigProvider.appConfig.apiServerUrl,
    },
    {
      provide: API_FILE_SERVER_URL_TOKEN,
      deps: [AppConfigProvider],
      useFactory: (appConfigProvider: AppConfigProvider) =>
        appConfigProvider.appConfig.apiFileServerUrl,
    },
    {
      provide: USE_OKTA_AUTH_TOKEN,
      deps: [AppConfigProvider],
      useFactory: (appConfigProvider: AppConfigProvider) => isOktaAuth(appConfigProvider),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseApiUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      deps: [
        USE_OKTA_AUTH_TOKEN,
        HttpClient,
        TokenStorageService,
        UserManagementService,
        AuthenticationService,
        Router,
      ],
      useFactory: (
        useOktaAuth: boolean,
        httpClient: HttpClient,
        tokenStorageService: TokenStorageService,
        userManagementService: UserManagementService,
        authenticationService: AuthenticationService,
        router: Router,
      ) => {
        return useOktaAuth
          ? new OktaAuthInterceptor(httpClient)
          : new AuthInterceptor(
              tokenStorageService,
              userManagementService,
              authenticationService,
              router,
            );
      },
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (router: Router, appConfigProvider: AppConfigProvider) => () => {
        const dynamicRoutes = [...routes];

        if (isOktaAuth(appConfigProvider)) {
          dynamicRoutes.splice(-1, 0, oktaCallBackRoute);
        }

        router.resetConfig([...dynamicRoutes]);
      },
      multi: true,
      deps: [Router, AppConfigProvider],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
