<h1 class="title">{{ 'dataAccess.titles.' + title | translate }}</h1>
<form [formGroup]="searchForm">
  <div class="row search-container input-group-50 mb-4">
    <span class="p-input-icon-left p-float-label">
      <i class="pi pi-search"></i>
      <input
        id="float-input"
        formControlName="searchText"
        type="text"
        pInputText
        placeholder="Search"
        [(ngModel)]="searchText"
      />
      <label for="float-input">{{ 'button.search' | translate }}</label>
      <button
        pButton
        type="button"
        class="btn btn-secondary btn-search"
        (click)="search(filterOption)"
        label="{{ 'button.search' | translate }}"
      ></button>
    </span>

    <button
      pButton
      type="button"
      class="btn btn-primary"
      label="{{ 'button.filter' | translate }}"
      (click)="this.visiblePopup = true"
    ></button>
  </div>
</form>

<div *ngIf="spinnerShow" class="card spinner">
  <p-progressSpinner></p-progressSpinner>
</div>

<ng-container *ngIf="columns.length">
  <div class="table-container">
    <p-table
      #dt
      [columns]="columns"
      [lazy]="true"
      [value]="rows"
      [paginator]="false"
      [exportFilename]="'dataAccess'"
      [rows]="pageSize"
      [totalRecords]="totalRecords"
      (onPage)="onPageChange($event)"
      class="table-max-height"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of columns">{{ col.header | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
        <tr>
          <ng-container *ngFor="let col of columns">
            <td
              *ngIf="
                col.field !== 'attachedFiles' &&
                !this.isEditField(col.field) &&
                !this.isDateTransformField(col.field)
              "
            >
              {{
                row[col.field] === 'True' || row[col.field] === 'False'
                  ? getStatus(row[col.field])
                  : row[col.field]
              }}
            </td>
            <td *ngIf="this.isEditField(col.field)" style="min-width: 240px">
              <div *ngIf="col.field === 'serviceCompletedDate'; else noteBlock" class="edit-text">
                {{ row[col.field] | appDefaultDateFormat }}
              </div>
              <ng-template #noteBlock>
                <div class="edit-text">
                  {{ row[col.field] }}
                </div>
              </ng-template>

              <button
                pButton
                type="button"
                class="edit-btn btn"
                icon="pi pi-pencil"
                (click)="openDialog(row, col.field)"
              ></button>
            </td>
            <td
              class="attachment"
              *ngIf="col.field === 'attachedFiles'"
              (click)="handleClick(row[col.field])"
            >
              {{ row[col.field] ? row[col.field]['name'] : '' }}
            </td>
            <td *ngIf="this.isDateTransformField(col.field)">
              {{ row[col.field] | appDefaultDateFormat }}
            </td>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="100">{{ 'dataAccess.table.emptyData' | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-paginator
    #paginator
    [rows]="pageSize"
    [rowsPerPageOptions]="pageSizeOptions"
    [totalRecords]="totalRecords"
    (onPageChange)="onPageChange($event)"
  ></p-paginator>

  <button
    [disabled]="(!searchText && !isExportButtonEnabled()) || !rows.length"
    pButton
    type="button"
    class="btn btn-primary mt-5 btn-export"
    label="{{ 'button.exportToCsv' | translate }}"
    (click)="exportCsv()"
  ></button>
</ng-container>

<p-dialog
  header="{{ 'dataAccess.titles.' + title | translate }} {{
    'dataAccess.filter.filterOption' | translate
  }}"
  [closable]="true"
  [modal]="true"
  [(visible)]="visiblePopup"
  [style]="{ width: '45vw' }"
>
  <app-filter-option
    #filterOptionComponent
    (dialogSubmit)="search($event)"
    [stateOption]="stateOption"
    [sampleTypes]="sampleTypes"
    [filterOptionList]="filterOptionList"
    [techniciansOption]="techniciansFilterOptions"
  ></app-filter-option>
</p-dialog>

<p-dialog
  header="{{ 'dataAccess.titles.editNote' | translate }}"
  [style]="{ width: '700px' }"
  [(visible)]="displayNoteDialog"
  [modal]="true"
  [closable]="true"
  [draggable]="false"
>
  <div class="p-float-label row input-group-50 edit-box">
    <textarea
      *ngIf="displayNoteDialog"
      [(ngModel)]="editData"
      (ngModelChange)="onEditNoteChange($event)"
      class="w-full"
      rows="5"
      cols="30"
    ></textarea>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      (onClick)="onCancelEdit()"
      label="{{ 'button.cancel' | translate }}"
      styleClass="btn-primary"
    ></p-button>

    <p-button
      (click)="saveEdit()"
      label="{{ 'button.save' | translate }}"
      styleClass="btn btn-secondary"
      [disabled]="!isSaving"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  header="{{ 'dataAccess.titles.editCompletedDate' | translate }}"
  [style]="{ width: '500px' }"
  [(visible)]="displayCompletedDateDialog"
  [modal]="true"
  [closable]="true"
  [draggable]="false"
>
  <div class="p-float-label row input-group-50 edit-box date-container">
    <p-calendar
      *ngIf="displayCompletedDateDialog"
      appDefaultCalendarDateFormat
      [(ngModel)]="editData"
      (ngModelChange)="onEditDateChange($event)"
      appendTo="body"
      [showTime]="false"
      id="dateAssigned"
      [placeholder]="(editData | appDefaultDateFormat) || ''"
      class="w-full"
      [showIcon]="true"
    ></p-calendar>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      (onClick)="onCancelEdit()"
      label="{{ 'button.cancel' | translate }}"
      styleClass="btn-primary"
    ></p-button>

    <p-button
      (click)="saveEdit()"
      label="{{ 'button.save' | translate }}"
      styleClass="btn btn-secondary"
      [disabled]="!isSaving"
    ></p-button>
  </ng-template>
</p-dialog>
