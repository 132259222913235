import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { combineLatest, map } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OptionsTableComponent } from 'src/app/common/options-table/options-table.component';
import { OptionsTableContainerComponent } from 'src/app/common/options-table-container/options-table-container.component';
import { OptionsTableLocationComponent } from 'src/app/common/options-table-location/options-table-location.component';
import { OptionsTablePackagingTypesComponent } from 'src/app/common/options-table-packaging-types/options-table-packaging-types.component';
import { OptionsTablePalletComponent } from 'src/app/common/options-table-pallet/options-table-pallet.component';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';
import { ProjectOptionsService } from 'src/app/services/api/project-options.service';

import { OptionsTableProvidersComponent } from '../../common/options-table-providers/options-table-providers.component';
import { OptionsTableResponsibilityComponent } from '../../common/options-table-responsibility/options-table-responsibility.component';
import { OptionsTableTransportationComponent } from '../../common/options-table-transportation/options-table-transportation.component';
import { optionsTableEntityTitleMap } from '../../core/consts/options-table-entity-title-map.const';
import { OptionsTableEntityKey } from '../../core/enums/options-table-entity-key.enum';
import { IdName } from '../../core/models/id-name.model';
import { ProviderType } from '../../core/models/provider.model';
import { WellInfoService } from '../../services/api/well-info.service';
import { OptionsTableEntityKeyToActionMapService } from '../../services/options-table-entity-key-to-action-map.service';
import { ToastService } from '../../services/toast.service';
import { LogsComponent } from './logs/logs.component';
import { UserTableComponent } from './user-table/user-table.component';
import { WellManagementComponent } from './well-management/well-management.component';

@Component({
  selector: 'app-admin-settings',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    TranslateModule,
    TabViewModule,
    OptionsTableComponent,
    TranslateModule,
    UserTableComponent,
    OptionsTableLocationComponent,
    OptionsTablePalletComponent,
    OptionsTableContainerComponent,
    OptionsTableProvidersComponent,
    OptionsTableTransportationComponent,
    OptionsTablePackagingTypesComponent,
    OptionsTableResponsibilityComponent,
    WellManagementComponent,
    LogsComponent,
  ],
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSettingsComponent extends Destroyable(Object) implements OnInit {
  readonly ProviderType = ProviderType;
  optionsTableEntities!: Record<OptionsTableEntityKey, IdName[]>;

  constructor(
    private cd: ChangeDetectorRef,
    private projectOptionsService: ProjectOptionsService,
    private wellInfoService: WellInfoService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private optionsTableEntityKeyToActionMapService: OptionsTableEntityKeyToActionMapService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initTables();
  }

  tabChanged(tabIndex: number, userTableComponent: UserTableComponent): void {
    const accountManagementTabIndex = 0;

    if (accountManagementTabIndex === tabIndex) {
      userTableComponent.refreshResponsibilities();
    }
  }

  getOptionsTableEntityKeys(): OptionsTableEntityKey[] {
    return Object.keys(this.optionsTableEntities) as OptionsTableEntityKey[];
  }

  private initTables() {
    combineLatest([
      this.wellInfoService.getBasinTypes(),
      this.projectOptionsService.getBiostratigraphicSampleTypes(),
      this.projectOptionsService.getBoxTypes(),
      this.projectOptionsService.getDepthUnits(),
      this.projectOptionsService.getEndCupFractionInfos(),
      this.projectOptionsService.getExtractionPositionInfos(),
      this.projectOptionsService.getFluidTypes(),
      this.projectOptionsService.getLengthUnits(),
      this.projectOptionsService.getMediumDeliveryInfos(),
      this.projectOptionsService.getOrientationTypes(),
      this.projectOptionsService.getPhysicalStatuses(),
      this.projectOptionsService.getPlateTypes(),
      this.projectOptionsService.getPreparationTypes(),
      this.projectOptionsService.getPreservationTypes(),
      this.projectOptionsService.getSampleOrigins(),
      this.projectOptionsService.getSampleStatuses(),
      this.projectOptionsService.getSamplingMethods(),
      this.projectOptionsService.getSectionTypes(),
      this.projectOptionsService.getSurfaceSampleTypes(),
      this.projectOptionsService.getTechnicalInfoses(),
      this.wellInfoService.getWellTypes(),
      this.projectOptionsService.getWeightUnits(),
      this.projectOptionsService.getZhZsDatObjectiveSets(),
      this.projectOptionsService.getZhZsDatSampleTypes(),
      this.projectOptionsService.getVolumeUnits(),
    ])
      .pipe(
        map<IdName[][], Record<OptionsTableEntityKey, IdName[]>>(
          ([
            basinTypes,
            biostratigraphicSampleTypes,
            boxTypes,
            depthUnits,
            endCupFractionInfos,
            extractionPositionInfos,
            fluidTypes,
            lengthUnits,
            mediumDeliveryInfos,
            orientationTypes,
            physicalStatuses,
            plateTypes,
            preparationTypes,
            preservationTypes,
            sampleOrigins,
            sampleStatuses,
            samplingMethods,
            sectionTypes,
            surfaceSampleTypes,
            technicalInfoses,
            wellTypes,
            weightUnits,
            zhZsDatObjectiveSets,
            zhZsDatSampleTypes,
            volumeUnits,
          ]) => {
            return {
              [OptionsTableEntityKey.basinTypes]: basinTypes,
              [OptionsTableEntityKey.biostratigraphicSampleTypes]: biostratigraphicSampleTypes,
              [OptionsTableEntityKey.boxTypes]: boxTypes,
              [OptionsTableEntityKey.depthUnits]: depthUnits,
              [OptionsTableEntityKey.endCupFractionInfos]: endCupFractionInfos,
              [OptionsTableEntityKey.extractionPositionInfos]: extractionPositionInfos,
              [OptionsTableEntityKey.fluidTypes]: fluidTypes,
              [OptionsTableEntityKey.lengthUnits]: lengthUnits,
              [OptionsTableEntityKey.mediumDeliveryInfos]: mediumDeliveryInfos,
              [OptionsTableEntityKey.orientationTypes]: orientationTypes,
              [OptionsTableEntityKey.physicalStatuses]: physicalStatuses,
              [OptionsTableEntityKey.plateTypes]: plateTypes,
              [OptionsTableEntityKey.preparationTypes]: preparationTypes,
              [OptionsTableEntityKey.preservationTypes]: preservationTypes,
              [OptionsTableEntityKey.sampleOrigins]: sampleOrigins,
              [OptionsTableEntityKey.sampleStatuses]: sampleStatuses,
              [OptionsTableEntityKey.samplingMethods]: samplingMethods,
              [OptionsTableEntityKey.sectionTypes]: sectionTypes,
              [OptionsTableEntityKey.surfaceSampleTypes]: surfaceSampleTypes,
              [OptionsTableEntityKey.technicalInfoses]: technicalInfoses,
              [OptionsTableEntityKey.wellTypes]: wellTypes,
              [OptionsTableEntityKey.weightUnits]: weightUnits,
              [OptionsTableEntityKey.zhZsDatObjectiveSets]: zhZsDatObjectiveSets,
              [OptionsTableEntityKey.zhZsDatSampleTypes]: zhZsDatSampleTypes,
              [OptionsTableEntityKey.volumeUnits]: volumeUnits,
            };
          },
        ),
        this.takeUntilDestroyed(),
      )
      .subscribe((result) => {
        this.optionsTableEntities = result;
        this.cd.detectChanges();
      });
  }

  createDeleteEntity(
    action: 'post' | 'delete',
    event: { endpointParam: string; name: string },
    key: OptionsTableEntityKey,
    entityTitleKey: string,
  ): void {
    const entityTitle = this.translateService.instant(entityTitleKey);

    this.optionsTableEntityKeyToActionMapService
      .doAction(action, key, event.endpointParam)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_x) => {
        this.toastService.toastSuccess(
          `${entityTitle} ${event.name} ${this.translateService.instant(
            `adminSettings.messages.${action === 'post' ? 'hasBeenCreated' : 'hasBeenDeleted'}`,
          )}`,
        );
      });
  }

  getEntityTitle(index: number): string {
    const optionsTableEntityKeys = this.getOptionsTableEntityKeys();
    const optionsTableEntityKey = optionsTableEntityKeys[index];

    return optionsTableEntityTitleMap[optionsTableEntityKey] || 'Unknown entity';
  }
}
