import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { PasswordModule } from 'primeng/password';
import { TagModule } from 'primeng/tag';
import { forkJoin } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { EMAIL_REGEX } from 'src/app/core/consts';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';
import { AdminSettingsService } from 'src/app/services/api/admin-settings.service';
import { ToastService } from 'src/app/services/toast.service';

import { Responsibility } from '../../core/models/responsibility.model';
import { Role } from '../../core/models/role.model';
import { USE_OKTA_AUTH_TOKEN } from '../../core/tokens/use-okta-auth.token';
import { ResponsibilityService } from '../../services/api/responsibility.service';
import { UserService } from '../../services/api/user.service';
import { UserManagementService } from '../../services/user-management.service';
import { User } from '../admin-settings/user-table/user.model';

@Component({
  selector: 'app-account-settings',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    TranslateModule,
    DropdownModule,
    DialogModule,
    PasswordModule,
    TagModule,
    MultiSelectModule,
  ],
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSettingsComponent extends Destroyable(Object) implements OnInit {
  readonly mediumPasswordRegex =
    '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{12,})';

  readonly strongPasswordRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})';

  mainForm!: FormGroup;
  user!: User;
  roles = new Array<Role>();
  responsibilities: Responsibility[] = [];
  roleMap!: Record<string, Role>;
  control = new FormControl();
  isPassModalVisible = false;
  newPassword = '';

  get isOktaAuthUsed(): boolean {
    return this.useOktaAuth;
  }

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private translate: TranslateService,
    private adminService: AdminSettingsService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private responsibilityService: ResponsibilityService,
    private userManagementService: UserManagementService,
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    forkJoin([this.userService.getRoles(), this.responsibilityService.getAll()])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([roles, responsibilities]) => {
        this.roles = roles;
        this.responsibilities = responsibilities;
        this.roleMap = roles.reduce<Record<string, Role>>((acc, role) => {
          return { ...acc, [role.id]: role };
        }, {});

        this.userService
          .getCurrentUser()
          .pipe(this.takeUntilDestroyed())
          .subscribe((userFromDb) => {
            this.user = { ...userFromDb };
            this.userManagementService.addUserInfo({ ...userFromDb });
            this.mainForm = this.fb.group({
              firstName: [this.user.firstName, [Validators.required]],
              lastName: [this.user.lastName, [Validators.required]],
              email: [this.user.email, [Validators.required, Validators.pattern(EMAIL_REGEX)]],
              contactNumber: [this.user.contactNumber],
              organizationName: { value: this.user.organizationName, disabled: true },
              roleId: { value: this.user.roleId, disabled: true },
              responsibilityIds: { value: this.user.responsibilityIds, disabled: true },
            });

            this.setUpControl();

            this.cd.markForCheck();
          });
      });
  }

  submitForm(): void {
    this.validateAllFormFields(this.mainForm);
    const userToSave: User = { ...this.user, ...this.mainForm.getRawValue() };

    if (this.mainForm.valid) {
      this.userService
        .editUser(this.user.id!, userToSave)
        .pipe(this.takeUntilDestroyed())
        .subscribe(() => {
          this.user = userToSave;
          this.userManagementService.addUserInfo(userToSave);
          this.toastService.toastSuccess(
            this.getTranslationText('accountSettings.messages.settingsUpdated'),
          );
        });
    } else
      this.toastService.toastError(
        this.getTranslationText('accountSettings.messages.fieldsInvalid'),
      );
  }

  resetForm(): void {
    this.initForm();
    this.mainForm.markAsUntouched();
    this.mainForm.markAsPristine();
    this.mainForm.clearValidators();
    this.cd.markForCheck();
  }

  changePasswordPopup(): void {
    this.isPassModalVisible = true;
  }

  onCloseChangePasswordModal(): void {
    this.isPassModalVisible = false;
  }

  onChangePassword(): void {
    const isWeak = this.newPassword.match(this.mediumPasswordRegex);

    if (this.newPassword.trim() && isWeak) {
      this.changePassword(this.user.email, this.newPassword);
      this.isPassModalVisible = false;
    } else if (!this.newPassword.trim()) {
      this.toastService.toastError(
        this.getTranslationText('errorMessage.passwordShouldNotBeEmpty'),
      );
    } else {
      this.toastService.toastError(
        this.getTranslationText('errorMessage.passwordShouldBeStronger'),
      );
    }
  }

  private changePassword(email: string, newPassword: string): void {
    this.adminService
      .changePassword(email, newPassword)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (_data) => {
          this.toastService.toastSuccess(
            this.getTranslationText('accountSettings.messages.passwordChanged'),
          );
        },
      });
  }

  checkFormDirty(controlPath: string): boolean | undefined {
    return this.mainForm.get(controlPath)?.invalid && this.mainForm.get(controlPath)?.dirty;
  }

  checkFormError(controlPath: string): ValidationErrors {
    const error = { required: false, email: false };
    const control = this.mainForm.get(controlPath) as FormControl;
    if (this.checkFormDirty(controlPath) && !!control?.errors?.['required']) error.required = true;
    else if (this.checkFormDirty(controlPath) && !!control?.errors?.['pattern']) error.email = true;
    return error;
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private setUpControl(): void {
    this.mainForm.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }
}
