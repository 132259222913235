<div class="header">
  <div class="title">{{ title }}</div>
</div>

<p-table
  [value]="entities"
  [paginator]="true"
  [rows]="5"
  [showCurrentPageReport]="true"
  [tableStyle]="{ 'min-width': '250px' }"
  currentPageReportTemplate=""
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: auto">{{ 'adminSettings.titles.table.bay' | translate }}</th>
      <th style="width: auto">{{ 'adminSettings.titles.table.area' | translate }}</th>
      <th style="width: auto">{{ 'adminSettings.titles.table.aisle' | translate }}</th>
      <th style="width: auto">{{ 'adminSettings.titles.table.level' | translate }}</th>
      <th style="width: auto">{{ 'adminSettings.titles.table.position' | translate }}</th>
      <th style="width: auto">{{ 'adminSettings.titles.table.occupied' | translate }}</th>
      <th style="width: auto">{{ 'adminSettings.titles.table.locationLabel' | translate }}</th>
      <th style="width: auto"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-entity>
    <tr>
      <td>{{ entity.bay }}</td>
      <td>{{ entity.area }}</td>
      <td>{{ entity.aisle }}</td>
      <td>{{ entity.level }}</td>
      <td>{{ entity.position }}</td>
      <td>{{ (entity.occupied ? 'messages.yes' : 'messages.no') | translate }}</td>
      <td>{{ entity.locationLabel }}</td>
      <td>
        <p-confirmPopup></p-confirmPopup>
        <button
          pButton
          type="button"
          class="delete-btn btn"
          icon="pi pi-trash"
          [disabled]="entity.occupied"
          (click)="confirm($event, entity)"
        ></button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <p-button
      type="button"
      icon="pi pi-plus"
      (click)="showDialog()"
      styleClass="btn-secondary"
    ></p-button>

    <p-dialog
      header="Header"
      [(visible)]="isAddModalVisible"
      [modal]="true"
      [style]="{ width: '400px' }"
      [draggable]="false"
      [resizable]="false"
      [closable]="false"
    >
      <ng-template pTemplate="header">
        <span class="text-xl font-bold"
          >{{ 'adminSettings.titles.actions.new' | translate }} {{ title }}</span
        >
      </ng-template>
      <div class="container add_pallet">
        <span class="p-float-label">
          <input pInputText id="bay" [(ngModel)]="entity.bay" />
          <label htmlFor="bay">{{ 'adminSettings.titles.table.bay' | translate }}</label>
        </span>

        <span class="p-float-label">
          <input pInputText id="area" [(ngModel)]="entity.area" required #areaInput="ngModel" />
          <label htmlFor="area">{{ 'adminSettings.titles.table.area' | translate }}*</label>
          <small
            *ngIf="!entity.area && areaInput.touched && areaInput.dirty"
            class="p-error p-invalid input-error"
          >
            {{ 'errorMessage.fieldIsRequired' | translate }}
          </small>
        </span>

        <span class="p-float-label">
          <input pInputText id="aisle" [(ngModel)]="entity.aisle" />
          <label htmlFor="aisle">{{ 'adminSettings.titles.table.aisle' | translate }}</label>
        </span>

        <span class="p-float-label">
          <input pInputText id="level" [(ngModel)]="entity.level" />
          <label htmlFor="level">{{ 'adminSettings.titles.table.level' | translate }}</label>
        </span>

        <span class="p-float-label">
          <input pInputText id="position" [(ngModel)]="entity.position" />
          <label htmlFor="position">{{ 'adminSettings.titles.table.position' | translate }}</label>
        </span>

        <span class="p-float-label">
          <input
            pInputText
            id="locationLabel"
            [(ngModel)]="entity.locationLabel"
            required
            #locationLabelInput="ngModel"
          />
          <label htmlFor="locationLabel"
            >{{ 'adminSettings.titles.table.locationLabel' | translate }}*</label
          >
          <small
            *ngIf="!entity.locationLabel && locationLabelInput.touched && locationLabelInput.dirty"
            class="p-error p-invalid input-error"
          >
            {{ 'errorMessage.fieldIsRequired' | translate }}
          </small>
        </span>
      </div>
      <ng-template pTemplate="footer">
        <p-button (click)="onCancelModal()" label="Cancel" styleClass="btn-primary"></p-button>
        <p-button
          [disabled]="!entity.locationLabel || !entity.area || isSavingInProgress"
          (onClick)="onSave()"
          label="Save"
          styleClass="btn-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>
  </ng-template>
</p-table>
