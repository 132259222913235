import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { DialogModule } from 'primeng/dialog';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { TechnicalInformationFormComponent } from 'src/app/common/technical-information-form/technical-information-form.component';

import { DropdownSearchPopupComponent } from '../../../common/dropdown-search-popup/dropdown-search-popup.component';
import { DropDownOption } from '../../../core/models/drop-down-option.model';
import { LocationModel } from '../../../core/models/location.model';
import { PalletSearchFormComponent } from '../forms/pallet-search-form/pallet-search-form.component';
import { SampleInformationComponent } from '../forms/sample-information/sample-information.component';
import { StorageFilterOptionComponent } from '../forms/storage-filter-option/storage-filter-option.component';
import { StorageInformationFormComponent } from '../forms/storage-information-form/storage-information-form.component';
import { PalletStorageModel } from '../models/pallet-search.model';

@Component({
  selector: 'app-depalletize-tab',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    StorageInformationFormComponent,
    TechnicalInformationFormComponent,
    SampleInformationComponent,
    PalletSearchFormComponent,
    TranslateModule,
    DialogModule,
    DropdownSearchPopupComponent,
    StorageFilterOptionComponent,
  ],
  templateUrl: './depalletize-tab.component.html',
  styleUrls: ['./depalletize-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepalletizeTabComponent {
  @Input() formGroup!: FormGroup;
  @Input() pallets!: PalletStorageModel[];
  @Input() allPallets!: PalletStorageModel[];
  @Input() locations!: LocationModel[];
  @Input() filterOption!: DropDownOption[];
  @Output() searchEvent = new EventEmitter<string>();

  search(value: string): void {
    this.searchEvent.emit(value);
  }
}
