<p-dropdown
  placeholder=" "
  optionLabel="name"
  appendTo="body"
  optionValue="id"
  [styleClass]="'w-full'"
  [formControl]="control"
  [filter]="filter"
  [filterBy]="filterBy"
  [options]="options"
  [showClear]="true"
  [editable]="false"
  (onFocus)="onFocusFun($event.target.value)"
  (onBlur)="onBlurFun($event.target.value, type, options)"
></p-dropdown>
