<div class="spinner-container" *ngIf="showSpinner">
  <div class="align-center">
    <p-progressSpinner></p-progressSpinner>
    <p>Please wait while items are being prepared for print</p>
  </div>
</div>
<div class="content" *ngIf="!showSpinner">
  <div class="img-container" *ngFor="let src of imgSrcs">
    <img [src]="src" />
  </div>
</div>
