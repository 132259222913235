import { ServiceScheduling, ServicesInformation, ServicesTable } from './service.model';

export class ServicesShapeImpl {
  service: ServicesInformation;
  serviceSchedules: [ServiceScheduling];

  constructor(si: ServicesInformation, sc: ServicesTable) {
    this.service = {
      applicationDate: si.applicationDate,
      companyName: si.companyName,
      description: si.description,
    };
    this.serviceSchedules = sc.table;
  }
}
