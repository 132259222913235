import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from '../../core/api.consts';

@Injectable({
  providedIn: 'root',
})
export class ReceiptExportService {
  private url = SGC_API;
  public receiptUrl = `${this.url}/LabelGenerator/receipt`;
  constructor(private httpClient: HttpClient) {}

  exportReceipt(deliveryId: string, timeZoneIana: string): Observable<Blob> {
    const params = new HttpParams().set('TimeZoneIana', timeZoneIana);

    return this.httpClient.get<Blob>(`${this.receiptUrl}/${deliveryId}`, {
      params,
      responseType: 'blob' as 'json',
    });
  }
}
