import { Injectable } from '@angular/core';

import { User } from '../features/admin-settings/user-table/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private readonly userInfoStorageKey = 'userInfo';

  get isAdmin(): boolean {
    return this.getUserInfo()!.isAdmin!;
  }

  get isTechnician(): boolean {
    return !this.isAdmin && this.isSgcUser;
  }

  get isCustomer(): boolean {
    return !this.isAdmin && !this.isSgcUser;
  }

  private get isSgcUser(): boolean {
    return this.getUserInfo()!.sgcUser!;
  }

  addUserInfo(userInfo: User): void {
    localStorage.setItem(this.userInfoStorageKey, JSON.stringify(userInfo));
  }

  getUserInfo(): User | undefined {
    const userInfo = localStorage.getItem(this.userInfoStorageKey);
    return userInfo ? JSON.parse(userInfo) : undefined;
  }

  removeUserInfo(): void {
    localStorage.removeItem(this.userInfoStorageKey);
  }
}
