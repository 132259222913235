import { Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IS_FILE_SERVICE_HEADER } from '../api.consts';
import { API_FILE_SERVER_URL_TOKEN } from '../tokens/api-file-server-url.token';
import { API_SERVER_URL_TOKEN } from '../tokens/api-server-url.token';

@Injectable()
export class BaseApiUrlInterceptor implements HttpInterceptor {
  private readonly baseApiUrl = `${this.apiServerUrl}`;
  private readonly baseFileApiUrl = `${this.apiFileServerUrl}`;

  constructor(
    @Inject(API_SERVER_URL_TOKEN) private readonly apiServerUrl: string,
    @Inject(API_FILE_SERVER_URL_TOKEN) private readonly apiFileServerUrl: string,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let url = '';
    if (request.url.startsWith('/assets')) {
      return next.handle(request);
    }

    if (request.headers.has(IS_FILE_SERVICE_HEADER)) {
      url = this.baseFileApiUrl + request.url;
    } else {
      url = this.baseApiUrl + request.url;
    }

    const apiRequest = request.clone({ url });
    return next.handle(apiRequest);
  }
}
