<ng-container [formGroup]="formGroup">
  <div class="row input-group-50">
    <span class="p-input-icon-left p-float-label">
      <i class="pi pi-search"></i>
      <input
        class="search-input"
        autocomplete="off"
        id="float-input"
        type="text"
        pInputText
        (click)="onClick()"
        (ngModelChange)="valueChanged($event)"
        formControlName="search"
      />
      <label for="float-input">{{ 'button.search' | translate }} {{ searchValue }}</label>
      <button
        pButton
        type="button"
        class="btn btn-secondary"
        [disabled]="!formGroup.get('search')?.value"
        (click)="search()"
        label="{{ 'button.search' | translate }}"
      ></button>
    </span>
  </div>
</ng-container>

<p-dialog
  *ngIf="pallets"
  header="Pallet"
  [closable]="true"
  [modal]="true"
  [(visible)]="visiblePalletPopup"
  [styleClass]="'dialog-popup'"
>
  <app-dropdown-search-popup
    #dropdownSearchPopupComponent
    [tableHeader]="'Pallet label'"
    [tableData]="pallets"
    (selectedDropDownOption)="onSelectedDropDownOption($event)"
  ></app-dropdown-search-popup>
</p-dialog>

<p-dialog
  *ngIf="location"
  header="Location"
  [closable]="true"
  [modal]="true"
  [(visible)]="visibleLocationPopup"
  [styleClass]="'dialog-popup'"
>
  <app-dropdown-search-popup
    #dropdownSearchPopupComponent
    [tableHeader]="'Location label'"
    [tableData]="location"
    [showLocationOccupied]="false"
    (selectedDropDownOption)="onSelectedDropDownOption($event)"
  ></app-dropdown-search-popup>
</p-dialog>
