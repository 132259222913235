<div class="header"></div>

<div class="content">
  <p-tabView (onChange)="tabChanged($event.index)" [activeIndex]="tabIndex">
    <p-tabPanel
      *ngIf="!isGisHidden"
      header="{{ 'dataAccess.titles.map' | translate }}"
      (click)="resizeMap()"
    >
      <app-gis-map #gisMapComponent (changeTab)="tabChanged($event)"></app-gis-map>
    </p-tabPanel>
    <p-tabPanel header="{{ 'dataAccess.titles.dataAccess' | translate }}">
      <app-access-table
        *ngIf="tabIndex === 0"
        [title]="'dataAccess'"
        [tableHeaders]="dataAccessHeader"
        [filterOptionList]="deliveryFilterOption"
        [tabIndex]="0"
        [dateTransformFields]="['deliveryCreated', 'receptionDate']"
      ></app-access-table>
    </p-tabPanel>

    <p-tabPanel header="{{ 'dataAccess.titles.serviceAccess' | translate }}">
      <app-access-table
        *ngIf="tabIndex === 1"
        [title]="'serviceAccess'"
        [tableHeaders]="serviceAccessHeader"
        [filterOptionList]="serviceFilterOption"
        [editFields]="['note', 'serviceCompletedDate']"
        [excludeFields]="['serviceScheduleId']"
        [tabIndex]="1"
        [searchText]="searchText"
        [dateTransformFields]="['dateOfService']"
      ></app-access-table>
    </p-tabPanel>

    <p-tabPanel header="{{ 'dataAccess.titles.AssignmentAccess' | translate }}">
      <app-access-table
        *ngIf="tabIndex === 2"
        [title]="'AssignmentAccess'"
        [tableHeaders]="assignmentAccessHeader"
        [filterOptionList]="assignmentFilterOption"
        [tabIndex]="2"
        [searchText]="searchText"
        [dateTransformFields]="['dateAssigned', 'dateCompleted']"
      ></app-access-table>
    </p-tabPanel>
  </p-tabView>
</div>
