import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';

import { TranslationComponent } from '../translation/translation.component';

@Component({
  selector: 'app-project-information',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationComponent,
    TranslateModule,
    InputSwitchModule,
  ],
  templateUrl: './project-information.component.html',
  styleUrls: ['./project-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectInformationComponent implements OnInit {
  @Input() parentGroup!: FormGroup;
  @Input() isExpanded = true;
  control = new FormControl();
  get projectInformationFormGroup(): FormGroup {
    return this.parentGroup.get('projectInformation') as FormGroup;
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setUpControl();
  }

  onFieldInput(): void {
    this.parentGroup.updateValueAndValidity();
    this.parentGroup.markAllAsTouched();
  }

  checkFormError(controlPath: string): ValidationErrors {
    const error = { required: false, email: false };
    const control = this.parentGroup.get(controlPath) as FormControl;
    if (this.checkFormDirty(controlPath) && !!control?.errors?.['required']) error.required = true;
    else if (this.checkFormDirty(controlPath) && !!control?.errors?.['pattern']) error.email = true;
    return error;
  }

  private setUpControl(): void {
    this.projectInformationFormGroup.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
  }

  private checkFormDirty(controlPath: string): boolean | undefined {
    return this.parentGroup.get(controlPath)?.invalid && this.parentGroup.get(controlPath)?.dirty;
  }
}
