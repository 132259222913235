import { Observable, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PASS_ERROR_HEADER, SGC_API } from '../../core/api.consts';
import { Token } from '../../core/models/token.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private url = `${SGC_API}/Authentication`;
  private loginUrl = `${this.url}/login`;
  private registerUrl = `${this.url}/register`;
  refreshTokenUrl = `${this.url}/refreshToken`;

  loginSubject = new Subject<void>();

  constructor(private httpClient: HttpClient) {}

  login(email: string, password: string): Observable<Token> {
    return this.httpClient.post<Token>(this.loginUrl, { email, password });
  }

  register(email: string, password: string): Observable<null> {
    return this.httpClient.post<null>(this.registerUrl, { email, password });
  }

  refresh(accessToken: string, refreshToken: string): Observable<Token> {
    return this.httpClient.post<Token>(
      this.refreshTokenUrl,
      {
        accessToken,
        refreshToken,
      },
      {
        headers: { [PASS_ERROR_HEADER]: '' },
      },
    );
  }
}
