import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { IdName } from '../core/models/id-name.model';
import { ProjectOptionsService } from './api/project-options.service';

@Injectable({
  providedIn: 'root',
})
export class SelfServiceToValidationTableValuesMapperService {
  constructor(private projectOptionsService: ProjectOptionsService) {}

  getMappedZhZsDatSampleTypes(): Observable<IdName[]> {
    return this.projectOptionsService.getZhZsDatSampleTypes().pipe(
      map((sampleTypes) =>
        sampleTypes.map((sampleType) => ({
          ...sampleType,
          name: `ZhZsDat – ${sampleType.name}`,
        })),
      ),
    );
  }
}
