import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { RippleModule } from 'primeng/ripple';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { FOOTER_TEXT, PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from '../../core/consts';
import { USE_OKTA_AUTH_TOKEN } from '../../core/tokens/use-okta-auth.token';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { LicenseService } from '../../services/api/license.service';
import { OrganizationsService } from '../../services/api/organizations.service';
import { TranslationComponent } from '../translation/translation.component';

@Component({
  standalone: true,
  selector: 'app-welcome-page',
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    PasswordModule,
    InputTextModule,
    CheckboxModule,
    ButtonModule,
    RippleModule,
    FormsModule,
    TranslationComponent,
    TranslateModule,
  ],
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent extends Destroyable(Object) implements OnInit {
  readonly passwordMinLength = PASSWORD_MIN_LENGTH;
  readonly emailAddressLocalStorageKey = 'userEmailAddress';
  readonly footerText = FOOTER_TEXT;

  @Input() buttonLabel = '';
  @Input() enforcePasswordPolicy = false;
  @Input() pageName = '';
  @Input() showPasswordField = true;
  @Input() showRememberMeCheckbox = true;
  @Output() buttonClicked = new EventEmitter<[string, string | null]>();

  appName = '';
  appDescription = '';
  appSlogan = '';
  backgroundImage = '';
  fullLogo = '';
  emailAddress = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required]);
  showLoginForm = true;

  hidePassword = true;
  rememberMe = new FormControl(false);

  get isOktaAuthUsed(): boolean {
    return this.useOktaAuth;
  }

  get passwordLength(): number {
    return this.password.value?.length ?? 0;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private organizationsService: OrganizationsService,
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
    private licenseService: LicenseService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeButtonLabel();
    this.initializePasswordValidators();
    this.initializeOrganizationInfo();
    this.initializeUserInfo();
    // this.initializeLicenseData();

    this.changeDetectorRef.detectChanges();
  }

  getEmailAddressErrorMessage(): string {
    if (this.emailAddress.hasError('required')) {
      return 'welcomePage.error.youMustEnterAValue';
    }

    return this.emailAddress.hasError('email') ? 'welcomePage.error.notAValidEmail' : '';
  }

  getPasswordErrorMessage(): string {
    if (this.password.hasError('required')) {
      return 'welcomePage.error.youMustEnterAValue';
    }
    if (this.password.hasError('minlength')) {
      return 'welcomePage.error.passwordMustBeAtLeast12CharactersLong';
    }

    if (this.password.hasError('pattern')) {
      return 'welcomePage.error.passwordMustHaveAtLeastOneDigit0To9';
    }

    return '';
  }

  onButtonClick(): void {
    if (!this.isOktaAuthUsed) {
      if (this.showRememberMeCheckbox) {
        if (this.rememberMe.value) {
          localStorage.setItem(
            this.emailAddressLocalStorageKey,
            JSON.stringify(this.emailAddress.value),
          );
        } else {
          localStorage.removeItem(this.emailAddressLocalStorageKey);
        }
      }
    }

    this.buttonClicked.emit([
      this.emailAddress.value!,
      this.showPasswordField ? this.password.value : null,
    ]);
  }

  private initializeButtonLabel(): void {
    if (!this.buttonLabel && this.pageName) {
      this.buttonLabel = this.pageName;
    }
  }

  private initializePasswordValidators(): void {
    if (this.enforcePasswordPolicy) {
      this.password.addValidators([
        Validators.minLength(PASSWORD_MIN_LENGTH),
        Validators.pattern(PASSWORD_REGEX),
      ]);
    }
  }

  private initializeOrganizationInfo(): void {
    this.organizationsService
      .getOrganizations()
      .pipe(this.takeUntilDestroyed())
      .subscribe((organizations) => {
        if (organizations.length) {
          this.appName = organizations[0].appName;
          this.appDescription = organizations[0].appDescription;
          this.appSlogan = organizations[0].appSlogan;
          this.backgroundImage = `url("${organizations[0].landingPageBackgroundImagePath}")`;
          this.fullLogo = organizations[0].organizationFullIconImagePath;
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  private initializeUserInfo(): void {
    if (this.showRememberMeCheckbox) {
      const storedEmailAddress = localStorage.getItem(this.emailAddressLocalStorageKey);
      if (storedEmailAddress) {
        this.emailAddress.setValue(JSON.parse(storedEmailAddress));
      }
    }
  }

  private async initializeLicenseData(): Promise<void> {
    this.showLoginForm = await this.licenseService.isLicenseExpired();
  }
}
