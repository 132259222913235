import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData'
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import vtkITKImageReader from '@kitware/vtk.js/IO/Misc/ITKImageReader';

import vtkITKHelper from '@kitware/vtk.js/Common/DataModel/ITKHelper';
import {convertItkToVtkImage} from '@kitware/vtk.js/Common/DataModel/ITKHelper';
// import { saveAs } from 'file-saver';

export class ConvertVTKData
{
 
    constructor()
    {
      this.sliceZ = 100;
    }
    createImageData( input_data, nx, ny, nz, dataType)
    {
        
        const dimension = [nx, ny, nz];
        const spacing = [1, 1, 1];
        const origin = [0.0, 0.0, 0.0];
        const imageData = vtkImageData.newInstance();
        const len_1 = 99;
        imageData.setOrigin(origin);
        imageData.setSpacing(spacing);
        imageData.setDimensions(dimension);

        
    
        const da = vtkDataArray.newInstance({
          numberOfComponents: 1,
          values: input_data,
        });
        da.setName('scalars');
        const cpd = imageData.getPointData();
        cpd.setScalars(da);
        // mapper.setInputData(id);
        return imageData
    


    }

    conver_tiff_to_png(file)
    {
      // var ConvertTiff = require('tiff-to-png');
      // const reader = new FileReader();
      
      // reader.onload = () => {
            //   const arrayBuffer = reader.result;
      //   vtkITKImageReader.setFileContent(arrayBuffer);
      //   const imageData = vtkITKImageReader.getOutputData();
      
      //   // saveAs(pngData, 'converted_image.png');
        
      // };
    
      // reader.readAsArrayBuffer(file);
    
    }

}

