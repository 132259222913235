import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { TechnicalInformationFormComponent } from 'src/app/common/technical-information-form/technical-information-form.component';
import { LocationModel } from 'src/app/core/models/location.model';

import { DropDownOption } from '../../../core/models/drop-down-option.model';
import { PalletSearchFormComponent } from '../forms/pallet-search-form/pallet-search-form.component';
import { SampleInformationComponent } from '../forms/sample-information/sample-information.component';
import { StorageFilterOptionComponent } from '../forms/storage-filter-option/storage-filter-option.component';
import { StorageInformationFormComponent } from '../forms/storage-information-form/storage-information-form.component';
import { StoreToLocationFormComponent } from '../forms/store-to-location-form/store-to-location-form.component';
import { WellSearchFormComponent } from '../forms/well-search-form/well-search-form.component';
import { PalletStorageModel } from '../models/pallet-search.model';

@Component({
  selector: 'app-store-tab',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    StorageInformationFormComponent,
    WellSearchFormComponent,
    SampleInformationComponent,
    TranslateModule,
    TechnicalInformationFormComponent,
    StoreToLocationFormComponent,
    PalletSearchFormComponent,
    StorageFilterOptionComponent,
  ],
  templateUrl: './store-tab.component.html',
  styleUrls: ['./store-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreTabComponent {
  @Input() formGroup!: FormGroup;
  @Input() pallets!: PalletStorageModel[];
  @Input() allPallets!: PalletStorageModel[];
  @Input() locations!: LocationModel[];
  @Input() filterOption!: DropDownOption[];
  @Output() searchEvent = new EventEmitter<string>();

  search(value: string): void {
    this.searchEvent.emit(value);
  }
}
