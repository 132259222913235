import { Routes } from '@angular/router';

import { AccountSettingsComponent } from '../features/account-settings/account-settings.component';
import { AdminSettingsComponent } from '../features/admin-settings/admin-settings.component';
import { AssignmentComponent } from '../features/assignment/assignment.component';
import { CalendarComponent } from '../features/calendar/calendar.component';
import { DashboardComponent } from '../features/dashboard/dashboard.component';
import { DataAccessComponent } from '../features/data-access/data-access.component';
import { ReceptionComponent } from '../features/reception/reception.component';
import { SampleManagementComponent } from '../features/sample-management/sample-management.component';
import { SelfServiceComponent } from '../features/self-service/self-service.component';
import { ServiceComponent } from '../features/service/service.component';
import { StorageComponent } from '../features/storage/storage.component';
import { ValidationComponent } from '../features/validation/validation.component';
import { VerificationComponent } from '../features/verification/verification.component';
import { VisualizationComponent } from '../features/visualization/visualization.component';
import { AdminOnlyGuard } from './guards/admin-only.guard';
import { AdminOrTechnicianGuard } from './guards/admin-or-technician.guard';
import { RoutePath } from './route-paths.enum';

export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: RoutePath.DASHBOARD,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AdminOrTechnicianGuard],
  },
  {
    path: 'data-access',
    component: DataAccessComponent,
    canActivate: [AdminOrTechnicianGuard],
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [AdminOrTechnicianGuard],
  },
  {
    path: 'self-service',
    children: [
      {
        path: '',
        component: SelfServiceComponent,
      },
      {
        path: 'edit',
        component: SelfServiceComponent,
      },
    ],
  },
  {
    path: 'reception',
    component: ReceptionComponent,
    canActivate: [AdminOrTechnicianGuard],
  },
  {
    path: 'verification',
    component: VerificationComponent,
    canActivate: [AdminOrTechnicianGuard],
  },
  {
    path: 'validation',
    component: ValidationComponent,
    canActivate: [AdminOrTechnicianGuard],
  },
  {
    path: 'storage',
    component: StorageComponent,
    canActivate: [AdminOrTechnicianGuard],
  },
  {
    path: 'service',
    component: ServiceComponent,
    canActivate: [AdminOnlyGuard],
  },
  {
    path: 'sample-management',
    component: SampleManagementComponent,
  },
  {
    path: 'visualization',
    component: VisualizationComponent,
  },
  {
    path: 'account-settings',
    component: AccountSettingsComponent,
  },
  {
    path: 'admin-settings',
    component: AdminSettingsComponent,
    canActivate: [AdminOnlyGuard],
  },
  {
    path: 'assignment',
    component: AssignmentComponent,
    canActivate: [AdminOnlyGuard],
  },
];
