import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { Paginator, PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Table, TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { combineLatest, first } from 'rxjs';

import { CommonModule, formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DEFAULT_DATE_FORMAT, DEFAULT_LOCALE } from '../../core/consts';
import { DropDownOption } from '../../core/models/drop-down-option.model';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { User } from '../../features/admin-settings/user-table/user.model';
import { DataAccessTableTitle } from '../../features/data-access/models/data-access.enum';
import {
  DataAccessModel,
  DataAccessServiceModel,
  FileAttachment,
} from '../../features/data-access/models/data-access.model';
import { DeliveryService } from '../../services/api/delivery.service';
import { FileUploadService } from '../../services/api/file-service.service';
import { ProjectStateService } from '../../services/api/project-state.service';
import { SearchServiceService } from '../../services/api/search-service.service';
import { UserService } from '../../services/api/user.service';
import { ToastService } from '../../services/toast.service';
import { DefaultCalendarDateFormatDirective } from '../directives/default-calendar-date-format.directive';
import { DefaultDateFormatPipe } from '../pipes/default-date-format.pipe';
import { FilterOptionComponent } from './filter-option/filter-option.component';
import { FilterOptionModel } from './filter-option/models/filter-option.model';

@Component({
  selector: 'app-access-table',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    TableModule,
    InputTextModule,
    TabViewModule,
    ButtonModule,
    DialogModule,
    FilterOptionComponent,
    PaginatorModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    DefaultDateFormatPipe,
    CalendarModule,
    DefaultCalendarDateFormatDirective,
  ],
  templateUrl: './access-table.component.html',
  styleUrls: ['./access-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessTableComponent extends Destroyable(Object) implements AfterViewInit, OnInit {
  @ViewChild('filterOptionComponent', { static: true })
  filterOptionComponent!: FilterOptionComponent;

  @ViewChild('paginator') paginator!: Paginator;
  @ViewChild('dt') dt!: Table;

  @Input() title!: string;
  @Input() tableHeaders!: string[];
  @Input() tableData!: DataAccessModel[];
  @Input() filterOptionList!: { [key: string]: boolean };
  @Input() tabIndex!: number;
  @Input() searchText = '';
  @Input() dateTransformFields: string[] = [];
  @Input() editFields: string[] = [];
  @Input() excludeFields: string[] = [];

  dataLoadedStatus: boolean[] = [false, false, false];
  lastRequestParamsStringified = '';
  searchForm!: FormGroup;
  dataAccessTableTitle = DataAccessTableTitle;
  filterOption!: FilterOptionModel;
  totalRecords!: number;
  pageSizeOptions: number[] = [10, 25, 50];
  pageSize = 10;
  columnsCached = false;

  rows: DataAccessModel[] = [];
  columns: { field: string; header: string }[] = [];

  spinnerShow = false;
  visiblePopup = false;
  stateOption!: DropDownOption[];
  sampleTypes: string[] = [
    'biostratigraphic',
    'core',
    'fluid',
    'petrographicPlate',
    'pistonCore',
    'plug',
    'preserved',
    'residue',
    'surfaceSample',
    'swc',
    'zhZsDat',
  ];

  users: User[] = [];
  techniciansFilterOptions: DropDownOption[] = [];
  displayNoteDialog = false;
  displayCompletedDateDialog = false;
  isSaving = false;
  editData = '';
  selectedRowData!: DataAccessModel;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private toastService: ToastService,
    private deliveryService: DeliveryService,
    private searchServiceService: SearchServiceService,
    private projectStateService: ProjectStateService,
    private fileUploadService: FileUploadService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.initList();
  }

  ngAfterViewInit() {
    if (!this.isDataLoaded(this.tabIndex)) {
      this.filterOptionComponent.filterSelect();
      this.setIsDataLoaded(this.tabIndex, true);
    }
  }

  isDateTransformField(field: string): boolean {
    return this.dateTransformFields.includes(field);
  }

  isEditField(field: string): boolean {
    return this.editFields.includes(field);
  }

  search(filterOption: FilterOptionModel): void {
    filterOption.page = 1;
    this.filterSubmit(filterOption);
    this.jumpToFirstPage();
  }

  exportCsv(): void {
    this.filterSubmit(this.filterOption, true);
  }

  isExportButtonEnabled(): boolean {
    const propertiesToExclude = ['page'];
    const hasNonEmptyValue = (value: any) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== undefined && value !== null && value !== '';
    };

    const valuesToCheck = Object.entries(this.filterOption)
      .filter(([key]) => !propertiesToExclude.includes(key))
      .map(([, value]) => hasNonEmptyValue(value))
      .filter(Boolean);

    return valuesToCheck.length > 0;
  }

  onPageChange(event: any): void {
    this.filterOption.page = event.first / event.rows + 1;
    this.pageSize = event.rows;
    this.filterSubmit(this.filterOption);
  }

  filterSubmit(filterOption: FilterOptionModel, exportCsv = false): void {
    this.visiblePopup = false;
    this.filterOption = filterOption;
    const filterParams = this.getFilterParams(filterOption, exportCsv);

    if (!this.checkIfRequestIsRepeated(filterParams)) {
      this.spinnerShow = true;

      switch (this.tabIndex) {
        case 0:
          this.deliveryFilter(filterParams, exportCsv);
          break;
        case 1:
          this.servicesFilter(filterParams, exportCsv);
          break;
        case 2:
          this.assignmentFilter(filterParams, exportCsv);
          break;
        default:
          break;
      }
    }
  }

  getStatus(value: string): string {
    return value === 'True' ? 'Completed' : 'Incomplete';
  }

  handleClick(fileData: FileAttachment): void {
    if (fileData?.name && fileData?.id) {
      this.fileUploadService
        .download(fileData?.id)
        .pipe(this.takeUntilDestroyed())
        .subscribe((file) => {
          this.downloadFile(file, fileData?.name);
        });
    }
  }

  openDialog(rowData: DataAccessModel, fieldName: string): void {
    this.isSaving = false;
    this.selectedRowData = { ...rowData };

    if (fieldName === 'note') {
      this.editData = this.selectedRowData.note || '';
      this.displayNoteDialog = true;
    } else {
      this.editData = this.selectedRowData.serviceCompletedDate
        ? formatDate(
            this.selectedRowData.serviceCompletedDate,
            DEFAULT_DATE_FORMAT.datePipe,
            DEFAULT_LOCALE,
          )
        : '';
      this.displayCompletedDateDialog = true;
    }
  }

  onCancelEdit(): void {
    this.closeEditDialog();
  }

  saveEdit(): void {
    if (this.selectedRowData && this.isSaving) {
      if (this.displayNoteDialog) this.saveNote();
      else this.saveCompletedDate();
    }
  }

  onEditNoteChange(newValue: string): void {
    if (newValue.trim() !== this.selectedRowData.note) this.isSaving = true;
    else this.isSaving = false;
  }

  onEditDateChange(newValue: string): void {
    if (newValue && newValue !== this.selectedRowData.serviceCompletedDate) this.isSaving = true;
    else this.isSaving = false;
  }

  private closeEditDialog(): void {
    this.displayNoteDialog = false;
    this.displayCompletedDateDialog = false;
  }

  private saveNote(): void {
    this.closeEditDialog();
    this.spinnerShow = true;

    this.searchServiceService
      .updateNote(this.selectedRowData.serviceScheduleId!, this.editData!)
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        const index = this.getIndexServiceTableData(this.selectedRowData.serviceScheduleId!);
        this.rows[index].note = this.editData;
        this.toastService.toastSuccess(
          `${this.getTranslationText('dataAccess.success.updatedSuccessfully')} `,
        );
        this.spinnerShow = false;
        this.cd.markForCheck();
      });
  }

  private getIndexServiceTableData(id: string): number {
    return this.rows.findIndex((item) => item.serviceScheduleId === id);
  }

  private saveCompletedDate(): void {
    this.closeEditDialog();
    this.spinnerShow = true;
    const completedDate = new Date(this.editData).toISOString();

    this.searchServiceService
      .updateCompletedDate(this.selectedRowData.serviceScheduleId!, completedDate)
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        const index = this.getIndexServiceTableData(this.selectedRowData.serviceScheduleId!);
        this.rows[index].serviceCompletedDate = this.editData;
        this.toastService.toastSuccess(
          `${this.getTranslationText('dataAccess.success.updatedSuccessfully')} `,
        );
        this.spinnerShow = false;
        this.cd.markForCheck();
      });
  }

  private downloadFile(fileData: ArrayBuffer, fileName: string): void {
    const blob = new Blob([fileData], { type: 'application/octet-stream' });

    const link = document.createElement('a');

    link.download = fileName;
    link.href = window.URL.createObjectURL(blob);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  private checkIfRequestIsRepeated(filterParams: HttpParams): boolean {
    const newFilterParams = filterParams.toString();
    if (this.lastRequestParamsStringified === newFilterParams) {
      return true;
    }
    this.lastRequestParamsStringified = newFilterParams;
    return false;
  }

  private prepareCsvData(rowData: DataAccessModel[]): void {
    const headerObservables = this.columns.map((column) => this.translate.stream(column.header));

    combineLatest(headerObservables)
      .pipe(first(), this.takeUntilDestroyed())
      .subscribe((translatedHeaders) => {
        const translatedColumns = this.columns.map((column, index) => {
          return { ...column, header: translatedHeaders[index] };
        });

        const csvContent = this.generateCSV(translatedColumns, rowData);

        this.downloadCsv(csvContent);
      });
  }

  private generateCSV(columns: { header: any; field: string }[], data: DataAccessModel[]): string {
    const header = columns.map((column) => column.header).join(',');
    const columnName = Object.keys(data[0]);
    const rows = data.map((item) =>
      columnName
        .map((column) => {
          const value = item[column as keyof DataAccessModel];
          return typeof value === 'string' && value.includes(',') ? `"${value}"` : value;
        })
        .join(','),
    );
    return `${header}\n${rows.join('\n')}`;
  }

  private downloadCsv(content: string) {
    const blob = new Blob([content], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'dataAccess.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  private initForm(): void {
    this.searchForm = this.fb.group({
      searchText: [''],
    });
  }

  private jumpToFirstPage(): void {
    const firstPageIndex = 0;

    if (this.paginator && this.paginator.getPage() !== firstPageIndex) {
      this.paginator.changePage(firstPageIndex);
    }
  }

  private generateColumns(dataRow: DataAccessModel): void {
    if (dataRow) {
      this.columns = Object.keys(dataRow)
        .filter((key) => !this.excludeFields.includes(key))
        .map((data) => ({
          field: data,
          header: (DataAccessTableTitle as any)[data],
        }));
    } else {
      this.columns = this.tableHeaders.map((header) => ({
        field: '',
        header,
      }));
    }
  }

  private isDataLoaded(tabIndex: number): boolean {
    return this.dataLoadedStatus[tabIndex] || false;
  }

  private setIsDataLoaded(tabIndex: number, value: boolean): void {
    this.dataLoadedStatus[tabIndex] = value;
  }

  private deliveryFilter(params: HttpParams, exportCsv = false): void {
    this.deliveryService
      .getDeliveryByFilter(params)
      .pipe(this.takeUntilDestroyed())
      .subscribe((result) => {
        this.loadData(result, exportCsv);
      });
  }

  private servicesFilter(params: HttpParams, exportCsv = false): void {
    this.searchServiceService
      .getServicesByFilter(params)
      .pipe(this.takeUntilDestroyed())
      .subscribe((result) => {
        this.loadData(result, exportCsv);
      });
  }

  private assignmentFilter(params: HttpParams, exportCsv = false): void {
    this.deliveryService
      .getAssignmentByFilter(params)
      .pipe(this.takeUntilDestroyed())
      .subscribe((result) => {
        this.loadData(result, exportCsv);
      });
  }

  private getFilterParams(filterOption: FilterOptionModel, exportCsv = false): HttpParams {
    let params = new HttpParams();

    Object.entries(filterOption).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item);
          });
        } else {
          params = params.set(key, value.toString());
        }
      }
    });

    params = params.append('pageSize', exportCsv ? -1 : this.pageSize);

    if (this.searchText) params = params.append('Search', this.searchText);

    return params;
  }

  private loadData(result: DataAccessServiceModel, exportCsv = false): void {
    if (exportCsv) {
      const exportData: DataAccessModel[] = JSON.parse(JSON.stringify(result.data));
      exportData.forEach((item) => {
        item.receptionStatus = item.receptionStatus === 'True' ? 'Completed' : 'Incomplete';
        item.validationStatus = item.validationStatus === 'True' ? 'Completed' : 'Incomplete';
        item.verificationStatus = item.verificationStatus === 'True' ? 'Completed' : 'Incomplete';
      });
      this.prepareCsvData(exportData);
    } else {
      this.rows = result.data;
      this.totalRecords = result.count;
      if (!this.columnsCached) {
        this.generateColumns(result.data[0]);
        this.columnsCached = true;
      }
    }

    this.spinnerShow = false;
    this.cd.detectChanges();
  }

  private initList(): void {
    combineLatest([this.projectStateService.getProjectStates(), this.userService.getUsers()])
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        [this.stateOption, this.users] = data;
        this.techniciansFilterOptions = this.users.map((user) => ({
          id: user.id!,
          name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
        }));
        this.cd.detectChanges();
      });
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }
}
