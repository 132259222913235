<header class="header">
  <h1 class="title">{{ 'dashboard.chart.service' | translate }}</h1>
  <button
    pButton
    type="button"
    icon="pi pi-cog"
    (click)="openOptions()"
    label="{{ 'dashboard.chart.options' | translate }}"
    class="btn btn-secondary options-btn"
  ></button>
</header>

<div class="chart-container">
  <p-chart
    type="bar"
    [data]="chartData"
    [options]="chartOptions"
    width="35vw"
    height="35vh"
  ></p-chart>
</div>

<div *ngIf="spinnerShow" class="card spinner">
  <p-progressSpinner></p-progressSpinner>
</div>
