import { DropDownOption } from '../../../core/models/drop-down-option.model';

export const SAMPLE_OPTION: DropDownOption[] = [
  {
    id: 'SampleLabel',
    name: 'Sample label',
  },
  {
    id: 'ContainerLabel',
    name: 'Container label',
  },
  {
    id: 'PalletLabel',
    name: 'Pallet label',
  },
  {
    id: 'LocationLabel',
    name: 'Location label',
  },
  {
    id: 'UwiOrUpi',
    name: 'Uwi Upi',
  },
];

export const PALLET_OPTION: DropDownOption[] = [
  {
    id: 'PalletLabel',
    name: 'Pallet label',
  },
  {
    id: 'LocationLabel',
    name: 'Location label',
  },
];
