export const SERVICE_TABLE_LABEL: { [key: string]: string } = {
  type: 'service.table.type',
  uwiOrUpi: 'service.table.uwiupi',
  name: 'service.table.name',
  sampleType: 'service.table.sampleType',
  totalBoxes: 'service.table.totalBoxes',
  totalSamples: 'service.table.totalSamples',
  topDepth: 'service.table.topDepth',
  bottomDepth: 'service.table.bottomDepth',
  depthUnitId: 'service.table.depthUnit',
  sampleLocations: 'service.table.sampleLocations',
  boxLocations: 'service.table.boxLocations',
  technicianId: 'service.table.technician',
  dateOfService: 'service.table.dateOfService',
  serviceAttachments: 'service.table.attachmentLocations',
  note: 'service.table.note',
};
