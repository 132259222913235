<ng-container [formGroup]="formGroup">
  <div formGroupName="{{ formName }}">
    <div class="row input-group-50">
      <span class="p-float-label">
        <p-dropdown
          formControlName="name"
          [filter]="true"
          filterBy="name"
          placeholder="{{ 'storage.forms.name' | translate }}*"
          optionLabel="name"
          [options]="dropdownOptions"
          [showClear]="true"
          appendTo="body"
          [editable]="false"
          optionValue="id"
          inputId="float-label"
        ></p-dropdown>
        <label htmlFor="name"> {{ 'storage.forms.name' | translate }}* </label>
        <small *ngIf="checkFormError(formName, 'name')" class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>
    </div>
  </div>
</ng-container>
