export enum DataAccessTableTitle {
  contractNumber = 'dataAccess.table.contractNumber',
  barcodeId = 'dataAccess.table.barcodeId',
  uwi = 'dataAccess.table.uwi',
  wellName = 'dataAccess.table.wellName',
  upi = 'dataAccess.table.upi',
  projectName = 'dataAccess.table.projectName',
  sampleType = 'dataAccess.table.sampleType',
  totalBoxes = 'dataAccess.table.totalBoxes',
  totalSamples = 'dataAccess.table.totalSamples',
  companyName = 'dataAccess.table.companyName',
  topDepth = 'dataAccess.table.topDepth',
  bottomDepth = 'dataAccess.table.bottomDepth',
  formation = 'dataAccess.table.formation',
  basin = 'dataAccess.table.basin',
  receptionStatus = 'dataAccess.table.receptionStatus',
  verificationStatus = 'dataAccess.table.verificationStatus',
  validationStatus = 'dataAccess.table.validationStatus',
  dateOfService = 'dataAccess.table.dateOfService',
  note = 'dataAccess.table.note',
  technician = 'dataAccess.table.technician',
  attachedFiles = 'dataAccess.table.attachment',
  state = 'dataAccess.table.state',
  dateAssigned = 'dataAccess.table.dateAssigned',
  dateCompleted = 'dataAccess.table.dateCompleted',
  deliveryCreated = 'dataAccess.table.deliveryCreated',
  receptionDate = 'dataAccess.table.receptionDate',
  serviceCompletedDate = 'dataAccess.table.completedDate',
}
