import { Calendar } from 'primeng/calendar';

import { Directive } from '@angular/core';

import { DEFAULT_DATE_FORMAT } from '../../core/consts';

@Directive({
  selector: '[appDefaultCalendarDateFormat]',
  standalone: true,
})
export class DefaultCalendarDateFormatDirective {
  constructor(private calendar: Calendar) {
    calendar.dateFormat = DEFAULT_DATE_FORMAT.primeNgCalendar;
  }
}
