import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarEvent, CalendarModule, CalendarView } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { DropDownOption } from '../../core/models/drop-down-option.model';
import { RoutePath } from '../../core/route-paths.enum';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { CalendarService } from '../../services/api/calendar.service';
import { FileUploadService } from '../../services/api/file-service.service';
import { ProjectStateService } from '../../services/api/project-state.service';
import {
  Activities,
  CalendarEventExtended,
  CalendarFilterModel,
  CalendarModel,
  CalendarTitle,
  Film,
} from './models/calendar.model';

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [
    CommonModule,
    CalendarModule,
    FormsModule,
    MultiSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    ButtonModule,
    ProgressSpinnerModule,
  ],
  providers: [DatePipe],
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent extends Destroyable(Object) implements OnInit {
  view: CalendarView = CalendarView.Month;
  activeDayIsOpen = false;
  viewDate: Date = new Date();
  formGroup!: FormGroup;
  stateOption!: DropDownOption[];
  spinnerShow = false;
  filterOption!: CalendarFilterModel;

  CalendarView = CalendarView;
  events: CalendarEventExtended[] = [];
  titleKeyListAssignment: string[] = [
    'technician',
    'state',
    'uwiOrUpi',
    'name',
    'sampleType',
    'totalBoxes',
    'companyName',
    'contractNumber',
    'note',
  ];

  titleKeyListService: string[] = [
    'technician',
    'uwiOrUpi',
    'name',
    'sampleType',
    'totalBoxes',
    'totalSamples',
    'note',
  ];

  calendarTitle = CalendarTitle;
  activities = Activities;
  calendarLocale = 'en';

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private projectStateService: ProjectStateService,
    private calendarService: CalendarService,
    private fileUploadService: FileUploadService,
    private router: Router,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.translate.onLangChange.pipe(this.takeUntilDestroyed()).subscribe((l) => {
      this.setCalendarLanguage(l.lang);
    });

    const viewDateAsDate = new Date(this.viewDate);
    this.filterOption = {
      ...this.filterOption,
      month: viewDateAsDate.getMonth() + 1,
      year: viewDateAsDate.getFullYear(),
    };
    this.initForm();
    this.initList();
    this.setCalendarLanguage(this.translate.currentLang);
  }

  apply(): void {
    this.spinnerShow = true;
    const { activities, states } = this.formGroup.getRawValue();
    this.filterOption = { ...this.filterOption, activities, states };
    this.getCalendarData();
  }

  getCalendarData(): void {
    const filterParams = this.getFilterParams(this.filterOption);

    this.calendarService
      .getCalendarInfo(filterParams)
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.generateEvents(data);
      });
  }

  handleTitleClick(event: CalendarEventExtended): void {
    this.router.navigate([RoutePath.DATA_ACCESS], {
      queryParams: { id: event.id!.toString(), activity: event.activity },
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent<{ film: Film }>[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  closeOpenMonthViewDay(newDate: Date): void {
    this.activeDayIsOpen = false;

    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    this.filterOption = { ...this.filterOption, month, year };

    this.apply();
  }

  setView(view: CalendarView): void {
    this.view = view;
  }

  handleUrlClick(fileName: string, fileId: string): void {
    if (fileName && fileId) {
      this.fileUploadService
        .download(fileId)
        .pipe(this.takeUntilDestroyed())
        .subscribe((file) => {
          this.downloadFile(file, fileName);
        });
    }
  }

  downloadFile(fileData: ArrayBuffer, fileName: string): void {
    const blob = new Blob([fileData], { type: 'application/octet-stream' });

    const link = document.createElement('a');

    link.download = fileName;
    link.href = window.URL.createObjectURL(blob);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  private setCalendarLanguage(lang: string): void {
    this.calendarLocale = lang;
    this.activityDropdownTranslate();
    if (lang === 'es') registerLocaleData(localeEs);
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      states: [],
      activities: [],
    });
  }

  private initList(): void {
    this.projectStateService
      .getProjectStates()
      .pipe(this.takeUntilDestroyed())
      .subscribe((stateOption) => {
        this.stateOption = stateOption;
        this.setDefaultSelectedOptions();
        this.apply();
        this.cd.markForCheck();
      });
  }

  private setDefaultSelectedOptions(): void {
    const defaultSelectedStates = this.stateOption.map((item) => item.id);
    const defaultSelectedActivities = this.activities.map((item) => item.id);

    this.formGroup.patchValue({
      states: defaultSelectedStates,
      activities: defaultSelectedActivities,
    });
  }

  private getFilterParams(filterOption: CalendarFilterModel): HttpParams {
    let params = new HttpParams();

    Object.entries(filterOption).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item);
          });
        } else {
          params = params.set(key, value.toString());
        }
      }
    });

    return params;
  }

  private generateEvents(data: CalendarModel[]): void {
    this.events = data.map((item) => ({
      title: this.getTitle(item),
      color: this.getColors(item),
      start: new Date(item.date),
      id: item.contractNumber,
      activity: item.activity,
      name: item.attachedFiles?.name,
      fileId: item.attachedFiles?.id,
    }));
    this.spinnerShow = false;
    this.cd.detectChanges();
  }

  private getColors(item: CalendarModel): { primary: string; secondary: string } {
    return item.activity === 'Assignment'
      ? { primary: '#e74c3c', secondary: '#ea6153' }
      : { primary: '#0000FF', secondary: '#2f81d3' };
  }

  private getTitle(item: CalendarModel): string {
    const titleList =
      item.activity === 'Assignment' ? this.titleKeyListAssignment : this.titleKeyListService;

    return [
      item.activity,
      Object.entries(item)
        .filter(([key, value]) => titleList.includes(key))
        .map(([key, value]) => `${this.calendarTitle[key]}: ${value}`),
    ].join(', ');
  }

  private activityDropdownTranslate(): void {
    this.activities = Activities.map((v) => {
      return {
        id: v.id!,
        name: this.translate.instant(v.name!),
      };
    });
  }
}
