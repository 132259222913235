import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { PalletStorageModel } from '../models/pallet-search.model';
import { SampleModel } from '../models/sample.model';

@Injectable({
  providedIn: 'root',
})
export class TableRowService {
  private rowSelected = new BehaviorSubject<Array<SampleModel | PalletStorageModel>>([]);

  emitChildEvent(samplesPicked: Array<SampleModel | PalletStorageModel>) {
    this.rowSelected.next(samplesPicked);
  }

  childEventListener() {
    return this.rowSelected.asObservable();
  }
}
