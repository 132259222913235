import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';

import { ProviderModel, ProviderType } from '../../core/models/provider.model';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { ProviderService } from '../../services/api/provider.service';
import { TranslationComponent } from '../translation/translation.component';

@Component({
  selector: 'app-service-provider',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationComponent,
    TranslateModule,
    DropdownModule,
  ],
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceProviderComponent extends Destroyable(Object) implements OnInit {
  @Input() required = true;
  @Input() parentGroup!: FormGroup;
  @Input() isExpanded = true;
  @Input() isWellManagementDialog = false;

  control = new FormControl();
  serviceProviders!: ProviderModel[];

  constructor(private cd: ChangeDetectorRef, private providerService: ProviderService) {
    super();
  }

  ngOnInit(): void {
    this.setUpControl();
    this.getData();
    this.trackServiceProviderFieldChanges();
  }

  private trackServiceProviderFieldChanges(): void {
    if (this.isWellManagementDialog) {
      this.parentGroup
        .get(['serviceProvider', 'companyName'])!
        .valueChanges.pipe(this.takeUntilDestroyed())
        .subscribe((selectedServiceProviderId) => {
          const selectedProvider = this.serviceProviders.find(
            (operator) => operator.id === selectedServiceProviderId,
          );
          const { email = null, contactNumber = null, contactName = null } = selectedProvider ?? {};
          this.parentGroup
            .get('serviceProvider')
            ?.patchValue({ email, contactNumber, contactName });
        });
    }
  }

  private setUpControl(): void {
    const serviceProviderFormGroup = this.parentGroup.get('serviceProvider') as FormGroup;
    serviceProviderFormGroup.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
  }

  checkFormDirty(controlPath: string): boolean | undefined {
    return this.parentGroup.get(controlPath)?.invalid && this.parentGroup.get(controlPath)?.dirty;
  }

  checkFormError(controlPath: string): ValidationErrors {
    const error = { required: false, email: false };
    const control = this.parentGroup.get(controlPath) as FormControl;
    if (this.checkFormDirty(controlPath) && !!control?.errors?.['required']) error.required = true;
    else if (this.checkFormDirty(controlPath) && !!control?.errors?.['pattern']) error.email = true;
    return error;
  }

  onFieldInput(): void {
    this.parentGroup.updateValueAndValidity();
    this.parentGroup.markAllAsTouched();
  }

  private getData(): void {
    this.providerService
      .getProviders(ProviderType.serviceProvider)
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.serviceProviders = data;
      });
  }
}
