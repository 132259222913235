import { TranslateModule } from '@ngx-translate/core';
import * as L from 'leaflet';
import { LatLngExpression } from 'leaflet';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { SplitterModule } from 'primeng/splitter';

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';

import { DeliveryService } from '../../../services/api/delivery.service';
import { GisService } from '../../../services/api/gis.service';
import { GisTableComponent } from './gis-table/gis-table.component';
import { GisMockService } from './mocks/mock.service';
import { WellData } from './models/well-data.model';
import { PopupComponent } from './popup/popup.component';

@Component({
  selector: 'app-gis-map',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    TranslateModule,
    PopupComponent,
    FormsModule,
    SidebarModule,
    DialogModule,
    GisTableComponent,
    ProgressSpinnerModule,
    SplitterModule,
  ],
  templateUrl: './gis-map.component.html',
  styleUrls: ['./gis-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GisMapComponent extends Destroyable(Object) implements AfterViewInit {
  @Output() changeTab: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild(GisTableComponent) gisTableComponent!: GisTableComponent;

  map!: L.Map;
  marker!: L.Marker;
  searchItem = '';
  isSidebarVisible = false;
  spinnerShow = false;
  tableDataCount = 0;
  mapTableData: WellData[] = [];
  selectedWell: WellData = {
    id: '',
    basinId: '',
    wellTypeId: '',
    wellName: '',
    latitude: 0,
    longitude: 0,
    north: 0,
    east: 0,
    origin: 0,
    uwiUpiCreatedInEpis: false,
    deliveries: [],
  };

  primeNgIcon = L.divIcon({
    html: '<i class="pi pi-map-marker marker-icon" style="color:blue; font-size:1.5rem; font-weight:bold"></i>',
    className: 'marker-icon',
  });

  primeNgIconSelected = L.divIcon({
    html: '<i class="pi pi-map-marker marker-icon" style="color:red; font-size:1.5rem; font-weight:bold"></i>',
    className: 'marker-icon-selected',
  });

  constructor(
    private mockService: GisMockService,
    private gisService: GisService,
    private deliveryService: DeliveryService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.getWellMarkers(0, 200);
  }

  onTabChange(tabIndex: number): void {
    this.changeTab.emit(tabIndex);
    this.setDefaultMarkerIcon();
  }

  getWellMarkers(page: number, pageSize: number): void {
    this.gisService
      .getGisBy(page, pageSize, '')
      .pipe(this.takeUntilDestroyed())
      .subscribe((resp) => {
        this.tableDataCount = resp.count;
        const dataArr = Array.from(resp.data);
        this.mapTableData = dataArr;
        this.applyMarkersOnMap(dataArr);
        this.cd.markForCheck();
      });
  }

  getDelivery(): void {
    this.getMapCenterCoordinates(this.searchItem);
  }

  resizeMap(): void {
    this.map.invalidateSize();
  }

  markerPick(well: WellData, marker: L.Marker): void {
    this.isSidebarVisible = true;
    this.selectedWell = well;
    marker.setIcon(this.primeNgIconSelected);
    this.cd.detectChanges();
  }

  setDefaultMarkerIcon(): void {
    this.isSidebarVisible = false;
    this.map.eachLayer((layer: L.Layer) => {
      if (layer instanceof L.Marker) {
        layer.setIcon(this.primeNgIcon);
      }
    });
  }

  onchangeWellData(d: { data: WellData[]; resetMap: boolean }): void {
    this.applyMarkersOnMap(d.data);

    if (d.resetMap) this.resetZoom();
  }

  onSelectedWell(well: WellData): void {
    let mapCenter: LatLngExpression | null = null;
    mapCenter = [well.latitude, well.longitude];
    this.map.flyTo(mapCenter, 17);
    this.cd.detectChanges();
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [4.710989, -74.072092],
      zoom: 7,
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 25,
      minZoom: 1,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);
  }

  resetZoom(): void {
    this.map.setView([4.710989, -74.072092], 7);
  }

  private applyMarkersOnMap(wellData: WellData[]): void {
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        this.map.removeLayer(layer);
      }
    });

    wellData.forEach((well) => {
      if (well.latitude && well.longitude) {
        const marker = L.marker([well.latitude, well.longitude], { icon: this.primeNgIcon });
        marker.addTo(this.map).on('click', () => this.markerPick(well, marker));
      }
    });
  }

  private getMapCenterCoordinates(searchItem: string): void {
    let mapCenter: LatLngExpression | null = null;
    this.mockService
      .getMockDataByName(searchItem)
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        if (data.length > 0) {
          mapCenter = [data[0].latitude, data[0].longitude];
          this.map.flyTo(mapCenter, 10);
          this.cd.detectChanges();
        }
      });
  }
}
