<div class="header">
  <h1 class="title">{{ 'adminSettings.titles.main' | translate }}</h1>
</div>
<div class="content">
  <p-tabView (onChange)="tabChanged($event.index, userTableComponent)">
    <p-tabPanel header="{{ 'adminSettings.titles.tabs.accountManagement' | translate }}">
      <app-user-table #userTableComponent></app-user-table>
    </p-tabPanel>
    <p-tabPanel header="{{ 'adminSettings.titles.tabs.tables' | translate }}">
      <div class="tables" *ngIf="optionsTableEntities">
        <div class="block" *ngFor="let entityKey of getOptionsTableEntityKeys(); let i = index">
          <app-options-table
            title="{{ getEntityTitle(i) | translate }}"
            [entities]="optionsTableEntities[entityKey]"
            (add)="createDeleteEntity('post', $event, entityKey, getEntityTitle(i))"
            (delete)="createDeleteEntity('delete', $event, entityKey, getEntityTitle(i))"
          ></app-options-table>
        </div>
        <app-options-table-pallet
          title="{{ 'adminSettings.titles.entities.pallets' | translate }}"
        ></app-options-table-pallet>
        <app-options-table-container
          title="{{ 'adminSettings.titles.entities.containers' | translate }}"
        ></app-options-table-container>
        <app-options-table-location
          title="{{ 'adminSettings.titles.entities.locations' | translate }}"
        ></app-options-table-location>
      </div>
      <div class="tables">
        <div class="block">
          <app-options-table-providers
            [type]="ProviderType.operator"
            title="{{ 'adminSettings.providers.operators' | translate }}"
          ></app-options-table-providers>
        </div>
        <div class="block">
          <app-options-table-providers
            [type]="ProviderType.serviceProvider"
            title="{{ 'adminSettings.providers.serviceProviders' | translate }}"
          ></app-options-table-providers>
        </div>
        <div class="block">
          <app-options-table-packaging-types
            title="{{ 'adminSettings.titles.entities.packagingTypes' | translate }}"
          ></app-options-table-packaging-types>
        </div>
        <div class="block">
          <app-options-table-responsibility
            title="{{ 'adminSettings.titles.entities.responsibilities' | translate }}"
          ></app-options-table-responsibility>
        </div>
      </div>

      <app-options-table-transportation
        title="{{ 'adminSettings.titles.entities.transportations' | translate }}"
      ></app-options-table-transportation>
    </p-tabPanel>
    <p-tabPanel header="{{ 'adminSettings.titles.tabs.wellManagement' | translate }}">
      <app-well-management></app-well-management>
    </p-tabPanel>
    <p-tabPanel header="{{ 'adminSettings.titles.tabs.logs' | translate }}">
      <app-logs></app-logs>
    </p-tabPanel>
  </p-tabView>
</div>
