<p-dialog
  [closable]="false"
  [modal]="true"
  [(visible)]="isVisible"
  [draggable]="false"
  [style]="{ width: '80vw', height: '50vw' }"
>
  <ng-template pTemplate="header">
    <div class="custom-header">
      <span class="p-dialog-title">{{ 'assignment.assignmentSearch' | translate }}</span>
      <div class="header-buttons">
        <div class="actions">
          <button
            pButton
            pRipple
            class="btn-primary"
            type="button"
            label="{{ 'button.cancel' | translate }}"
            (click)="cancelSearch()"
          ></button>

          <button
            pButton
            pRipple
            class="btn-secondary"
            type="button"
            label="{{ 'button.select' | translate }}"
            (click)="select()"
            [disabled]="!selectedItems.length"
          ></button>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="content" [formGroup]="assignmentFormGroup">
    <app-well-search-form
      searchValue="UWI/UPI"
      [formGroup]="assignmentFormGroup"
      (searchEvent)="search($event)"
    ></app-well-search-form>

    <div *ngIf="spinnerShow" class="card spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>

    <div class="content-table" formGroupName="assignmentTable">
      <p-table
        [columns]="assignmentTableLabels"
        [value]="getAssignmentTableFormControl().controls"
        dataKey="deliveryId"
        [(selection)]="selectedItems"
        [rows]="initialTableRowCount"
        [totalRecords]="getAssignmentTableFormControl().controls.length"
        [resizableColumns]="true"
        styleClass="p-datatable-gridlines basicTable"
        dataKey="value.deliveryId"
        [paginator]="true"
        rowExpandMode="single"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>

            <ng-container *ngFor="let header of headers">
              <th class="white-space-nowrap">
                {{ header | translate }}
              </th>
            </ng-container>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <ng-container [formArrayName]="'table'">
            <tr [formGroupName]="rowIndex" class="vertical-align-middle">
              <td>
                <div class="p-field-checkbox text-center">
                  <p-tableCheckbox [value]="rowData" id="assignment-checkbox"></p-tableCheckbox>
                </div>
              </td>

              <ng-container *ngFor="let col of columns[0] | keyvalue : orderOriginal">
                <td
                  class="px-1 py-1"
                  *ngIf="
                    !assignmentTableDropdownOption[col.key] &&
                    col.key !== 'dateAssigned' &&
                    col.key !== 'note'
                  "
                >
                  <span class="white-space-pre">{{
                    col.key === 'dateCompleted'
                      ? (getCellValue(rowIndex, col.key) | appDefaultDateFormat)
                      : getCellValue(rowIndex, col.key)
                  }}</span>
                </td>

                <td *ngIf="assignmentTableDropdownOption[col.key]">
                  <app-dropdown-editable
                    [filter]="col.key === 'technician'"
                    [filterBy]="col.key === 'technician' ? 'name' : ''"
                    [type]="col.key"
                    [tableFormGroup]="assignmentTableFormGroup"
                    [formControlName]="col.key"
                    [options]="this.assignmentTableDropdownOption[col.key]"
                    [isDisabled]="col.key === 'technician'"
                    (ngModelChange)="onDropdownValueChanged(rowIndex, col.key)"
                  >
                  </app-dropdown-editable>
                </td>

                <td *ngIf="col.key === 'dateAssigned'">
                  <p-calendar
                    appDefaultCalendarDateFormat
                    appendTo="body"
                    [showTime]="false"
                    [readonlyInput]="true"
                    id="dateAssigned"
                    [placeholder]="(getCellValue(rowIndex, col.key) | appDefaultDateFormat) || ''"
                    [formControl]="getTableFormControlBy(rowIndex, col.key)"
                    [appControlDisabled]="true"
                  ></p-calendar>
                </td>

                <td class="px-1 py-1" *ngIf="col.key === 'note'">
                  <input
                    #note
                    class="p-inputtext p-component p-element"
                    type="text"
                    [pTooltip]="note.value"
                    [tooltipDisabled]="note.value.length < noteMaxLengthBeforeTooltip"
                    [formControlName]="col.key"
                    [appControlDisabled]="true"
                  />
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td [colSpan]="12" class="empty-message">
              {{ 'storage.table.empty-cells' | translate }}
            </td>
          </tr>
        </ng-template></p-table
      >
    </div>
  </div>
</p-dialog>
