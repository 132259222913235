import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { filter as filterFunc, finalize } from 'rxjs';

import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { IdName } from 'src/app/core/models/id-name.model';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';

import { CapitalizeStringPipe } from '../../../common/pipes/capitalize-string.pipe';
import { DateValidationService } from '../../../services/date-validation.service';
import { INITIAL_SAMPLE_TYPE_CHART_STATE } from './sample-types-chart-filter/consts/initial-sample-type-chart-state.const';
import { DashboardFilterResult } from './sample-types-chart-filter/models/dashboard-filter-result.model';
import { SampleTypesChartFilters } from './sample-types-chart-filter/models/sample-types-chart-filters.model';
import { SampleTypesFilter } from './sample-types-chart-filter/models/sample-types-filter.model';
import { SampleTypesChartFilterComponent } from './sample-types-chart-filter/sample-types-chart-filter.component';
import { SampleTypesService } from './services/sample-types.service';

@Component({
  selector: 'app-sample-types-widget',
  standalone: true,
  imports: [
    CommonModule,
    ChartModule,
    SampleTypesChartFilterComponent,
    ButtonModule,
    TranslateModule,
    ProgressSpinnerModule,
  ],
  providers: [DialogService, DatePipe, CapitalizeStringPipe],
  templateUrl: './sample-types-widget.component.html',
  styleUrls: ['./sample-types-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleTypesWidgetComponent extends Destroyable(Object) implements OnInit {
  spinnerShow = false;
  sampleTypes: IdName[] = [
    { id: 'BiostratigraphicBox', name: 'Biostratigraphic' },
    { id: 'CoreBox', name: 'Core' },
    { id: 'FluidBox', name: 'Fluid' },
    { id: 'PetrographicPlateBox', name: 'PetrographicPlate' },
    { id: 'PistonCoreBox', name: 'PistonCore' },
    { id: 'PlugBox', name: 'Plug' },
    { id: 'PreservedBox', name: 'Preserved' },
    { id: 'ResidueBox', name: 'Residue' },
    { id: 'SwcBox', name: 'Swc' },
    { id: 'SurfaceSampleBox', name: 'SurfaceSample' },
    { id: 'ZhZsDatBox', name: 'ZhZsDat' },
  ];

  filterResultData!: DashboardFilterResult[];
  xAxisTitle!: string;
  ref!: DynamicDialogRef;
  chartData: any;
  chartOptions: any;
  filterData: SampleTypesChartFilters = JSON.parse(JSON.stringify(INITIAL_SAMPLE_TYPE_CHART_STATE));

  private readonly seriesColor: Record<string, string> = {
    Biostratigraphic: 'purple',
    Core: 'green',
    Fluid: '#00B2F9',
    PetrographicPlate: '#000000',
    PistonCore: '#FF8F00',
    Plug: '#f37cfe',
    Preserved: '#b43349',
    Crude: '#58c572',
    Residue: '#7692e7',
    Swc: '#d7c40f',
    ZhZsDat: '#27138c',
    SurfaceSample: '#4b46c8',
  };

  private readonly displayId: Record<string, string> = {
    day: 'Day',
    week: 'Week',
    month: 'Month',
  };

  constructor(
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private sampleTypesService: SampleTypesService,
    private capitalizeStringPipe: CapitalizeStringPipe,
    private dateValidationService: DateValidationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setInitialFilters();
    this.applyFilters();
  }

  openOptions(): void {
    this.ref = this.dialogService.open(SampleTypesChartFilterComponent, {
      data: { ...this.filterData, showDialog: true },
      styleClass: 'dialog-custom-size',
      header: this.getTranslationText('dashboard.chart.samplePlotOptions'),
      showHeader: true,
    });

    this.ref.onClose
      .pipe(
        filterFunc((filterData) => !!filterData),
        this.takeUntilDestroyed(),
      )
      .subscribe((data: SampleTypesChartFilters) => {
        this.filterData = data;
        this.applyFilters();
      });
  }

  private applyFilters(): void {
    this.showSpinner();
    const pickedDisplay =
      (Object.keys(this.filterData.display) as (keyof typeof this.filterData.display)[]).find(
        (key) => this.filterData.display[key],
      ) ?? '';

    const sampleTypesSearchObj: { [key: string]: string } = {};

    this.sampleTypes
      .filter(
        (sampleTypes) =>
          !this.filterData.sampleTypesIds.length ||
          this.filterData.sampleTypesIds.includes(sampleTypes.id),
      )
      .forEach((item, index) => {
        sampleTypesSearchObj[`SampleTypes[${index}].name`] = item.id;
      });

    const selectedStartDate = new Date(this.filterData.duration.startDate);
    const selectedEndDate = new Date(this.filterData.duration.endDate);

    const filterData: SampleTypesFilter = {
      startDate: selectedStartDate.toISOString(),
      endDate: new Date(
        selectedEndDate.getFullYear(),
        selectedEndDate.getMonth(),
        selectedEndDate.getDate() + 1,
        0,
        0,
        0,
      ).toISOString(),
      display: this.displayId[pickedDisplay],
      timeZoneIana: Intl.DateTimeFormat().resolvedOptions().timeZone,
      sampleTypes: sampleTypesSearchObj,
    };

    this.dateValidationService.validateDate(selectedStartDate, selectedEndDate);

    this.sampleTypesService
      .getFilteredSamples(filterData)
      .pipe(
        finalize(() => {
          this.hideSpinner();
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe((data) => {
        this.filterResultData = data;
        this.buildChart(this.filterResultData);
      });
  }

  private buildChart(filterResultData: DashboardFilterResult[]): void {
    const datasets = this.sampleTypes
      .filter(
        (sampleTypes) =>
          !this.filterData.sampleTypesIds.length ||
          this.filterData.sampleTypesIds.includes(sampleTypes.id),
      )
      .map((el) => {
        const data = filterResultData.map((result) => {
          const value = result.value.find((entry) => entry.displayName === el.name);
          return value ? value.count : 0;
        });

        return {
          type: 'bar',
          label: this.capitalizeStringPipe.transform(el.name),
          backgroundColor: this.seriesColor[el.name],
          data,
        };
      });

    switch (true) {
      case this.filterData.display.day:
        this.xAxisTitle = this.getTranslationText('dashboard.chart.days');
        break;
      case this.filterData.display.month:
        this.xAxisTitle = this.getTranslationText('dashboard.chart.months');
        break;
      default:
        this.xAxisTitle = this.getTranslationText('dashboard.chart.weeks');
    }

    const categories = filterResultData.map((resData) => resData.groupKey);

    this.chartData = {
      labels: categories,
      datasets,
    };

    this.chartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        legend: {
          labels: {
            color: 'black',
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: 'black',
          },
          grid: {
            color: 'white',
            drawBorder: false,
          },
          title: {
            display: true,
            text: this.xAxisTitle,
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: 'black',
          },
          grid: {
            color: 'white',
            drawBorder: false,
          },
          title: {
            display: true,
            text: this.getTranslationText('dashboard.chart.counts'),
          },
        },
      },
    };

    this.cd.markForCheck();
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }

  private setInitialFilters(): void {
    const newDate = new Date();
    const sixMonthAgo = new Date(
      newDate.getFullYear(),
      newDate.getMonth() - 6,
      newDate.getDate(),
      0,
      0,
      0,
    );
    const today = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0);
    this.filterData.duration.startDate = sixMonthAgo.toISOString();
    this.filterData.duration.endDate = today.toISOString();
  }

  private showSpinner(): void {
    this.spinnerShow = true;
    this.cd.markForCheck();
  }

  private hideSpinner(): void {
    this.spinnerShow = false;
    this.cd.markForCheck();
  }
}
