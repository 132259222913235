import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

import { CommonModule, formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { NotificationType } from 'src/app/core/enums/notification-type.enum';
import { UserNotification } from 'src/app/core/models/user-notification.model';

import { DefaultDateFormatPipe } from '../../common/pipes/default-date-format.pipe';
import { DEFAULT_DATE_FORMAT } from '../../core/consts';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { FileUploadService } from '../../services/api/file-service.service';

@Component({
  selector: 'app-dashboard-notifications',
  standalone: true,
  imports: [CommonModule, ButtonModule, TranslateModule, DefaultDateFormatPipe],
  templateUrl: './dashboard-notifications.component.html',
  styleUrls: ['./dashboard-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardNotificationsComponent extends Destroyable(Object) {
  @Input() notification!: UserNotification;
  @Output() delete = new EventEmitter<string>();

  notificationTypeTitleKeyMap: Record<NotificationType, string> = {
    [NotificationType.ASSIGMENT]: 'dashboard.notifications.assignment',
    [NotificationType.SERVICE_SCHEDULE]: 'dashboard.notifications.service',
    [NotificationType.WELL_REQUEST]: 'dashboard.notifications.message',
  };

  constructor(private fileUploadService: FileUploadService) {
    super();
  }

  onDelete(id: string): void {
    this.delete.emit(id);
  }

  hasAttachments(message: string): boolean {
    return message.includes('Attachments:');
  }

  diplayMsg(message: string): string {
    const regexDateTime = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/;
    const dateMatch = message.match(regexDateTime);
    let messageWithFormattedDate = message;

    if (dateMatch?.length) {
      const formattedDate = formatDate(dateMatch[0], DEFAULT_DATE_FORMAT.datePipe, 'en-US');
      messageWithFormattedDate = messageWithFormattedDate.replace(dateMatch[0], formattedDate);
    }

    const regexAttachments = /(.+Attachments:)/;
    const match = messageWithFormattedDate.match(regexAttachments);

    return match && match.length > 1 ? match[0] : '';
  }

  attachment(message: string): void {
    const { fileId, fileName } = this.extractFileInformation(message);

    if (fileName && fileId) {
      this.fileUploadService
        .download(fileId)
        .pipe(this.takeUntilDestroyed())
        .subscribe((file) => {
          this.downloadFile(file, fileName);
        });
    }
  }

  private downloadFile(fileData: ArrayBuffer, fileName: string): void {
    const blob = new Blob([fileData], { type: 'application/octet-stream' });

    const link = document.createElement('a');

    link.download = fileName;
    link.href = window.URL.createObjectURL(blob);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  extractFileInformation(message: string): { fileId: string; fileName: string } {
    const match = message.match(/Attachments:\s*([^\s]+(?:\s*\(\d+\))?\.?\w*)\s*id:\s*([\w-]+)/);

    if (match) {
      const [, fileName, fileId] = match.map((part) => part.trim());
      return { fileId, fileName };
    }

    return { fileId: '', fileName: '' };
  }
}
