<div class="table-container">
  <p-table
    [value]="searchValues"
    dataKey="id"
    [paginator]="true"
    [rows]="5"
    [selectionMode]="'multiple'"
    [(selection)]="selectedItems"
    (onRowSelect)="emitSelectedItems()"
    (onRowUnselect)="emitSelectedItems()"
    [resizableColumns]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th *ngIf="!isSingleEntry" style="width: 4rem">
          <p-tableHeaderCheckbox (click)="emitSelectedItems()"></p-tableHeaderCheckbox>
        </th>
        <th *ngIf="!isShortened">
          <div class="header-container">
            {{ 'storage.table.sample-label' | translate }}
            <p-button
              icon="pi pi-print"
              styleClass="p-button-info"
              [disabled]="selectedItems | printDisabled : PrintLabelType.sample"
              (click)="printAllLabels(PrintLabelType.sample)"
              pTooltip="{{ 'storage.print.allSamples' | translate }}"
              tooltipPosition="bottom"
            ></p-button>
          </div>
        </th>
        <th *ngIf="!isShortened">
          <div class="header-container">
            {{ 'storage.table.container-label' | translate }}
            <p-button
              icon="pi pi-print"
              styleClass="p-button-info"
              [disabled]="selectedItems | printDisabled : PrintLabelType.container"
              (click)="printAllLabels(PrintLabelType.container)"
              pTooltip="{{ 'storage.print.allContainers' | translate }}"
              tooltipPosition="bottom"
            ></p-button>
          </div>
        </th>
        <th>
          <div class="header-container">
            {{ 'storage.table.pallet-label' | translate }}
            <p-button
              *ngIf="!isSingleEntry"
              icon="pi pi-print"
              styleClass="p-button-info"
              [disabled]="selectedItems | printDisabled : PrintLabelType.pallet"
              (click)="printAllLabels(PrintLabelType.pallet)"
              pTooltip="{{ 'storage.print.allPallets' | translate }}"
              tooltipPosition="bottom"
            ></p-button>
          </div>
        </th>
        <th>
          <div class="header-container">
            {{ 'storage.table.location-label' | translate }}
            <p-button
              *ngIf="!isSingleEntry"
              icon="pi pi-print"
              styleClass="p-button-info"
              [disabled]="selectedItems | printDisabled : PrintLabelType.location"
              (click)="printAllLabels(PrintLabelType.location)"
              pTooltip="{{ 'storage.print.allLocations' | translate }}"
              tooltipPosition="bottom"
            ></p-button>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-value>
      <tr>
        <td *ngIf="!isSingleEntry">
          <p-tableCheckbox [value]="value"></p-tableCheckbox>
        </td>
        <td *ngIf="!isShortened">
          <div class="value" [ngClass]="value.sampleLabel ? 'space-between' : 'flex-end'">
            {{ value.sampleLabel ?? '&#160;' }}
            <app-print-label
              [labelType]="printLabelType.sample"
              [entity]="{
                label: value.sampleLabel,
                wellName: value.wellName,
                uwi: value.uwi,
                sampleType: value.sampleType,
                unitNumber: value.unitNumber,
                depth: value.depth
              }"
            ></app-print-label>
          </div>
        </td>
        <td *ngIf="!isShortened">
          <div class="value" [ngClass]="value.containerLabel ? 'space-between' : 'flex-end'">
            {{ value.containerLabel ?? '&#160;' }}
            <app-print-label
              [labelType]="printLabelType.container"
              [entity]="{ label: value.containerLabel }"
            ></app-print-label>
          </div>
        </td>
        <td>
          <div class="value" [ngClass]="value.palletLabel ? 'space-between' : 'flex-end'">
            {{
              value.palletLabel ? (value.palletLabel | appFormatPalletLabel : pallets) : '&#160;'
            }}
            <app-print-label
              [labelType]="printLabelType.pallet"
              [entity]="{ label: value.palletLabel }"
            ></app-print-label>
          </div>
        </td>
        <td>
          <div class="value" [ngClass]="value.locationLabel ? 'space-between' : 'flex-end'">
            {{ value.locationLabel ?? '&#160;' }}
            <app-print-label
              [labelType]="printLabelType.location"
              [entity]="{
                label: value.locationLabel,
                bay: value.locationBay,
                position: value.locationPosition
              }"
            ></app-print-label>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="5">
          <ng-container *ngIf="!isSearchPerformed">
            {{ 'storage.table.empty-cells' | translate }}
          </ng-container>
          <ng-container *ngIf="isSearchPerformed">
            {{ 'storage.table.empty-search' | translate }}
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
