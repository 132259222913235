import { ButtonModule } from 'primeng/button';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PrintLabelType } from 'src/app/core/enums/print-label-type.enum';
import {
  PrintableContainerLabel,
  PrintableLocationLabel,
  PrintablePalletLabel,
  PrintableSampleLabel,
} from 'src/app/core/models/printable-labels.model';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';
import { PrintLabelService } from 'src/app/services/api/print-label.service';

import { SampleLabelPayload } from '../../core/models/sample-label-payload.model';

@Component({
  selector: 'app-print-label',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './print-label.component.html',
  styleUrls: ['./print-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintLabelComponent extends Destroyable(Object) {
  @Input() entity!:
    | PrintableContainerLabel
    | PrintableLocationLabel
    | PrintablePalletLabel
    | PrintableSampleLabel;

  @Input() labelType!: PrintLabelType;

  readonly printLabelType = PrintLabelType;

  constructor(private printLabelService: PrintLabelService) {
    super();
  }

  printLabel(): void {
    switch (this.labelType) {
      case PrintLabelType.pallet:
        this.getPalletLabel(this.entity);
        break;
      case PrintLabelType.container:
        this.getContainerLabel(this.entity);
        break;
      case PrintLabelType.location:
        this.getLocationLabel(this.entity as PrintableLocationLabel);
        break;
      case PrintLabelType.sample:
        this.getSampleLabel(this.entity as PrintableSampleLabel);
        break;
      case PrintLabelType.box:
        break;

      default:
        break;
    }
  }

  getPalletLabel(entity: PrintablePalletLabel): void {
    this.printLabelService
      .getPalletLabel(entity.label)
      .pipe(this.takeUntilDestroyed())
      .subscribe((label) => {
        this.print(label.body);
      });
  }

  getContainerLabel(entity: PrintableContainerLabel): void {
    this.printLabelService
      .getContainerLabel(entity.label)
      .pipe(this.takeUntilDestroyed())
      .subscribe((label) => {
        this.print(label.body);
      });
  }

  getLocationLabel(entity: PrintableLocationLabel): void {
    this.printLabelService
      .getLocationLabel(entity.label, entity.bay, entity.position)
      .pipe(this.takeUntilDestroyed())
      .subscribe((label) => {
        this.print(label.body);
      });
  }

  getSampleLabel(entity: PrintableSampleLabel): void {
    const sampleLabelPayload = new SampleLabelPayload(
      entity.label,
      entity.wellName,
      entity.uwi,
      entity.sampleType,
      entity.unitNumber,
      entity.depth,
    );

    this.printLabelService
      .getSampleLabel(sampleLabelPayload)
      .pipe(this.takeUntilDestroyed())
      .subscribe((label) => {
        this.print(label.body!);
      });
  }

  print(body: Blob): void {
    const fileUrl = URL.createObjectURL(body);
    const printWindow = window.open(fileUrl, '_blank', 'resizable=yes');
    printWindow?.addEventListener('load', () => {
      printWindow?.print();
      URL.revokeObjectURL(fileUrl);
    });
  }
}
