import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from 'src/app/core/api.consts';

import { SampleLabelPayload } from '../../core/models/sample-label-payload.model';

@Injectable({
  providedIn: 'root',
})
export class PrintLabelService {
  private url = `${SGC_API}/LabelGenerator`;
  public container = `${this.url}/container`;
  public pallet = `${this.url}/pallet`;
  public location = `${this.url}/location`;
  public sample = `${this.url}/sample`;
  public box = `${this.url}/box`;
  headers = new HttpHeaders({
    observe: 'response',
    responseType: 'blob' as 'json',
  });

  constructor(private httpClient: HttpClient) {}

  getContainerLabel(label: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.container}`,
      {
        org_name: 'SGC',
        barcode_value: label,
        barcode_string: 'LPN',
      },
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }

  getPalletLabel(label: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.pallet}`,
      {
        org_name: 'SGC',
        barcode_value: label,
        barcode_string: 'Pallet',
      },
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }

  getLocationLabel(label: string, bay: string, position: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.location}`,
      {
        org_name: 'SGC',
        barcode_value: label,
        location_bay: bay,
        location_position: position,
      },
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }

  getSampleLabel(payload: SampleLabelPayload): Observable<HttpResponse<Blob>> {
    return this.httpClient.post<Blob>(`${this.sample}`, payload, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
