export enum OptionsTableEntityKey {
  basinTypes = 'BasinTypes',
  biostratigraphicSampleTypes = 'BiostratigraphicSampleTypes',
  boxTypes = 'BoxTypes',
  depthUnits = 'DepthUnits',
  endCupFractionInfos = 'EndCupFractionInfos',
  extractionPositionInfos = 'ExtractionPositionInfos',
  fluidTypes = 'FluidTypes',
  lengthUnits = 'LengthUnits',
  mediumDeliveryInfos = 'MediumDeliveryInfos',
  orientationTypes = 'OrientationTypes',
  physicalStatuses = 'PhysicalStatuses',
  plateTypes = 'PlateTypes',
  preparationTypes = 'PreparationTypes',
  preservationTypes = 'PreservationTypes',
  sampleOrigins = 'SampleOrigins',
  sampleStatuses = 'SampleStatuses',
  samplingMethods = 'SamplingMethods',
  sectionTypes = 'SectionTypes',
  surfaceSampleTypes = 'SurfaceSampleTypes',
  technicalInfoses = 'TechnicalInfoses',
  wellTypes = 'WellTypes',
  weightUnits = 'WeightUnits',
  zhZsDatObjectiveSets = 'zhZsDatObjectiveSets',
  zhZsDatSampleTypes = 'ZhZsDatSampleTypes',
  volumeUnits = 'VolumeUnits',
}
