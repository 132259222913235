export interface ProviderModel {
  id?: string;
  companyName: string;
  contactName: string;
  contactNumber: string;
  email: string;
}
export enum ProviderType {
  operator = 'Operator',
  serviceProvider = 'Service Provider',
}
