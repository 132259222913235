<div class="header">
  <div class="flex-1 flex flex-column gap-2 mb-3">
    <span class="p-input-icon-left p-float-label w-full">
      <i class="pi pi-search"></i>
      <input
        id="float-input"
        class="search-input not-part-of-basicTable"
        type="text"
        pInputText
        placeholder="Search"
        [(ngModel)]="searchText"
        (input)="filterTable()"
      />
      <label for="float-input">{{ 'button.search' | translate }}</label>
    </span>

    <p-checkbox
      *ngIf="isContainerSearchPopup"
      [label]="'sampleManagement.receptionPage.showEmptyContainersOnly' | translate"
      [binary]="true"
      [labelStyleClass]="'cursor-pointer'"
      [trueValue]="'true'"
      [falseValue]="null"
      [(ngModel)]="isEmptyOnlyDisplayed"
      (onChange)="filterTable()"
    ></p-checkbox>
  </div>

  <div class="actions flex-1 flex align-items-baseline justify-content-end">
    <button
      pButton
      pRipple
      type="button"
      label="{{ 'button.clear' | translate }}"
      (click)="resetPage()"
      class="btn btn-primary"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      label="{{ 'button.select' | translate }}"
      class="btn btn-secondary"
      [disabled]="!selectedRow"
      (click)="select()"
    ></button>
  </div>
</div>
<div *ngIf="showSpinner" class="card spinner">
  <p-progressSpinner></p-progressSpinner>
</div>
<ng-container *ngIf="rows.length">
  <p-table [value]="rows" [paginator]="true" [rows]="10" [responsive]="true">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ tableHeader }}</th>
        <th *ngIf="showLocationOccupied">
          {{ 'adminSettings.titles.table.occupied' | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
      <tr
        [class.selected]="selectedRow === row"
        [class.disabled-row]="isRowDisabled(row) || (row.occupied && this.showLocationOccupied)"
        (click)="onRowSelect(row)"
      >
        <td>
          <ng-container *ngIf="row.name; else locationLabel">
            {{ row.name }}
          </ng-container>
          <ng-template #locationLabel>
            <ng-container *ngIf="row.palletLabel">
              {{ row.palletLabel }}
            </ng-container>
            <ng-container *ngIf="!row.palletLabel">
              {{ row.locationLabel }}
            </ng-container>
          </ng-template>
        </td>
        <td *ngIf="!row.palletLabel && !row.containerLabel && this.showLocationOccupied">
          {{ (row.occupied ? 'messages.yes' : 'messages.no') | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div
    *ngIf="isContainerSearchPopup"
    class="mt-2 w-max cursor-pointer new-container"
    (click)="showContainerModel()"
  >
    <i class="pi pi-plus" style="font-size: 13px"></i>
    {{ 'sampleManagement.receptionPage.newContainer' | translate }}
  </div>
</ng-container>

<p-dialog
  header="Header"
  [(visible)]="isAddModalVisible"
  [modal]="true"
  [style]="{ width: '400px' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
>
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">{{
      'sampleManagement.receptionPage.newContainer' | translate
    }}</span>
  </ng-template>
  <div class="container add_pallet">
    <span class="p-float-label">
      <input pInputText id="containerLabel" [(ngModel)]="entity.containerLabel" [disabled]="true" />
      <label htmlFor="containerLabel">{{
        'sampleManagement.receptionPage.containerLabel' | translate
      }}</label>
    </span>
  </div>
  <ng-template pTemplate="footer">
    <p-button (click)="onCancelModal()" label="Cancel" styleClass="btn-primary"></p-button>
    <p-button
      [disabled]="!entity.containerLabel"
      (onClick)="addContainer()"
      label="Add"
      styleClass="btn-secondary"
    ></p-button>
  </ng-template>
</p-dialog>
