import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ShortTransportationModel } from '../../core/models/transportation.model';

@Component({
  selector: 'app-transportation-search',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    RippleModule,
    SharedModule,
    TableModule,
    TranslateModule,
    FormsModule,
  ],
  templateUrl: './transportation-search.component.html',
  styleUrls: ['./transportation-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationSearchComponent implements OnChanges, OnInit {
  @Input() tableData: ShortTransportationModel[] = [];
  @Output() selectedTransportationId: EventEmitter<any> = new EventEmitter<any>();
  tableHeaders: string[] = ['Company name', 'Driver name', 'License plate'];
  rows: ShortTransportationModel[] = [];
  selectedRow: ShortTransportationModel | null = null;
  searchText!: string;
  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['tableData'].currentValue) {
      this.rows = simpleChanges['tableData'].currentValue;
    }
  }

  ngOnInit(): void {
    if (this.tableData) {
      this.rows = this.tableData;
    }
    this.cd.markForCheck();
  }

  onRowSelect(row: ShortTransportationModel): void {
    this.selectedRow = row;
  }

  resetPage(): void {
    this.searchText = '';
    this.selectedRow = null;
    this.rows = this.tableData;
  }

  select(): void {
    this.selectedTransportationId.emit(this.selectedRow!.id);
  }

  filterTable(): void {
    if (!this.searchText) {
      this.rows = this.tableData;
    } else {
      this.rows = this.tableData.filter((row) => {
        const values = Object.keys(row)
          .filter((key) => key !== 'id')
          .map((key) =>
            row[key as keyof ShortTransportationModel]
              ? row[key as keyof ShortTransportationModel]?.toString().toLowerCase()
              : '',
          );
        return values.some((value) => value && value.includes(this.searchText.toLowerCase()));
      });
    }
  }
}
