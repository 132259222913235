import { Injectable } from '@angular/core';

import {
  SelfServiceToValidationTable,
  TabInfo,
} from '../../common/self-service-to-validation-table/models/self-service-to-validation.model';
import { PrintDelivery } from '../../core/models/print-delivery.model';
import { PrintDeliveryTable } from '../../core/models/print-delivery-table.model';
import {
  downloadDoc,
  SAMPLE_INFO_TABLE_DROPDOWN_OPTION,
  TABLE_NAME,
  TableNameType,
} from '../../core/table-consts';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { Verification } from '../../features/verification/models/verification-sample-information.model';
import { PrintDeliveryService } from './print-delivery.service';

@Injectable({
  providedIn: 'root',
})
export class PrintDeliveryDocService extends Destroyable(Object) {
  sampleInfoTableDropdownOption = SAMPLE_INFO_TABLE_DROPDOWN_OPTION;
  tableName = TABLE_NAME;
  tableNameType = TableNameType;

  constructor(private printDeliveryService: PrintDeliveryService) {
    super();
  }

  downloadDoc(
    contractId: string,
    barcodeId: string,
    basin: string,
    operatorProvider: string,
    serviceProvider: string,
    data: Verification,
    tableData: TabInfo[],
  ): void {
    let depthUnit = '';
    const printDeliveryData = new PrintDelivery(
      contractId,
      barcodeId,
      basin!,
      operatorProvider!,
      serviceProvider!,
      data,
    );

    tableData.forEach((key, index) => {
      if (tableData[index].data.length > 0) {
        tableData[index].data.forEach((d: SelfServiceToValidationTable) => {
          const packagingType = this.sampleInfoTableDropdownOption['packagingTypeId'].find(
            (obj) => obj.id === d.packagingTypeId,
          )?.name;
          if (!depthUnit && d.depthUnitId) depthUnit = d.depthUnitId;

          printDeliveryData.records.push(
            new PrintDeliveryTable(packagingType!, this.tableNameType[this.tableName[index]], d),
          );
        });
      }
    });

    if (depthUnit) {
      depthUnit =
        this.sampleInfoTableDropdownOption['depthUnitId'].find((obj) => obj.id === depthUnit)
          ?.name || '';

      printDeliveryData.depth_unit = depthUnit;
    }

    printDeliveryData.records = printDeliveryData.records.filter((record) =>
      Object.keys(record).some(
        (key) => key !== 'Type' && record[key as keyof PrintDeliveryTable] !== '',
      ),
    );

    this.printDeliveryService
      .printDelivery(printDeliveryData)
      .pipe(this.takeUntilDestroyed())
      .subscribe((printForm) => {
        if (printForm) {
          const fileName = 'PrintDelivery.docx';
          const mimeType = '.docx';
          downloadDoc(printForm, fileName, mimeType);
        }
      });
  }
}
