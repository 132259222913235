<ng-container [formGroup]="parentGroup">
  <p-accordionTab
    header="{{ 'selfServices.transportationInformation' | translate }}"
    formGroupName="transportationInformation"
    [selected]="isExpanded"
  >
    <div class="row input-group-50">
      <span class="p-float-label">
        <p-dropdown
          id="companyName-input"
          formControlName="companyName"
          placeholder="{{ 'selfServices.transportationCompanyName' | translate }}"
          optionValue="id"
          [options]="transportations"
          [showClear]="true"
          [editable]="false"
          [readonly]="true"
          (click)="displayPopup()"
        >
          <ng-template pTemplate="selectedItem">
            <div
              class="flex align-items-center"
              *ngIf="parentGroup.get(['transportationInformation', 'companyName'])!.getRawValue()"
            >
              <span class="dropdown-value">{{
                transportationMap[
                  parentGroup.get(['transportationInformation', 'companyName'])!.getRawValue()
                ] | buildTransportationName
              }}</span>
            </div>
          </ng-template>
        </p-dropdown>
        <label for="companyName-input"
          >{{ 'selfServices.transportationCompanyName' | translate
          }}<span *ngIf="required">*</span></label
        >
        <small
          *ngIf="checkFormError('transportationInformation.companyName')['required']"
          class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>
    </div>
  </p-accordionTab>
</ng-container>
<p-dialog
  header="{{ 'selfServices.transportationInformation' | translate }}"
  [closable]="true"
  [modal]="true"
  [(visible)]="isSearchPopupVisible"
  [style]="{ width: '80vw', height: '50vw' }"
>
  <app-transportation-search
    #transportationSearchComponent
    [tableData]="transportations"
    (selectedTransportationId)="onSelectTransportation($event)"
  ></app-transportation-search>
</p-dialog>
