import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { finalize } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DropDownOption } from '../../core/models/drop-down-option.model';
import { ProviderModel, ProviderType } from '../../core/models/provider.model';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { ProviderService } from '../../services/api/provider.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-options-table-providers',
  standalone: true,
  templateUrl: './options-table-providers.component.html',
  styleUrls: ['./options-table-providers.component.scss'],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    DialogModule,
    ConfirmPopupModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsTableProvidersComponent extends Destroyable(Object) implements OnInit {
  @Input() title = '';
  @Input() type = ProviderType.operator;
  providers = new Array<DropDownOption | unknown>();
  isModalVisible = false;
  isSavingInProgress = false;
  mode = '';
  provider: ProviderModel = {
    id: undefined,
    companyName: '',
    contactName: '',
    contactNumber: '',
    email: '',
  };

  constructor(
    private cd: ChangeDetectorRef,
    private providerService: ProviderService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.initProviders();
  }

  showDialog(): void {
    this.mode = 'Add';
    this.isModalVisible = true;
  }

  onCancelModal(): void {
    this.closeDialog();
    this.clearProviderData();
  }

  closeDialog(): void {
    this.isModalVisible = false;
  }

  onSave(): void {
    this.isSavingInProgress = true;

    if (this.mode === 'Add') {
      this.providerService
        .createProvider(this.provider, this.type)
        .pipe(
          this.takeUntilDestroyed(),
          finalize(() => {
            this.isSavingInProgress = false;
            this.cd.markForCheck();
          }),
        )
        .subscribe(() => {
          this.toastService.toastSuccess(
            `${this.title} ${this.provider.companyName} ${this.translate.instant(
              'adminSettings.messages.hasBeenCreated',
            )}`,
          );
          this.closeDialog();
          this.clearProviderData();
        });
    } else {
      this.providerService
        .editProvider(this.provider, this.type)
        .pipe(
          this.takeUntilDestroyed(),
          finalize(() => {
            this.isSavingInProgress = false;
            this.cd.markForCheck();
          }),
        )
        .subscribe(() => {
          this.toastService.toastSuccess(
            `${this.title} ${this.provider.companyName} ${this.translate.instant(
              'adminSettings.messages.hasBeenUpdated',
            )}`,
          );
          this.closeDialog();
          this.clearProviderData();
        });
    }
  }

  onEdit(provider: DropDownOption): void {
    this.providerService
      .getProviderById(provider.id, this.type)
      .subscribe((data: ProviderModel) => {
        this.provider = data;
        this.provider.id = provider.id;
        this.mode = 'Edit';
        this.isModalVisible = true;
        this.cd.detectChanges();
      });
  }

  private initProviders() {
    this.providerService
      .getProviders(this.type)
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.providers = data;
        this.cd.detectChanges();
      });
  }

  private clearProviderData(): void {
    this.provider = {
      id: undefined,
      companyName: '',
      contactName: '',
      contactNumber: '',
      email: '',
    };
  }
}
