<div #sidenavContainer class="main-wrapper min-h-screen flex surface-ground">
  <div
    class="sidenav-container surface-section h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-1 border-right-1 surface-border select-none"
    style="width: 80px"
    [class.sidenav-collapsed]="!sidenavOpenedAnimation"
    [@openClose]="sidenavOpened"
    (@openClose.done)="onAnimationEvent($event)"
    (@openClose.start)="onAnimationEvent($event)"
  >
    <div class="sidenav-header-absolute" [class.collapsed]="!sidenavOpened">
      <ng-template [ngIf]="sidenavOpened">
        <app-translation></app-translation>
        <ng-template #statusTemplate [ngIf]="isAdmin">
          <div
            class="service-box"
            (mouseover)="this.isHovered = true; op.show($event)"
            [ngClass]="{ red: serviceDown && isHovered, green: !serviceDown && isHovered }"
          >
            <span class="circle" *ngIf="!serviceDown">
              <i
                class="pi"
                [ngClass]="{ 'pi-check': isHovered, 'pi-check-circle': !isHovered }"
              ></i>
            </span>
            <span class="circle" *ngIf="serviceDown">
              <i
                class="pi"
                [ngClass]="{ 'pi-times': isHovered, 'pi-times-circle': !isHovered }"
              ></i>
            </span>
          </div>

          <p-overlayPanel #op (onHide)="onOverlayHide()" [appendTo]="sidenavContainer">
            <ng-template pTemplate="content">
              <h4 class="title">{{ 'sidebar.status.serviceStatus' | translate }}</h4>
              <ul>
                <li
                  class="service-box"
                  *ngFor="let service of serviceStatusArray"
                  [ngClass]="{ red: !service.alive, green: service.alive }"
                >
                  <span class="circle">
                    <i
                      class="pi"
                      [ngClass]="{ 'pi-check': service.alive, 'pi-times': !service.alive }"
                    ></i>
                  </span>
                  <span class="status-name">{{
                    'sidebar.status.' + service.name | translate
                  }}</span>
                </li>
              </ul>
            </ng-template>
          </p-overlayPanel>
        </ng-template>
      </ng-template>

      <button
        pButton
        pRipple
        type="button"
        [icon]="'pi ' + (sidenavOpened ? 'pi-times' : 'pi-bars')"
        class="sidebar-expand p-button-rounded p-button-secondary p-button-text"
        (click)="sidenavOpened = !sidenavOpened"
      ></button>
    </div>

    <div class="flex flex-column h-full">
      <div
        class="flex align-items-center px-5 flex-shrink-0 pt-7"
        style="height: 170px"
        [style.height.px]="sidenavOpened ? 140 : 140"
      >
        <img
          [src]="sidenavOpenedAnimation ? logoImage : logoImageSmall"
          alt="Image"
          height="{{ sidenavOpenedAnimation ? 130 : 100 }}"
          [style.padding.px]="sidenavOpenedAnimation ? 0 : 15"
          [style.padding-top.px]="sidenavOpenedAnimation ? 0 : 45"
          [ngStyle]="{ 'margin-left.em': '-2', 'margin-top.em': '-2' }"
        />
      </div>
      <hr class="divider mb-3 mx-3 border-top-1 border-none surface-border" />
      <div class="overflow-y-auto">
        <ul class="sidenav-item-ul list-none p-3 m-0">
          <li
            *ngFor="let menuItem of sidebarMenuItems"
            pTooltip="{{ menuItem.labelKey | translate }}"
            [tooltipDisabled]="sidenavOpened"
            [class.active]="menuItem.isActive"
          >
            <a
              pRipple
              [routerLink]="[menuItem.url]"
              class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors"
            >
              <i class="pi mr-2" [ngClass]="menuItem.icon"></i>
              <span class="font-medium sidenav-item-label">{{
                menuItem.labelKey | translate
              }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="mt-auto">
        <a
          (click)="logout()"
          pRipple
          class="m-3 flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors"
          pTooltip="Log out"
          [tooltipDisabled]="sidenavOpened"
        >
          <i class="pi pi-sign-out mr-2"></i>
          <span class="font-medium sidenav-item-label">{{ 'sidebar.logOut' | translate }}</span>
        </a>
      </div>
    </div>
  </div>

  <div class="min-h-screen w-1rem flex flex-column relative flex-auto">
    <div class="flex content-container flex-column flex-auto">
      <app-content></app-content>
    </div>

    <div
      class="footer flex align-items-center px-4 surface-0 border-bottom-1 surface-border relative lg:static"
      [ngClass]="showLicenseExpirationWarning ? 'justify-content-between' : 'justify-content-end'"
    >
      <p *ngIf="showLicenseExpirationWarning">
        {{ 'welcomePage.error.licenseWillExpire' | translate }}
        {{ daysRemainingForLicenseExpiration }} {{ 'welcomePage.error.days' | translate }}.
        {{ 'welcomePage.error.contactAdmin' | translate }}
      </p>
      <div class="flex">
        {{ footerText | translate }}
      </div>
    </div>
  </div>
</div>
