<div class="content">
  <div class="search-input search-filter">
    <app-storage-filter-option
      [formGroup]="formGroup"
      [filterOption]="filterOption"
    ></app-storage-filter-option>
    <app-well-search-form
      searchValue=""
      [formGroup]="formGroup"
      (searchEvent)="search($event)"
    ></app-well-search-form>
  </div>
  <p-accordion [multiple]="true">
    <p-accordionTab header="{{ 'storage.tabs.sample-information' | translate }}" [selected]="true">
      <app-sample-information
        [isSearchPerformed]="isSearchPerformed"
        [searchValues]="samples"
        [pallets]="pallets"
      >
      </app-sample-information>
    </p-accordionTab>
  </p-accordion>
</div>
