export enum EntityCacheKey {
  WELL_TYPES = 'wellTypes',
  BASIN_TYPES = 'basinTypes',
  BOX_TYPES = 'boxTypes',
  DEPTH_UNITS = 'depthUnits',
  LENGTH_UNITS = 'lengthUnits',
  WEIGHT_UNITS = 'weightUnits',
  SECTION_TYPES = 'sectionTypes',
  TECHNICAL_INFOSES = 'technicalInfoses',
  MEDIUM_DELIVERY_INFOS = 'mediumDeliveryInfos',
  FLUID_TYPES = 'fluidTypes',
  BIOSTRATIGRAPHIC_SAMPLE_TYPES = 'biostratigraphicSampleTypes',
  END_CUP_FRACTION_INFOS = 'endCupFractionInfos',
  EXTRACTION_POSITION_INFOS = 'extractionPositionInfos',
  ORIENTATION_TYPES = 'orientationTypes',
  PHYSICAL_STATUSES = 'physicalStatuses',
  PLATE_TYPES = 'plateTypes',
  PREPARATION_TYPES = 'preparationTypes',
  PRESERVATION_TYPES = 'preservationTypes',
  PROJECT_STATES = 'projectStates',
  SAMPLE_ORIGINS = 'sampleOrigins',
  SAMPLE_STATUSES = 'sampleStatuses',
  SAMPLING_METHODS = 'samplingMethods',
  SURFACE_SAMPLE_TYPES = 'surfaceSampleTypes',
  ZH_ZS_DAT_OBJECTIVE_SETS = 'zhZsDatObjectiveSets',
  ZH_ZS_DAT_SAMPLE_TYPES = 'zhZsDatSampleTypes',
  PALLETS = 'pallets',
  CONTAINERS = 'containers',
  TRANSPORTATIONS = 'transportations',
  OPERATORS = 'operators',
  SERVICE_PROVIDERS = 'serviceProviders',
  PACKAGING_TYPES = 'packagingTypes',
  LOCATIONS = 'locations',
  ORGANIZATIONS = 'organizations',
  VOLUME_UNITS = 'volumeUnits',
  OPERATOR_COMPANY_NAMES = 'operatorCompanyNames',
  SERVICE_PROVIDER_COMPANY_NAMES = 'serviceProviderCompanyNames',
}
