import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { PasswordModule } from 'primeng/password';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { forkJoin, map } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EMAIL_REGEX } from 'src/app/core/consts';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';
import { AdminSettingsService } from 'src/app/services/api/admin-settings.service';
import { ToastService } from 'src/app/services/toast.service';

import { Responsibility } from '../../../core/models/responsibility.model';
import { Role } from '../../../core/models/role.model';
import { USE_OKTA_AUTH_TOKEN } from '../../../core/tokens/use-okta-auth.token';
import { ResponsibilityService } from '../../../services/api/responsibility.service';
import { UserService } from '../../../services/api/user.service';
import { User } from './user.model';

@Component({
  selector: 'app-user-table',
  standalone: true,
  providers: [ConfirmationService, ToastService],
  imports: [
    CommonModule,
    TableModule,
    TranslateModule,
    DialogModule,
    ButtonModule,
    ConfirmPopupModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    CheckboxModule,
    PasswordModule,
    DropdownModule,
    TooltipModule,
    TagModule,
    MultiSelectModule,
  ],
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTableComponent extends Destroyable(Object) implements OnInit {
  readonly mediumPasswordRegex =
    '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{12,})';

  readonly strongPasswordRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})';

  users = new Array<User>();
  roles = new Array<Role>();
  roleMap!: Record<string, Role>;
  responsibilities: Responsibility[] = [];

  userToSave: User = {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    organizationName: '',
    roleId: '',
    sgcUser: false,
    isActive: true,
    password: '',
    responsibilityIds: [],
  };

  isEdit = false;
  isUserModalVisible = false;
  isPassModalVisible = false;
  selectedUserEmail!: string;
  newPassword = '';
  emailPattern = EMAIL_REGEX;

  get isOktaAuthUsed(): boolean {
    return this.useOktaAuth;
  }

  get isNewUserValid(): boolean {
    return !!(
      (this.isOktaAuthUsed || this.userToSave.password) &&
      this.userToSave.email.match(this.emailPattern) &&
      this.userToSave.roleId &&
      this.userToSave.lastName &&
      this.userToSave.firstName
    );
  }

  get isExistingUserValid(): boolean {
    return !!this.userToSave.roleId;
  }

  get modeKey(): string {
    return this.isEdit ? 'adminSettings.titles.actions.edit' : 'adminSettings.titles.actions.new';
  }

  constructor(
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private toastService: ToastService,
    private adminService: AdminSettingsService,
    private userService: UserService,
    private responsibilityService: ResponsibilityService,
    private cd: ChangeDetectorRef,
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initLists();
  }

  getRoleNameById(id: string): string {
    if (this.roles.length && !!id) {
      return (
        this.roles.filter((x) => {
          return x.id === id;
        })[0].name || ''
      );
    }
    return '';
  }

  onCreate(): void {
    this.isEdit = false;
    this.openUserDialog();
  }

  onEdit(user: User): void {
    this.isEdit = true;
    this.userToSave = { ...user };
    this.openUserDialog();
  }

  onCancelModal(): void {
    this.clearUserData();
    this.closeUserDialog();
  }

  onSave(): void {
    if (!this.userToSave.sgcUser && this.roleMap[this.userToSave.roleId].isAdmin) {
      this.toastService.toastError(
        `${this.translate.instant('adminSettings.messages.nonSgcAdminError')}`,
      );
      return;
    }
    if (this.isEdit) {
      this.userService
        .editUser(this.userToSave.id!, this.userToSave)
        .pipe(this.takeUntilDestroyed())
        .subscribe(() => {
          this.toastService.toastSuccess(
            `${this.translate.instant('adminSettings.titles.entities.users')} ${
              this.userToSave.email
            } ${this.translate.instant('adminSettings.messages.hasBeenUpdated')}`,
          );
          this.initLists();
          this.clearUserData();
          this.closeUserDialog();
        });
    } else {
      this.createUser();
    }
  }

  onShowChangePasswordModal(id: string): void {
    this.selectedUserEmail = id;
    this.isPassModalVisible = true;
  }

  onChangePassword(): void {
    if (!this.isPasswordStrong(this.newPassword)) {
      this.toastService.toastError(
        this.getTranslationText('errorMessage.passwordShouldBeStronger'),
      );
      return;
    }

    this.changePassword(this.selectedUserEmail, this.newPassword);
    this.onCloseChangePasswordModal();
  }

  onCloseChangePasswordModal(): void {
    this.selectedUserEmail = '';
    this.isPassModalVisible = false;
  }

  confirm(event: Event, id: string): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.getTranslationText('confirmation.message'),
      acceptButtonStyleClass: 'btn-secondary',
      rejectButtonStyleClass: 'btn-primary',
      acceptLabel: this.getTranslationText('confirmation.confirm_btn'),
      rejectLabel: this.getTranslationText('confirmation.cancel_btn'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteUser(id);
      },
    });
  }

  refreshResponsibilities(): void {
    this.responsibilityService
      .getAll()
      .pipe(this.takeUntilDestroyed())
      .subscribe((responsibilities) => {
        this.responsibilities = responsibilities;
      });
  }

  private createUser(): void {
    if (!this.isPasswordStrong(this.userToSave.password) && !this.isOktaAuthUsed) {
      this.toastService.toastError(
        this.getTranslationText('errorMessage.passwordShouldBeStronger'),
      );
      return;
    }

    this.userService
      .createUser(this.userToSave)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.toastService.toastSuccess(
          `${this.translate.instant('adminSettings.titles.entities.users')} ${
            this.userToSave.email
          } ${this.translate.instant('adminSettings.messages.hasBeenCreated')}`,
        );
        this.initLists();
        this.clearUserData();
        this.closeUserDialog();
      });
  }

  private isPasswordStrong(password: string): boolean {
    return !!password.match(this.mediumPasswordRegex);
  }

  private initLists(): void {
    forkJoin([
      this.userService.getUsers(),
      this.userService.getRoles(),
      this.responsibilityService.getAll(),
    ])
      .pipe(
        map(([users, roles, responsibilities]) => {
          return { users, roles, responsibilities };
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe((response) => {
        (this.users as any) = response.users;
        this.roles = response.roles;
        this.responsibilities = response.responsibilities;
        this.roleMap = response.roles.reduce<Record<string, Role>>((acc, role) => {
          return { ...acc, [role.id]: role };
        }, {});
        this.cd.markForCheck();
      });
  }

  private deleteUser(userId: string): void {
    this.adminService
      .deleteUser(userId)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_data) => {
        this.initLists();
        this.toastService.toastSuccess(
          this.getTranslationText('adminSettings.messages.userDeleted'),
        );
      });
  }

  private changePassword(email: string, newPassword: string): void {
    this.adminService
      .changePassword(email, newPassword)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_data) => {
        this.toastService.toastSuccess(
          this.getTranslationText('adminSettings.messages.passwordChanged'),
        );
      });
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }

  private openUserDialog(): void {
    this.isUserModalVisible = true;
  }

  private closeUserDialog(): void {
    this.isUserModalVisible = false;
  }

  private clearUserData(): void {
    this.userToSave = {
      contactNumber: '',
      email: '',
      firstName: '',
      sgcUser: false,
      lastName: '',
      organizationName: '',
      roleId: '',
      isActive: true,
      password: '',
      responsibilityIds: [],
    };
  }
}
