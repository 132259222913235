import { TranslateModule } from '@ngx-translate/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { WelcomePageComponent } from '../../common/welcome-page/welcome-page.component';
import { RoutePath } from '../../core/route-paths.enum';
import { USE_OKTA_AUTH_TOKEN } from '../../core/tokens/use-okta-auth.token';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { AuthenticationService } from '../../services/api/authentication.service';
import { TokenStorageService } from '../../services/api/token-storage.service';

@Component({
  standalone: true,
  selector: 'app-login',
  imports: [CommonModule, WelcomePageComponent, RouterLink, TranslateModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends Destroyable(Object) {
  routeLinks = RoutePath;
  constructor(
    private authenticationService: AuthenticationService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
  ) {
    super();
  }

  login([email, password]: [string, string | null]): void {
    if (this.useOktaAuth) {
      this.oktaAuth.signInWithRedirect();
      return;
    }

    this.authenticationService
      .login(email, password!)
      .pipe(this.takeUntilDestroyed())
      .subscribe((token) => {
        this.tokenStorageService.saveTokens(token);
        this.router.navigate([this.routeLinks.DASHBOARD]);
      });
  }
}
