import { TranslateModule } from '@ngx-translate/core';
import { TabViewModule } from 'primeng/tabview';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PictureViewComponent } from './picture-view/picture-view.component';
import { PlotComponent } from './plot/plot.component';
import { Render3DDataComponent } from './render3-ddata/render3-ddata.component';
import { VideoPlayerComponent } from './video-player/video-player.component';

@Component({
  selector: 'app-visualization',
  standalone: true,
  imports: [
    CommonModule,
    TabViewModule,
    PictureViewComponent,
    VideoPlayerComponent,
    PlotComponent,
    Render3DDataComponent,
    TranslateModule,
  ],
  templateUrl: './visualization.component.html',
  styleUrls: ['./visualization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisualizationComponent {}
