import { BehaviorSubject, catchError, EMPTY, Observable, switchMap, take, throwError } from 'rxjs';
import { filter } from 'rxjs/operators';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserManagementService } from 'src/app/services/user-management.service';

import { AuthenticationService } from '../../services/api/authentication.service';
import { TokenStorageService } from '../../services/api/token-storage.service';
import { PASS_ERROR_HEADER, TOKEN_EXPIRED_HEADER_NAME } from '../api.consts';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor {
  private isRefreshing = false;
  private accessTokenSubject$ = new BehaviorSubject<string | null>(null);

  constructor(
    private tokenStorageService: TokenStorageService,
    private userManagementService: UserManagementService,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.tokenStorageService.getAccessToken();
    let authRequest = request.clone();

    if (token && !request.url.includes(this.authenticationService.refreshTokenUrl)) {
      authRequest = this.addTokenHeader(authRequest, token);
    }

    let shouldThrowOutside = false;

    if (request.headers.has(PASS_ERROR_HEADER)) {
      shouldThrowOutside = true;
    }

    return next.handle(authRequest).pipe(
      catchError((err) => {
        if (
          err instanceof HttpErrorResponse &&
          err.status === 401 &&
          err.headers.has(TOKEN_EXPIRED_HEADER_NAME)
        ) {
          return this.handleExpiredTokenError(request, next);
        }

        return shouldThrowOutside ? throwError(() => err) : EMPTY;
      }),
    );
  }

  private addTokenHeader(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  private handleExpiredTokenError(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject$.next(null);
      const accessToken = this.tokenStorageService.getAccessToken();
      const refreshToken = this.tokenStorageService.getRefreshToken();

      if (!!accessToken && !!refreshToken) {
        return this.authenticationService.refresh(accessToken, refreshToken).pipe(
          switchMap((token) => {
            this.isRefreshing = false;
            this.tokenStorageService.saveTokens(token);
            this.accessTokenSubject$.next(token.accessToken);

            return next.handle(this.addTokenHeader(request, token.accessToken));
          }),
          catchError(() => {
            this.isRefreshing = false;
            this.tokenStorageService.removeTokens();
            this.userManagementService.removeUserInfo();
            this.router.navigate(['login']);

            return EMPTY;
          }),
        );
      }
    }

    return this.accessTokenSubject$.pipe(
      filter<string | null>((token) => !!token),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token as string))),
    );
  }
}
