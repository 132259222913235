import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from '../../core/api.consts';
import { EntityCacheKey } from '../../core/enums/entity-cache-key.enum';
import { IdName } from '../../core/models/id-name.model';
import { CacheService } from '../cache.service';

@Injectable({
  providedIn: 'root',
})
export class WellInfoService {
  private url = `${SGC_API}/WellInfos`;

  private getBasinTypesUrl = `${this.url}/BasinTypes`;
  private getWellTypesUrl = `${this.url}/WellTypes`;

  private createDeleteBasinTypeUrl = `${this.url}/BasinType`;
  private createDeleteWellTypeUrl = `${this.url}/WellType`;

  constructor(private httpClient: HttpClient, private cacheService: CacheService) {}

  getBasinTypes(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.BASIN_TYPES,
      this.httpClient.get<IdName[]>(this.getBasinTypesUrl),
    );
  }

  getWellTypes(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.WELL_TYPES,
      this.httpClient.get<IdName[]>(this.getWellTypesUrl),
    );
  }

  createBasinType(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.BASIN_TYPES,
      this.httpClient.post<IdName>(`${this.createDeleteBasinTypeUrl}?option=${name}`, {}),
    );
  }

  createWellType(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.WELL_TYPES,
      this.httpClient.post<IdName>(`${this.createDeleteWellTypeUrl}?option=${name}`, {}),
    );
  }

  deleteBasinType(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.BASIN_TYPES,
      this.httpClient.delete<null>(`${this.createDeleteBasinTypeUrl}/${id}`),
    );
  }

  deleteWellType(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.WELL_TYPES,
      this.httpClient.delete<null>(`${this.createDeleteWellTypeUrl}/${id}`),
    );
  }
}
