import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { combineLatest } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';

@Component({
  selector: 'app-sample-management',
  standalone: true,
  imports: [
    CommonModule,
    StepsModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    InputTextareaModule,
    TranslateModule,
    TableModule,
  ],
  templateUrl: './sample-management.component.html',
  styleUrls: ['./sample-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleManagementComponent extends Destroyable(Object) implements OnInit {
  activeIndex = 2;
  sampleManagementValidationTableItems: {
    sampleType: string;
    numberOfBoxes: number;
    numberOfSamples: number;
    verifiedBy: string;
    date: string;
    typePackaging: string;
    approve: string;
    receipt: string;
  }[] = [
    {
      sampleType: 'Wet trench',
      numberOfBoxes: 10,
      numberOfSamples: 100,
      verifiedBy: 'Tony A.',
      date: '11/11/2022',
      typePackaging: 'standart box',
      approve: 'yes',
      receipt: 'double click to generate receipt',
    },
    {
      sampleType: 'Core',
      numberOfBoxes: 15,
      numberOfSamples: 225,
      verifiedBy: '',
      date: '12/05/2022',
      typePackaging: 'big box',
      approve: 'no',
      receipt: 'generated',
    },
    {
      sampleType: 'SWC',
      numberOfBoxes: 20,
      numberOfSamples: 400,
      verifiedBy: 'Joe. B',
      date: '10/22/2022',
      typePackaging: 'big box',
      approve: 'yes',
      receipt: 'not generated',
    },
  ];

  sampleManagementReceiptStepperItems: MenuItem[] = [
    {
      label: this.translateService.instant('sampleManagement.receiptPage.generateReceipt'),
      state: {
        labelKey: 'sampleManagement.receiptPage.generateReceipt',
      },
    },
    {
      label: this.translateService.instant('sampleManagement.receiptPage.sendReceipt'),
      state: {
        labelKey: 'sampleManagement.receiptPage.sendReceipt',
      },
    },
    {
      label: this.translateService.instant('sampleManagement.receiptPage.downloadForFilling'),
      state: {
        labelKey: 'sampleManagement.receiptPage.downloadForFilling',
      },
    },
  ];

  sampleManagementStepperItems: MenuItem[] = [
    {
      label: this.translateService.instant('sampleManagement.reception'),
      state: {
        labelKey: 'sampleManagement.reception',
      },
      command: (_: any) => {
        this.activeIndex = 0;
        this.changeDetectorRef.detectChanges();
      },
    },
    {
      label: this.translateService.instant('sampleManagement.verification'),
      state: {
        labelKey: 'sampleManagement.verification',
      },
      command: (_: any) => {
        this.activeIndex = 1;
        this.changeDetectorRef.detectChanges();
      },
    },
    {
      label: this.translateService.instant('sampleManagement.validation'),
      state: {
        labelKey: 'sampleManagement.validation',
      },
      command: (_: any) => {
        this.activeIndex = 2;
        this.changeDetectorRef.detectChanges();
      },
    },
    {
      label: this.translateService.instant('sampleManagement.storage'),
      state: {
        labelKey: 'sampleManagement.storage',
      },
      command: (_: any) => {
        this.activeIndex = 3;
        this.changeDetectorRef.detectChanges();
      },
    },
    {
      label: this.translateService.instant('sampleManagement.receipt'),
      state: {
        labelKey: 'sampleManagement.receipt',
      },
      command: (_: any) => {
        this.activeIndex = 4;
        this.changeDetectorRef.detectChanges();
      },
    },
  ];

  sampleManagementHeaderFormGroup = new FormGroup({
    reception: new FormGroup({
      upi: new FormControl(''),
    }),
    validation: new FormGroup({
      wellOrProjectValidation: new FormControl(''),
    }),
    receipt: new FormGroup({
      generateReceiptWellOrProjectPerSampleType: new FormControl(''),
      receiptCode: new FormControl(''),
    }),
  });

  sampleManagementBodyFormGroup = new FormGroup({
    reception: new FormGroup({
      operator: new FormGroup({
        name: new FormControl(''),
        geologistName: new FormControl(''),
        contractNumber: new FormControl(''),
        email: new FormControl(''),
      }),
      serviceCompany: new FormGroup({
        operatorName: new FormControl(''),
        geologistName: new FormControl(''),
        contractNumber: new FormControl(''),
        email: new FormControl(''),
      }),
      contract: new FormGroup({
        contractNumber: new FormControl(''),
      }),
      wellInformation: new FormGroup({
        wellName: new FormControl(''),
        longitude: new FormControl(''),
        latitude: new FormControl(''),
      }),
      sampleInformation: new FormGroup({
        sampleType: new FormControl(''),
        set: new FormControl(''),
        box: new FormControl(''),
        top: new FormControl(''),
        base: new FormControl(''),
        interval: new FormControl(''),
        quantity: new FormControl(''),
        packingType: new FormControl(''),
        totalBoxes: new FormControl(''),
        totalSamples: new FormControl(''),
        formation: new FormControl(''),
        diameter: new FormControl(''),
        percentageRecovered: new FormControl(''),
      }),
      transportCompany: new FormGroup({
        companyName: new FormControl(''),
        driverName: new FormControl(''),
        vehicleLicensePlate: new FormControl(''),
        contractNumber: new FormControl(''),
      }),
      packaging: new FormGroup({
        packagingType: new FormControl(''),
      }),
    }),
    validation: new FormGroup({
      well: new FormControl(''),
      id: new FormControl(''),
      name: new FormControl(''),
      technicianAssignment: new FormGroup({
        wellTechnician: new FormControl(''),
        wellTechnicianTechnicianName: new FormControl(''),
        wellVerification: new FormControl(''),
        wellVerificationTechnicianName: new FormControl(''),
        wellValidation: new FormControl(''),
        wellValidationTechnicianName: new FormControl(''),
        storage: new FormControl(''),
        storageTechnicianName: new FormControl(''),
        receipt: new FormControl(''),
        receiptTechnicianName: new FormControl(''),
      }),
    }),
    receipt: new FormGroup({
      well: new FormControl(''),
      id: new FormControl(''),
      name: new FormControl(''),
      generalInfo: new FormGroup({
        sampleType: new FormControl(''),
        receiptTechnician: new FormControl(''),
        operationCompany: new FormControl(''),
        contractName: new FormControl(''),
      }),
      letter: new FormControl(''),
    }),
  });

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.trackSampleManagementStepperLabelsTranslations();
  }

  private trackSampleManagementStepperLabelsTranslations(): void {
    combineLatest([
      this.translateService.stream(
        this.sampleManagementStepperItems.map((stepperItem) => stepperItem.state!['labelKey']),
      ),
      this.translateService.stream(
        this.sampleManagementReceiptStepperItems.map(
          (stepperItem) => stepperItem.state!['labelKey'],
        ),
      ),
    ])
      .pipe(this.takeUntilDestroyed())
      .subscribe(
        ([stepperTranslation, receiptStepperTranslation]: [
          Record<string, string>,
          Record<string, string>,
        ]) => {
          const sampleManagementStepperItemsCopy = [...this.sampleManagementStepperItems];
          const sampleManagementReceiptStepperItemsCopy = [
            ...this.sampleManagementReceiptStepperItems,
          ];
          Object.entries(stepperTranslation).forEach(([key, val]) => {
            sampleManagementStepperItemsCopy.find(
              (item) => item.state!['labelKey'] === key,
            )!.label = val;
          });
          Object.entries(receiptStepperTranslation).forEach(([key, val]) => {
            sampleManagementReceiptStepperItemsCopy.find(
              (item) => item.state!['labelKey'] === key,
            )!.label = val;
          });
          this.sampleManagementStepperItems = sampleManagementStepperItemsCopy;
          this.sampleManagementReceiptStepperItems = sampleManagementReceiptStepperItemsCopy;
        },
      );
  }
}
