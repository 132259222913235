import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslationComponent } from '../translation/translation.component';

@Component({
  selector: 'app-additional-information',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    InputTextModule,
    CalendarModule,
    InputTextareaModule,
    MessagesModule,
    MessageModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationComponent,
    TranslateModule,
  ],
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalInformationComponent implements OnInit {
  @Input() parentGroup!: FormGroup;
  @Input() isExpanded = true;
  @Input() noteLabel = 'additionalInformation.notes';
  control = new FormControl();

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setUpControl();
  }

  private setUpControl(): void {
    const additionalInformationFormGroup = this.parentGroup.get(
      'additionalInformation',
    ) as FormGroup;
    additionalInformationFormGroup.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
  }

  checkFormError(controlPath: string): boolean | undefined {
    return this.parentGroup.get(controlPath)?.invalid && this.parentGroup.get(controlPath)?.dirty;
  }
}
