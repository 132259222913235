import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from 'src/app/core/api.consts';

@Injectable({
  providedIn: 'root',
})
export class AdminSettingsService {
  private url = SGC_API;
  private readonly deleteUserUrl = `${this.url}/Authentication/DeactivateUser`;
  private readonly passwordUrl = `${this.url}/Authentication/forceChangePassword`;

  constructor(private httpClient: HttpClient) {}

  changePassword(userEmail: string, newPassword: string): Observable<unknown> {
    return this.httpClient.put<unknown>(this.passwordUrl, { userEmail, newPassword });
  }

  deleteUser(userId: string): Observable<unknown> {
    return this.httpClient.delete<unknown>(`${this.deleteUserUrl}/${userId}`);
  }
}
