<div class="grid">
  <div class="cell">
    <h1 class="title">
      {{ 'dashboard.grid.titles.notifications' | translate }}
      <button
        class="btn-refresh btn-secondary"
        (click)="onNotificationsRequest()"
        pTooltip="{{ 'dashboard.grid.titles.getNotifications' | translate }}"
      >
        <i class="pi pi-refresh"></i>
      </button>
    </h1>
    <div class="content">
      <ng-container *ngIf="notifications.length; else noNotificationsMessage">
        <app-dashboard-notifications
          *ngFor="let notification of notifications"
          [notification]="notification"
          (delete)="deleteNotification($event)"
        ></app-dashboard-notifications>
      </ng-container>

      <ng-template #noNotificationsMessage>
        <div class="no-notifications-container">
          <h2>{{ 'dashboard.grid.titles.noNotifications' | translate }}</h2>
        </div>
      </ng-template>
    </div>

    <div *ngIf="spinnerShow" class="card spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <div class="cell">
    <app-sample-types-widget></app-sample-types-widget>
  </div>
  <div class="cell">
    <app-services-widget></app-services-widget>
  </div>
  <div class="cell">
    <app-warehouse-widget></app-warehouse-widget>
  </div>
</div>
