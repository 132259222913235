<div #imageDiv id="imageDiv"></div>

<div class="slidecontainer">
  <input
    type="range"
    min="0"
    max="{{ maxZ }}"
    value="{{ sliceZ }}"
    class="slider"
    id="myRange"
    (input)="OnChange($event)"
  />
</div>
<div class="btn-container">
  <button pButton pRipple icon="pi pi-plus" class="btn btn-secondary" (click)="addSlice()"></button>
  <span>{{ sliceZ }}</span>
  <button
    pButton
    pRipple
    icon="pi pi-minus"
    class="btn btn-secondary"
    (click)="reduceSlice()"
  ></button>
  <button
    pButton
    pRipple
    class="btn btn-primary"
    label="{{ 'visualization.intensity' | translate }}"
  ></button>
  {{ intensity }}
</div>
