import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';

declare let Tiff: any;
@Component({
  selector: 'app-picture-view',
  standalone: true,
  imports: [CommonModule, ButtonModule, RippleModule],
  templateUrl: './picture-view.component.html',
  styleUrls: ['./picture-view.component.scss', './../upload-styles.scss'],
})
export class PictureViewComponent {
  imageSrc: any = null;

  constructor(private cd: ChangeDetectorRef) {}

  onFileSelected(event: any) {
    this.imageSrc = null;

    const { files } = event.target;
    if (files.length === 0) {
      // User canceled the selection
      this.imageSrc = null;
      return;
    }

    const file = event.target.files[0];

    if (file.name.endsWith('.jpg') || file.name.endsWith('.png')) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgSrc = e.target.result;
        this.imageSrc = imgSrc;
        this.cd.detectChanges();
      };
      reader.readAsDataURL(file);
    } else if (file.name.endsWith('.tif') || file.name.endsWith('.tiff')) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const tiff = new Tiff({ buffer: reader.result });
        const canvas = tiff.toCanvas();
        this.imageSrc = canvas.toDataURL();
        this.cd.detectChanges();
      };

      reader.readAsArrayBuffer(file);
    } else {
      alert('file format is  not surpported');
      this.imageSrc = null;
    }
  }
}
