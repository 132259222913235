import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RadioButtonModule } from 'primeng/radiobutton';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CapitalizeStringPipe } from 'src/app/common/pipes/capitalize-string.pipe';
import { IdName } from 'src/app/core/models/id-name.model';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';

import { DefaultCalendarDateFormatDirective } from '../../../../common/directives/default-calendar-date-format.directive';
import { DefaultDateFormatPipe } from '../../../../common/pipes/default-date-format.pipe';
import { DateValidationService } from '../../../../services/date-validation.service';
import { ServiceChartFilters } from './models/service-chart-filters.model';

@Component({
  selector: 'app-services-charts-filter',
  standalone: true,
  imports: [
    DialogModule,
    CheckboxModule,
    CommonModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    TranslateModule,
    CapitalizeStringPipe,
    DefaultCalendarDateFormatDirective,
    DefaultDateFormatPipe,
  ],
  templateUrl: './services-charts-filter.component.html',
  styleUrls: ['./services-charts-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesChartsFilterComponent extends Destroyable(Object) implements OnInit {
  dataObject!: ServiceChartFilters;
  sampleTypeFormGroup!: FormGroup;
  statusFormGroup!: FormGroup;
  displayFormGroup!: FormGroup;
  teamsFormGroup!: FormGroup;
  durabilityFormGroup!: FormGroup;
  displayOptions!: IdName[];
  teamsOptions!: IdName[];
  sampleTypesOptions: IdName[] = [
    { id: '1', name: 'BiostratigraphicBox' },
    { id: '2', name: 'CoreBox' },
    { id: '3', name: 'FluidBox' },
    { id: '4', name: 'PetrographicPlateBox' },
    { id: '5', name: 'PistonCoreBox' },
    { id: '6', name: 'PlugBox' },
    { id: '7', name: 'PreservedBox' },
    { id: '8', name: 'ResidueBox' },
    { id: '9', name: 'SwcBox' },
    { id: '10', name: 'SurfaceSampleBox' },
    { id: '11', name: 'ZhZsDatBox' },
  ];

  pickedDisplay = 'week';
  isStartDateGreaterThanEndDate = false;

  private readonly displayLabels: Record<string, string> = {
    day: 'Day',
    week: 'Week',
    month: 'Month',
  };

  get sampleTypeFormArray(): FormGroup {
    return this.sampleTypeFormGroup.get('sampleType') as FormGroup;
  }

  constructor(
    public dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private dateValidationService: DateValidationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initData();
  }

  initOptions(): void {
    this.displayOptions = Object.keys(this.dataObject.display).map(
      (id) => ({ id, name: this.displayLabels[id] } as IdName),
    );

    this.pickedDisplay =
      (Object.keys(this.dataObject.display) as (keyof typeof this.dataObject.display)[]).find(
        (key) => this.dataObject.display[key],
      ) ?? '';
  }

  apply(): void {
    const sampleTypeFormGroupValue = this.sampleTypeFormArray.getRawValue();

    const sampleTypesIds = Object.entries(sampleTypeFormGroupValue)
      .filter(([key, value]) => value === true)
      .map(([key]) => {
        return this.sampleTypesOptions[Number(key) - 1].name;
      });

    const newDataObject: ServiceChartFilters = {
      sampleTypesIds,
      display: { ...this.dataObject.display },
      duration: { ...this.durabilityFormGroup.value },
    };
    Object.keys(newDataObject.display).forEach((key) => {
      newDataObject.display[key as keyof typeof newDataObject.display] = false;
    });

    newDataObject.display[
      this.displayFormGroup.get('display')?.value as keyof typeof newDataObject.display
    ] = true;

    this.dialogRef.close(newDataObject);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  private initData(): void {
    this.dataObject = this.dialogConfig.data;
    this.initOptions();
    this.initSampleTypeFormGroup();
    this.initDisplayGroup();
    this.initDurabilityGroup();
  }

  private initSampleTypeFormGroup(): void {
    const selectedSampleTypes = this.dataObject.sampleTypesIds
      ? [...this.dataObject.sampleTypesIds]
      : [];

    const formControls = new FormGroup(
      this.sampleTypesOptions.reduce((acc, item) => {
        return { ...acc, [item.id]: new FormControl(selectedSampleTypes.includes(item.name)) };
      }, {}),
    );

    this.sampleTypeFormGroup = new FormGroup({
      sampleType: formControls,
    });
  }

  private initDisplayGroup(): void {
    this.displayFormGroup = new FormGroup({
      display: new FormControl(this.pickedDisplay),
    });
  }

  private initDurabilityGroup(): void {
    this.durabilityFormGroup = new FormGroup({
      startDate: new FormControl(this.dataObject.duration.startDate),
      endDate: new FormControl(this.dataObject.duration.endDate),
    });

    this.durabilityFormGroup.controls['startDate'].valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((date) => {
        if (date && this.durabilityFormGroup.controls['endDate'].value) {
          const startDate = new Date(date);
          const endDate = new Date(this.durabilityFormGroup.controls['endDate'].value);
          this.isStartDateGreaterThanEndDate = this.dateValidationService.isEndDateBeforeStartDate(
            startDate,
            endDate,
          );
        } else {
          this.isStartDateGreaterThanEndDate = false;
        }
      });

    this.durabilityFormGroup.controls['endDate'].valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((date) => {
        if (this.durabilityFormGroup.controls['startDate'].value && date) {
          const startDate = new Date(this.durabilityFormGroup.controls['startDate'].value);
          const endDate = new Date(date);
          this.isStartDateGreaterThanEndDate = this.dateValidationService.isEndDateBeforeStartDate(
            startDate,
            endDate,
          );
        } else {
          this.isStartDateGreaterThanEndDate = false;
        }
      });
  }
}
