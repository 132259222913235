<ng-container [formGroup]="parentGroup">
  <p-accordionTab
    header="{{ 'selfServices.projectInformation' | translate }}"
    formGroupName="projectInformation"
    [selected]="isExpanded"
  >
    <div class="row input-group-50">
      <span class="p-float-label">
        <input
          maxlength="256"
          (input)="onFieldInput()"
          [ngClass]="{
            'ng-invalid':
              parentGroup.get('projectInformation.upi')!.touched &&
              parentGroup.getError('atLeastOneFieldRequired')
          }"
          id="upi-input"
          type="text"
          pInputText
          formControlName="upi"
        />
        <label for="upi-input">{{ 'selfServices.upi' | translate }}</label>
        <small
          *ngIf="
            checkFormError('projectInformation.upi')['required'] &&
            checkFormError('wellInformation.uwi')['required']
          "
          class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>
      <span class="p-float-label">
        <input
          maxlength="256"
          id="project-name-input"
          type="text"
          pInputText
          formControlName="projectName"
        />
        <label for="project-name-input">{{ 'selfServices.projectName' | translate }}</label>
      </span>
    </div>
    <div class="row input-group-50">
      <span class="input-switch">
        <label for="epis-checkbox">{{ 'selfServices.epis' | translate }}</label>
        <p-inputSwitch id="epis-checkbox" formControlName="uwiUpiCreatedInEpis"></p-inputSwitch>
      </span>
    </div>
  </p-accordionTab>
</ng-container>
