import { OptionsTableEntityKey } from '../enums/options-table-entity-key.enum';

export const optionsTableEntityTitleMap: Record<OptionsTableEntityKey, string> = {
  [OptionsTableEntityKey.basinTypes]: 'adminSettings.titles.entities.basinTypes',
  [OptionsTableEntityKey.biostratigraphicSampleTypes]:
    'adminSettings.titles.entities.biostratigraphicSampleTypes',
  [OptionsTableEntityKey.boxTypes]: 'adminSettings.titles.entities.boxTypes',
  [OptionsTableEntityKey.depthUnits]: 'adminSettings.titles.entities.depthUnits',
  [OptionsTableEntityKey.endCupFractionInfos]: 'adminSettings.titles.entities.endCupFractionInfos',
  [OptionsTableEntityKey.extractionPositionInfos]:
    'adminSettings.titles.entities.extractionPositionInfos',
  [OptionsTableEntityKey.fluidTypes]: 'adminSettings.titles.entities.fluidTypes',
  [OptionsTableEntityKey.lengthUnits]: 'adminSettings.titles.entities.lengthUnits',
  [OptionsTableEntityKey.mediumDeliveryInfos]: 'adminSettings.titles.entities.mediumDeliveryInfos',
  [OptionsTableEntityKey.orientationTypes]: 'adminSettings.titles.entities.orientationTypes',
  [OptionsTableEntityKey.physicalStatuses]: 'adminSettings.titles.entities.physicalStatuses',
  [OptionsTableEntityKey.plateTypes]: 'adminSettings.titles.entities.plateTypes',
  [OptionsTableEntityKey.preparationTypes]: 'adminSettings.titles.entities.preparationTypes',
  [OptionsTableEntityKey.preservationTypes]: 'adminSettings.titles.entities.preservationTypes',
  [OptionsTableEntityKey.sampleOrigins]: 'adminSettings.titles.entities.sampleOrigins',
  [OptionsTableEntityKey.sampleStatuses]: 'adminSettings.titles.entities.sampleStatuses',
  [OptionsTableEntityKey.samplingMethods]: 'adminSettings.titles.entities.samplingMethods',
  [OptionsTableEntityKey.sectionTypes]: 'adminSettings.titles.entities.sectionTypes',
  [OptionsTableEntityKey.surfaceSampleTypes]: 'adminSettings.titles.entities.surfaceSampleTypes',
  [OptionsTableEntityKey.technicalInfoses]: 'adminSettings.titles.entities.technicalInfoses',
  [OptionsTableEntityKey.wellTypes]: 'adminSettings.titles.entities.wellTypes',
  [OptionsTableEntityKey.weightUnits]: 'adminSettings.titles.entities.weightUnits',
  [OptionsTableEntityKey.zhZsDatObjectiveSets]:
    'adminSettings.titles.entities.zhZsDatObjectiveSets',
  [OptionsTableEntityKey.zhZsDatSampleTypes]: 'adminSettings.titles.entities.zhZsDatSampleTypes',
  [OptionsTableEntityKey.volumeUnits]: 'adminSettings.titles.entities.volumeUnits',
};
