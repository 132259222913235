import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DropDownOption } from 'src/app/core/models/drop-down-option.model';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';
import { PackagingTypeService } from 'src/app/services/api/packaging-type.service';
import { ToastService } from 'src/app/services/toast.service';

import { DropdownOptionPackagingType } from './models/dropdown-option-packaging-type.model';

@Component({
  selector: 'app-dropdown-packaging-type',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ButtonModule,
    TranslateModule,
    ToastModule,
    DialogModule,
    MessageModule,
  ],
  templateUrl: './dropdown-packaging-type.component.html',
  styleUrls: ['./dropdown-packaging-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfirmationService],
})
export class DropdownPackagingTypeComponent extends Destroyable(Object) {
  @Input() packagingType!: string | null;
  @Input() options: DropDownOption[] = [];

  @Output() packagingTypeChange = new EventEmitter<string | null>();
  @Output() focusOut = new EventEmitter();
  isDialogVisible = false;
  dialogInfo: {
    isStockAvailable: boolean;
    packagingTypeName: string;
    dropdownRef: Dropdown | null;
    packagingType: DropdownOptionPackagingType | null;
  } = { isStockAvailable: false, dropdownRef: null, packagingType: null, packagingTypeName: '' };

  constructor(
    private packagingTypeService: PackagingTypeService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {
    super();
  }

  focusOutEmitter(): void {
    this.focusOut.emit();
  }

  onPackageTypeClicked(
    e: PointerEvent | MouseEvent,
    packagingType: DropDownOption | DropdownOptionPackagingType,
    dropdownRef: Dropdown,
  ): void {
    this.openOutOfStockDialog(packagingType as DropdownOptionPackagingType, dropdownRef);
    this.isDialogVisible = true;
    e.preventDefault();
    e.stopPropagation();
  }

  openOutOfStockDialog(packagingType: DropdownOptionPackagingType, dropdownRef: Dropdown): void {
    this.dialogInfo = {
      isStockAvailable: !!packagingType.count,
      packagingTypeName: packagingType.name,
      dropdownRef,
      packagingType,
    };
  }

  onUseStock(): void {
    if (this.dialogInfo.packagingType) {
      this.dialogInfo.packagingType.count -= 1;
      this.packagingTypeService
        .updateCount(this.dialogInfo.packagingType)
        .pipe(this.takeUntilDestroyed())
        .subscribe((_) => {
          if (this.dialogInfo.packagingType?.id) {
            this.packagingType = this.dialogInfo.packagingType?.id;
            this.packagingTypeChange.emit(this.dialogInfo.packagingType?.id);
          }
          this.toastService.toastSuccess(
            this.translate.instant('packagingType.toast.usedFromStock'),
          );
          this.isDialogVisible = false;
        });
    }
  }

  onUseAsIs(): void {
    if (this.dialogInfo.packagingType?.id) {
      this.packagingType = this.dialogInfo.packagingType?.id;
      this.packagingTypeChange.emit(this.dialogInfo.packagingType?.id);
    }
    this.toastService.toastSuccess(this.translate.instant('packagingType.toast.usedAsIs'));
    this.isDialogVisible = false;
  }

  onDropdownChange(event: { value: SelectItem }): void {
    if (!event.value) {
      this.packagingType = null;
      this.packagingTypeChange.emit(this.packagingType);
    }
  }
}
