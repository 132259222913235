<div class="header">
  <h1 class="title">{{ 'accountSettings.titles.main' | translate }}</h1>
  <div class="actions">
    <button
      pButton
      type="button"
      class="btn btn-primary"
      label="{{ 'button.clear' | translate }}"
      (click)="resetForm()"
    ></button>
    <button
      pButton
      type="button"
      class="btn btn-secondary"
      (click)="submitForm()"
      label="{{ 'button.save' | translate }}"
    ></button>
  </div>
</div>
<div class="content">
  <form [formGroup]="mainForm" *ngIf="user">
    <div class="row input-group-50">
      <span class="p-float-label">
        <input id="float-input" type="text" pInputText formControlName="firstName" />
        <label for="float-input">{{ 'accountSettings.formLabels.firstName' | translate }} *</label>
        <small *ngIf="checkFormError('firstName')['required']" class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>
      <span class="p-float-label">
        <input id="float-input" type="text" pInputText formControlName="lastName" />
        <label for="float-input">{{ 'accountSettings.formLabels.lastName' | translate }} *</label>
        <small *ngIf="checkFormError('lastName')['required']" class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>
    </div>
    <div class="row input-group-50">
      <span class="p-float-label">
        <input id="float-input" type="text" [disabled]="true" pInputText [value]="user.email" />
        <label for="float-input">{{ 'accountSettings.formLabels.email' | translate }}</label>
        <small *ngIf="checkFormError('email')['required']" class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
        <small *ngIf="checkFormError('email')['email']" class="p-error p-invalid"
          >{{ 'errorMessage.emailPattern' | translate }}
        </small>
      </span>
      <span class="p-float-label">
        <input id="float-input" type="text" pInputText formControlName="contactNumber" />
        <label for="float-input">{{
          'accountSettings.formLabels.contactNumber' | translate
        }}</label>
      </span>
    </div>
    <div class="row input-group-50 flex align-items-center">
      <span class="p-float-label">
        <input id="float-input" type="text" pInputText formControlName="organizationName" />
        <label for="float-input">{{
          'accountSettings.formLabels.organizationName' | translate
        }}</label>
      </span>
      <span class="p-float-label">
        <p-dropdown
          [styleClass]="'w-full'"
          [appendTo]="'body'"
          [optionValue]="'id'"
          [options]="roles"
          [required]="true"
          formControlName="roleId"
          placeholder="Role"
          optionLabel="name"
          inputId="float-label"
        >
          <ng-template pTemplate="selectedItem">
            <div
              class="flex align-items-center justify-content-between"
              *ngIf="mainForm.get('roleId')!.getRawValue()"
            >
              <span>{{ roleMap[mainForm.get('roleId')!.getRawValue()].name }}</span>
              <p-tag
                *ngIf="roleMap[mainForm.get('roleId')!.getRawValue()].isAdmin"
                severity="success"
                [value]="'tag.admin' | translate"
                [rounded]="true"
              ></p-tag>
            </div>
          </ng-template>

          <ng-template let-role pTemplate="item">
            <div class="flex align-items-center justify-content-between">
              <span>{{ role.name }}</span>
              <p-tag
                *ngIf="role.isAdmin"
                severity="success"
                [value]="'tag.admin' | translate"
                [rounded]="true"
              ></p-tag>
            </div>
          </ng-template>
        </p-dropdown>
        <label htmlFor="role"> {{ 'accountSettings.formLabels.role' | translate }} * </label>
      </span>
    </div>

    <div class="row input-group-50">
      <span class="p-float-label row">
        <p-multiSelect
          id="responsibilities"
          formControlName="responsibilityIds"
          [options]="responsibilities"
          [styleClass]="'w-full'"
          optionValue="id"
          optionLabel="name"
          appendTo="body"
        ></p-multiSelect>

        <label htmlFor="responsibilities">
          {{ 'accountSettings.formLabels.responsibilities' | translate }}
        </label>
      </span>
    </div>
  </form>
  <hr />
  <ng-container *ngIf="!isOktaAuthUsed">
    <button
      pButton
      type="button"
      class="btn btn-secondary"
      (click)="changePasswordPopup()"
      label="Change password"
    ></button>

    <p-dialog
      header="Header"
      [(visible)]="isPassModalVisible"
      [modal]="true"
      [style]="{ width: '400px' }"
      [draggable]="false"
      [resizable]="false"
      [closable]="false"
    >
      <ng-template pTemplate="header">
        <span class="text-xl font-bold">
          {{ 'changePassword.title' | translate }}
        </span>
      </ng-template>
      <div class="col-wide">
        <span class="p-float-label">
          <p-password
            [(ngModel)]="newPassword"
            [toggleMask]="true"
            [appendTo]="'body'"
            [mediumRegex]="mediumPasswordRegex"
            [strongRegex]="strongPasswordRegex"
          ></p-password>
          <label for="password">{{ 'changePassword.password' | translate }}</label>
        </span>
      </div>

      <ng-template pTemplate="footer">
        <p-button
          (click)="onCloseChangePasswordModal()"
          label="{{ 'button.cancel' | translate }}"
          styleClass="btn-primary"
        ></p-button>
        <p-button
          (click)="onChangePassword()"
          label="{{ 'button.apply' | translate }}"
          styleClass="btn-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>
  </ng-container>
</div>
