import { DeliveryTotalNumber } from '../../../common/delivery-table-search-uwi-contract/models/delivery-total-number.model';
import { TabInfo } from '../../../common/self-service-to-validation-table/models/self-service-to-validation.model';

export interface AdditionalInformation {
  notes: string;
}

export interface Operator {
  companyName: string;
  email: string;
  contactName: string;
  contactNumber: string;
}

export interface ServiceProvider {
  companyName: string;
  email: string;
  contactName: string;
  contactNumber: string;
}

export interface ProjectInformation {
  projectName: string;
  upi: string;
  uwiUpiCreatedInEpis: boolean;
}

export interface TransportationInformation {
  companyName: string;
  contactNumber: string;
  driverName: string;
  vehicleLicensePlate: string;
}

export interface SelfServiceWellInformation {
  id?: string;
  basinId: string;
  uwi: string;
  wellName: string;
  wellTypeId: string;
  latitude: number | null;
  longitude: number | null;
  north: number | null;
  east: number | null;
  origin: string;
  uwiUpiCreatedInEpis: boolean | null;
}

export interface ContractInformation {
  contractNumber?: string;
  contractYear?: string;
}

export interface SelfServices {
  contractInformation: ContractInformation;
  additionalInformation: AdditionalInformation;
  operator: Operator;
  projectInformation: ProjectInformation;
  serviceProvider: ServiceProvider;
  transportationInformation: TransportationInformation;
  wellInformation: SelfServiceWellInformation;
}

export interface SelfServicesSampleInformation {
  title?: string;
  numberOfSet?: number | null;
  objectiveSetId?: string;
  boxTypeId?: string;
  boxNumber?: number | null;
  boxTopDepth?: number | null;
  boxBottomDepth?: number | null;
  numberSamplesPerBox?: number | null;
  depthUnitId?: string;
  packagingTypeId?: string;
  tubesPerBox?: number | null;
  numberOfCores?: number | null;
  sectionTypeId?: string;
  latitude?: number | null;
  longitude?: number | null;
  north?: number | null;
  east?: number | null;
  origin?: string | null;
  uwiUpiCreatedInEpis?: boolean | null;
  formation?: string | null;
  diameter?: number | null;
  recoveryPercent?: number | null;
  typeOfPackaging?: string;
  technicalInfoId?: string;
  mediumDeliveryInfoId?: string;
  lengthUnitId?: string;
  samplesPerBox?: number | null;
  length?: number | null;
  endCupFractionId?: string;
  extractionPositionId?: string;
  analysisPerformance?: string;
  weight?: number | null;
  weightUnitId?: string;
  fluidTypeId?: string;
  numberOfBottles?: number | null;
  samplingInterval?: number | null;
  samplingSite?: string;
  formationGeology?: string;
  formationOperation?: string;
  samplingMethodId?: string;
  samplingMethod?: string;
  bottleNumber?: number | null;
  analysisPerformed?: string;
  sampleOriginId?: string;
  stain?: string;
  sampleTypeId?: string;
  locality?: string;
  sampleName?: string;
  penetrationLength?: number | null;
  recoveryLength?: number | null;
  location?: string;
  section?: string;
  shotDate?: Date | null;
  laboratory?: string;
  verified?: boolean;
  zhZsDatSamples?: [];
  coreSamples?: [];
  preservedSamples?: [];
  plugSamples?: [];
  swcSamples?: [];
  fluidSamples?: [];
  residueSampleDto?: [];
  petrographicPlateSamples?: [];
  biostratigraphicPlateSamples?: [];
  surfaceSamples?: [];
  pistonCoreSamples?: [];
  crudeSamplesDtos?: [];
}

export interface SelfServicesShape {
  barcodeId?: string;
  contractNumber: string;
  yearOfContract: string;
  operatorId: string | null;
  operatorContactName: string | null;
  operatorContactNumber: string | null;
  operatorEmail: string | null;
  serviceProviderId: string | null;
  serviceProviderContactName: string | null;
  serviceProviderContactNumber: string | null;
  serviceProviderEmail: string | null;
  transportationProviderId: string | null;
  projectName: string;
  notes: string;
  zhZsDatBoxes: SelfServicesSampleInformation[];
  coreBoxes: SelfServicesSampleInformation[];
  preservedBoxes: SelfServicesSampleInformation[];
  plugBoxes: SelfServicesSampleInformation[];
  swcBoxes: SelfServicesSampleInformation[];
  fluidBoxes: SelfServicesSampleInformation[];
  residueBoxes: SelfServicesSampleInformation[];
  petrographicPlatesBoxes: SelfServicesSampleInformation[];
  biostratigraphicBoxes: SelfServicesSampleInformation[];
  surfaceSampleBoxes: SelfServicesSampleInformation[];
  pistonCoreBoxes: SelfServicesSampleInformation[];
  crudeBoxes: SelfServicesSampleInformation[];
  deliveryId?: string;
}

export class SelfServicesSampleInformationImpl implements SelfServicesShape {
  contractNumber: string;
  yearOfContract: string;
  operatorId: string | null;
  operatorContactName: string | null;
  operatorContactNumber: string | null;
  operatorEmail: string | null;
  serviceProviderId: string | null;
  serviceProviderContactName: string | null;
  serviceProviderContactNumber: string | null;
  serviceProviderEmail: string | null;
  transportationProviderId: string | null;
  upi: string;
  projectName: string;
  uwiUpiCreatedInEpis: boolean;
  notes: string;
  totalNumberOfBoxes: number;
  totalNumberOfSamples: number;
  zhZsDatBoxes: SelfServicesSampleInformation[];
  coreBoxes: SelfServicesSampleInformation[];
  preservedBoxes: SelfServicesSampleInformation[];
  plugBoxes: SelfServicesSampleInformation[];
  swcBoxes: SelfServicesSampleInformation[];
  fluidBoxes: SelfServicesSampleInformation[];
  residueBoxes: SelfServicesSampleInformation[];
  petrographicPlatesBoxes: SelfServicesSampleInformation[];
  biostratigraphicBoxes: SelfServicesSampleInformation[];
  surfaceSampleBoxes: SelfServicesSampleInformation[];
  pistonCoreBoxes: SelfServicesSampleInformation[];
  crudeBoxes: SelfServicesSampleInformation[];
  wellInformation!: SelfServiceWellInformation;

  constructor(totalNumber: DeliveryTotalNumber, ss?: SelfServices | any, table?: TabInfo[]) {
    this.contractNumber = ss?.contractInformation?.contractNumber ?? '';
    this.yearOfContract = ss!.contractInformation?.contractYear;
    this.operatorId = ss?.operator?.companyName ?? null;
    this.operatorContactName = ss?.operator?.contactName ?? null;
    this.operatorContactNumber = ss?.operator?.contactNumber ?? null;
    this.operatorEmail = ss?.operator?.email ?? null;
    this.serviceProviderId = ss?.serviceProvider?.companyName ?? null;
    this.serviceProviderContactName = ss?.serviceProvider?.contactName ?? null;
    this.serviceProviderContactNumber = ss?.serviceProvider?.contactNumber ?? null;
    this.serviceProviderEmail = ss?.serviceProvider?.email ?? null;
    this.transportationProviderId = ss?.transportationInformation.companyName ?? null;
    this.wellInformation = {
      id: ss?.wellInformation?.id ?? null,
      basinId: ss?.wellInformation?.basin || null,
      uwi: ss?.wellInformation?.uwi ?? '',
      wellName: ss?.wellInformation?.wellName ?? '',
      wellTypeId: ss?.wellInformation?.wellType || null,
      latitude: ss?.wellInformation?.latitude ?? null,
      longitude: ss?.wellInformation?.longitude ?? null,
      north: ss?.wellInformation?.north ?? null,
      east: ss?.wellInformation?.east ?? null,
      origin: ss?.wellInformation?.origin ?? '',
      uwiUpiCreatedInEpis: ss?.wellInformation?.uwiUpiCreatedInEpis ?? null,
    };

    this.upi = ss?.projectInformation?.upi ?? '';
    this.projectName = ss?.projectInformation?.projectName ?? '';
    this.uwiUpiCreatedInEpis = ss?.projectInformation?.uwiUpiCreatedInEpis ?? null;
    this.notes = ss?.additionalInformation?.notes ?? '';
    this.totalNumberOfBoxes = totalNumber.totalCount;
    this.totalNumberOfSamples = totalNumber.sumOfValue;
    this.zhZsDatBoxes = table![0].data ?? [];
    this.coreBoxes = table![1].data ?? [];
    this.preservedBoxes = table![2].data ?? [];
    this.plugBoxes = table![3].data ?? [];
    this.swcBoxes = table![4].data ?? [];
    this.fluidBoxes = table![5].data ?? [];
    this.residueBoxes = table![6].data ?? [];
    this.petrographicPlatesBoxes = table![7].data ?? [];
    this.biostratigraphicBoxes = table![8].data ?? [];
    this.surfaceSampleBoxes = table![9].data ?? [];
    this.pistonCoreBoxes = table![10].data ?? [];
    this.crudeBoxes = table![11].data ?? [];
  }
}
