<div appearance="fill" style="width: 300px">
  <span class="p-float-label">
    <p-dropdown
      [options]="colormaps"
      placeholder="{{ 'visualization.selectColorMap' | translate }}"
      [(ngModel)]="selectedColormap"
      optionLabel="name"
      (ngModelChange)="handleSelectedColormapChange($event)"
      inputId="color-map"
    >
      <ng-template pTemplate="item" let-colormap>
        <div
          *ngFor="let color of generateColormapColors(colormap.interpolate)"
          [style.background-color]="color"
          class="color-box"
        ></div>
        {{ colormap.name }}
      </ng-template>
    </p-dropdown>
    <label for="color-map">{{ 'visualization.selectColorMap' | translate }}</label>
  </span>
</div>
