<div class="container">
  <div style="margin-bottom: 50px">
    <label #upl for="picture-data-upload">
      <button pButton pRipple (click)="this.upl.click()" class="btn btn-secondary custom-upload">
        Upload .jpg, .png, .tif, .tiff files<i class="pi pi-upload"></i>
      </button>
    </label>
    <input
      id="picture-data-upload"
      type="file"
      accept=".jpg, .png, .tif, .tiff"
      (change)="onFileSelected($event)"
    />
  </div>
  <div class="image-container" *ngIf="imageSrc">
    <img [src]="imageSrc" alt="Selected Image" />
  </div>
</div>
