<div class="content">
  <h3 class="checkbox-name">{{ 'dashboard.widget.sample-types' | translate }}</h3>
  <div
    *ngIf="sampleTypeFormGroup && sampleTypesOptions?.length && sampleTypeFormArray"
    [formGroup]="sampleTypeFormGroup"
    class="field-wrapper"
  >
    <div formArrayName="sampleType">
      <div *ngFor="let sampleType of sampleTypesOptions; let i = index" class="field-checkbox">
        <p-checkbox
          [formControlName]="i + 1"
          [value]="sampleTypesOptions[i].name"
          [inputId]="'sample-type-status-' + i"
          [binary]="true"
        ></p-checkbox>
        <label [for]="'sample-type-status-' + i">{{
          sampleTypesOptions[i].name.substring(0, sampleTypesOptions[i].name.length - 3)
            | capitalizeString
        }}</label>
      </div>
    </div>
  </div>

  <!-- <h3 class="checkbox-name">{{ 'dashboard.widget.teams' | translate }}</h3>
  <div *ngIf="teamsFormGroup" [formGroup]="teamsFormGroup" class="field-wrapper">
    <div class="row multi-select">
      <p-multiSelect
        [formControlName]="'teams'"
        [label]="'Teams'"
        [options]="teamsOptions"
        optionLabel="name"
      >
      </p-multiSelect>
    </div>
  </div> -->

  <!-- <h3 class="checkbox-name">{{ 'dashboard.widget.status' | translate }}</h3>
  <div
    *ngIf="statusFormGroup && dataObject.sampleStatus"
    [formGroup]="statusFormGroup"
    class="field-wrapper"
  >
    <div *ngFor="let status of sampleStatusOptions; let i" class="field-checkbox">
      <p-checkbox
        [binary]="true"
        [formControlName]="status.id"
        [value]="status"
        [inputId]="status.id"
        [id]="'sample-type-status-' + i"
      ></p-checkbox>
      <label [for]="status.id">{{ status.name | titlecase }}</label>
    </div>
  </div> -->

  <h3 class="checkbox-name">{{ 'dashboard.widget.duration' | translate }}</h3>
  <div
    class="checkbox-duration-container"
    *ngIf="durabilityFormGroup && dataObject.duration"
    [formGroup]="durabilityFormGroup"
  >
    <p-calendar
      appDefaultCalendarDateFormat
      formControlName="startDate"
      [showIcon]="true"
      appendTo="body"
      placeholder="{{ dataObject.duration.startDate | appDefaultDateFormat }}"
    ></p-calendar>
    <p-calendar
      appDefaultCalendarDateFormat
      formControlName="endDate"
      [showIcon]="true"
      appendTo="body"
      placeholder="{{ dataObject.duration.endDate | appDefaultDateFormat }}"
    ></p-calendar>
  </div>

  <h3 class="checkbox-name">{{ 'dashboard.widget.display' | translate }}</h3>
  <div
    *ngIf="displayFormGroup && dataObject.display"
    [formGroup]="displayFormGroup"
    class="field-wrapper"
  >
    <div class="row radio-btns">
      <div *ngFor="let display of displayOptions" class="field-checkbox">
        <p-radioButton
          [inputId]="display.id"
          [value]="display.id"
          formControlName="display"
        ></p-radioButton>
        <label [for]="display.id" class="ml-2">{{
          'dashboard.widget.' + display.id | translate
        }}</label>
      </div>
    </div>
  </div>
</div>
<div class="actions">
  <span class="error-message" *ngIf="isStartDateGreaterThanEndDate">
    {{ 'dataAccess.filter.validation.startDateGreaterThanEndDateMessage' | translate }}
  </span>
  <button
    pButton
    class="btn btn-primary"
    id="sample-types-chart-filter-cancel-btn"
    (click)="cancel()"
  >
    {{ 'button.cancel' | translate }}
  </button>
  <button
    pButton
    class="btn btn-secondary"
    (click)="apply()"
    [disabled]="isStartDateGreaterThanEndDate"
    cdkFocusInitial
    id="sample-types-chart-filter-confirm-btn"
  >
    {{ 'button.apply' | translate }}
  </button>
</div>
