import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { combineLatest, Subscription } from 'rxjs';

import { CommonModule, KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { ProjectOptionsService } from 'src/app/services/api/project-options.service';

import { DefaultCalendarDateFormatDirective } from '../../common/directives/default-calendar-date-format.directive';
import { DropdownEditableComponent } from '../../common/dropdown-editable/dropdown-editable.component';
import { ComapanyInformation, DropDownOption } from '../../core/models/drop-down-option.model';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { FileUploadService } from '../../services/api/file-service.service';
import { ProviderService } from '../../services/api/provider.service';
import { SearchServiceService } from '../../services/api/search-service.service';
import { UserService } from '../../services/api/user.service';
import { LocaleService } from '../../services/locale.service';
import { ToastService } from '../../services/toast.service';
import { User } from '../admin-settings/user-table/user.model';
import {
  ServiceScheduling,
  ServiceSchedulingShapeImpl,
  ServiceSearch,
} from './models/service.model';
import { ServicesShapeImpl } from './models/service-shape.model';
import { SearchServiceComponent } from './search-service/search-service.component';
import { SERVICE_TABLE_LABEL } from './service-labels';

@Component({
  selector: 'app-service',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    RippleModule,
    CalendarModule,
    DropdownModule,
    InputTextareaModule,
    TableModule,
    ReactiveFormsModule,
    InputNumberModule,
    DialogModule,
    SearchServiceComponent,
    DropdownEditableComponent,
    InputTextModule,
    ProgressSpinnerModule,
    DefaultCalendarDateFormatDirective,
  ],
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceComponent extends Destroyable(Object) implements OnInit, AfterViewInit {
  @ViewChildren('scrollMe') scrollContainers!: QueryList<ElementRef>;
  title = 'service.serviceScheduler';
  companyName: ComapanyInformation[] = [];
  serviceTableLabel = SERVICE_TABLE_LABEL;
  serviceSchedulingFormGroup!: FormGroup;
  serviceFormGroup!: FormGroup;
  initialTableRowCount = 5;
  tableNumberFields = ['totalBoxes', 'totalSamples', 'topDepth', 'bottomDepth'];
  tableDateFields = ['dateOfService'];
  visible = false;
  users: User[] = [];
  searchSelectedList: ServiceSearch[] = [];
  serviceDropDownoption: { [key: string]: DropDownOption[] } = {
    depthUnitId: [],
    sectionTypeId: [],
    technicianId: [],
  };

  companyTypeDropdown = [
    { id: '1', name: 'Operator', translation: 'service.operator' },
    { id: '2', name: 'Service Provider', translation: 'service.serviceProvider' },
  ];

  operatorsCompanyNames: DropDownOption[] = [];
  serviceProvidersCompanyNames: DropDownOption[] = [];
  formProvidersNamesDropdown: DropDownOption[] = [];

  requiredFieldsTable: { [key: string]: string } = {
    technicianId: 'technicianId',
  };

  uploadIndex = 0;
  attachments: { attachmentId: string; note: string; fileName: string }[] = [];
  ALLOWED_ATTACHMENTS_EXTENSIONS = [
    'txt',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'pdf',
    'csv',
    'ppt',
    'pptx',
    'png',
    'jpeg',
    'bmp',
    'gif',
    'jpg',
    'zip',
  ];

  showSpinner = false;
  companyTypeSubscription$!: Subscription | undefined;

  get tableFormGroup(): FormGroup {
    return this.serviceFormGroup.get('table') as FormGroup;
  }

  get defaultLocale(): string {
    return this.localeService.getDefaultLocale();
  }

  constructor(
    private localeService: LocaleService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private toastService: ToastService,
    private translate: TranslateService,
    private searchServiceService: SearchServiceService,
    private userService: UserService,
    private projectOptionsService: ProjectOptionsService,
    private uploadService: FileUploadService,
    private providerService: ProviderService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initList();
    this.initForm();
    this.translate.onLangChange.pipe(this.takeUntilDestroyed()).subscribe((event) => {
      this.companyTypeDropdown.forEach((type) => {
        type.name = this.getTranslationText(type.translation);
      });
    });
  }

  ngAfterViewInit(): void {
    this.addEmptyRows();
    this.cd.markForCheck();
  }

  save() {
    if (!this.searchSelectedList.length) {
      this.toastService.toastError(
        this.getTranslationText('service.message.error.fillTableFields'),
      );
    } else if (this.serviceFormGroup.valid) {
      const serviceInformation = this.serviceFormGroup.getRawValue();
      const serviceTable = this.serviceSchedulingFormGroup.getRawValue();
      serviceInformation.companyName = `${serviceInformation.companyNameType}, ${serviceInformation.providerCompanyName}`;

      serviceTable.table = serviceTable.table.map((item: ServiceScheduling, index: number) => {
        if (this.attachments[index]?.attachmentId) {
          item.serviceAttachments = [
            {
              attachmentId: this.attachments[index].attachmentId,
              note: this.attachments[index].note,
            },
          ];
        } else {
          item.serviceAttachments = null;
        }

        return item;
      });

      const serviceSchedule = new ServicesShapeImpl(serviceInformation, serviceTable);

      this.searchServiceService
        .create(serviceSchedule)
        .pipe(this.takeUntilDestroyed())
        .subscribe((_response) => {
          this.toastService.toastSuccess(this.getTranslationText('service.success'));
          this.resetForm();
        });
    } else {
      this.serviceFormGroup.updateValueAndValidity();
      this.serviceFormGroup.markAllAsTouched();
      this.validateAllFormFields(this.serviceFormGroup);

      this.toastService.toastError(this.getFirstMessage());
    }
  }

  resetForm() {
    if (this.companyTypeSubscription$) {
      this.companyTypeSubscription$.unsubscribe();
    }

    this.serviceFormGroup.markAllAsTouched();
    this.serviceFormGroup.reset(undefined, { emitEvent: false });
    this.searchSelectedList = [];
    this.serviceFormGroup.markAsUntouched();
    this.serviceFormGroup.markAsPristine();
    this.serviceFormGroup.clearValidators();
    this.attachments = [];
    this.initForm();
    this.addEmptyRows();
    this.cd.markForCheck();
  }

  clearTable() {
    this.tableFormGroup.markAllAsTouched();
    this.tableFormGroup.reset(undefined, { emitEvent: false });
    this.tableFormGroup.markAsUntouched();
    this.tableFormGroup.markAsPristine();
    this.tableFormGroup.clearValidators();
  }

  onServiceDataReceived(data: ServiceSearch[]): void {
    if (data.length < 1) {
      this.toastService.toastError(
        this.getTranslationText('service.message.error.selectTableValue'),
      );
      return;
    }
    const searchValue = data.map((s) => {
      return new ServiceSchedulingShapeImpl(s);
    });

    this.searchSelectedList = data;
    this.visible = false;
    this.clearTable();
    this.clearFormArray(this.tableFormGroup.get('table') as FormArray);

    searchValue.forEach((serviceScheduling) => {
      this.addRow(serviceScheduling);
    });

    this.cd.markForCheck();
  }

  onCancelClick(data: boolean): void {
    this.visible = data;
  }

  orderOriginal = (_a: KeyValue<string, string>, _b: KeyValue<string, string>): number => {
    return 0;
  };

  getSampleTableFormControl(): FormArray {
    const control = this.serviceSchedulingFormGroup.get('table') as FormArray;
    return control;
  }

  getFormIndexArrayControls(index: number): any {
    const control = this.serviceSchedulingFormGroup.get('table') as FormArray;
    return control.at(index);
  }

  integerInput(value: string): boolean {
    return this.tableNumberFields.includes(value);
  }

  dateInput(value: string): boolean {
    return this.tableDateFields.includes(value);
  }

  openSearchPopup(fieldName: string): void {
    if (fieldName !== 'note' && fieldName !== 'technicianId') {
      this.visible = true;
    }
  }

  onKeyDown(event: KeyboardEvent, fieldName: string): void {
    if (fieldName !== 'note') {
      event.preventDefault();
    }
  }

  deleteRow(index: number): void {
    const tableArray = this.serviceSchedulingFormGroup.get('table') as FormArray;
    tableArray.removeAt(index);
    this.searchSelectedList.splice(index, 1);

    if (!tableArray.length) {
      this.addEmptyRows();
      this.cd.markForCheck();
    }
  }

  onUpload(e: Event): void {
    const target = e.target as HTMLInputElement;

    if (target?.files?.length) {
      const file = target.files.item(0)!;
      const fileSize = file.size / (1024 * 1024);
      const maxAllowedFileSize = 10;
      const splittedFileName = file.name.split('.');
      const fileExtension = splittedFileName[splittedFileName.length - 1];

      if (!this.ALLOWED_ATTACHMENTS_EXTENSIONS.includes(fileExtension.toLocaleLowerCase())) {
        this.toastService.toastError(this.getTranslationText('service.message.error.fileFormat'));
        return;
      }

      if (fileSize > maxAllowedFileSize) {
        this.toastService.toastError(this.getTranslationText('service.message.error.maxFileSize'));
      } else {
        this.showSpinner = true;
        this.uploadService.upload(file).subscribe((res) => {
          this.attachments[this.uploadIndex] = {
            attachmentId: res.id,
            note: '',
            fileName: res.name,
          };
          this.showSpinner = false;
          this.cd.detectChanges();
        });
      }
    }
  }

  saveUploadIndex(index: number): void {
    this.uploadIndex = index;
  }

  deleteAttachment(index: number): void {
    this.showSpinner = true;
    this.uploadService.deleteFile(`${this.attachments[index].attachmentId}`).subscribe(() => {
      this.attachments.splice(index, 1, {
        attachmentId: '',
        note: '',
        fileName: '',
      });
      this.showSpinner = false;
      this.cd.detectChanges();
    });
  }

  private initList(): void {
    combineLatest([this.projectOptionsService.getDepthUnits(), this.userService.getUsers()])
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        [this.serviceDropDownoption['depthUnitId'], this.users] = data;
        this.serviceDropDownoption['technicianId'] = this.users.map((user) => ({
          id: user.id!,
          name: `${user.firstName ?? ''} ${user.lastName ?? ''} ${user.email ?? ''} ${
            user.contactNumber ?? ''
          }`,
        }));
        this.cd.markForCheck();
      });

    this.searchServiceService
      .getInformation()
      .pipe(this.takeUntilDestroyed())
      .subscribe((companyInfo) => {
        this.companyName = companyInfo;
        this.cd.detectChanges();
      });

    this.providerService
      .getOperatorsCompanyNames()
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.operatorsCompanyNames = data;
        this.formProvidersNamesDropdown = data;
      });
    this.providerService
      .getServiceProvidersCompanyNames()
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.serviceProvidersCompanyNames = data;
      });
  }

  private scrollToBottom(rowIndex: number): void {
    this.scrollContainers.forEach((container, index) => {
      if (rowIndex === index) {
        const scrollElement = container.nativeElement;
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
    });
  }

  private initForm(): void {
    this.initTableForm();
    this.serviceFormGroup = this.fb.group({
      applicationDate: ['', [Validators.required]],
      companyName: [''],
      companyNameType: ['', [Validators.required]],
      providerCompanyName: [{ value: '', disabled: true }, [Validators.required]],
      description: [''],
      table: this.serviceSchedulingFormGroup,
    });
    this.companyTypeSubscription$ = this.serviceFormGroup
      .get('companyNameType')
      ?.valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe((type) => {
        this.serviceFormGroup.get('providerCompanyName')?.enable();
        this.formProvidersNamesDropdown =
          type === 'Operator' ? this.operatorsCompanyNames : this.serviceProvidersCompanyNames;
      });
  }

  private initTableForm(): void {
    const tableFormGroup: { [key: string]: FormArray } = {};
    tableFormGroup['table'] = this.fb.array([]);
    this.serviceSchedulingFormGroup = this.fb.group(tableFormGroup);
  }

  private clearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  private getFirstMessage(): string {
    const array: ValidationErrors[] | null = [];
    const controlWithError =
      this.getErrorControl(this.serviceFormGroup, array).shift() ||
      this.getTableErrorControl(array).shift();
    const controlErrors = controlWithError?.[1].errors;

    if (controlErrors?.['required']) {
      return `${this.getTranslationText(
        `service.message.error.${controlWithError?.[0]}`,
      )} ${this.getTranslationText('errorMessage.shouldNotBeEmpty')}.`;
    }
    return `${this.getTranslationText('errorMessage.unhandledValidationError')}`;
  }

  private getErrorControl(formGroup: FormGroup, array: ValidationErrors[]): ValidationErrors[] {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl && control.errors) {
        array.push([field, control]);
      } else if (control instanceof FormGroup) {
        this.getErrorControl(control, array);
      }
    });
    return array;
  }

  private getTableErrorControl(array: ValidationErrors[]): ValidationErrors[] {
    const tablecontrol = this.tableFormGroup.get('table') as FormArray;
    tablecontrol.controls.forEach((row) => {
      const controls = row.get('technicianId');
      if (controls?.errors) {
        array.push(['technician', controls]);
      }
    });
    return array;
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  checkFormError(controlPath: string): boolean | undefined {
    return (
      this.serviceFormGroup.get(controlPath)?.invalid &&
      this.serviceFormGroup.get(controlPath)?.dirty
    );
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }

  private addEmptyRows() {
    for (let i = 0; i < this.initialTableRowCount; i += 1) {
      this.addRow();
    }
  }

  private addRow(data?: ServiceScheduling) {
    const control = this.serviceSchedulingFormGroup.get('table') as FormArray;
    control.push(this.createTableFormGroup(data));
  }

  private createTableFormGroup(data?: ServiceScheduling): FormGroup {
    const formData: { [key: string]: any } = {
      id: data?.id ?? null,
      deliveryId: data?.deliveryId ?? null,
      type: data?.type ?? '',
      uwiOrUpi: data?.uwiOrUpi ?? '',
      name: data?.name ?? '',
      sampleType: data?.sampleType ?? '',
      totalBoxes: data?.totalBoxes ?? null,
      totalSamples: data?.totalSamples ?? null,
      topDepth: data?.topDepth ?? null,
      bottomDepth: data?.bottomDepth ?? null,
      depthUnitId: data?.depthUnitId ?? '',
      sampleLocations: data?.sampleLocations ?? null,
      boxLocations: data?.boxLocations ?? null,
      dateOfService: data?.dateOfService ?? null,
      technicianId: [data?.technicianId ?? null, Validators.required],
      note: data?.note ?? '',
      wellId: data?.wellId ?? null,
      projectId: data?.projectId ?? null,
      serviceAttachments: this.fb.array([
        this.fb.group({
          attachmentId: '',
          note: '',
        }),
      ]),
    };

    return this.fb.group(formData);
  }
}
