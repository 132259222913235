import { Observable, Subscriber } from 'rxjs';

import { Injectable } from '@angular/core';

import { EntityCacheKey } from '../../core/enums/entity-cache-key.enum';
import { Organization } from '../../core/models/organization.model';
import { CacheService } from '../cache.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  constructor(private cacheService: CacheService) {}

  getOrganizations(): Observable<Organization[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.ORGANIZATIONS,
      this.getHardcodedOrganizations(),
    );
  }

  private getHardcodedOrganizations(): Observable<Organization[]> {
    const response: Organization[] = [
      {
        id: '6acbf799-8dc1-ec11-997e-28187874182f',
        organizationName: 'Lorem',
        primaryColor: 'FFFFFF',
        appName: 'CoreMemory',
        secondaryColor: '000000',
        appDescription: 'Advanced digital rock workspace for teams collaboration',
        appSlogan: 'Core digital twins',
        organizationFullIconImagePath: './assets/images/SGC_login_logo_big.png',
        organizationSmallIconImagePath: './assets/images/SGC_login_logo_big.png',
        landingPageBackgroundImagePath: './assets/images/SGC_login_background_big.png',
      },
    ];

    const result = new Observable((subscriber: Subscriber<Organization[]>) => {
      setTimeout(() => {
        subscriber.next(response);
        subscriber.complete();
      }, 100);
    });
    return result;
  }
}
