export enum RoutePath {
  LOGIN = '/login',
  DASHBOARD = '/dashboard',
  CALENDAR = '/calendar',
  DATA_ACCESS = '/data-access',
  SELF_SERVICE = '/self-service',
  RECEPTION = '/reception',
  VERIFICATION = '/verification',
  VALIDATION = '/validation',
  STORAGE = '/storage',
  SERVICE = '/service',
  SAMPLE_MANAGEMENT = '/sample-management',
  VISUALIZATION = '/visualization',
  ACCOUNT_SETTINGS = '/account-settings',
  ADMIN_SETTINGS = '/admin-settings',
  ASSIGNMENT = '/assignment',
}
