<div class="header">
  <div class="row input-group-50 mb-3">
    <span class="p-input-icon-left p-float-label">
      <i class="pi pi-search"></i>
      <input
        id="float-input"
        type="text"
        pInputText
        placeholder="Search"
        [(ngModel)]="searchText"
        (input)="filterTable()"
      />
      <label for="float-input">{{ 'button.search' | translate }}</label>
    </span>
  </div>
  <div class="actions">
    <button
      pButton
      pRipple
      type="button"
      label="{{ 'button.clear' | translate }}"
      (click)="resetPage()"
      class="btn btn-primary"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      label="{{ 'button.select' | translate }}"
      class="btn btn-secondary"
      [disabled]="!selectedRow"
      (click)="select()"
    ></button>
  </div>
</div>
<ng-container *ngIf="rows.length">
  <p-table [value]="rows" [paginator]="true" [rows]="10" [responsive]="true">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of tableHeaders">{{ col | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
      <tr [class.selected]="selectedRow === row" (click)="onRowSelect(row)">
        <td>{{ row.contractNumber }}</td>
        <td style="min-width: 150px">{{ row.barcodeId }}</td>
        <td>{{ row.uwi }}</td>
        <td>{{ row.wellName }}</td>
        <td>{{ row.upi }}</td>
        <td>{{ row.projectName }}</td>
        <td>{{ row.sampleType }}</td>
        <td>{{ row.totalBoxes }}</td>
        <td>{{ row.totalSamples }}</td>
        <td>{{ row.companyName }}</td>
        <td>{{ row.topDepth }}</td>
        <td>{{ row.bottomDepth }}</td>
        <td>{{ row.formation }}</td>
        <td>{{ row.basin }}</td>
        <td style="min-width: 300px">{{ row.notes }}</td>
        <td style="min-width: 200px">{{ row.receptionDate | appDefaultDateFormat }}</td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
