<div class="w-6 mb-4">
  <span class="p-input-icon-left p-float-label w-full">
    <i class="pi pi-search"></i>
    <input
      class="w-full"
      id="float-input"
      type="text"
      pInputText
      autocomplete="off"
      [formControl]="searchFormControl"
    />
    <label for="float-input">{{ 'adminSettings.wellManagement.searchLabel' | translate }}</label>
  </span>
</div>

<p-table
  [value]="deliveryTemplates"
  [paginator]="false"
  [rows]="10"
  [showCurrentPageReport]="true"
  [tableStyle]="{ 'min-width': '250px' }"
  currentPageReportTemplate=""
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'contractNumber' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'contractYear' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'uwi' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'wellName' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'wellType' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'basin' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'latitude' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'longitude' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'north' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'east' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'origin' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'upi' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'projectName' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'epis' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'serviceProviderName' | translate }}</th>
      <th style="width: auto">{{ tableHeaderKeysPrefix + 'operatorName' | translate }}</th>
      <th style="width: auto"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-deliveryTemplate>
    <tr>
      <td>{{ deliveryTemplate.contractNumber }}</td>
      <td>{{ deliveryTemplate.contractYear }}</td>
      <td>{{ deliveryTemplate.uwi }}</td>
      <td>{{ deliveryTemplate.wellName }}</td>
      <td>{{ deliveryTemplate.wellTypeName }}</td>
      <td>{{ deliveryTemplate.basinName }}</td>
      <td>{{ deliveryTemplate.latitude }}</td>
      <td>{{ deliveryTemplate.longitude }}</td>
      <td>{{ deliveryTemplate.north }}</td>
      <td>{{ deliveryTemplate.east }}</td>
      <td>{{ deliveryTemplate.origin }}</td>
      <td>{{ deliveryTemplate.upi }}</td>
      <td>{{ deliveryTemplate.projectName }}</td>
      <td>
        <div class="checkbox-container">
          <p-checkbox
            [(ngModel)]="deliveryTemplate.uwiUpiCreatedInEpis"
            [binary]="true"
            (onChange)="confirmCheckboxChanges($event, deliveryTemplate)"
          ></p-checkbox>
        </div>
      </td>
      <td>{{ deliveryTemplate.serviceProviderCompanyName }}</td>
      <td>{{ deliveryTemplate.operatorCompanyName }}</td>
      <td>
        <p-confirmPopup></p-confirmPopup>
        <button
          [disabled]="!deliveryTemplate.isTemplate"
          pButton
          type="button"
          class="delete-btn btn"
          icon="pi pi-trash"
          (click)="confirmDelete($event, deliveryTemplate)"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>

<div class="flex justify-content-between align-items-center">
  <p-button
    type="button"
    icon="pi pi-plus"
    styleClass="btn-secondary"
    (click)="showModal()"
    [label]="'adminSettings.wellManagement.actions.addNew' | translate"
  ></p-button>

  <p-dialog
    header="Header"
    [(visible)]="isCreatePopupVisible"
    [modal]="true"
    [style]="{ width: '1200px', height: '800px' }"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
  >
    <ng-template pTemplate="header">
      <span class="text-xl font-bold"
        >{{ 'adminSettings.titles.actions.new' | translate }}
        {{ 'adminSettings.wellManagement.entityName' | translate }}</span
      >
    </ng-template>

    <p-accordion [multiple]="true" [formGroup]="deliveryTemplateFormGroup">
      <app-contract-information
        [parentGroup]="deliveryTemplateFormGroup"
      ></app-contract-information>

      <app-operator
        [required]="false"
        [parentGroup]="deliveryTemplateFormGroup"
        [isWellManagementDialog]="true"
      ></app-operator>

      <app-service-provider
        [required]="false"
        [parentGroup]="deliveryTemplateFormGroup"
        [isWellManagementDialog]="true"
      ></app-service-provider>

      <app-well-information
        [required]="false"
        [parentGroup]="deliveryTemplateFormGroup"
      ></app-well-information>

      <app-project-information [parentGroup]="deliveryTemplateFormGroup"></app-project-information>
    </p-accordion>

    <ng-template pTemplate="footer">
      <p-button
        (onClick)="onCancelModal()"
        [label]="'button.cancel' | translate"
        styleClass="btn-primary"
      ></p-button>

      <p-button
        (onClick)="onSave()"
        [label]="'button.save' | translate"
        styleClass="btn-secondary"
      ></p-button>
    </ng-template>
  </p-dialog>

  <p-paginator
    (onPageChange)="onPageChanged($event)"
    [rows]="paginatorOptions.pageSize"
    [totalRecords]="paginatorOptions.count"
    [rowsPerPageOptions]="[10, 20, 30]"
    [dropdownAppendTo]="'body'"
  ></p-paginator>
</div>

<div *ngIf="spinnerShow" class="card spinner">
  <p-progressSpinner></p-progressSpinner>
</div>
