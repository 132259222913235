import { SampleTypesChartFilters } from '../models/sample-types-chart-filters.model';

export const INITIAL_SAMPLE_TYPE_CHART_STATE: SampleTypesChartFilters = {
  sampleTypesIds: [],
  teamsIds: [],
  sampleStatus: {
    checkedIn: true,
    checkedOut: true,
  },
  display: {
    day: false,
    week: false,
    month: true,
  },
  duration: {
    startDate: '',
    endDate: '',
  },
};
