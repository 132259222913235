import { OktaAuthGuard } from '@okta/okta-angular';
import { map, Observable, of, switchMap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AppLevelInfoResolver } from '../resolvers/app-level-info.resolver';
import { USE_OKTA_AUTH_TOKEN } from '../tokens/use-okta-auth.token';
import { AuthGuardService } from './auth-guard.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicAuthGuard implements CanActivate {
  constructor(
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
    private authGuardService: AuthGuardService,
    private oktaAuthGuard: OktaAuthGuard,
    private appLevelInfoResolver: AppLevelInfoResolver,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const canActivateObservable = this.useOktaAuth
      ? fromPromise(this.oktaAuthGuard.canActivate(route, state))
      : of(this.authGuardService.canActivate(route, state));

    return canActivateObservable.pipe(
      switchMap((canActivate) => {
        if (canActivate) {
          return this.appLevelInfoResolver.resolve(route, state).pipe(map(() => canActivate));
        }

        return of(canActivate);
      }),
    );
  }
}
