import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { PrintLabelComponent } from 'src/app/common/print-label/print-label.component';
import { PrintLabelType } from 'src/app/core/enums/print-label-type.enum';

import { PrintDisabledPipe } from '../../../../common/pipes/print-disabled.pipe';
import { PalletStorageModel } from '../../models/pallet-search.model';
import { SampleModel } from '../../models/sample.model';
import { FormatPalletLabelPipe } from '../../pipes/format-pallet-label.pipe';
import { TableRowService } from '../../services/table-row.service';

@Component({
  selector: 'app-sample-information',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    TranslateModule,
    CheckboxModule,
    PrintLabelComponent,
    ButtonModule,
    TooltipModule,
    PrintDisabledPipe,
    FormatPalletLabelPipe,
  ],
  templateUrl: './sample-information.component.html',
  styleUrls: ['./sample-information.component.scss'],
  providers: [PrintDisabledPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleInformationComponent {
  @Input() isSearchPerformed = false;
  @Input() isSingleEntry = false;
  @Input() pallets!: PalletStorageModel[];
  @Input() set searchValues(values: Array<SampleModel | PalletStorageModel>) {
    if (!this.isSingleEntry) {
      this.selectedItems = [];
      this.emitSelectedItems();
    }

    this._searchValues = values;

    if (this.isSingleEntry) {
      this.selectedItems = values;
      this.emitSelectedItems();
    }
  }

  get searchValues(): Array<SampleModel | PalletStorageModel> {
    return this._searchValues;
  }

  @Input() isShortened = false;

  private _searchValues: Array<SampleModel | PalletStorageModel> = [];

  selectedItems: Array<SampleModel | PalletStorageModel> = [];
  printLabelType = PrintLabelType;

  constructor(private tableRowService: TableRowService, private router: Router) {}

  emitSelectedItems(): void {
    this.tableRowService.emitChildEvent(this.selectedItems);
  }

  protected readonly PrintLabelType = PrintLabelType;

  printAllLabels(labelType: PrintLabelType): void {
    if (this.selectedItems.length <= 1) {
      return;
    }
    localStorage.setItem('type', labelType);
    switch (labelType) {
      case PrintLabelType.sample:
        localStorage.setItem(
          'data',
          JSON.stringify(
            this.selectedItems.map((item) => {
              return {
                label: 'sampleLabel' in item ? item.sampleLabel : '',
                wellName: 'wellName' in item ? item.wellName : '',
                uwi: 'uwi' in item ? item.uwi : '',
                sampleType: 'sampleType' in item ? item.sampleType : '',
                unitNumber: 'unitNumber' in item ? item.unitNumber : '',
                depth: 'depth' in item ? item.depth : '',
              };
            }),
          ),
        );
        break;
      case PrintLabelType.container:
        localStorage.setItem(
          'data',
          this.selectedItems.map((item) => (item as SampleModel).containerLabel).join(','),
        );
        break;
      case PrintLabelType.pallet:
        localStorage.setItem(
          'data',
          this.selectedItems.map((item) => (item as SampleModel).palletLabel).join(','),
        );
        break;
      case PrintLabelType.location:
        localStorage.setItem(
          'data',
          JSON.stringify(
            this.selectedItems.map((item) => {
              return {
                position: 'locationPosition' in item ? item.locationPosition : '',
                bay: 'locationBay' in item ? item.locationBay : '',
                label: item.locationLabel,
              };
            }),
          ),
        );
        break;
      default:
        break;
    }

    const url = this.router.serializeUrl(this.router.createUrlTree(['/print']));
    window.open(
      url,
      '_blank',
      `width=${window.screen.availWidth},height=${window.screen.availHeight}`,
    );
  }
}
