<p-tabView *ngIf="tabs.length" (onChange)="loadTabData($event)" [scrollable]="true">
  <p-tabPanel
    *ngFor="let tab of tabs; let tabIndex = index"
    header="{{ tab.header | translate }}"
    [disabled]="tab.tableName === 'crudeBoxes'"
  >
    <div *ngIf="tabIndex === activeTabIndex">
      <p-table
        [columns]="tab.columns"
        [value]="tab.data"
        [rows]="5"
        [paginator]="true"
        [resizableColumns]="true"
        styleClass="p-datatable-gridlines basicTable"
        [scrollable]="true"
        scrollHeight="500px"
        scrollDirection="vertical"
        [virtualScroll]="false"
        [sortField]="'boxTopDepth'"
        [sortOrder]="1"
        (sortFunction)="customSort($event)"
        [customSort]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <ng-container *ngFor="let col of tab.columns">
              <th *ngIf="childTable && col.field === 'verified'">
                {{ 'verification.table.headers.verified' | translate }}
              </th>
              <th *ngIf="col.header && !notDisplayField.includes(col.field)">
                {{ col.header | translate }}
              </th>
            </ng-container>
            <th *ngIf="childTable"></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr>
            <ng-container
              *ngFor="let col of tab.columns; let i = index; let last = last; first as isFirst"
            >
              <td *ngIf="childTable && col.field === 'verified'">
                <div class="p-field-checkbox text-center">
                  <p-checkbox
                    [(ngModel)]="rowData[col.field]"
                    (onChange)="rowValueChanged(tab)"
                    [binary]="true"
                    id="rememberMe"
                  ></p-checkbox>
                </div>
              </td>
              <ng-container *ngIf="col.header && !notDisplayField.includes(col.field)">
                <td
                  class="px-1 py-1"
                  *ngIf="
                    !sampleInfoTableDropdownOption[col.field] &&
                    !integerInput(col.field) &&
                    !floatInput(col.field) &&
                    !dateInput(col.field) &&
                    col.field !== 'id'
                  "
                >
                  <input
                    [(ngModel)]="rowData[col.field]"
                    (focusout)="rowValueChanged(tab)"
                    class="p-inputtext p-component p-element w-full"
                    type="text"
                  />
                </td>
                <td class="px-1 py-1" *ngIf="col.field === 'id'">
                  {{ rowData[col.field] }}
                </td>
                <td
                  class="px-1 py-1"
                  *ngIf="
                    !sampleInfoTableDropdownOption[col.field] &&
                    integerInput(col.field) &&
                    !dateInput(col.field)
                  "
                >
                  <p-inputNumber
                    [(ngModel)]="rowData[col.field]"
                    (focusout)="rowValueChanged(tab)"
                    [locale]="defaultLocale"
                    inputId="integeronly"
                  >
                  </p-inputNumber>
                </td>

                <td
                  class="px-1 py-1"
                  *ngIf="
                    !sampleInfoTableDropdownOption[col.field] &&
                    floatInput(col.field) &&
                    !dateInput(col.field) &&
                    col.field !== 'boxTopDepth' &&
                    col.field !== 'boxBottomDepth'
                  "
                >
                  <p-inputNumber
                    [(ngModel)]="rowData[col.field]"
                    (focusout)="rowValueChanged(tab)"
                    mode="decimal"
                    [minFractionDigits]="0"
                    [maxFractionDigits]="10"
                    [locale]="defaultLocale"
                  >
                  </p-inputNumber>
                </td>
                <td
                  class="px-1 py-1"
                  *ngIf="
                    !sampleInfoTableDropdownOption[col.field] &&
                    floatInput(col.field) &&
                    !dateInput(col.field) &&
                    (col.field === 'boxTopDepth' || col.field === 'boxBottomDepth')
                  "
                >
                  <p-inputNumber
                    [(ngModel)]="rowData[col.field]"
                    (focusout)="rowValueChanged(tab)"
                    mode="decimal"
                    [minFractionDigits]="0"
                    [maxFractionDigits]="10"
                    [locale]="defaultLocale"
                    pTooltip="{{
                      'tooltip.bottomDepthShouldBeGreaterOrEqualToTopDepth' | translate
                    }}"
                    tooltipPosition="left"
                  >
                  </p-inputNumber>
                </td>

                <td class="table_date" *ngIf="dateInput(col.field)">
                  <p-calendar
                    appDefaultCalendarDateFormat
                    [(ngModel)]="rowData[col.field]"
                    (focusout)="rowValueChanged(tab)"
                    [showButtonBar]="true"
                    [readonlyInput]="true"
                    appendTo="body"
                    placeholder=""
                    id="date"
                    [showIcon]="true"
                  ></p-calendar>
                </td>
                <td *ngIf="sampleInfoTableDropdownOption[col.field]">
                  <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'packagingTypeId'">
                      <p-dropdown
                        *ngIf="selfServiceTable; else packagingCountBlock"
                        [(ngModel)]="rowData[col.field]"
                        (focusout)="rowValueChanged(tab)"
                        placeholder=" "
                        optionLabel="name"
                        [options]="this.sampleInfoTableDropdownOption[col.field]"
                        [showClear]="true"
                        appendTo="body"
                        [editable]="false"
                        optionValue="id"
                      ></p-dropdown>

                      <ng-template #packagingCountBlock>
                        <app-dropdown-packaging-type
                          (focusOut)="rowValueChanged(tab)"
                          [(packagingType)]="rowData[col.field]"
                          [options]="this.sampleInfoTableDropdownOption[col.field]"
                        ></app-dropdown-packaging-type>
                      </ng-template>
                    </ng-container>

                    <div *ngSwitchCase="'containerId'" class="dropdown-with-icon">
                      <i
                        class="pi pi-print print-icon"
                        (click)="printContainer(containerDropDown)"
                      ></i>
                      <p-dropdown
                        #containerDropDown
                        [(ngModel)]="rowData[col.field]"
                        (focusout)="rowValueChanged(tab)"
                        placeholder=" "
                        optionLabel="name"
                        [options]="this.sampleInfoTableDropdownOption[col.field]"
                        [showClear]="true"
                        appendTo="body"
                        [editable]="false"
                        optionValue="id"
                        [readonly]="
                          col.field === 'containerId' ||
                          col.field === 'locationId' ||
                          col.field === 'palletId'
                        "
                        (click)="showDialog(rowIndex, col.field)"
                      ></p-dropdown>
                    </div>

                    <ng-container *ngSwitchDefault>
                      <p-dropdown
                        [(ngModel)]="rowData[col.field]"
                        (focusout)="rowValueChanged(tab)"
                        placeholder=" "
                        optionLabel="name"
                        [options]="this.sampleInfoTableDropdownOption[col.field]"
                        [showClear]="true"
                        appendTo="body"
                        [editable]="false"
                        optionValue="id"
                        [readonly]="
                          col.field === 'containerId' ||
                          col.field === 'locationId' ||
                          col.field === 'palletId'
                        "
                        (click)="showDialog(rowIndex, col.field)"
                      ></p-dropdown>
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
            </ng-container>
            <td *ngIf="childTable" class="text-center" (click)="toggleChildTable(tab, rowData)">
              <i class="pi pi-ellipsis-v"></i>
            </td>
          </tr>
          <ng-container *ngIf="childTable">
            <tr *ngIf="rowData[childTableName[tabIndex]].length && rowData.actions.expanded">
              <td [attr.colspan]="tab.columns.length">
                <app-child-table
                  [validationTable]="validationTable"
                  [sampleDetailTableDropdownOption]="sampleInfoTableDropdownOption"
                  [tabIndex]="tabIndex"
                  [parentRowIndex]="rowIndex"
                  [tab]="tab"
                  [rowData]="rowData"
                  [wellName]="wellName"
                  [uwi]="uwi"
                  [bulkUploadCompleted]="bulkUploadCompleted"
                  (validateChanged)="rowValueChanged(tab)"
                ></app-child-table>

                <button
                  class="btn-secondary"
                  type="button"
                  pButton
                  icon="pi pi-plus"
                  [pTooltip]="'tooltip.addRow' | translate"
                  (click)="addRowToChildTable(tabIndex, rowIndex)"
                ></button>
              </td>
            </tr>
          </ng-container>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="flex column-gap-6">
            <div class="flex">
              {{ tableSummary.labels[tabIndex].label1 | translate }}:
              {{ tableSummary.value[tabIndex].sumOfValue }}
            </div>
            <div class="flex">
              {{ tableSummary.labels[tabIndex].label2 | translate }}:
              {{ tableSummary.value[tabIndex].totalCount }}
            </div>
            <div class="flex" *ngIf="validationTable">
              {{ tableSummary.validatedLabels[tabIndex].label3 | translate }}:
              {{ tableSummary.value[tabIndex].validatedCount }}/{{
                tableSummary.value[tabIndex].samplesTotal
              }}
            </div>
            <div class="flex" *ngIf="verificationTable">
              {{ tableSummary.verifiedLabels[tabIndex].label3 | translate }}:
              {{ tableSummary.value[tabIndex].verifiedCount }}/{{
                tableSummary.value[tabIndex].totalCount
              }}
            </div>
          </div>
        </ng-template>
      </p-table>
    </div>
  </p-tabPanel>
</p-tabView>
<div *ngIf="spinnerShow" class="spinner">
  <p-progressSpinner></p-progressSpinner>
</div>

<div class="card flex flex-wrap gap-4" style="color: blue">
  <div class="cursor-pointer w-max" (click)="fileInput.click()">
    <input
      type="file"
      #fileInput
      style="display: none"
      (change)="onFileSelected($event)"
      accept=".xlsx, .xls"
    />
    <i class="pi pi-upload mb-4 mt-4"></i>
    <span class="font-bold ml-1">{{
      !childTable ? ('button.boxBulkUpload' | translate) : ('button.sampleBulkUpload' | translate)
    }}</span>
  </div>

  <div class="cursor-pointer w-max" (click)="downloadTemplate()">
    <i class="pi pi-download mb-4 mt-4"></i>
    <span class="font-bold ml-1">{{
      !childTable
        ? ('button.downloadBoxTemplate' | translate)
        : ('button.downloadSampleTemplate' | translate)
    }}</span>
  </div>
</div>
<p-dialog
  *ngIf="selectedDropDownColumnName && sampleInfoTableDropdownOption['containerId'].length"
  [header]="'reception.sampleInformationTable.container' | translate"
  [closable]="true"
  [modal]="true"
  [(visible)]="visibleContainerPopup"
  [style]="{ width: '40vw', height: '50vw' }"
>
  <app-dropdown-search-popup
    #dropdownSearchPopupComponent
    [tableHeader]="'selfServices.sampleInformationTable.dialog.containerCode' | translate"
    [isContainerSearchPopup]="true"
    [tableData]="this.sampleInfoTableDropdownOption['containerId']"
    (selectedDropDownOption)="onSelectedDropDownOption($event)"
  ></app-dropdown-search-popup>
</p-dialog>

<p-dialog
  *ngIf="selectedDropDownColumnName && this.sampleInfoTableDropdownOption['locationId'].length"
  [header]="'reception.sampleInformationTable.location' | translate"
  [closable]="true"
  [modal]="true"
  [(visible)]="visibleLocationPopup"
  [style]="{ width: '40vw', height: '50vw' }"
>
  <app-dropdown-search-popup
    #dropdownSearchPopupComponent
    [tableHeader]="'selfServices.sampleInformationTable.dialog.locationLabel' | translate"
    [showLocationOccupied]="true"
    [tableData]="this.sampleInfoTableDropdownOption['locationId']"
    (selectedDropDownOption)="onSelectedDropDownOption($event)"
  ></app-dropdown-search-popup>
</p-dialog>

<p-dialog
  *ngIf="selectedDropDownColumnName && this.sampleInfoTableDropdownOption['palletId'].length"
  [header]="'reception.sampleInformationTable.pallet' | translate"
  [closable]="true"
  [modal]="true"
  [(visible)]="visiblePalletPopup"
  [style]="{ width: '40vw', height: '50vw' }"
>
  <app-dropdown-search-popup
    #dropdownSearchPopupComponent
    [tableHeader]="'selfServices.sampleInformationTable.dialog.palletLabel' | translate"
    [tableData]="this.sampleInfoTableDropdownOption['palletId']"
    (selectedDropDownOption)="onSelectedDropDownOption($event)"
  ></app-dropdown-search-popup>
</p-dialog>
