import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SEPARATOR_BETWEEN_PALLET_LABEL_AND_CONTAINER_COUNT } from '../../core/consts';
import { ContainerModel } from '../../core/models/container.model';
import { DropDownOption } from '../../core/models/drop-down-option.model';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { StorageService } from '../../features/storage/services/storage.service';
import { ToastService } from '../../services/toast.service';
import { DropDownPopupOption } from './models/dropdown-search-popup.model';

@Component({
  selector: 'app-dropdown-search-popup',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    RippleModule,
    TranslateModule,
    DialogModule,
    ProgressSpinnerModule,
    CheckboxModule,
  ],
  templateUrl: './dropdown-search-popup.component.html',
  styleUrls: ['./dropdown-search-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownSearchPopupComponent extends Destroyable(Object) implements OnInit {
  @Input() set tableData(value: DropDownPopupOption[]) {
    this._tableData = value;
    this.filterTable();
    this.cd.markForCheck();
  }

  get tableData(): DropDownPopupOption[] {
    return this._tableData;
  }

  @Input() showLocationOccupied = false;
  @Input() tableHeader = '';
  @Input() isRowDisabled = (_: DropDownPopupOption) => false;
  @Input() isContainerSearchPopup = false;
  @Output() selectedDropDownOption: EventEmitter<any> = new EventEmitter<any>();

  rows: DropDownPopupOption[] = [];
  selectedRow: DropDownPopupOption | null = null;
  searchText!: string;
  isEmptyOnlyDisplayed: 'true' | null = null;

  isAddModalVisible = false;
  showSpinner = false;
  entity: ContainerModel = { id: undefined, containerLabel: '' };

  private _tableData: DropDownPopupOption[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private toastService: ToastService,
    private translate: TranslateService,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.rows = this.tableData;
    this.cd.markForCheck();
  }

  onRowSelect(row: DropDownOption): void {
    this.selectedRow =
      this.isRowDisabled(row) || (row.occupied && this.showLocationOccupied) ? null : row;
  }

  resetPage(): void {
    this.searchText = '';
    this.isEmptyOnlyDisplayed = null;
    this.selectedRow = null;
    this.rows = this.tableData;
  }

  select(): void {
    if (this.tableHeader === 'Pallet label') {
      this.selectedDropDownOption.emit({
        ...this.selectedRow,
        palletLabel: this.selectedRow!['palletLabel'].split(
          SEPARATOR_BETWEEN_PALLET_LABEL_AND_CONTAINER_COUNT,
        )[0],
      });

      return;
    }

    this.selectedDropDownOption.emit(this.selectedRow);
  }

  filterTable(): void {
    this.selectedRow = null;

    if (!this.searchText && !this.isEmptyOnlyDisplayed) {
      this.rows = this.tableData;
    } else {
      this.rows = this.tableData.filter((row) => {
        let isSearchTextPassed = false;
        let isEmptyOnlyPassed = false;

        if (this.searchText) {
          const value = row['palletLabel'] ?? row['locationLabel'] ?? row['name'];
          const lowerCasedValue = value ? value.toString().toLowerCase() : '';
          isSearchTextPassed = lowerCasedValue.includes(this.searchText.toLowerCase());
        }

        if (this.isEmptyOnlyDisplayed) {
          isEmptyOnlyPassed = row['isEmpty'];
        }

        if (this.searchText && this.isEmptyOnlyDisplayed) {
          return isSearchTextPassed && isEmptyOnlyPassed;
        }

        return this.searchText ? isSearchTextPassed : isEmptyOnlyPassed;
      });
    }
  }

  showContainerModel(): void {
    this.storageService
      .generateNewContainerLabel()
      .pipe(this.takeUntilDestroyed())
      .subscribe((label) => {
        this.entity.containerLabel = label;
        this.isAddModalVisible = true;
        this.cd.detectChanges();
      });
  }

  onCancelModal(): void {
    this.clearEntityData();
    this.closeDialog();
  }

  addContainer(): void {
    this.closeDialog();
    this.showSpinner = true;
    this.cd.detectChanges();
    this.storageService
      .createContainer(this.entity)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_) => {
        this.toastService.toastSuccess(
          `${this.translate.instant('sampleManagement.receptionPage.container')} ${
            this.entity.containerLabel
          } ${this.translate.instant('confirmation.hasBeenCreated')}`,
        );
        this.initEntities();
      });
  }

  private initEntities(): void {
    let emittedCount = 0;

    this.storageService
      .getAllContainers()
      .pipe(
        tap(() => {
          emittedCount += 1;
          if (emittedCount === 2) {
            this.showSpinner = false;
          }
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe((data) => {
        const options = data as DropDownOption[];
        options.map((d) => {
          d.name = d.containerLabel;
          return d;
        });
        this.tableData = options;
        this.searchText = this.entity.containerLabel;
        this.filterTable();
        [this.selectedRow] = this.rows;

        this.cd.detectChanges();
      });
  }

  private clearEntityData(): void {
    this.entity = { id: undefined, containerLabel: '' };
  }

  private closeDialog(): void {
    this.isAddModalVisible = false;
  }
}
