<p-table
  [value]="users"
  [tableStyle]="{ 'min-width': '50rem' }"
  [rowsPerPageOptions]="[5, 10, 15, 20, 50, 100]"
  [paginator]="true"
  [rows]="10"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="firstName" field="firstName">
        {{ 'adminSettings.titles.table.firstName' | translate }}
      </th>
      <th pSortableColumn="lastName" field="lastName">
        {{ 'adminSettings.titles.table.lastName' | translate }}
      </th>
      <th pSortableColumn="sgcUser" field="sgcUser">
        {{ 'adminSettings.titles.table.SgcPersonnel' | translate }}
      </th>
      <th pSortableColumn="email" field="email">
        {{ 'adminSettings.titles.table.email' | translate }}
      </th>
      <th pSortableColumn="contactNumber" field="contactNumber">
        {{ 'adminSettings.titles.table.contactNumber' | translate }}
      </th>
      <th pSortableColumn="roleId" field="roleId">
        {{ 'adminSettings.titles.table.role' | translate }}
      </th>
      <th pSortableColumn="organizationName" field="organizationName">
        {{ 'adminSettings.titles.table.organizationName' | translate }}
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
    <tr
      [class.inactive-user]="!user.isActive"
      pTooltip="{{ 'adminSettings.titles.table.inactive' | translate }}"
      tooltipPosition="top"
      [tooltipDisabled]="user.isActive"
    >
      <td>{{ user.firstName }}</td>
      <td>{{ user.lastName }}</td>
      <td>{{ user.sgcUser ? 'Yes' : 'No' }}</td>
      <td>{{ user.email }}</td>
      <td>{{ user.contactNumber }}</td>
      <td>
        {{ getRoleNameById(user.roleId) }}
        <p-tag
          *ngIf="user.isAdmin"
          severity="success"
          [value]="'tag.admin' | translate"
          [rounded]="true"
        ></p-tag>
      </td>
      <td>{{ user.organizationName }}</td>
      <td class="actions">
        <ng-container *ngIf="!isOktaAuthUsed">
          <p-button
            icon="pi pi-lock"
            styleClass="p-button-info"
            (onClick)="onShowChangePasswordModal(user.email)"
            [disabled]="!user.isActive"
          ></p-button>
        </ng-container>

        <button
          pButton
          type="button"
          class="edit-btn btn"
          (click)="onEdit(user)"
          [disabled]="!user.isActive"
          icon="pi pi-pencil"
        ></button>

        <p-confirmPopup></p-confirmPopup>
        <p-button
          [disabled]="!user.isActive"
          icon="pi pi-trash"
          styleClass="p-button-danger"
          (onClick)="confirm($event, user.id)"
        ></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <p-button icon="pi pi-plus" styleClass="btn-secondary" (click)="onCreate()"></p-button>
    <p-dialog
      header="Header"
      [(visible)]="isUserModalVisible"
      [modal]="true"
      [style]="{ width: '800px' }"
      [draggable]="false"
      [resizable]="false"
      [closable]="false"
    >
      <ng-template pTemplate="header">
        <span class="text-xl font-bold">
          {{ modeKey | translate }}
          {{ 'adminSettings.titles.entities.users' | translate }}
        </span>
      </ng-template>
      <div class="col">
        <span class="p-float-label row">
          <input
            pInputText
            id="firstName"
            [(ngModel)]="userToSave.firstName"
            [required]="true"
            #firstNameInput="ngModel"
          />
          <label htmlFor="firstName"> {{ 'adminSettings.user.firstName' | translate }}* </label>
          <small
            *ngIf="!userToSave.firstName && firstNameInput.touched && firstNameInput.dirty"
            class="p-error p-invalid email-error"
          >
            {{ 'errorMessage.fieldIsRequired' | translate }}
          </small>
        </span>

        <span class="p-float-label row">
          <input
            pInputText
            id="email"
            [required]="true"
            [(ngModel)]="userToSave.email"
            [pattern]="emailPattern"
            [disabled]="isEdit"
            #emailInput="ngModel"
          />
          <label htmlFor="email"> {{ 'adminSettings.user.email' | translate }} * </label>
          <small
            *ngIf="!userToSave.email.match(emailPattern) && emailInput.touched && emailInput.dirty"
            class="p-error p-invalid email-error"
          >
            {{ 'errorMessage.emailPattern' | translate }}
          </small>
        </span>

        <span class="p-float-label row">
          <input pInputText id="organizationName" [(ngModel)]="userToSave.organizationName" />
          <label htmlFor="organizationName">
            {{ 'adminSettings.user.organizationName' | translate }}
          </label>
        </span>

        <span class="p-float-label row">
          <p-multiSelect
            id="responsibilities"
            [(ngModel)]="userToSave.responsibilityIds"
            [options]="responsibilities"
            [styleClass]="'w-full'"
            optionValue="id"
            optionLabel="name"
            appendTo="body"
          ></p-multiSelect>

          <label htmlFor="responsibilities">
            {{ 'adminSettings.user.responsibilities' | translate }}
          </label>
        </span>

        <div class="checkbox-container flex align-items-center gap-1">
          <p-checkbox
            name="sgcUser"
            [(ngModel)]="userToSave.sgcUser"
            [binary]="true"
            inputId="sgcUser"
          ></p-checkbox>
          <label for="sgcUser">
            {{ 'adminSettings.user.sgcUser' | translate }}
          </label>
        </div>
      </div>

      <div class="col">
        <span class="p-float-label row">
          <input
            pInputText
            id="lastName"
            [(ngModel)]="userToSave.lastName"
            [required]="true"
            #lastNameInput="ngModel"
          />
          <label htmlFor="lastName"> {{ 'adminSettings.user.lastName' | translate }}* </label>
          <small
            *ngIf="!userToSave.lastName && lastNameInput.touched && lastNameInput.dirty"
            class="p-error p-invalid email-error"
          >
            {{ 'errorMessage.fieldIsRequired' | translate }}
          </small>
        </span>

        <span class="p-float-label row">
          <input pInputText id="contactNumber" [(ngModel)]="userToSave.contactNumber" />
          <label htmlFor="contactNumber">
            {{ 'adminSettings.user.contactNumber' | translate }}
          </label>
        </span>

        <span class="p-float-label row">
          <p-dropdown
            [appendTo]="'body'"
            [optionValue]="'id'"
            [options]="roles"
            [(ngModel)]="userToSave.roleId"
            [required]="true"
            placeholder="Role"
            optionLabel="name"
            inputId="float-label"
          >
            <ng-template pTemplate="selectedItem">
              <div
                class="flex align-items-center justify-content-between"
                *ngIf="userToSave.roleId"
              >
                <span>{{ roleMap[userToSave.roleId].name }}</span>
                <p-tag
                  *ngIf="roleMap[userToSave.roleId].isAdmin"
                  severity="success"
                  [value]="'tag.admin' | translate"
                  [rounded]="true"
                ></p-tag>
              </div>
            </ng-template>

            <ng-template let-role pTemplate="item">
              <div class="flex align-items-center justify-content-between">
                <span>{{ role.name }}</span>
                <p-tag
                  *ngIf="role.isAdmin"
                  severity="success"
                  [value]="'tag.admin' | translate"
                  [rounded]="true"
                ></p-tag>
              </div>
            </ng-template>
          </p-dropdown>
          <label htmlFor="role"> {{ 'adminSettings.user.role' | translate }} * </label>
        </span>

        <span class="p-float-label row" *ngIf="!isOktaAuthUsed && !isEdit">
          <p-password
            id="password"
            [(ngModel)]="userToSave.password"
            [required]="true"
            [toggleMask]="true"
            [appendTo]="'body'"
            [mediumRegex]="mediumPasswordRegex"
            [strongRegex]="strongPasswordRegex"
          ></p-password>
          <label for="password"> {{ 'adminSettings.user.password' | translate }} * </label>
        </span>
      </div>

      <ng-template pTemplate="footer">
        <p-button
          (onClick)="onCancelModal()"
          label="{{ 'button.cancel' | translate }}"
          styleClass="btn-primary"
        ></p-button>

        <p-button
          (onClick)="onSave()"
          label="{{ 'button.save' | translate }}"
          styleClass="btn-secondary"
          [disabled]="isEdit ? !isExistingUserValid : !isNewUserValid"
        ></p-button>
      </ng-template>
    </p-dialog>
  </ng-template>
</p-table>

<p-dialog
  header="Header"
  [(visible)]="isPassModalVisible"
  [modal]="true"
  [style]="{ width: '400px' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
>
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">
      {{ 'changePassword.title' | translate }}
    </span>
  </ng-template>
  <div class="col-wide">
    <span class="p-float-label">
      <p-password [(ngModel)]="newPassword" [toggleMask]="true" [appendTo]="'body'"></p-password>
      <label for="password">{{ 'changePassword.password' | translate }}</label>
    </span>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      (click)="onCloseChangePasswordModal()"
      label="{{ 'button.cancel' | translate }}"
      styleClass="btn-primary"
    ></p-button>
    <p-button
      (click)="onChangePassword()"
      label="{{ 'button.apply' | translate }}"
      styleClass="btn-secondary"
    ></p-button>
  </ng-template>
</p-dialog>
