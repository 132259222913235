import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { combineLatest, finalize } from 'rxjs';

import { CommonModule, KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { ControlDisabledDirective } from '../../../common/directives/control-disabled.directive';
import { DefaultCalendarDateFormatDirective } from '../../../common/directives/default-calendar-date-format.directive';
import { DropdownEditableComponent } from '../../../common/dropdown-editable/dropdown-editable.component';
import { DefaultDateFormatPipe } from '../../../common/pipes/default-date-format.pipe';
import { DropDownOption } from '../../../core/models/drop-down-option.model';
import { ProjectState } from '../../../core/models/project-state.model';
import { Destroyable } from '../../../core/utils/mixins/destroyable.mixin';
import { DeliveryService } from '../../../services/api/delivery.service';
import { ProjectStateService } from '../../../services/api/project-state.service';
import { UserService } from '../../../services/api/user.service';
import { User } from '../../admin-settings/user-table/user.model';
import { WellSearchFormComponent } from '../../storage/forms/well-search-form/well-search-form.component';
import { GetDeliveryForAssignment } from '../models/get-delivery-for-assignment.model';
import { AssignmentTableInformation } from '../table-values/assignment-table-information.model';
import { ASSIGNMENT_TABLE_HEADERS } from '../table-values/table-headers.const';

@Component({
  selector: 'app-search-assignment-popup',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    DropdownModule,
    ProgressSpinnerModule,
    RippleModule,
    SharedModule,
    TableModule,
    TranslateModule,
    WellSearchFormComponent,
    FormsModule,
    InputTextModule,
    CalendarModule,
    DropdownEditableComponent,
    ReactiveFormsModule,
    TooltipModule,
    ControlDisabledDirective,
    DefaultCalendarDateFormatDirective,
    DefaultDateFormatPipe,
  ],
  templateUrl: './search-assignment-popup.component.html',
  styleUrls: ['./search-assignment-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchAssignmentPopupComponent extends Destroyable(Object) implements OnInit {
  @Input() isVisible = false;
  @Output() cancelClicked = new EventEmitter<void>();
  @Output() selectClicked = new EventEmitter<GetDeliveryForAssignment[]>();

  readonly assignmentTableLabels = ASSIGNMENT_TABLE_HEADERS;
  readonly initialTableRowCount = 7;
  readonly noteMaxLengthBeforeTooltip = 22;

  assignmentFormGroup!: FormGroup;
  assignmentTableFormGroup!: FormGroup;
  selectedItems: FormControl[] = [];
  headers!: string[];
  spinnerShow = false;
  assignmentTableDropdownOption: { [key: string]: DropDownOption[] } = {
    state: [],
    technician: [],
  };

  private foundDeliveryForAssignments: GetDeliveryForAssignment[] = [];

  constructor(
    private deliveryService: DeliveryService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private projectStateService: ProjectStateService,
    private userService: UserService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getTableHeaderLabel();
    this.getOptions();
    this.initForm();
  }

  getCellValue(rowIndex: number, colKey: string): string {
    return this.assignmentTableFormGroup.get(['table', rowIndex.toString(), colKey])!.value;
  }

  orderOriginal = (_a: KeyValue<string, string>, _b: KeyValue<string, string>): number => {
    return 0;
  };

  getAssignmentTableFormControl(): FormArray {
    return this.assignmentTableFormGroup.get('table') as FormArray;
  }

  getTableHeaderLabel(): void {
    this.headers = Object.values(this.assignmentTableLabels[0]);
  }

  search(term: string): void {
    this.spinnerShow = true;

    this.deliveryService
      .getDeliveriesBy(term)
      .pipe(
        finalize(() => {
          this.spinnerShow = false;
          this.cd.markForCheck();
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe({
        next: (data: GetDeliveryForAssignment[]) => {
          this.initForm(term);
          this.foundDeliveryForAssignments = data;
          this.addEmptyRows();
          this.cd.detectChanges();
        },
      });
  }

  cancelSearch(): void {
    this.clearPopupState();
    this.cancelClicked.emit();
  }

  select(): void {
    this.selectClicked.emit(
      this.foundDeliveryForAssignments.filter((delivery) =>
        this.selectedItems
          .map((selectedItem) => selectedItem.getRawValue().deliveryId)
          .includes(delivery.id),
      ),
    );
    this.clearPopupState();
  }

  onDropdownValueChanged(rowIndex: number, controlKey: string): void {
    const rowFormGroup = this.assignmentTableFormGroup.get('table')?.get(rowIndex.toString());

    if (controlKey === 'state') {
      const { projectStateRelatedInfos } = this.foundDeliveryForAssignments[rowIndex];
      const currentProjectStateRelatedInfo = projectStateRelatedInfos.find(
        (info) => info.projectStateId === rowFormGroup?.get('state')!.getRawValue(),
      )!;
      const currentProjectStateRelatedInfoFormValue: Partial<AssignmentTableInformation> = {
        technician: currentProjectStateRelatedInfo.technicianId ?? '',
        dateAssigned: currentProjectStateRelatedInfo.dateAssigned ?? '',
        dateCompleted: currentProjectStateRelatedInfo.dateCompleted ?? '',
        note: currentProjectStateRelatedInfo.note ?? '',
      };
      rowFormGroup?.patchValue(currentProjectStateRelatedInfoFormValue);
    }
  }

  getTableFormControlBy(rowIndex: number, controlName: string): FormControl {
    return this.assignmentFormGroup
      ?.get('assignmentTable')
      ?.get('table')
      ?.get([rowIndex.toString()])
      ?.get(controlName) as FormControl;
  }

  private initForm(searchText = ''): void {
    const tableFormGroup: { [key: string]: FormArray } = {};
    const key = 'table';
    tableFormGroup[key] = this.fb.array([]);

    this.assignmentTableLabels.forEach((_) => {
      tableFormGroup[key] = this.fb.array([]);
    });

    this.assignmentTableFormGroup = this.fb.group(tableFormGroup);

    this.assignmentFormGroup = this.fb.group({
      search: [searchText],
      assignmentTable: this.assignmentTableFormGroup,
    });
  }

  private addEmptyRows(): void {
    this.foundDeliveryForAssignments.forEach((foundDeliveryForAssignment) => {
      const { projectStateRelatedInfos } = foundDeliveryForAssignment;
      const currentProjectStateRelatedInfo = projectStateRelatedInfos.find(
        (info) => info.projectStateId === foundDeliveryForAssignment.projectStateId,
      )!;

      const data: AssignmentTableInformation = {
        deliveryId: foundDeliveryForAssignment.id,
        state: foundDeliveryForAssignment?.projectStateId ?? '',
        type: foundDeliveryForAssignment?.type ?? '',
        uwiupi: foundDeliveryForAssignment?.uwiOrUpi ?? '',
        name: foundDeliveryForAssignment?.name ?? '',
        sampleTypeAndTotalBoxes: foundDeliveryForAssignment?.sampleBoxInfos
          .map((info) => `${info.sampleType}: ${info.numberOfBoxes}`)
          .join('\n'),
        companyName: foundDeliveryForAssignment.companyName ?? '',
        contractNumber: foundDeliveryForAssignment?.contractNumber ?? '',
        technician: currentProjectStateRelatedInfo.technicianId ?? '',
        dateAssigned: currentProjectStateRelatedInfo.dateAssigned ?? '',
        dateCompleted: currentProjectStateRelatedInfo.dateCompleted ?? '',
        note: currentProjectStateRelatedInfo.note ?? '',
      };

      this.addRow(data);
    });
  }

  private addRow(data: AssignmentTableInformation) {
    const control = this.assignmentTableFormGroup.get(`table`) as FormArray;
    control.push(this.initiateAssignmentTableForm(data));
    this.cd.detectChanges();
  }

  private initiateAssignmentTableForm(data: AssignmentTableInformation): FormGroup {
    const currentTable = data;
    const formData: { [key: string]: string[] } = {};
    Object.entries(currentTable).forEach(([key, value]) => {
      formData[key] = [value];
    });
    return this.fb.group(formData);
  }

  private getOptions(): void {
    combineLatest([this.userService.getUsers(), this.projectStateService.getProjectStates()])
      .pipe(this.takeUntilDestroyed())
      .subscribe((data: [User[], ProjectState[]]) => {
        const [users, projectStates] = data;
        this.assignmentTableDropdownOption['state'] = projectStates.filter(
          (projectState) => projectState.isAssignmentCompatible,
        );
        this.assignmentTableDropdownOption['technician'] = users.map((user) => ({
          id: user.id!,
          name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
        }));
        this.cd.markForCheck();
      });
  }

  private clearPopupState(): void {
    this.initForm();
    this.foundDeliveryForAssignments = [];
    this.selectedItems = [];
    this.addEmptyRows();
  }
}
