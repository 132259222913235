import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';

import { ShortTransportationModel } from '../../core/models/transportation.model';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { ProviderService } from '../../services/api/provider.service';
import { TranslationComponent } from '../translation/translation.component';
import { TransportationSearchComponent } from '../transportation-search/transportation-search.component';
import { BuildTransportationNamePipe } from './pipes/build-transportation-name.pipe';

@Component({
  selector: 'app-transportation-information',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationComponent,
    TranslateModule,
    DropdownModule,
    TransportationSearchComponent,
    DialogModule,
    BuildTransportationNamePipe,
  ],
  templateUrl: './transportation-information.component.html',
  styleUrls: ['./transportation-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationInformationComponent extends Destroyable(Object) implements OnInit {
  @Input() parentGroup!: FormGroup;
  @Input() isExpanded = true;
  @Input() required = true;
  @ViewChild('transportationSearchComponent')
  transportationSearchComponent!: TransportationSearchComponent;

  transportations: ShortTransportationModel[] = [];
  transportationMap!: Record<string, ShortTransportationModel>;
  isSearchPopupVisible = false;

  constructor(private providerService: ProviderService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.setUpControl();
    this.loadTransportations();
  }

  checkFormError(controlPath: string): ValidationErrors {
    const error = { required: false, email: false };
    const control = this.parentGroup.get(controlPath) as FormControl;
    if (this.checkFormDirty(controlPath) && !!control?.errors?.['required']) error.required = true;
    else if (this.checkFormDirty(controlPath) && !!control?.errors?.['pattern']) error.email = true;
    return error;
  }

  displayPopup(): void {
    this.isSearchPopupVisible = true;
    this.cd.detectChanges();
  }

  onSelectTransportation(transportationId: string): void {
    this.transportationSearchComponent.resetPage();
    this.parentGroup.get(['transportationInformation', 'companyName'])!.setValue(transportationId);
    this.isSearchPopupVisible = false;
  }

  private setUpControl(): void {
    const operatorFormGroup = this.parentGroup.get('operator') as FormGroup;
    operatorFormGroup.markAsTouched = () => {
      this.cd.markForCheck();
    };
  }

  private loadTransportations(): void {
    this.providerService
      .getTransportations()
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.transportations = data;
        this.transportationMap = data.reduce<Record<string, ShortTransportationModel>>(
          (acc, transportation) => {
            return { ...acc, [transportation.id!]: transportation };
          },
          {},
        );
      });
  }

  private checkFormDirty(controlPath: string): boolean | undefined {
    return this.parentGroup.get(controlPath)?.invalid && this.parentGroup.get(controlPath)?.dirty;
  }
}
