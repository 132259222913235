<form [formGroup]="formGroup">
  <div class="row input-group-50 mt-3">
    <span class="p-float-label">
      <p-multiSelect
        id="sample-type-dropdown"
        [options]="sampleTypes"
        [filter]="false"
        filterBy="name"
        formControlName="sampleType"
        placeholder="Select a sampleType"
        [showClear]="true"
        [styleClass]="'w-full'"
      ></p-multiSelect>
      <label for="sample-type-dropdown">{{ 'dataAccess.filter.sampleType' | translate }}</label>
    </span>

    <span class="p-float-label" *ngIf="filterOptionList['state']">
      <p-dropdown
        id="state-dropdown"
        [filter]="true"
        filterBy="name"
        formControlName="state"
        [options]="stateOption"
        optionValue="name"
        optionLabel="name"
        placeholder="Select a state"
        [showClear]="true"
        [styleClass]="'w-full'"
      ></p-dropdown>
      <label for="state-dropdown">{{ 'dataAccess.filter.state' | translate }}</label>
    </span>
  </div>

  <div class="row input-group-50" *ngIf="filterOptionList['technicianIds']">
    <span class="p-float-label">
      <p-multiSelect
        id="technician-dropdown"
        [filter]="true"
        filterBy="name"
        formControlName="technicianIds"
        [options]="techniciansOption"
        optionValue="id"
        optionLabel="name"
        placeholder="Select a technician"
        [showClear]="true"
        [styleClass]="'w-full'"
      ></p-multiSelect>
      <label for="technician-dropdown">{{ 'dataAccess.filter.technicianId' | translate }}</label>
    </span>
  </div>

  <div class="row input-group-50">
    <span class="p-float-label">
      <p-inputNumber
        id="minDepth-input"
        inputId="minDepth-input"
        formControlName="minDepth"
        mode="decimal"
        [minFractionDigits]="0"
        [maxFractionDigits]="10"
        [locale]="defaultLocale"
      >
      </p-inputNumber>
      <label for="minDepth-input">{{ 'dataAccess.filter.minDepth' | translate }}</label>
    </span>

    <span class="p-float-label">
      <p-inputNumber
        id="maxDepth-input"
        inputId="maxDepth-input"
        formControlName="maxDepth"
        aria-describedby="maxDepth_validationMessage"
        mode="decimal"
        [minFractionDigits]="0"
        [maxFractionDigits]="10"
        [locale]="defaultLocale"
      >
      </p-inputNumber>
      <label for="maxDepth-input">{{ 'dataAccess.filter.maxDepth' | translate }}</label>
    </span>
  </div>

  <div class="row input-group-50" *ngIf="filterOptionList['date']">
    <span class="p-float-label">
      <p-calendar
        id="minDateOfAssignment"
        appDefaultCalendarDateFormat
        [appendTo]="'body'"
        formControlName="minDateOfAssignment"
      ></p-calendar>
      <label for="minDateOfAssignment">{{
        'dataAccess.filter.minDateOfAssignment' | translate
      }}</label>
    </span>

    <span class="p-float-label">
      <p-calendar
        id="maxDateOfAssignment"
        appDefaultCalendarDateFormat
        [appendTo]="'body'"
        formControlName="maxDateOfAssignment"
      ></p-calendar>
      <label for="maxDateOfAssignment">{{
        'dataAccess.filter.maxDateOfAssignment' | translate
      }}</label>
    </span>
  </div>

  <div class="row input-group-50" *ngIf="filterOptionList['dateService']">
    <span class="p-float-label">
      <p-calendar
        id="minDateOfService"
        appDefaultCalendarDateFormat
        [appendTo]="'body'"
        formControlName="minDateOfService"
      ></p-calendar>
      <label for="minDateOfAssignment">{{
        'dataAccess.filter.minDateOfService' | translate
      }}</label>
    </span>

    <span class="p-float-label">
      <p-calendar
        id="maxDateOfService"
        appDefaultCalendarDateFormat
        [appendTo]="'body'"
        formControlName="maxDateOfService"
      ></p-calendar>
      <label for="maxDateOfAssignment">{{
        'dataAccess.filter.maxDateOfService' | translate
      }}</label>
    </span>
  </div>

  <div class="row input-group-50" *ngIf="filterOptionList['status']">
    <div class="p-grid checkbox-container">
      <div class="p-col-4">
        <span>{{ 'dataAccess.filter.receptionStatus' | translate }}</span>
        <div class="checkbox-body">
          <p-checkbox
            [binary]="true"
            formControlName="receptionStatusComplete"
            label="{{ 'dataAccess.filter.completed' | translate }}"
          ></p-checkbox>
        </div>
        <div class="checkbox-body">
          <p-checkbox
            [binary]="true"
            formControlName="receptionStatusIncomplete"
            label="{{ 'dataAccess.filter.incomplete' | translate }}"
          ></p-checkbox>
        </div>
      </div>

      <div class="p-col-4">
        <span>{{ 'dataAccess.filter.verificationStatus' | translate }}</span>
        <div class="checkbox-body">
          <p-checkbox
            [binary]="true"
            formControlName="verificationStatusComplete"
            label="{{ 'dataAccess.filter.completed' | translate }}"
          ></p-checkbox>
        </div>
        <div class="checkbox-body">
          <p-checkbox
            [binary]="true"
            formControlName="verificationStatusIncomplete"
            label="{{ 'dataAccess.filter.incomplete' | translate }}"
          ></p-checkbox>
        </div>
      </div>

      <div class="p-col-4">
        <span>{{ 'dataAccess.filter.validationStatus' | translate }}</span>
        <div class="checkbox-body">
          <p-checkbox
            [binary]="true"
            formControlName="validationStatusComplete"
            label="{{ 'dataAccess.filter.completed' | translate }}"
          ></p-checkbox>
        </div>
        <div class="checkbox-body">
          <p-checkbox
            [binary]="true"
            formControlName="validationStatusIncomplete"
            label="{{ 'dataAccess.filter.incomplete' | translate }}"
          ></p-checkbox>
        </div>
      </div>
    </div>
  </div>

  <div class="row input-group-50" *ngIf="filterOptionList['deliveryCreated']">
    <span class="p-float-label">
      <p-calendar
        id="startDateDeliveryCreated"
        appDefaultCalendarDateFormat
        [appendTo]="'body'"
        formControlName="startDateOfDeliveryCreated"
      ></p-calendar>
      <label for="startDateDeliveryCreated">{{
        'dataAccess.filter.startDateOfDeliveryCreated' | translate
      }}</label>
    </span>

    <span class="p-float-label">
      <p-calendar
        id="endDateDeliveryCreated"
        appDefaultCalendarDateFormat
        [appendTo]="'body'"
        formControlName="endDateOfDeliveryCreated"
      ></p-calendar>
      <label for="endDateDeliveryCreated">{{
        'dataAccess.filter.endDateOfDeliveryCreated' | translate
      }}</label>
    </span>
  </div>

  <div class="row input-group-50" *ngIf="filterOptionList['receptionDate']">
    <span class="p-float-label">
      <p-calendar
        id="receptionStartDate"
        appDefaultCalendarDateFormat
        [appendTo]="'body'"
        formControlName="receptionStartDate"
      ></p-calendar>
      <label for="receptionStartDate">{{
        'dataAccess.filter.receptionStartDate' | translate
      }}</label>
    </span>

    <span class="p-float-label">
      <p-calendar
        id="receptionEndDate"
        appDefaultCalendarDateFormat
        [appendTo]="'body'"
        formControlName="receptionEndDate"
      ></p-calendar>
      <label for="receptionEndDate">{{ 'dataAccess.filter.receptionEndDate' | translate }}</label>
    </span>
  </div>

  <div class="footer mt-5">
    <div class="validation-message">
      <span *ngIf="isMaxDepthHigherThanMinDepth">{{
        'dataAccess.filter.validation.minGreaterThanMaxDepthMessage' | translate
      }}</span>
      <span
        *ngIf="
          isEndDatehHigherThanStartDate && this.filterOptionList['receptionDate'];
          else otherDateMessage
        "
        id="endDate_validationMessage"
        >{{ 'dataAccess.filter.validation.startDateGreaterThanEndDateMessage' | translate }}</span
      >
      <ng-template #otherDateMessage>
        <span *ngIf="isEndDatehHigherThanStartDate">{{
          'dataAccess.filter.validation.minDateGreaterThanMaxDateMessage' | translate
        }}</span>
      </ng-template>
    </div>
    <div class="actions">
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'button.clear' | translate }}"
        (click)="resetForm()"
        class="btn btn-primary"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'button.apply' | translate }}"
        class="btn btn-secondary"
        [disabled]="isMaxDepthHigherThanMinDepth || isEndDatehHigherThanStartDate"
        (click)="filterSelect()"
      ></button>
    </div>
  </div>
</form>
