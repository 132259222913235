import { Observable, switchMap, timer } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from '../../core/api.consts';
import { ServiceStatus } from '../../layout/models/service-status.model';

@Injectable({
  providedIn: 'root',
})
export class HealthCheckService {
  private url = `${SGC_API}/HealthCheck`;
  private initialDelay = 0; // Delay for the initial API call in milliseconds
  private intervalTime = 5 * 60 * 1000; // 5 minutes in milliseconds

  constructor(private httpClient: HttpClient) {}

  getStandByStatus(): Observable<ServiceStatus[]> {
    return this.httpClient.get<ServiceStatus[]>(this.url);
  }

  fetchStandByStatusRegularly(): Observable<ServiceStatus[]> {
    return timer(this.initialDelay, this.intervalTime).pipe(
      switchMap(() => this.httpClient.get<ServiceStatus[]>(this.url)),
    );
  }
}
