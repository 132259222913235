import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { filter as filterFunc, finalize } from 'rxjs';

import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';

import { DashboardFilterValue } from '../sample-types-widget/sample-types-chart-filter/models/dashboard-filter-value.model';
import { WarehouseWidgetService } from './services/warehouse-widget.service';
import { INITIAL_WAREHOUSE_CHART_STATE } from './warehouse-chart-filter/consts/initial-warehouse-chart-state.conts';
import { WarehouseChartFilters } from './warehouse-chart-filter/models/warehouse-chart-filter.model';
import { WarehouseFilterModel } from './warehouse-chart-filter/models/warehouse-filters.model';
import { WarehouseChartFilterComponent } from './warehouse-chart-filter/warehouse-chart-filter.component';

@Component({
  selector: 'app-warehouse-widget',
  standalone: true,
  imports: [
    CommonModule,
    ChartModule,
    WarehouseChartFilterComponent,
    ButtonModule,
    TranslateModule,
    ProgressSpinnerModule,
  ],
  providers: [DialogService, DatePipe],
  templateUrl: './warehouse-widget.component.html',
  styleUrls: ['./warehouse-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarehouseWidgetComponent extends Destroyable(Object) implements OnInit {
  spinnerShow = false;
  areas!: string[];

  filterResultData!: DashboardFilterValue[];
  xAxisTitle!: string;
  ref!: DynamicDialogRef;
  chartData: any;
  chartOptions: any;
  filterData: WarehouseChartFilters = JSON.parse(JSON.stringify(INITIAL_WAREHOUSE_CHART_STATE));

  constructor(
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private warehouseWidgetService: WarehouseWidgetService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getData();
  }

  openOptions(): void {
    this.ref = this.dialogService.open(WarehouseChartFilterComponent, {
      data: { ...this.filterData, showDialog: true },
      styleClass: 'dialog-custom-style',
      header: this.getTranslationText('dashboard.chart.warehousePlotOptions'),
      showHeader: true,
    });

    this.ref.onClose
      .pipe(
        filterFunc((filterData) => !!filterData),
        this.takeUntilDestroyed(),
      )
      .subscribe((data: WarehouseChartFilters) => {
        this.filterData = data;
        this.applyFilters();
      });
  }

  private applyFilters(): void {
    this.showSpinner();
    const areaSearchObj: { [key: string]: string } = {};

    this.areas
      .filter((areas) => !this.filterData.areaIds.length || this.filterData.areaIds.includes(areas))
      .forEach((item, index) => {
        areaSearchObj[`Areas[${index}]`] = item;
      });

    const filterData: WarehouseFilterModel = {
      area: areaSearchObj,
    };

    this.warehouseWidgetService
      .getFilteredWarehouses(filterData)
      .pipe(
        finalize(() => {
          this.hideSpinner();
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe((data) => {
        this.filterResultData = data;
        this.buildChart(this.filterResultData);
      });
  }

  private buildChart(filterResultData: DashboardFilterValue[]): void {
    const categories = Array.from(
      new Set(
        filterResultData.map((data) =>
          data.displayName.replace(
            /(occupied|unOccupied)/,
            (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase(),
          ),
        ),
      ),
    );

    const data = filterResultData
      .map((resData) =>
        resData.displayName.includes('occupied') || resData.displayName.includes('unOccupied')
          ? resData.count
          : null,
      )
      .filter((val) => val !== null);

    const datasets = [{ data }];

    this.chartData = {
      labels: categories,
      datasets,
    };

    this.chartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        legend: {
          labels: {
            color: 'black',
          },
        },
      },
    };

    this.cd.markForCheck();
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }

  private getData(): void {
    this.showSpinner();
    this.warehouseWidgetService
      .getAreas()
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.areas = data;
        this.applyFilters();
      });
  }

  private showSpinner(): void {
    this.spinnerShow = true;
    this.cd.markForCheck();
  }

  private hideSpinner(): void {
    this.spinnerShow = false;
    this.cd.markForCheck();
  }
}
