import { TranslateModule } from '@ngx-translate/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { finalize } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UserNotification } from 'src/app/core/models/user-notification.model';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';
import { UserNotificationsService } from 'src/app/services/api/user-notifications.service';

import { DashboardNotificationsComponent } from '../dashboard-notifications/dashboard-notifications.component';
import { SampleTypesWidgetComponent } from './sample-types-widget/sample-types-widget.component';
import { ServicesWidgetComponent } from './services-widget/services-widget.component';
import { WarehouseWidgetComponent } from './warehouse-widget/warehouse-widget.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TooltipModule,
    DashboardNotificationsComponent,
    SampleTypesWidgetComponent,
    ServicesWidgetComponent,
    WarehouseWidgetComponent,
    ProgressSpinnerModule,
  ],
})
export class DashboardComponent extends Destroyable(Object) implements OnInit {
  spinnerShow = false;
  notifications = new Array<UserNotification>();

  constructor(
    private userNotificationsService: UserNotificationsService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getNotifications();
  }

  onNotificationsRequest(): void {
    this.getNotifications();
  }

  deleteNotification(id: string): void {
    this.showSpinner();
    this.userNotificationsService
      .deleteNotification(id)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (_) => {
          this.getNotifications();
          this.cd.detectChanges();
        },
        error: () => {
          this.hideSpinner();
        },
      });
  }

  private getNotifications(): void {
    this.showSpinner();
    this.userNotificationsService
      .getNotifications()
      .pipe(
        finalize(() => {
          this.hideSpinner();
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe((value) => {
        this.notifications = value;
      });
  }

  private showSpinner(): void {
    this.spinnerShow = true;
    this.cd.markForCheck();
  }

  private hideSpinner(): void {
    this.spinnerShow = false;
    this.cd.markForCheck();
  }
}
