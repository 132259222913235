<div class="header">
  <div class="title">{{ title }}</div>
</div>

<p-table
  [value]="responsibilities"
  [paginator]="false"
  [rows]="5"
  [showCurrentPageReport]="true"
  [tableStyle]="{ 'min-width': '250px' }"
  currentPageReportTemplate=""
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 75%">{{ 'adminSettings.titles.table.name' | translate }}</th>
      <th style="width: 25%"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-responsibility>
    <tr>
      <td>{{ responsibility.name }}</td>
      <td>
        <button
          pButton
          type="button"
          class="edit-btn btn"
          (click)="onEdit(responsibility)"
          icon="pi pi-pencil"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>

<div class="flex justify-content-between align-items-center">
  <p-button
    type="button"
    icon="pi pi-plus"
    (click)="onCreate()"
    styleClass="btn-secondary"
  ></p-button>

  <p-dialog
    header="Header"
    [(visible)]="isModalVisible"
    [modal]="true"
    [style]="{ width: '400px' }"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
  >
    <ng-template pTemplate="header">
      <span class="text-xl font-bold"
        >{{ modeKey | translate }}
        {{ 'adminSettings.titles.entities.responsibilities' | translate }}</span
      >
    </ng-template>

    <div class="container add_pallet">
      <span class="p-float-label">
        <input pInputText id="responsibilityName" [formControl]="responsibilityFormControl" />
        <label htmlFor="responsibilityName">{{
          'adminSettings.titles.table.name' | translate
        }}</label>
      </span>

      <small
        class="inline-block w-full text-right"
        *ngIf="!responsibilityFormControl.invalid || !responsibilityFormControl.dirty"
        >{{ responsibilityFormControl.value!.length }}/64
      </small>

      <small
        *ngIf="responsibilityFormControl.dirty && responsibilityFormControl.hasError('required')"
        class="p-error p-invalid"
        >{{ 'errorMessage.fieldIsRequired' | translate }}
      </small>

      <small
        *ngIf="responsibilityFormControl.dirty && responsibilityFormControl.hasError('maxlength')"
        class="p-error p-invalid"
        >{{ 'errorMessage.fieldIsTooLong' | translate }}
      </small>
    </div>

    <ng-template pTemplate="footer">
      <p-button
        (click)="onCancelModal()"
        [label]="'button.cancel' | translate"
        styleClass="btn-primary"
      ></p-button>

      <p-button
        [disabled]="responsibilityFormControl.invalid || isSavingInProgress"
        (onClick)="onSave()"
        [label]="'button.save' | translate"
        styleClass="btn-secondary"
      ></p-button>
    </ng-template>
  </p-dialog>

  <p-paginator
    (onPageChange)="onPageChanged($event)"
    [rows]="paginatorOptions.pageSize"
    [totalRecords]="paginatorOptions.count"
  ></p-paginator>
</div>
