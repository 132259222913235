import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { finalize } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PackagingTypeModel } from 'src/app/core/models/packaging-type.model';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';
import { PackagingTypeService } from 'src/app/services/api/packaging-type.service';
import { CacheService } from 'src/app/services/cache.service';
import { ToastService } from 'src/app/services/toast.service';

import { PageEvent } from '../../core/models/page-event.model';
import { PaginatorOptions } from '../../core/models/paginator-options.model';
import { LocaleService } from '../../services/locale.service';

@Component({
  selector: 'app-options-table-packaging-types',
  standalone: true,
  providers: [ConfirmationService],
  imports: [
    CommonModule,
    TableModule,
    TranslateModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    ConfirmPopupModule,
    PaginatorModule,
    InputNumberModule,
  ],
  templateUrl: './options-table-packaging-types.component.html',
  styleUrls: ['./options-table-packaging-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsTablePackagingTypesComponent extends Destroyable(Object) implements OnInit {
  @Input() title = 'test';

  entities = new Array<PackagingTypeModel | unknown>();
  isSavingInProgress = false;
  filterData: PaginatorOptions = {
    page: 1,
    pageSize: 5,
    count: 0,
  };

  isAddModalVisible = false;
  entity: PackagingTypeModel = { id: null, name: '', count: null };
  duplicateCount: number | null = null;

  get defaultLocale(): string {
    return this.localeService.getDefaultLocale();
  }

  constructor(
    private localeService: LocaleService,
    private confirmationService: ConfirmationService,
    private cacheService: CacheService,
    private packagingTypeService: PackagingTypeService,
    private toastService: ToastService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initEntities();
  }

  onDelete(entityId: string): void {
    this.packagingTypeService
      .deletePackagingType(entityId)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_) => {
        this.initEntities();
      });
  }

  showDialog(): void {
    this.isAddModalVisible = true;
  }

  onSave(): void {
    this.isSavingInProgress = true;

    this.packagingTypeService
      .createPackagingType(this.entity)
      .pipe(
        this.takeUntilDestroyed(),
        finalize(() => {
          this.isSavingInProgress = false;
          this.cd.markForCheck();
        }),
      )
      .subscribe((_) => {
        this.toastService.toastSuccess(
          `${this.entity.name} ${this.translate.instant('adminSettings.messages.hasBeenCreated')}`,
        );
        this.initEntities();
        this.closeDialog();
        this.clearEntityData();
      });
  }

  onSubmitCountChanges(entity: PackagingTypeModel): void {
    this.packagingTypeService
      .updateCount(entity)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_) => {
        this.toastService.toastSuccess(
          `${entity.name} ${this.translate.instant('adminSettings.messages.hasBeenUpdated')}`,
        );
        this.initEntities();
        this.closeDialog();
      });
  }

  onCancelCountChanges(entity: PackagingTypeModel): void {
    entity.count = this.duplicateCount;
  }

  duplicateInitialValue(entity: PackagingTypeModel): void {
    if (entity.count || entity.count === 0) {
      this.duplicateCount = entity.count;
    }
  }

  onCancelModal(): void {
    this.clearEntityData();
    this.closeDialog();
  }

  confirm(event: Event, id: string): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.getTranslationText('confirmation.message'),
      acceptButtonStyleClass: 'btn-secondary',
      rejectButtonStyleClass: 'btn-primary',
      acceptLabel: this.getTranslationText('confirmation.confirm_btn'),
      rejectLabel: this.getTranslationText('confirmation.cancel_btn'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDelete(id);
      },
    });
  }

  onPageChanged(event: PageEvent): void {
    this.filterData = {
      ...this.filterData,
      page: event.page + 1,
    };

    this.initEntities();
  }

  private initEntities(): void {
    this.packagingTypeService
      .getPackagingTypes(this.filterData)
      .pipe(this.takeUntilDestroyed())
      .subscribe((result) => {
        this.entities = result.data;
        this.filterData.count = result.count;
        this.cd.detectChanges();
      });
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }

  private closeDialog(): void {
    this.isAddModalVisible = false;
  }

  private clearEntityData(): void {
    this.entity = { id: null, name: '', count: null };
  }
}
