import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { finalize } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LocationModel } from 'src/app/core/models/location.model';
import { PalletModel } from 'src/app/core/models/pallet.model';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';

import { StorageService } from '../../features/storage/services/storage.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-options-table-pallet',
  standalone: true,
  providers: [ConfirmationService],
  imports: [
    CommonModule,
    TableModule,
    TranslateModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    ConfirmPopupModule,
    DropdownModule,
  ],
  templateUrl: './options-table-pallet.component.html',
  styleUrls: ['./options-table-pallet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsTablePalletComponent extends Destroyable(Object) implements OnInit {
  @Input() title = 'test';
  entities = new Array<PalletModel | unknown>();

  isAddModalVisible = false;
  isSavingInProgress = false;
  entity: PalletModel = { palletLabel: '' };
  locations = new Array<LocationModel>();

  constructor(
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initEntities();
  }

  onDelete(entity: PalletModel): void {
    this.storageService
      .deletePallet(entity.id!)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_) => {
        this.toastService.toastSuccess(
          `${this.title} ${entity.palletLabel} ${this.translate.instant(
            'adminSettings.messages.hasBeenDeleted',
          )}`,
        );
        this.initEntities();
      });
  }

  showDialog(): void {
    this.storageService
      .generateNewPalletLabel()
      .pipe(this.takeUntilDestroyed())
      .subscribe((label) => {
        this.entity.palletLabel = label;
        this.cd.detectChanges();
      });
    this.isAddModalVisible = true;
  }

  onSave(): void {
    this.isSavingInProgress = true;
    this.storageService
      .createPallet(this.entity)
      .pipe(
        this.takeUntilDestroyed(),
        finalize(() => {
          this.isSavingInProgress = false;
          this.cd.markForCheck();
        }),
      )
      .subscribe((_) => {
        this.toastService.toastSuccess(
          `${this.title} ${this.entity.palletLabel} ${this.translate.instant(
            'adminSettings.messages.hasBeenCreated',
          )}`,
        );
        this.initEntities();
        this.closeDialog();
        this.entity = { palletLabel: '' };
      });
  }

  onCancelModal(): void {
    this.entity = { palletLabel: '' };
    this.closeDialog();
  }

  confirm(event: Event, entity: PalletModel) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.getTranslationText('confirmation.message'),
      acceptButtonStyleClass: 'btn-secondary',
      rejectButtonStyleClass: 'btn-primary',
      acceptLabel: this.getTranslationText('confirmation.confirm_btn'),
      rejectLabel: this.getTranslationText('confirmation.cancel_btn'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDelete(entity);
      },
    });
  }

  private initEntities(): void {
    this.storageService
      .getAllLocations()
      .pipe(this.takeUntilDestroyed())
      .subscribe((l) => {
        this.locations = l;

        this.storageService
          .getAllPallets()
          .pipe(this.takeUntilDestroyed())
          .subscribe((data) => {
            this.entities = data;
            this.cd.detectChanges();
          });
      });
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }

  private closeDialog(): void {
    this.isAddModalVisible = false;
  }
}
