<div class="container">
  <div style="margin-bottom: 50px">
    <label #upl for="video-data-upload">
      <button pButton pRipple (click)="this.upl.click()" class="btn btn-secondary custom-upload">
        Upload .mp4 files<i class="pi pi-upload"></i>
      </button>
    </label>
    <input id="video-data-upload" type="file" accept=".mp4" (change)="onFileSelected($event)" />
  </div>
  <video controls *ngIf="videoSrc">
    <source [src]="videoSrc" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>
