import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { RippleModule } from 'primeng/ripple';

import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { LocaleService } from '../../../services/locale.service';
import { ColorMapsComponent } from './color-maps/color-maps.component';
import { ConvertVTKData } from './convertVTKData';
import { ImageViewComponent } from './image-view/image-view.component';
import { OpacityComponent } from './opacity/opacity.component';
import { VolumeViewComponent } from './volume-view/volume-view.component';

@Component({
  selector: 'app-render3-ddata',
  standalone: true,
  imports: [
    CommonModule,
    ImageViewComponent,
    VolumeViewComponent,
    FormsModule,
    DropdownModule,
    ColorMapsComponent,
    OpacityComponent,
    ButtonModule,
    RippleModule,
    InputNumberModule,
    TranslateModule,
  ],
  templateUrl: './render3-ddata.component.html',
  styleUrls: ['./render3-ddata.component.scss', './../upload-styles.scss'],
})
export class Render3DDataComponent {
  selectedColormapValue: any;
  colorMapArray: any;
  opacityArray: any;
  @ViewChild('imageViewRef', { static: false }) imageView!: ImageViewComponent;
  @ViewChild('volumeViewRef', { static: false }) volumeView!: VolumeViewComponent;
  isBusy: any = null;
  selectedFile?: File;
  dataArray: any = null;
  vtdDataUtil = new ConvertVTKData();
  nx = 200;
  ny = 200;
  nz = 200;
  dataType = 'uint8';
  dataTypeList: string[] = ['uint8', 'int8', 'int16', 'uint16'];
  minValue = 0;
  maxValue = 0;
  max_z: any = null;

  get defaultLocale(): string {
    return this.localeService.getDefaultLocale();
  }

  constructor(private localeService: LocaleService, private cd: ChangeDetectorRef) {}

  onFileSelected(event: any) {
    // eslint-disable-next-line prefer-destructuring
    this.selectedFile = event.target.files[0];
    this.dataArray = null;
    this.cd.detectChanges();
  }

  onOpenClick() {
    if (!this.selectedFile) {
      return;
    }
    if (!this.selectedFile.name.endsWith('.raw')) {
      alert('only .raw file is supported');
      return;
    }
    this.isBusy = true;
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        // this.dataArray = null;
        const buffer = reader.result;

        const dataView = new DataView(buffer);

        if (this.dataType === 'uint8') {
          this.dataArray = new Uint8Array(dataView.byteLength);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < dataView.byteLength; i++) {
            this.dataArray[i] = dataView.getUint8(i);
          }
        } else if (this.dataType === 'int8') {
          this.dataArray = new Int8Array(dataView.byteLength);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < dataView.byteLength; i++) {
            this.dataArray[i] = dataView.getInt8(i);
          }
        } else if (this.dataType === 'uint16') {
          this.dataArray = new Uint16Array(dataView.byteLength / 2);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < dataView.byteLength / 2; i++) {
            this.dataArray[i] = dataView.getUint8(i * 2) + dataView.getUint8(i * 2 + 1) * 255;
          }
        } else if (this.dataType === 'int16') {
          this.dataArray = new Int16Array(dataView.byteLength / 2);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < dataView.byteLength / 2; i++) {
            this.dataArray[i] = dataView.getUint8(i * 2) + dataView.getInt8(i * 2 + 1) * 255;
          }
        } else {
          this.dataArray = null;
        }

        if (this.dataArray != null) {
          let temp = 0;
          let minValue = 100000000;
          let maxValue = -100000000;

          this.dataArray.forEach((item: number) => {
            temp = item;
            if (temp > maxValue) {
              maxValue = temp;
            }
            if (temp < minValue) {
              minValue = temp;
            }
          });

          this.minValue = minValue;
          this.maxValue = maxValue;
          this.dataArray = this.vtdDataUtil.createImageData(
            this.dataArray,
            this.nx,
            this.ny,
            this.nz,
            this.dataType,
          );
          const dims = this.dataArray.getDimensions();
          this.max_z = dims[2] - 1;
        }
      } else {
        console.error('reader.result is not an ArrayBuffer');
      }
      this.cd.detectChanges();
      this.isBusy = false;
    };
    reader.readAsArrayBuffer(this.selectedFile);
  }

  handleSelectedColormapChange(colormap: any): void {
    this.selectedColormapValue = colormap;
    this.colorMapArray = [];

    this.selectedColormapValue.forEach((item: string, index: number) => {
      const rgbValues = item.match(/\d+/g);

      if (rgbValues && rgbValues.length === 3) {
        const r = parseInt(rgbValues[0], 10);
        const g = parseInt(rgbValues[1], 10);
        const b = parseInt(rgbValues[2], 10);

        this.colorMapArray.push([r, g, b]);
      } else {
        console.log(`Invalid color string at index ${index}: ${item}`);
      }
    });
  }

  handleOpacityArrayChange(opacityArray: any): void {
    this.opacityArray = opacityArray;
  }

  applyColorMap() {
    if (this.imageView) {
      this.imageView.setColorMap(this.colorMapArray);
    }
  }

  applyColorMapOnVolume() {
    if (this.volumeView) {
      this.volumeView.setColorMap(this.colorMapArray);
    }
  }

  applyOpacityOnVolume() {
    this.volumeView.setOpacityFunction(this.opacityArray);
  }
}
