import { Observable } from 'rxjs';

import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PASS_ERROR_HEADER, SGC_API } from '../../core/api.consts';
import { Log } from '../../core/models/log.model';
import { LogFilterRequest } from '../../core/models/log/log-filter-request.model';
import { PaginationResponse } from '../../core/models/pagination-response.model';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private url = `${SGC_API}/Logs`;

  constructor(private httpClient: HttpClient) {}

  getAllBy(logOptions: Partial<LogFilterRequest>): Observable<PaginationResponse<Log>> {
    const params = new HttpParams({
      fromObject: logOptions as unknown as HttpParamsOptions['fromObject'],
    });

    return this.httpClient.get<PaginationResponse<Log>>(this.url, {
      params,
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }
}
