import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from '../../core/api.consts';
import { CalendarModel } from '../../features/calendar/models/calendar.model';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  private url = `${SGC_API}`;
  private calendarUrl = `${this.url}/calendar`;

  constructor(private httpClient: HttpClient) {}

  getCalendarInfo(params: HttpParams): Observable<CalendarModel[]> {
    return this.httpClient.get<CalendarModel[]>(`${this.calendarUrl}`, { params });
  }
}
