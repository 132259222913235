import { DataAccessTableTitle } from './data-access.enum';

export interface FileAttachment {
  id: string;
  name: string;
}

export interface DataAccessModel {
  contractNumber: string;
  barcodeId?: string;
  uwi: string;
  wellName: string;
  upi: string;
  projectName?: string;
  sampleType?: string;
  totalBoxes?: number;
  totalSamples?: number;
  companyName?: string;
  topDepth?: number;
  bottomDepth?: number;
  formation?: string;
  basin?: string;
  receptionStatus?: string;
  verificationStatus?: string;
  validationStatus?: string;
  dateOfService?: string;
  note?: string;
  technician?: string;
  attachedFiles?: FileAttachment;
  state?: string;
  dateAssigned?: string;
  dateCompleted?: string;
  deliveryCreated?: string;
  receptionDate?: string;
  serviceCompletedDate?: string;
  serviceScheduleId?: string;
}

export interface DataAccessServiceModel {
  count: number;
  data: DataAccessModel[];
}

export const DataAccessHeader = [
  DataAccessTableTitle.contractNumber,
  DataAccessTableTitle.barcodeId,
  DataAccessTableTitle.uwi,
  DataAccessTableTitle.wellName,
  DataAccessTableTitle.upi,
  DataAccessTableTitle.projectName,
  DataAccessTableTitle.sampleType,
  DataAccessTableTitle.totalBoxes,
  DataAccessTableTitle.totalSamples,
  DataAccessTableTitle.companyName,
  DataAccessTableTitle.topDepth,
  DataAccessTableTitle.bottomDepth,
  DataAccessTableTitle.formation,
  DataAccessTableTitle.basin,
  DataAccessTableTitle.receptionStatus,
  DataAccessTableTitle.verificationStatus,
  DataAccessTableTitle.validationStatus,
  DataAccessTableTitle.deliveryCreated,
  DataAccessTableTitle.receptionDate,
];

export const ServiceAccessHeader = [
  DataAccessTableTitle.contractNumber,
  DataAccessTableTitle.uwi,
  DataAccessTableTitle.wellName,
  DataAccessTableTitle.upi,
  DataAccessTableTitle.projectName,
  DataAccessTableTitle.sampleType,
  DataAccessTableTitle.totalBoxes,
  DataAccessTableTitle.totalSamples,
  DataAccessTableTitle.companyName,
  DataAccessTableTitle.topDepth,
  DataAccessTableTitle.bottomDepth,
  DataAccessTableTitle.formation,
  DataAccessTableTitle.basin,
  DataAccessTableTitle.dateOfService,
  DataAccessTableTitle.serviceCompletedDate,
  DataAccessTableTitle.note,
  DataAccessTableTitle.technician,
  DataAccessTableTitle.attachedFiles,
];

export const AssignmentAccessHeader = [
  DataAccessTableTitle.contractNumber,
  DataAccessTableTitle.uwi,
  DataAccessTableTitle.wellName,
  DataAccessTableTitle.upi,
  DataAccessTableTitle.projectName,
  DataAccessTableTitle.sampleType,
  DataAccessTableTitle.totalBoxes,
  DataAccessTableTitle.totalSamples,
  DataAccessTableTitle.companyName,
  DataAccessTableTitle.topDepth,
  DataAccessTableTitle.bottomDepth,
  DataAccessTableTitle.formation,
  DataAccessTableTitle.basin,
  DataAccessTableTitle.state,
  DataAccessTableTitle.technician,
  DataAccessTableTitle.dateAssigned,
  DataAccessTableTitle.dateCompleted,
  DataAccessTableTitle.note,
];
