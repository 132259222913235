<div class="header">
  <div class="row input-group-50 mb-3">
    <span class="p-input-icon-left p-float-label">
      <i class="pi pi-search"></i>
      <input
        id="float-input"
        type="text"
        pInputText
        placeholder="{{ 'dataAccess.map.wellName' | translate }}"
        [(ngModel)]="searchText"
        [formControl]="searchInputControl"
      />
      <label for="float-input">{{ 'button.search' | translate }}</label>
    </span>
  </div>
  <div class="actions">
    <button
      pButton
      pRipple
      type="button"
      label="{{ 'button.clear' | translate }}"
      (click)="resetPage()"
      class="btn btn-primary"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      label="{{ 'button.select' | translate }}"
      class="btn btn-secondary"
      [disabled]="!selectedRow"
      (click)="select()"
    ></button>
  </div>
</div>
<ng-container *ngIf="tableHeaders.length">
  <p-table
    [value]="tableData"
    [paginator]="false"
    [rows]="100"
    [showCurrentPageReport]="true"
    currentPageReportTemplate=""
  >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of tableHeaders">{{ col | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
      <ng-container *ngFor="let delivery of row.deliveries; let lastDeliveryIndex = last">
        <tr [class.selected]="selectedRow === row" (click)="onRowSelect(row)">
          <td>{{ delivery.contractNumber }}</td>
          <td>{{ delivery.wellName }}</td>
          <td>{{ delivery.uwi }}</td>
          <td>{{ row.latitude }}</td>
          <td>{{ row.longitude }}</td>
          <td>{{ row.north }}</td>
          <td>{{ row.origin }}</td>
          <td>
            <ng-container *ngFor="let sample of delivery.samples; let lastSampleIndex = last">
              {{ sample.type }}-{{ sample.topDepth }}-{{ sample.bottomDepth }}
              <ng-container *ngIf="!lastSampleIndex">, </ng-container>
            </ng-container>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="100">{{ 'dataAccess.table.emptyData' | translate }}</td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
    (onPageChange)="onPageChanged($event)"
    [rows]="paginatorOptions.pageSize"
    [totalRecords]="paginatorOptions.count"
    [rowsPerPageOptions]="[200, 500, 1000]"
    [dropdownAppendTo]="'body'"
  ></p-paginator>
</ng-container>

<div *ngIf="spinnerShow" class="card spinner">
  <p-progressSpinner></p-progressSpinner>
</div>
