<ng-container [formGroup]="formGroup">
  <div class="row input-group-50">
    <span class="p-input-icon-left p-float-label">
      <i class="pi pi-search"></i>
      <input
        class="search-input"
        id="float-input"
        type="text"
        pInputText
        (ngModelChange)="valueChanged($event)"
        (keyup.enter)="onEnterClicked()"
        formControlName="search"
      />
      <label for="float-input">{{ 'button.search' | translate }} {{ searchValue }}</label>
      <button
        pButton
        type="button"
        class="btn btn-secondary"
        [disabled]="!formGroup.get('search')?.value"
        (click)="search()"
        label="{{ 'button.search' | translate }}"
      ></button>
    </span>
  </div>
</ng-container>
