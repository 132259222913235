import { catchError, EMPTY, Observable, throwError } from 'rxjs';

import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { oktaAuth, PASS_ERROR_HEADER } from '../api.consts';

@Injectable()
export class OktaAuthInterceptor implements HttpInterceptor {
  constructor(public http: HttpClient) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const condition = req.url.includes('token') || req.url.includes('refresh');
    const accessToken: string | undefined = oktaAuth.getAccessToken();
    let authRequest = req.clone();
    let shouldThrowOutside = false;

    if (req.headers.has(PASS_ERROR_HEADER)) {
      shouldThrowOutside = true;
    }

    if (!condition) {
      authRequest = this.addTokenToRequest(authRequest, accessToken);
    }

    return next
      .handle(authRequest)
      .pipe(catchError((err) => (shouldThrowOutside ? throwError(() => err) : EMPTY)));
  }

  private addTokenToRequest(
    request: HttpRequest<any>,
    token: string | undefined,
  ): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}
