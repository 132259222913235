import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

import { VolumeView } from './volumeView';

@Component({
  selector: 'app-volume-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './volume-view.component.html',
  styleUrls: ['./volume-view.component.scss'],
})
export class VolumeViewComponent implements AfterViewInit {
  @Input() inputData?: any;
  @Input() minValue = 0;
  @Input() maxValue = 0;
  @ViewChild('volumeDiv') volumeDiv!: ElementRef;
  cs: VolumeView = new VolumeView();

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    const container = this.volumeDiv.nativeElement;
    // let cs = new ConeView();
    this.cs.Initialize(container, this.inputData, this.minValue, this.maxValue);
    this.cd.detectChanges();
  }

  setColorMap(colorMapArray: any) {
    this.cs.setColorMap(colorMapArray, this.minValue, this.maxValue);
    this.cd.detectChanges();
  }

  setOpacityFunction(opacityArray: any) {
    this.cs.setOpacityFunction(opacityArray, this.minValue, this.maxValue);
    this.cd.detectChanges();
  }
}
