import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { finalize } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IdName } from '../../core/models/id-name.model';
import { TransportationModel } from '../../core/models/transportation.model';
import { Destroyable } from '../../core/utils/mixins/destroyable.mixin';
import { ProviderService } from '../../services/api/provider.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-options-table-transportation',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    FormsModule,
    InputTextModule,
    SharedModule,
    TableModule,
    TranslateModule,
  ],
  templateUrl: './options-table-transportation.component.html',
  styleUrls: ['./options-table-transportation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsTableTransportationComponent extends Destroyable(Object) implements OnInit {
  @Input() title = '';
  entities: (TransportationModel | unknown)[] = [];
  isModalVisible = false;
  isSavingInProgress = false;
  isEdit = false;
  entity: TransportationModel = {
    id: undefined,
    companyName: '',
    driverName: '',
    contactNumber: '',
    licensePlate: '',
    driverDocument: '',
  };

  get modeKey(): string {
    return this.isEdit ? 'adminSettings.titles.actions.edit' : 'adminSettings.titles.actions.new';
  }

  constructor(
    private cd: ChangeDetectorRef,
    private providerService: ProviderService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.initEntities();
  }

  onCreate() {
    this.isEdit = false;
    this.openDialog();
  }

  onEdit(rowData: IdName): void {
    this.isEdit = true;
    this.providerService
      .getTransportationById(rowData.id)
      .subscribe((resp: TransportationModel) => {
        this.entity = resp;
        this.entity.id = rowData.id;
        this.openDialog();
        this.cd.markForCheck();
      });
  }

  onCancelModal(): void {
    this.clearEntityData();
    this.closeDialog();
  }

  onSave(): void {
    this.isSavingInProgress = true;

    if (this.isEdit) {
      this.providerService
        .editTransportation(this.entity)
        .pipe(
          this.takeUntilDestroyed(),
          finalize(() => {
            this.isSavingInProgress = false;
            this.cd.markForCheck();
          }),
        )
        .subscribe(() => {
          this.toastService.toastSuccess(
            `${this.title} ${this.entity.companyName} ${this.translate.instant(
              'adminSettings.messages.hasBeenUpdated',
            )}`,
          );
          this.initEntities();
          this.clearEntityData();
          this.closeDialog();
        });
    } else {
      this.providerService
        .createTransportation(this.entity)
        .pipe(
          this.takeUntilDestroyed(),
          finalize(() => {
            this.isSavingInProgress = false;
            this.cd.markForCheck();
          }),
        )
        .subscribe(() => {
          this.toastService.toastSuccess(
            `${this.title} ${this.entity.companyName} ${this.translate.instant(
              'adminSettings.messages.hasBeenCreated',
            )}`,
          );
          this.initEntities();
          this.clearEntityData();
          this.closeDialog();
        });
    }
  }

  private initEntities() {
    this.providerService
      .getTransportations()
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.entities = data;
        this.cd.markForCheck();
      });
  }

  private clearEntityData(): void {
    this.entity = {
      id: undefined,
      companyName: '',
      driverName: '',
      contactNumber: '',
      licensePlate: '',
      driverDocument: '',
    };
  }

  private openDialog(): void {
    this.isModalVisible = true;
  }

  private closeDialog(): void {
    this.isModalVisible = false;
  }
}
