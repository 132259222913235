import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { DropDownOption } from '../../../../core/models/drop-down-option.model';

@Component({
  selector: 'app-storage-filter-option',
  standalone: true,
  imports: [CommonModule, DropdownModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './storage-filter-option.component.html',
  styleUrls: ['./storage-filter-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageFilterOptionComponent {
  @Input() filterOption!: DropDownOption[];
  @Input() formGroup!: FormGroup;
}
