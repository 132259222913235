export const DEFAULT_DEBOUNCE_TIME = 500;
export const DEFAULT_LOCALE = 'en-US';
export const FOOTER_TEXT = 'footer.poweredByHalliburton';
export const PASSWORD_MIN_LENGTH = 12;
export const PASSWORD_REGEX = /[0-9]/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MAX_ALLOWED_CONTAINER_COUNT_ASSOCIATED_WITH_PALLET = 56;
export const SEPARATOR_BETWEEN_PALLET_LABEL_AND_CONTAINER_COUNT = ' - ';
export const DEFAULT_DATE_FORMAT = {
  datePipe: 'yyyy-MM-dd',
  dateTimePipe: 'yyyy-MM-dd HH:mm:ss',
  primeNgCalendar: 'yy-mm-dd',
};
