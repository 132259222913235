import { map, Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from 'src/app/core/api.consts';
import { PackagingTypeModel } from 'src/app/core/models/packaging-type.model';

import { EntityCacheKey } from '../../core/enums/entity-cache-key.enum';
import { PaginationResponse } from '../../core/models/pagination-response.model';
import { PaginatorOptions } from '../../core/models/paginator-options.model';
import { CacheService } from '../cache.service';

@Injectable({
  providedIn: 'root',
})
export class PackagingTypeService {
  private url = SGC_API;

  private readonly packagingTypeURL = `${this.url}/packagingTypes`;

  constructor(private httpClient: HttpClient, private cacheService: CacheService) {}

  getAllPackagingTypes(): Observable<
    (PackagingTypeModel & { id: NonNullable<PackagingTypeModel['id']> })[]
  > {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.PACKAGING_TYPES,
      this.getPackagingTypes({ page: 1, pageSize: -1 }).pipe(map((resp) => resp.data)),
    );
  }

  getPackagingTypes(
    filterData: Omit<PaginatorOptions, 'count'>,
  ): Observable<
    PaginationResponse<PackagingTypeModel & { id: NonNullable<PackagingTypeModel['id']> }>
  > {
    const parameters = new HttpParams()
      .set('page', filterData.page)
      .set('pageSize', filterData.pageSize);
    return this.httpClient.get<
      PaginationResponse<PackagingTypeModel & { id: NonNullable<PackagingTypeModel['id']> }>
    >(this.packagingTypeURL, {
      params: parameters,
    });
  }

  createPackagingType(packagingType: PackagingTypeModel): Observable<PackagingTypeModel> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PACKAGING_TYPES,
      this.httpClient.post<PackagingTypeModel>(this.packagingTypeURL, packagingType),
    );
  }

  updateCount(packagingType: PackagingTypeModel): Observable<PackagingTypeModel> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PACKAGING_TYPES,
      this.httpClient.put<PackagingTypeModel>(
        `${this.packagingTypeURL}/${packagingType.id}`,
        packagingType,
      ),
    );
  }

  deletePackagingType(id: string): Observable<PackagingTypeModel> {
    return this.httpClient.delete<PackagingTypeModel>(`${this.packagingTypeURL}/${id}`);
  }
}
