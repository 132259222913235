<span class="p-float-label filter-dropdown" [formGroup]="formGroup">
  <p-dropdown
    id="search-type-dropdown"
    formControlName="filter"
    [options]="filterOption"
    placeholder=""
    optionValue="id"
    optionLabel="name"
    [styleClass]="'w-full'"
  ></p-dropdown>
  <label for="search-type-dropdown">{{ 'storage.forms.searchBy' | translate }}</label>
</span>
