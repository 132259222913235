import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Operator, ServiceProvider } from 'src/app/features/reception/models/reception.model';

import { SGC_API } from '../../core/api.consts';
import { EntityCacheKey } from '../../core/enums/entity-cache-key.enum';
import { DropDownOption } from '../../core/models/drop-down-option.model';
import { ProviderModel, ProviderType } from '../../core/models/provider.model';
import {
  ShortTransportationModel,
  TransportationModel,
} from '../../core/models/transportation.model';
import { CacheService } from '../cache.service';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  private url = `${SGC_API}/Providers`;

  private getTransportationProvidersUrl = `${this.url}/transportationProviders`;
  private getOperatorsUrl = `${this.url}/operators`;
  private getServiceProvidersUrl = `${this.url}/serviceProviders`;

  private getTransportationProviderByIdUrl = `${this.url}/transportationProvider`;
  private getOperatorByIdUrl = `${this.url}/operator`;
  private getServiceProviderByIdUrl = `${this.url}/serviceProvider`;

  private getOperatorsCompanyNameUrl = `${this.url}/operatorsCompanyName`;
  private getServiceProvidersCompanyNameUrl = `${this.url}/serviceProvidersCompanyName`;

  private createTransportationProviderUrl = `${this.url}/createTransportationProvider`;
  private createOperatorUrl = `${this.url}/createOperator`;
  private createServiceProviderUrl = `${this.url}/createServiceProvider`;

  private editTransportationProviderUrl = `${this.url}/editTransportationProvider`;
  private editOperatorUrl = `${this.url}/editOperator`;
  private editServiceProviderUrl = `${this.url}/editServiceProvider`;

  constructor(private httpClient: HttpClient, private cacheService: CacheService) {}

  getTransportations(): Observable<ShortTransportationModel[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.TRANSPORTATIONS,
      this.httpClient.get<ShortTransportationModel[]>(this.getTransportationProvidersUrl),
    );
  }

  getProviders(type: ProviderType): Observable<Operator[] | ServiceProvider[]> {
    return type === ProviderType.operator ? this.getOperators() : this.getServiceProviders();
  }

  getTransportationById(id: string): Observable<TransportationModel> {
    return this.httpClient.get<TransportationModel>(
      `${this.getTransportationProviderByIdUrl}/${id}`,
    );
  }

  getProviderById(id: string, type: ProviderType): Observable<ProviderModel> {
    return type === ProviderType.operator
      ? this.getOperatorById(id)
      : this.getServiceProviderById(id);
  }

  createTransportation(data: TransportationModel): Observable<TransportationModel> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.TRANSPORTATIONS,
      this.httpClient.post<TransportationModel>(this.createTransportationProviderUrl, data),
    );
  }

  createProvider(provider: ProviderModel, type: ProviderType): Observable<ProviderModel> {
    return type === ProviderType.operator
      ? this.createOperator(provider)
      : this.createServiceProvider(provider);
  }

  editTransportation(data: TransportationModel): Observable<TransportationModel> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.TRANSPORTATIONS,
      this.httpClient.put<TransportationModel>(this.editTransportationProviderUrl, data),
    );
  }

  editProvider(data: ProviderModel, type: ProviderType): Observable<ProviderModel> {
    return type === ProviderType.operator
      ? this.editOperator(data)
      : this.editServiceProvider(data);
  }

  getOperatorsCompanyNames(): Observable<DropDownOption[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.OPERATOR_COMPANY_NAMES,
      this.httpClient.get<DropDownOption[]>(this.getOperatorsCompanyNameUrl),
    );
  }

  getServiceProvidersCompanyNames(): Observable<DropDownOption[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.SERVICE_PROVIDER_COMPANY_NAMES,
      this.httpClient.get<DropDownOption[]>(this.getServiceProvidersCompanyNameUrl),
    );
  }

  private editOperator(operator: ProviderModel): Observable<ProviderModel> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.OPERATORS,
      this.httpClient.put<ProviderModel>(this.editOperatorUrl, operator),
    );
  }

  private editServiceProvider(serviceProvider: ProviderModel): Observable<ProviderModel> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.SERVICE_PROVIDERS,
      this.httpClient.put<ProviderModel>(this.editServiceProviderUrl, serviceProvider),
    );
  }

  private createOperator(operator: ProviderModel): Observable<ProviderModel> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.OPERATORS,
      this.httpClient.post<ProviderModel>(`${this.createOperatorUrl}`, operator),
    );
  }

  private createServiceProvider(serviceProvider: ProviderModel): Observable<ProviderModel> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.SERVICE_PROVIDERS,
      this.httpClient.post<ProviderModel>(`${this.createServiceProviderUrl}`, serviceProvider),
    );
  }

  private getOperators(): Observable<Operator[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.OPERATORS,
      this.httpClient.get<Operator[]>(this.getOperatorsUrl),
    );
  }

  private getServiceProviders(): Observable<ServiceProvider[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.SERVICE_PROVIDERS,
      this.httpClient.get<ServiceProvider[]>(this.getServiceProvidersUrl),
    );
  }

  private getOperatorById(id: string): Observable<ProviderModel> {
    return this.httpClient.get<ProviderModel>(`${this.getOperatorByIdUrl}/${id}`);
  }

  private getServiceProviderById(id: string): Observable<ProviderModel> {
    return this.httpClient.get<ProviderModel>(`${this.getServiceProviderByIdUrl}/${id}`);
  }
}
