<div class="left-panel" [style.background-image]="backgroundImage">
  <h1 class="left-h1">{{ appName }}</h1>
  <h2 class="left-h2">{{ appDescription }}</h2>
</div>
<div class="right-panel">
  <div class="flex justify-content-center">
    <img alt="logo" [src]="fullLogo" class="logo" />
    <div class="mt-3">
      <app-translation></app-translation>
    </div>
  </div>

  <div class="login-form">
    <h3 class="right-h3">{{ pageName }}</h3>
    <h4 class="right-h4">{{ 'welcomePage.welcomeTo' | translate }} {{ appName }}</h4>
    <h5 class="right-h5">{{ appSlogan }}</h5>

    <form>
      <ng-container *ngIf="!isOktaAuthUsed">
        <div class="field">
          <input
            type="text"
            [formControl]="emailAddress"
            id="email-input"
            placeholder="{{ 'welcomePage.email' | translate }}"
            pInputText
          />
          <small
            *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched)"
            id="email-input-help"
            class="p-error block"
            >{{ getEmailAddressErrorMessage() | translate }}</small
          >
        </div>

        <div class="field mb-1" *ngIf="showPasswordField">
          <span class="p-input-icon-right">
            <i
              [className]="hidePassword ? 'pi pi-eye-slash' : 'pi pi-eye'"
              (click)="hidePassword = !hidePassword"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hidePassword"
            ></i>
            <input
              [formControl]="password"
              [type]="hidePassword ? 'password' : 'text'"
              id="password-input"
              placeholder="{{ 'welcomePage.password' | translate }}"
              pInputText
            />
          </span>
          <div class="hint-container">
            <span class="hint">{{ passwordLength }}</span>
            <small
              *ngIf="password.invalid && (password.dirty || password.touched)"
              id="password-input-help"
              class="p-error block"
              >{{ getPasswordErrorMessage() | translate }}</small
            >
          </div>
        </div>

        <div class="p-field-checkbox" *ngIf="showRememberMeCheckbox">
          <p-checkbox [formControl]="rememberMe" [binary]="true" id="rememberMe"></p-checkbox>
          <label for="rememberMe"> {{ 'welcomePage.rememberMe' | translate }}</label>
        </div>
      </ng-container>

      <button
        pButton
        pRipple
        class="w-full mt-3 p-button-secondary"
        id="login-btn"
        type="button"
        [label]="buttonLabel"
        (click)="onButtonClick()"
        [disabled]="
          !isOktaAuthUsed && (emailAddress.invalid || (showPasswordField && password.invalid))
        "
      ></button>

      <div class="actions">
        <ng-content></ng-content>
      </div>
    </form>
    <div *ngIf="!showLoginForm">
      <h2>{{ 'welcomePage.error.licenseExpired' | translate }}</h2>
      <p>{{ 'welcomePage.error.contactAdmin' | translate }}</p>
    </div>
  </div>
  <span class="flex-auto"></span>
  <footer class="footer">{{ footerText | translate }}</footer>
</div>
