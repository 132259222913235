import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { Paginator, PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { catchError, EMPTY, finalize, Subject, switchMap, tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DefaultCalendarDateFormatDirective } from '../../../common/directives/default-calendar-date-format.directive';
import { DefaultDateFormatPipe } from '../../../common/pipes/default-date-format.pipe';
import { IdName } from '../../../core/models/id-name.model';
import { Log } from '../../../core/models/log.model';
import { LogFilter } from '../../../core/models/log/log-filter.model';
import { LogFilterRequest } from '../../../core/models/log/log-filter-request.model';
import { PageEvent } from '../../../core/models/page-event.model';
import { PaginatorOptions } from '../../../core/models/paginator-options.model';
import { isKey } from '../../../core/utils/is-key.util';
import { Destroyable } from '../../../core/utils/mixins/destroyable.mixin';
import { LogService } from '../../../services/api/log.service';
import { DateValidationService } from '../../../services/date-validation.service';
import { logServiceDisplayName } from '../consts/log-service-display-name.const';
import { logTypeDisplayName } from '../consts/log-type-display-name.const';
import { LogServiceEnum } from '../enums/log-service.enum';
import { LogTypeEnum } from '../enums/log-type.enum';
import { LogServiceDisplayPipe } from '../pipes/log-service-display.pipe';
import { LogTypeDisplayPipe } from '../pipes/log-type-display.pipe';

@Component({
  selector: 'app-logs',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    ConfirmPopupModule,
    SharedModule,
    TableModule,
    TranslateModule,
    DefaultDateFormatPipe,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    CalendarModule,
    DefaultCalendarDateFormatDirective,
    DialogModule,
    RippleModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    PaginatorModule,
    LogTypeDisplayPipe,
    LogServiceDisplayPipe,
  ],
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsComponent extends Destroyable(Object) implements OnInit {
  @ViewChild(Paginator) paginator!: Paginator;

  readonly tableHeaderKeysPrefix = 'adminSettings.logs.tableHeaders.';
  readonly initialFilterValue: LogFilter = {
    startDate: null,
    endDate: null,
    types: [],
    services: [],
  };

  searchMessageControl = new FormControl<string>('');
  isFilterLogsPopupVisible = false;
  isLogsLoading = false;
  filterFormGroup = new FormGroup({
    startDate: new FormControl<Date | null>(this.initialFilterValue.startDate),
    endDate: new FormControl<Date | null>(this.initialFilterValue.endDate),
    types: new FormControl(this.initialFilterValue.types, { nonNullable: true }),
    services: new FormControl(this.initialFilterValue.services, { nonNullable: true }),
  });

  logs!: Log[];
  logTypes!: IdName[];
  logServices!: IdName[];

  paginatorOptions: PaginatorOptions = {
    page: 0,
    pageSize: 10,
    count: 0,
  };

  isStartDateGreaterThanEndDate = false;

  private logsLoader = new Subject<void>();

  constructor(
    private logService: LogService,
    private cd: ChangeDetectorRef,
    private dateValidationService: DateValidationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupLogsLoader();
    this.loadLogs();
    this.generateOptions();
    this.setupDateValidation();
  }

  onPageChanged(pageEvent: PageEvent): void {
    this.paginatorOptions = {
      ...this.paginatorOptions,
      page: pageEvent.page,
      pageSize: pageEvent.rows,
    };

    this.loadLogs();
  }

  onEnterPressed(): void {
    this.changePageToFirstSilently();
    this.loadLogs();
  }

  onSearchBtnClicked(): void {
    this.changePageToFirstSilently();
    this.loadLogs();
  }

  onFilterBtnClicked(): void {
    this.openFilterPopup();
  }

  onApplyBtnClicked(): void {
    this.closeFilterPopup();
    this.changePageToFirstSilently();
    this.loadLogs();
    this.dateValidationService.validateDate(
      this.filterFormGroup.getRawValue().startDate!,
      this.filterFormGroup.getRawValue().endDate!,
    );
  }

  onClearBtnClicked(): void {
    this.filterFormGroup.reset(this.initialFilterValue);
  }

  private prepareLogFilterRequest(): Partial<LogFilterRequest> {
    const { startDate, endDate, ...restFilter } = this.filterFormGroup.getRawValue();
    const message = this.searchMessageControl.getRawValue() as string;

    const logOptions: LogFilterRequest = {
      startDate: startDate?.toISOString() ?? null,
      endDate: endDate
        ? new Date(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate() + 1,
            0,
            0,
            0,
          ).toISOString()
        : null,
      message,
      page: this.paginatorOptions.page + 1,
      pageSize: this.paginatorOptions.pageSize,
      ...restFilter,
    };

    const logOptionsWithValues = Object.keys(logOptions).reduce<Partial<LogFilterRequest>>(
      (acc, key) => {
        if (isKey(logOptions, key) && logOptions[key]) {
          return { ...acc, [key]: logOptions[key] };
        }

        return { ...acc };
      },
      {},
    );

    return logOptionsWithValues;
  }

  private setupLogsLoader(): void {
    this.logsLoader
      .asObservable()
      .pipe(
        tap(() => {
          this.isLogsLoading = true;
        }),
        switchMap(() =>
          this.logService.getAllBy(this.prepareLogFilterRequest()).pipe(
            catchError(() => EMPTY),
            finalize(() => {
              this.isLogsLoading = false;
              this.cd.markForCheck();
            }),
          ),
        ),
        this.takeUntilDestroyed(),
      )
      .subscribe((resp) => {
        this.logs = resp.data;
        this.paginatorOptions.count = resp.count;
      });
  }

  private loadLogs(): void {
    this.logsLoader.next();
  }

  private generateOptions(): void {
    this.logTypes = Object.values(LogTypeEnum).map((logType) => {
      return { id: logType, name: logTypeDisplayName[logType] };
    });
    this.logServices = Object.values(LogServiceEnum).map((logService) => {
      return { id: logService, name: logServiceDisplayName[logService] };
    });
  }

  private openFilterPopup(): void {
    this.isFilterLogsPopupVisible = true;
  }

  private closeFilterPopup(): void {
    this.isFilterLogsPopupVisible = false;
  }

  private changePageSilently(pageIndex: number): void {
    const pageCount = this.paginator.getPageCount();

    if (pageIndex >= 0 && pageIndex < pageCount) {
      this.paginator._first = this.paginator.rows * pageIndex;
      this.paginator.updatePageLinks();
      this.paginator.updatePaginatorState();

      this.paginatorOptions = {
        ...this.paginatorOptions,
        page: pageIndex,
        pageSize: this.paginator.rows,
      };

      this.paginator['cd'].markForCheck();
    }
  }

  private changePageToFirstSilently(): void {
    if (!this.paginator.isFirstPage()) {
      this.changePageSilently(0);
    }
  }

  private setupDateValidation(): void {
    this.filterFormGroup.controls['startDate'].valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((date) => {
        if (date && this.filterFormGroup.controls['endDate'].value) {
          const startDate = new Date(date);
          const endDate = new Date(this.filterFormGroup.controls['endDate'].value);
          this.isStartDateGreaterThanEndDate = this.dateValidationService.isEndDateBeforeStartDate(
            startDate,
            endDate,
          );
        } else {
          this.isStartDateGreaterThanEndDate = false;
        }
      });

    this.filterFormGroup.controls['endDate'].valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((date) => {
        if (this.filterFormGroup.controls['startDate'].value && date) {
          const startDate = new Date(this.filterFormGroup.controls['startDate'].value);
          const endDate = new Date(date);
          this.isStartDateGreaterThanEndDate = this.dateValidationService.isEndDateBeforeStartDate(
            startDate,
            endDate,
          );
        } else {
          this.isStartDateGreaterThanEndDate = false;
        }
      });
  }
}
