import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IS_FILE_SERVICE_HEADER, SGC_API } from '../../core/api.consts';
import { FileUploadedResponse } from '../../features/service/models/upload-service.model';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private url = `/fileservice${SGC_API}/Files`;
  constructor(private httpClient: HttpClient) {}

  upload(file: File): Observable<FileUploadedResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', file.type);

    return this.httpClient.post<any>(`${this.url}`, formData, {
      headers: { [IS_FILE_SERVICE_HEADER]: 'true' },
    });
  }

  deleteFile(id: string): Observable<any> {
    return this.httpClient.delete<null>(`${this.url}/${id}`, {
      headers: { [IS_FILE_SERVICE_HEADER]: 'true' },
    });
  }

  download(fileId: string): Observable<ArrayBuffer> {
    return this.httpClient.get(`${this.url}/${fileId}`, {
      responseType: 'arraybuffer',
      headers: { [IS_FILE_SERVICE_HEADER]: 'true' },
    });
  }
}
