<div class="content">
  <p-tabView>
    <p-tabPanel header="{{ 'visualization.chart' | translate }}">
      <app-plot></app-plot>
    </p-tabPanel>
    <p-tabPanel header="{{ 'visualization.render' | translate }}">
      <app-render3-ddata></app-render3-ddata>
    </p-tabPanel>
    <p-tabPanel header="{{ 'visualization.image' | translate }}">
      <app-picture-view></app-picture-view>
    </p-tabPanel>
    <p-tabPanel header="{{ 'visualization.video' | translate }}">
      <app-video-player></app-video-player>
    </p-tabPanel>
  </p-tabView>
</div>
