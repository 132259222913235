<ng-container [ngSwitch]="activeIndex" [formGroup]="sampleManagementHeaderFormGroup">
  <div *ngSwitchCase="0" class="line-with-controls half-width" formGroupName="reception">
    <span class="p-float-label">
      <input
        type="text"
        id="sample-management-reception-upi-control"
        pInputText
        formControlName="upi"
      />
      <label for="sample-management-reception-upi-control">{{
        'sampleManagement.receptionPage.upi' | translate
      }}</label>
    </span>
  </div>

  <div *ngSwitchCase="2" class="line-with-controls half-width" formGroupName="validation">
    <span class="p-float-label">
      <input
        type="text"
        id="sample-management-validation-well-or-project-validation-control"
        pInputText
        formControlName="wellOrProjectValidation"
      />
      <label for="sample-management-validation-well-or-project-validation-control">{{
        'sampleManagement.validationPage.wellOrProjectValidation' | translate
      }}</label>
    </span>
  </div>

  <div *ngSwitchCase="4" class="line-with-controls few-columns" formGroupName="receipt">
    <span class="p-float-label">
      <input
        type="text"
        id="sample-management-receipt-generate-receipt-well-or-project-per-sample-type-control"
        pInputText
        formControlName="generateReceiptWellOrProjectPerSampleType"
      />
      <label
        for="sample-management-receipt-generate-receipt-well-or-project-per-sample-type-control"
        >{{
          'sampleManagement.receiptPage.generateReceiptWellOrProjectPerSampleType' | translate
        }}</label
      >
    </span>

    <div class="few-columns">
      <span class="title">{{ 'sampleManagement.receiptPage.receiptCode' | translate }}</span>

      <span class="p-float-label p-input-icon-right">
        <input
          type="text"
          id="sample-management-receipt-receipt-code-control"
          pInputText
          formControlName="receiptCode"
        />
        <label for="sample-management-receipt-receipt-code-control">{{
          'sampleManagement.receiptPage.receiptCode' | translate
        }}</label>
        <i class="fa-solid fa-barcode"></i>
      </span>
    </div>
  </div>

  <div *ngSwitchDefault></div>
</ng-container>

<hr class="line-separator" />

<div class="sample-management-stepper half-width">
  <p-steps
    [model]="sampleManagementStepperItems"
    [(activeIndex)]="activeIndex"
    [readonly]="false"
  ></p-steps>
</div>

<ng-container [ngSwitch]="activeIndex" [formGroup]="sampleManagementBodyFormGroup">
  <div *ngSwitchCase="0" formGroupName="reception">
    <div formGroupName="operator">
      <h1 class="title">{{ 'sampleManagement.receptionPage.operator' | translate }}</h1>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-operator-name-control"
            pInputText
            formControlName="name"
          />
          <label for="sample-management-reception-operator-name-control">{{
            'sampleManagement.receptionPage.name' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-operator-geologist-name-control"
            pInputText
            formControlName="geologistName"
          />
          <label for="sample-management-reception-operator-geologist-name-control">{{
            'sampleManagement.receptionPage.nameOfTheGeologist' | translate
          }}</label>
        </span>
      </div>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-operator-contract-number-control"
            pInputText
            formControlName="contractNumber"
          />
          <label for="sample-management-reception-operator-contract-number-control">{{
            'sampleManagement.receptionPage.contractNumber' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-operator-email-control"
            pInputText
            formControlName="email"
          />
          <label for="sample-management-reception-operator-email-control">{{
            'sampleManagement.receptionPage.email' | translate
          }}</label>
        </span>
      </div>
    </div>

    <div formGroupName="serviceCompany">
      <h1 class="title">{{ 'sampleManagement.receptionPage.serviceCompany' | translate }}</h1>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-service-company-operator-name-control"
            pInputText
            formControlName="operatorName"
          />
          <label for="sample-management-reception-service-company-operator-name-control">{{
            'sampleManagement.receptionPage.operatorName' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-service-company-geologist-name-control"
            pInputText
            formControlName="geologistName"
          />
          <label for="sample-management-reception-service-company-geologist-name-control">{{
            'sampleManagement.receptionPage.nameOfTheGeologist' | translate
          }}</label>
        </span>
      </div>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-service-company-contract-number-control"
            pInputText
            formControlName="contractNumber"
          />
          <label for="sample-management-reception-service-company-contract-number-control">{{
            'sampleManagement.receptionPage.contractNumber' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-service-company-email-control"
            pInputText
            formControlName="email"
          />
          <label for="sample-management-reception-service-company-email-control">{{
            'sampleManagement.receptionPage.email' | translate
          }}</label>
        </span>
      </div>
    </div>

    <div formGroupName="contract">
      <h1 class="title">{{ 'sampleManagement.receptionPage.contract' | translate }}</h1>

      <div class="line-with-controls half-width">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-contract-contract-number-control"
            pInputText
            formControlName="contractNumber"
          />
          <label for="sample-management-reception-contract-contract-number-control">{{
            'sampleManagement.receptionPage.contractNumber' | translate
          }}</label>
        </span>
      </div>
    </div>

    <div formGroupName="wellInformation">
      <h1 class="title">{{ 'sampleManagement.receptionPage.wellInformation' | translate }}</h1>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-well-information-well-name-control"
            pInputText
            formControlName="wellName"
          />
          <label for="sample-management-reception-well-information-well-name-control">{{
            'sampleManagement.receptionPage.wellName' | translate
          }}</label>
        </span>

        <div class="few-columns">
          <span class="p-float-label">
            <input
              type="text"
              id="sample-management-reception-well-information-longitude-control"
              pInputText
              formControlName="longitude"
            />
            <label for="sample-management-reception-well-information-longitude-control">{{
              'sampleManagement.receptionPage.longitude' | translate
            }}</label>
          </span>

          <span class="p-float-label">
            <input
              type="text"
              id="sample-management-reception-well-information-latitude-control"
              pInputText
              formControlName="latitude"
            />
            <label for="sample-management-reception-well-information-latitude-control">{{
              'sampleManagement.receptionPage.latitude' | translate
            }}</label>
          </span>
        </div>
      </div>
    </div>

    <div class="sample-management-reception-create-btn">
      <button
        pButton
        type="button"
        class="btn btn-secondary"
        [label]="'button.create' | translate"
      ></button>
    </div>

    <div formGroupName="sampleInformation">
      <h1 class="title">{{ 'sampleManagement.receptionPage.sampleInformation' | translate }}</h1>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <p-dropdown
            id="sample-management-reception-sample-information-sample-type-control"
            formControlName="sampleType"
            [options]="[
              { name: 'option1', id: '1' },
              { name: 'option2', id: '2' },
              { name: 'option3', id: '3' }
            ]"
            placeholder="Sample type"
            optionLabel="name"
            [showClear]="true"
          ></p-dropdown>
          <label for="sample-management-reception-sample-information-sample-type-control">{{
            'sampleManagement.receptionPage.sampleType' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-sample-information-set-control"
            pInputText
            formControlName="set"
          />
          <label for="sample-management-reception-sample-information-set-control">{{
            'sampleManagement.receptionPage.set' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-sample-information-box-control"
            pInputText
            formControlName="box"
          />
          <label for="sample-management-reception-sample-information-box-control">{{
            'sampleManagement.receptionPage.box' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-sample-information-top-control"
            pInputText
            formControlName="top"
          />
          <label for="sample-management-reception-sample-information-top-control">{{
            'sampleManagement.receptionPage.top' | translate
          }}</label>
        </span>
      </div>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-sample-information-base-control"
            pInputText
            formControlName="base"
          />
          <label for="sample-management-reception-sample-information-base-control">{{
            'sampleManagement.receptionPage.base' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-sample-information-interval-control"
            pInputText
            formControlName="interval"
          />
          <label for="sample-management-reception-sample-information-interval-control">{{
            'sampleManagement.receptionPage.interval' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-sample-information-quantity-control"
            pInputText
            formControlName="quantity"
          />
          <label for="sample-management-reception-sample-information-quantity-control">{{
            'sampleManagement.receptionPage.quantity' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <p-dropdown
            id="sample-management-reception-sample-information-packing-type-control"
            formControlName="packingType"
            [options]="[
              { name: 'option1', id: '1' },
              { name: 'option2', id: '2' },
              { name: 'option3', id: '3' }
            ]"
            [placeholder]="'sampleManagement.receptionPage.typeOfPacking' | translate"
            optionLabel="name"
            [showClear]="true"
          ></p-dropdown>
          <label for="sample-management-reception-sample-information-packing-type-control">{{
            'sampleManagement.receptionPage.typeOfPacking' | translate
          }}</label>
        </span>
      </div>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-sample-information-total-boxes-control"
            pInputText
            formControlName="totalBoxes"
          />
          <label for="sample-management-reception-sample-information-total-boxes-control">{{
            'sampleManagement.receptionPage.totalBoxes' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-sample-information-total-samples-control"
            pInputText
            formControlName="totalSamples"
          />
          <label for="sample-management-reception-sample-information-total-samples-control">{{
            'sampleManagement.receptionPage.totalSamples' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <p-dropdown
            id="sample-management-reception-sample-information-formation-control"
            formControlName="formation"
            [options]="[
              { name: 'option1', id: '1' },
              { name: 'option2', id: '2' },
              { name: 'option3', id: '3' }
            ]"
            [placeholder]="'sampleManagement.receptionPage.formation' | translate"
            optionLabel="name"
            [showClear]="true"
          ></p-dropdown>
          <label for="sample-management-reception-sample-information-formation-control">{{
            'sampleManagement.receptionPage.formation' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <p-dropdown
            id="sample-management-reception-sample-information-diameter-control"
            formControlName="diameter"
            [options]="[
              { name: 'option1', id: '1' },
              { name: 'option2', id: '2' },
              { name: 'option3', id: '3' }
            ]"
            [placeholder]="'sampleManagement.receptionPage.diameter' | translate"
            optionLabel="name"
            [showClear]="true"
          ></p-dropdown>
          <label for="sample-management-reception-sample-information-diameter-control">{{
            'sampleManagement.receptionPage.diameter' | translate
          }}</label>
        </span>
      </div>

      <div class="line-with-controls half-width">
        <div class="half-width">
          <span class="p-float-label">
            <input
              type="text"
              id="sample-management-reception-sample-information-percentage-recovered-control"
              pInputText
              formControlName="percentageRecovered"
            />
            <label
              for="sample-management-reception-sample-information-percentage-recovered-control"
              >{{ 'sampleManagement.receptionPage.percentageRecovered' | translate }}</label
            >
          </span>
        </div>
      </div>
    </div>

    <div formGroupName="transportCompany">
      <h1 class="title">{{ 'sampleManagement.receptionPage.transportCompany' | translate }}</h1>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-transport-company-company-name-control"
            pInputText
            formControlName="companyName"
          />
          <label for="sample-management-reception-transport-company-company-name-control">{{
            'sampleManagement.receptionPage.companyName' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-transport-company-driver-name-control"
            pInputText
            formControlName="driverName"
          />
          <label for="sample-management-reception-transport-company-driver-name-control">{{
            'sampleManagement.receptionPage.driversName' | translate
          }}</label>
        </span>
      </div>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-transport-company-vehicle-license-plate-control"
            pInputText
            formControlName="vehicleLicensePlate"
          />
          <label
            for="sample-management-reception-transport-company-vehicle-license-plate-control"
            >{{ 'sampleManagement.receptionPage.vehicleLicensePlate' | translate }}</label
          >
        </span>
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-transport-company-contract-number-control"
            pInputText
            formControlName="contractNumber"
          />
          <label for="sample-management-reception-transport-company-contract-number-control">{{
            'sampleManagement.receptionPage.contractNumber' | translate
          }}</label>
        </span>
      </div>
    </div>

    <div formGroupName="packaging">
      <h1 class="title">{{ 'sampleManagement.receptionPage.packaging' | translate }}</h1>

      <div class="line-with-controls half-width">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-reception-packaging-packaging-type-control"
            pInputText
            formControlName="packagingType"
          />
          <label for="sample-management-reception-packaging-packaging-type-control">{{
            'sampleManagement.receptionPage.typeOfPackaging' | translate
          }}</label>
        </span>
      </div>
    </div>

    <div class="sample-management-reception-action-btns">
      <button
        pButton
        type="button"
        class="btn btn-primary"
        [label]="'button.cancel' | translate"
      ></button>

      <button
        pButton
        type="button"
        class="btn btn-secondary"
        [label]="'button.save' | translate"
      ></button>
    </div>
  </div>

  <div *ngSwitchCase="2" formGroupName="validation">
    <div class="line-with-controls half-width">
      <div class="few-columns">
        <span class="p-float-label">
          <p-dropdown
            id="sample-management-validation-well-control"
            formControlName="well"
            [options]="[
              { name: 'option1', id: '1' },
              { name: 'option2', id: '2' },
              { name: 'option3', id: '3' }
            ]"
            placeholder="well"
            optionLabel="name"
            [showClear]="true"
          ></p-dropdown>
          <label for="sample-management-validation-well-control">{{
            'sampleManagement.validationPage.well' | translate
          }}</label>
        </span>

        <span class="p-float-label p-input-icon-right">
          <input
            type="text"
            id="sample-management-validation-id-control"
            pInputText
            formControlName="id"
          />
          <label for="sample-management-validation-id-control">{{
            'sampleManagement.validationPage.id' | translate
          }}</label>
          <i class="fa-solid fa-magnifying-glass"></i>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-validation-name-control"
            pInputText
            formControlName="name"
          />
          <label for="sample-management-validation-name-control">{{
            'sampleManagement.validationPage.name' | translate
          }}</label>
        </span>
      </div>
    </div>

    <div formGroupName="technicianAssignment">
      <h1 class="title">{{ 'sampleManagement.validationPage.technicianAssigment' | translate }}</h1>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-validation-technician-assignment-well-technician-control"
            pInputText
            formControlName="wellTechnician"
          />
          <label for="sample-management-validation-technician-assignment-well-technician-control">{{
            'sampleManagement.validationPage.wellTechnician' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-validation-technician-assignment-well-technician-technician-name-control"
            pInputText
            formControlName="wellTechnicianTechnicianName"
          />
          <label
            for="sample-management-validation-technician-assignment-well-technician-technician-name-control"
            >{{ 'sampleManagement.validationPage.technicianName' | translate }}</label
          >
        </span>
      </div>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-validation-technician-assignment-well-verification-control"
            pInputText
            formControlName="wellVerification"
          />
          <label
            for="sample-management-validation-technician-assignment-well-verification-control"
            >{{ 'sampleManagement.validationPage.wellVerification' | translate }}</label
          >
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-validation-technician-assignment-well-verification-technician-name-control"
            pInputText
            formControlName="wellVerificationTechnicianName"
          />
          <label
            for="sample-management-validation-technician-assignment-well-verification-technician-name-control"
            >{{ 'sampleManagement.validationPage.technicianName' | translate }}</label
          >
        </span>
      </div>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-validation-technician-assignment-well-validation-control"
            pInputText
            formControlName="wellValidation"
          />
          <label for="sample-management-validation-technician-assignment-well-validation-control">{{
            'sampleManagement.validationPage.wellValidation' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-validation-technician-assignment-well-validation-technician-name-control"
            pInputText
            formControlName="wellValidationTechnicianName"
          />
          <label
            for="sample-management-validation-technician-assignment-well-validation-technician-name-control"
            >{{ 'sampleManagement.validationPage.technicianName' | translate }}</label
          >
        </span>
      </div>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-validation-technician-assignment-storage-control"
            pInputText
            formControlName="storage"
          />
          <label for="sample-management-validation-technician-assignment-storage-control">{{
            'sampleManagement.validationPage.storage' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-validation-technician-assignment-storage-technician-name-control"
            pInputText
            formControlName="storageTechnicianName"
          />
          <label
            for="sample-management-validation-technician-assignment-storage-technician-name-control"
            >{{ 'sampleManagement.validationPage.technicianName' | translate }}</label
          >
        </span>
      </div>

      <div class="line-with-controls few-columns">
        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-validation-technician-assignment-receipt-control"
            pInputText
            formControlName="receipt"
          />
          <label for="sample-management-validation-technician-assignment-receipt-control">{{
            'sampleManagement.validationPage.receipt' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-validation-technician-assignment-receipt-technician-name-control"
            pInputText
            formControlName="receiptTechnicianName"
          />
          <label
            for="sample-management-validation-technician-assignment-receipt-technician-name-control"
            >{{ 'sampleManagement.validationPage.technicianName' | translate }}</label
          >
        </span>
      </div>
    </div>

    <div>
      <h1 class="title text-center">{{ 'sampleManagement.validationPage.details' | translate }}</h1>

      <div class="sample-management-validation-details-table-container">
        <p-table [value]="sampleManagementValidationTableItems">
          <ng-template pTemplate="header">
            <tr>
              <th>{{ 'sampleManagement.validationPage.detailsTable.sampleType' | translate }}</th>
              <th>
                {{ 'sampleManagement.validationPage.detailsTable.numberOfBoxes' | translate }}
              </th>
              <th>
                {{ 'sampleManagement.validationPage.detailsTable.numberOfSamples' | translate }}
              </th>
              <th>{{ 'sampleManagement.validationPage.detailsTable.verifiedBy' | translate }}</th>
              <th>{{ 'sampleManagement.validationPage.detailsTable.date' | translate }}</th>
              <th>
                {{ 'sampleManagement.validationPage.detailsTable.typePackaging' | translate }}
              </th>
              <th>{{ 'sampleManagement.validationPage.detailsTable.approve' | translate }}</th>
              <th>{{ 'sampleManagement.validationPage.detailsTable.receipt' | translate }}</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-item>
            <tr>
              <td>{{ item.sampleType }}</td>
              <td>{{ item.numberOfBoxes }}</td>
              <td>{{ item.numberOfSamples }}</td>
              <td>{{ item.verifiedBy }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.typePackaging }}</td>
              <td>{{ item.approve }}</td>
              <td>{{ item.receipt }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="sample-management-validation-action-btns">
      <button
        pButton
        type="button"
        class="btn btn-primary"
        [label]="'button.cancel' | translate"
      ></button>

      <button
        pButton
        type="button"
        class="btn btn-secondary"
        [label]="'button.returnToVerification' | translate"
      ></button>

      <button
        pButton
        type="button"
        class="btn btn-secondary"
        [label]="'button.save' | translate"
      ></button>
    </div>
  </div>

  <div *ngSwitchCase="4" formGroupName="receipt">
    <div class="line-with-controls half-width">
      <div class="few-columns">
        <span class="p-float-label">
          <p-dropdown
            id="sample-management-receipt-well-control"
            formControlName="well"
            [options]="[
              { name: 'option1', id: '1' },
              { name: 'option2', id: '2' },
              { name: 'option3', id: '3' }
            ]"
            placeholder="well"
            optionLabel="name"
            [showClear]="true"
          ></p-dropdown>
          <label for="sample-management-receipt-well-control">{{
            'sampleManagement.receiptPage.well' | translate
          }}</label>
        </span>

        <span class="p-float-label p-input-icon-right">
          <input
            type="text"
            id="sample-management-receipt-id-control"
            pInputText
            formControlName="id"
          />
          <label for="sample-management-receipt-id-control">{{
            'sampleManagement.receiptPage.id' | translate
          }}</label>
          <i class="fa-solid fa-magnifying-glass"></i>
        </span>

        <span class="p-float-label">
          <input
            type="text"
            id="sample-management-receipt-name-control"
            pInputText
            formControlName="name"
          />
          <label for="sample-management-receipt-name-control">{{
            'sampleManagement.receiptPage.name' | translate
          }}</label>
        </span>
      </div>
    </div>

    <div formGroupName="generalInfo">
      <h1 class="title">{{ 'sampleManagement.receiptPage.generalInfo' | translate }}</h1>

      <div class="line-with-controls few-columns">
        <div class="few-columns">
          <span class="title">{{ 'sampleManagement.receiptPage.sampleType' | translate }}</span>

          <span class="p-float-label">
            <p-dropdown
              id="sample-management-receipt-general-info-sample-type-control"
              formControlName="sampleType"
              [options]="[
                { name: 'option1', id: '1' },
                { name: 'option2', id: '2' },
                { name: 'option3', id: '3' }
              ]"
              [placeholder]="'sampleManagement.receiptPage.sampleType' | translate"
              optionLabel="name"
              [showClear]="true"
            ></p-dropdown>
            <label for="sample-management-receipt-general-info-sample-type-control">{{
              'sampleManagement.receiptPage.sampleType' | translate
            }}</label>
          </span>
        </div>

        <div class="few-columns">
          <span class="title">{{
            'sampleManagement.receiptPage.receiptTechnician' | translate
          }}</span>

          <span class="p-float-label">
            <p-dropdown
              id="sample-management-receipt-general-info-receipt-technician-control"
              formControlName="receiptTechnician"
              [options]="[
                { name: 'option1', id: '1' },
                { name: 'option2', id: '2' },
                { name: 'option3', id: '3' }
              ]"
              [placeholder]="'sampleManagement.receiptPage.receiptTechnician' | translate"
              optionLabel="name"
              [showClear]="true"
            ></p-dropdown>
            <label for="sample-management-receipt-general-info-receipt-technician-control">{{
              'sampleManagement.receiptPage.receiptTechnician' | translate
            }}</label>
          </span>
        </div>
      </div>

      <div class="line-with-controls few-columns">
        <div class="few-columns">
          <span class="title">{{
            'sampleManagement.receiptPage.operationCompany' | translate
          }}</span>

          <span class="p-float-label">
            <input
              type="text"
              id="sample-management-receipt-general-info-operation-company-control"
              pInputText
              formControlName="operationCompany"
            />
            <label for="sample-management-receipt-general-info-operation-company-control">{{
              'sampleManagement.receiptPage.operationCompany' | translate
            }}</label>
          </span>
        </div>

        <div class="few-columns">
          <span class="title">{{ 'sampleManagement.receiptPage.contract' | translate }}</span>

          <span class="p-float-label">
            <input
              type="text"
              id="sample-management-receipt-general-info-contract-name-control"
              pInputText
              formControlName="contractName"
            />
            <label for="sample-management-receipt-general-info-contract-name-control">{{
              'sampleManagement.receiptPage.contractName' | translate
            }}</label>
          </span>
        </div>
      </div>
    </div>

    <div class="line-with-controls">
      <span class="p-float-label">
        <textarea
          id="sample-management-receipt-letter-control"
          pInputTextarea
          class="w-full"
          formControlName="letter"
        ></textarea>
        <label for="sample-management-receipt-letter-control">{{
          'sampleManagement.receiptPage.letter' | translate
        }}</label>
      </span>
    </div>

    <div class="sample-management-stepper half-width">
      <p-steps
        class="sample-management-receipt-stepper-items"
        [model]="sampleManagementReceiptStepperItems"
        [activeIndex]="0"
        [readonly]="false"
      ></p-steps>
    </div>

    <div class="sample-management-receipt-action-btns">
      <button
        pButton
        type="button"
        class="btn btn-primary"
        [label]="'button.cancel' | translate"
      ></button>

      <button
        pButton
        type="button"
        class="btn btn-secondary"
        [label]="'button.download' | translate"
      ></button>

      <button
        pButton
        type="button"
        class="btn btn-secondary"
        [label]="'button.send' | translate"
      ></button>

      <button
        pButton
        type="button"
        class="btn btn-secondary"
        [label]="'button.save' | translate"
      ></button>
    </div>
  </div>
</ng-container>
