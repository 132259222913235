<ng-container [formGroup]="formGroup">
  <div class="row input-group-50">
    <span class="p-float-label">
      <p-dropdown
        id="float-input"
        formControlName="palletCodeSearch"
        [options]="pallets"
        [dataKey]="'id'"
        placeholder="{{ 'storage.placeholder.select-pallet' | translate }}"
        optionLabel="palletLabel"
        [showClear]="true"
        [editable]="false"
        [readonly]="true"
        (click)="displayPopUp()"
      ></p-dropdown>
      <label for="float-input">{{ 'storage.table.pallet-label' | translate }}*</label>
      <small *ngIf="checkFormError('palletCodeSearch')" class="p-error p-invalid"
        >{{ 'errorMessage.fieldIsRequired' | translate }}
      </small>
    </span>
  </div>
</ng-container>

<p-dialog
  *ngIf="pallets"
  header="Pallet"
  [closable]="true"
  [modal]="true"
  [(visible)]="visiblePalletPopup"
  [styleClass]="'dialog-popup'"
>
  <app-dropdown-search-popup
    #dropdownSearchPopupComponent
    [tableHeader]="'Pallet label'"
    [tableData]="pallets"
    [isRowDisabled]="isPalletDisabledForPalletizing"
    (selectedDropDownOption)="onSelectedDropDownOption($event)"
  ></app-dropdown-search-popup>
</p-dialog>
