import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DropdownSearchPopupComponent } from '../../../../common/dropdown-search-popup/dropdown-search-popup.component';
import { DropDownPopupOption } from '../../../../common/dropdown-search-popup/models/dropdown-search-popup.model';
import { MAX_ALLOWED_CONTAINER_COUNT_ASSOCIATED_WITH_PALLET } from '../../../../core/consts';
import { PalletStorageModel } from '../../models/pallet-search.model';

@Component({
  selector: 'app-assign-pallet',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    TranslateModule,
    DialogModule,
    DropdownSearchPopupComponent,
  ],
  templateUrl: './assign-pallet.component.html',
  styleUrls: ['./assign-pallet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignPalletComponent implements OnInit {
  @ViewChild('dropdownSearchPopupComponent')
  dropdownSearchPopupComponent!: DropdownSearchPopupComponent;

  @Input() formGroup!: FormGroup;
  @Input() pallets!: PalletStorageModel[];

  visiblePalletPopup = false;
  control = new FormControl();
  isPalletDisabledForPalletizing = (row: DropDownPopupOption) =>
    row['containerCount'] >= MAX_ALLOWED_CONTAINER_COUNT_ASSOCIATED_WITH_PALLET;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setUpControl();
  }

  checkFormError(controlPath: string): boolean | undefined {
    return this.formGroup.get(controlPath)?.invalid && this.formGroup.get(controlPath)?.dirty;
  }

  displayPopUp(): void {
    this.visiblePalletPopup = true;
  }

  onSelectedDropDownOption(selectedDropDownOption: PalletStorageModel): void {
    if (this.dropdownSearchPopupComponent) this.dropdownSearchPopupComponent.resetPage();
    this.formGroup.patchValue({ palletCodeSearch: selectedDropDownOption });
    this.visiblePalletPopup = false;
  }

  private setUpControl(): void {
    const assignFormGroup = this.formGroup.get('palletCodeSearch') as FormGroup;
    assignFormGroup.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
  }
}
