<div class="content">
  <div class="search search-filter">
    <app-storage-filter-option
      [formGroup]="formGroup"
      [filterOption]="filterOption"
    ></app-storage-filter-option>
    <app-pallet-search-form
      [formGroup]="formGroup"
      searchValue=""
      [pallets]="allPallets"
      [location]="locations"
      (searchEvent)="search($event)"
    ></app-pallet-search-form>
  </div>
  <p-accordion [multiple]="true">
    <p-accordionTab header="{{ 'storage.tabs.sample-information' | translate }}" [selected]="true">
      <app-sample-information
        [searchValues]="pallets"
        [pallets]="allPallets"
        [isShortened]="true"
        [isSingleEntry]="true"
      ></app-sample-information>
    </p-accordionTab>
    <p-accordionTab header="{{ 'storage.tabs.store-to-location' | translate }}" [selected]="true">
      <app-store-to-location-form
        [formGroup]="formGroup"
        [locations]="locations"
      ></app-store-to-location-form>
    </p-accordionTab>
  </p-accordion>
</div>
