<div style="width: 50%; margin-bottom: 20px" *ngIf="display">
  <p-table [value]="dataSource">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'visualization.valueTableHeader' | translate }}</th>
        <th>{{ 'visualization.opacityTableHeader' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ item.value }}</td>
        <td>{{ item.opacity }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div style="display: flex; justify-content: flex-start; gap: 20px" class="padding-top-10">
  <div style="flex: 0.3">
    <span class="p-float-label">
      <p-inputNumber
        inputId="intensity"
        [(ngModel)]="intensity"
        [showButtons]="true"
        mode="decimal"
        [minFractionDigits]="0"
        [maxFractionDigits]="2"
        [locale]="defaultLocale"
        incrementButtonClass="btn-primary"
        decrementButtonClass="btn-primary"
      >
      </p-inputNumber>
      <label htmlFor="number-input">{{ 'visualization.intensity' | translate }}</label>
    </span>
  </div>

  <div style="flex: 0.3">
    <span class="p-float-label">
      <p-inputNumber
        inputId="opacity"
        [(ngModel)]="opacity"
        [showButtons]="true"
        mode="decimal"
        [minFractionDigits]="0"
        [maxFractionDigits]="2"
        [locale]="defaultLocale"
        incrementButtonClass="btn-primary"
        decrementButtonClass="btn-primary"
      >
      </p-inputNumber>
      <label htmlFor="number-input">{{ 'visualization.opacityTableHeader' | translate }}</label>
    </span>
  </div>

  <button pButton pRipple class="btn btn-secondary" (click)="addToTable()">
    {{ 'visualization.addToTable' | translate }}
  </button>

  <button pButton pRipple class="btn btn-secondary" (click)="removeFromTable()">
    {{ 'visualization.removeFromTable' | translate }}
  </button>
</div>
