import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DropdownPackagingTypeComponent } from 'src/app/common/dropdown-packaging-type/dropdown-packaging-type.component';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';
import { PrintLabelService } from 'src/app/services/api/print-label.service';

import { DropdownEditableComponent } from '../../../common/dropdown-editable/dropdown-editable.component';
import { DropdownSearchPopupComponent } from '../../../common/dropdown-search-popup/dropdown-search-popup.component';
import { DropDownPopupOption } from '../../../common/dropdown-search-popup/models/dropdown-search-popup.model';
import { TabInfo } from '../../../common/self-service-to-validation-table/models/self-service-to-validation.model';
import { SampleType } from '../../../core/enums/sample-type.enum';
import { SampleInformation } from '../../../core/models/delivery-full.model';
import { DropDownOption } from '../../../core/models/drop-down-option.model';
import { SampleLabelPayload } from '../../../core/models/sample-label-payload.model';
import {
  CHILD_TABLE_INPUT_FIELDS_FLOAT,
  CHILD_TABLE_INPUT_FIELDS_NUMBER,
  CHILD_TABLE_NAME,
  TABLE_CHECKBOX,
  TABLE_DATE_FIELDS,
  TABLE_NAME,
  TableNameType,
} from '../../../core/table-consts';
import { generateSampleLabel } from '../../../core/utils/generate-sample-label.util';
import { LocaleService } from '../../../services/locale.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-child-table',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    TranslateModule,
    InputNumberModule,
    CheckboxModule,
    DropdownEditableComponent,
    DropdownModule,
    FormsModule,
    DialogModule,
    DropdownSearchPopupComponent,
    DropdownPackagingTypeComponent,
    TooltipModule,
  ],
  templateUrl: './child-table.component.html',
  styleUrls: ['./child-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildTableComponent extends Destroyable(Object) implements AfterViewInit {
  @ViewChild('dropdownSearchPopupComponent')
  dropdownSearchPopupComponent!: DropdownSearchPopupComponent;

  @Input() tabIndex!: number;
  @Input() tab!: TabInfo;
  @Input() rowData: any;
  @Input() validationTable = false;
  @Input() sampleDetailTableDropdownOption!: { [key: string]: DropDownOption[] };
  @Input() parentRowIndex = 0;
  @Input() wellName!: string;
  @Input() uwi!: string;
  @Input() bulkUploadCompleted!: boolean;
  @Output() validateChanged = new EventEmitter();

  tableNameType = TableNameType;
  tableName = TABLE_NAME;
  tableDateFields = TABLE_DATE_FIELDS;
  inputFieldsNumber = CHILD_TABLE_INPUT_FIELDS_NUMBER;
  inputFieldsFloat = CHILD_TABLE_INPUT_FIELDS_FLOAT;
  tableCheckbox = TABLE_CHECKBOX;
  childTableName = CHILD_TABLE_NAME;

  visibleContainerPopup = false;
  selectedDropDownRowIndex: number | null = null;
  selectedDropDownColumnName = '';

  sampleDepthFieldsName = [
    'sampleBottomDepth',
    'sampleTopDepth',
    'topPreservedDepth',
    'botomPreservedDepth',
    'topMissingDepth',
    'bottomMissinDepth',
  ];

  sampleWeightFieldsName = ['minimumWeight', 'remainingWeight'];

  get defaultLocale(): string {
    return this.localeService.getDefaultLocale();
  }

  constructor(
    private localeService: LocaleService,
    private printLabelService: PrintLabelService,
    private cd: ChangeDetectorRef,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.bulkUploadCompleted) this.bulkUploadSampleLabelAutoGenerate(this.rowData);
  }

  typeOf(value: string): boolean {
    return this.inputFieldsNumber.includes(value);
  }

  floatInput(value: string): boolean {
    return this.inputFieldsFloat.includes(value);
  }

  showDialog(index: number, columnName: string): void {
    if (columnName === 'containerId') {
      this.selectedDropDownRowIndex = index;
      this.selectedDropDownColumnName = columnName;
      this.visibleContainerPopup = true;
    }
  }

  onValidatedClicked(_e: any): void {
    this.validateChanged.emit();
  }

  onSelectedDropDownOption(selectedDropDownOption: DropDownPopupOption): void {
    if (this.dropdownSearchPopupComponent) this.dropdownSearchPopupComponent.resetPage();

    this.visibleContainerPopup = false;
    const index = this.sampleDetailTableDropdownOption['containerId'].findIndex(
      (item) => item.id === selectedDropDownOption['id'],
    );

    if (index === -1) {
      this.sampleDetailTableDropdownOption['containerId'].push(
        selectedDropDownOption as DropDownOption,
      );
    }

    this.tab.data[this.parentRowIndex][this.childTableName[this.tabIndex]][
      this.selectedDropDownRowIndex || 0
    ][this.selectedDropDownColumnName] = selectedDropDownOption['id'];
  }

  customSort(event: SortEvent): void {
    event.data?.sort((data1, data2) => {
      const value1 = data1.sampleTopDepth;
      const value2 = data2.sampleTopDepth;

      if (!value1 || value1 === '') {
        return 1;
      }
      if (!value2 || value2 === '') {
        return -1;
      }

      if (value1 < value2) {
        return -1;
      }
      if (value1 > value2) {
        return 1;
      }

      const bottomDepth1 = data1.sampleBottomDepth;
      const bottomDepth2 = data2.sampleBottomDepth;

      if (!bottomDepth1 || bottomDepth1 === '') {
        return 1;
      }

      if (!bottomDepth2 || bottomDepth2 === '') {
        return -1;
      }

      if (bottomDepth1 < bottomDepth2) {
        return -1;
      }

      if (bottomDepth1 > bottomDepth2) {
        return 1;
      }

      return 0;
    });
  }

  printContainer(dropdown: Dropdown): void {
    if (!dropdown.label) {
      this.toastService.toastError(this.translate.instant('errorMessage.ContainerCodeRequired'));
      return;
    }

    this.printLabelService
      .getContainerLabel(dropdown.label)
      .pipe(this.takeUntilDestroyed())
      .subscribe((response) => {
        this.print(response.body);
      });
  }

  printSampleLabel(childRowData: any): void {
    const sampleType = this.tableNameType[this.tableName[this.tabIndex]] as SampleType;
    const unitNumber = this.getUnitNumberForSampleLabel(childRowData);
    const depthFullValue = this.getDepthFullValueForSampleLabel(childRowData);

    const sampleLabelPayload = new SampleLabelPayload(
      childRowData.sampleLabel,
      this.wellName ?? '',
      this.uwi ?? '',
      sampleType,
      unitNumber,
      depthFullValue,
    );

    this.printLabelService
      .getSampleLabel(sampleLabelPayload)
      .pipe(this.takeUntilDestroyed())
      .subscribe((response) => {
        this.print(response.body!);
      });
  }

  generateAndFillSampleLabel(childRowData: any): void {
    const sampleType = this.tableNameType[this.tableName[this.tabIndex]] as SampleType;
    const unitNumber = this.getUnitNumberForSampleLabel(childRowData);
    const depthFullValue = this.getDepthFullValueForSampleLabel(childRowData);

    childRowData['sampleLabel'] = generateSampleLabel(
      this.uwi ?? '',
      sampleType,
      unitNumber,
      depthFullValue,
    );
  }

  markForCheck(): void {
    this.cd.markForCheck();
  }

  private getUnitNumberForSampleLabel(childRowData: any): string {
    const unitNumberKeyMap: Record<SampleType, string> = {
      [SampleType.ZH_ZS_DAT]: '',
      [SampleType.CORE]: 'coreNumber',
      [SampleType.PRESERVED]: 'coreNumber',
      [SampleType.PLUG]: '',
      [SampleType.SWC]: '',
      [SampleType.FLUID]: 'sampleNumber',
      [SampleType.RESIDUE]: '',
      [SampleType.PETROGRAPHIC_PLATE]: '',
      [SampleType.BIOSTRATIGRAPHIC]: '',
      [SampleType.SURFACE_SAMPLE]: '',
      [SampleType.PISTON_CORE]: '',
      [SampleType.CRUDE]: 'sampleNumber',
    };
    const sampleType = this.tableNameType[this.tableName[this.tabIndex]] as SampleType;
    const unitNumberKey = unitNumberKeyMap[sampleType];

    return unitNumberKey ? String(childRowData[unitNumberKey] ?? '') : '';
  }

  private getDepthFullValueForSampleLabel(childRowData: any): string {
    const depthKeysMap: Record<SampleType, string[]> = {
      [SampleType.ZH_ZS_DAT]: ['sampleTopDepth', 'sampleBottomDepth'],
      [SampleType.CORE]: ['sampleTopDepth', 'sampleBottomDepth'],
      [SampleType.PRESERVED]: ['topPreservedDepth', 'botomPreservedDepth'],
      [SampleType.PLUG]: ['depth'],
      [SampleType.SWC]: ['depth'],
      [SampleType.FLUID]: ['sampleTopDepth', 'sampleBottomDepth'],
      [SampleType.RESIDUE]: ['sampleTopDepth', 'sampleBottomDepth'],
      [SampleType.PETROGRAPHIC_PLATE]: ['sampleTopDepth', 'sampleBottomDepth'],
      [SampleType.BIOSTRATIGRAPHIC]: ['sampleTopDepth', 'sampleBottomDepth'],
      [SampleType.SURFACE_SAMPLE]: ['sampleTopDepth', 'sampleBottomDepth'],
      [SampleType.PISTON_CORE]: [''],
      [SampleType.CRUDE]: ['sampleTopDepth', 'sampleBottomDepth'],
    };
    const sampleType = this.tableNameType[this.tableName[this.tabIndex]] as SampleType;
    const depthKeys = depthKeysMap[sampleType];

    return depthKeys.map((depthKey) => childRowData[depthKey]).join(' - ');
  }

  private print(body: Blob): void {
    const fileUrl = URL.createObjectURL(body);
    const printWindow = window.open(fileUrl, '_blank');
    printWindow?.addEventListener('load', () => {
      printWindow?.print();
      URL.revokeObjectURL(fileUrl);
    });
  }

  private bulkUploadSampleLabelAutoGenerate(rowData: SampleInformation): void {
    rowData[this.childTableName[this.tabIndex]].forEach((sampleData: SampleInformation) => {
      const keysLength = Object.keys(sampleData).length;

      if (keysLength) this.generateAndFillSampleLabel(sampleData);
    });
  }
}
