import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from 'src/app/core/api.consts';
import { UserNotification } from 'src/app/core/models/user-notification.model';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationsService extends Destroyable(Object) {
  private notificationsUrl = `${SGC_API}/notifications`;

  constructor(private httpClient: HttpClient) {
    super();
  }

  getNotifications(): Observable<UserNotification[]> {
    return this.httpClient.get<UserNotification[]>(this.notificationsUrl);
  }

  postNotification(payload: {
    title: string;
    recipientIds: string[];
    message: string;
  }): Observable<null> {
    return this.httpClient.post<null>(this.notificationsUrl, payload);
  }

  deleteNotification(id: string): Observable<UserNotification> {
    return this.httpClient.delete<UserNotification>(`${this.notificationsUrl}/${id}`);
  }
}
