import { int } from '@kitware/vtk.js/types';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

import { ImageView } from './imageView';

@Component({
  selector: 'app-image-view',
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonModule, RippleModule],

  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss'],
})
export class ImageViewComponent implements AfterViewInit {
  @Input() sliceZ = 100;
  @Input() intensity?: int;
  @Input() inputData?: any;
  @Input() maxZ?: any;
  @Input() minValue = 0;
  @Input() maxValue = 0;
  @ViewChild('imageDiv') imageDiv!: ElementRef;
  cs: ImageView = new ImageView();

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    const container = this.imageDiv.nativeElement;
    const dims = this.inputData.getDimensions();
    this.sliceZ = dims[2] / 2;
    this.cs.Initialize(container, this, this.inputData, this.minValue, this.maxValue);
    this.cd.detectChanges();
  }

  OnChange(event: Event) {
    const sliderValue = Number((<HTMLInputElement>event.target).value);
    this.inputData.getDimensions();
    this.sliceZ = sliderValue;
    this.cs.SetZ(this.sliceZ);
    this.cd.detectChanges();
  }

  addSlice() {
    if (this.sliceZ <= this.maxZ) {
      this.sliceZ += 1;
    } else {
      this.sliceZ = 0;
    }

    this.cs.SetZ(this.sliceZ);
    this.cd.detectChanges();
  }

  reduceSlice() {
    if (this.sliceZ > 0) {
      this.sliceZ -= 1;
    } else {
      this.sliceZ = 0;
    }
    this.cs.SetZ(this.sliceZ);
    this.cd.detectChanges();
  }

  setColorMap(colorMapArray: any) {
    this.cs.setColorMap(colorMapArray, this.minValue, this.maxValue);
    this.cd.detectChanges();
  }
}
