<div class="header">
  <h1 class="title">{{ 'validation.title' | translate }}</h1>
  <div class="actions">
    <button
      pButton
      type="button"
      (click)="resetForm(true)"
      class="btn btn-primary"
      label="{{ 'button.clear' | translate }}"
    ></button>
    <p-confirmPopup></p-confirmPopup>
    <button
      pButton
      type="button"
      (click)="save($event)"
      [disabled]="!this.selectedDeliveryId"
      class="btn btn-secondary"
      label="{{ 'button.save' | translate }}"
    ></button>
  </div>
</div>

<div class="content">
  <p-accordion [multiple]="true">
    <div class="row input-group-50 mb-3">
      <span class="p-input-icon-left p-float-label">
        <i class="pi pi-search"></i>
        <input
          class="search-input"
          id="float-input"
          type="text"
          pInputText
          autocomplete="off"
          [readonly]="true"
          [formGroup]="verificationSearchFormGroup"
          (click)="openPopup()"
        />
        <label for="float-input">{{ 'button.search' | translate }}</label>
      </span>
    </div>
    <div *ngIf="spinnerShow" class="card spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <form [formGroup]="verificationFormGroup" *ngIf="verificationFormGroup && selectedDeliveryId">
      <app-contract-information [parentGroup]="verificationFormGroup"></app-contract-information>

      <app-operator [required]="false" [parentGroup]="verificationFormGroup"></app-operator>

      <app-service-provider
        [required]="false"
        [parentGroup]="verificationFormGroup"
      ></app-service-provider>

      <app-well-information
        [parentGroup]="verificationFormGroup"
        [required]="false"
      ></app-well-information>

      <app-project-information [parentGroup]="verificationFormGroup"></app-project-information>
      <p-accordionTab
        header="{{ 'validation.tabs.verifyingTechnicalInformation' | translate }}"
        [selected]="true"
      >
        <app-technical-information-form
          [formGroup]="verificationFormGroup"
          formName="validationTechnicalInformation"
          [options]="userInformationDropdownOption"
        ></app-technical-information-form>
      </p-accordionTab>

      <p-accordionTab
        header="{{ 'verification.tabs.verifyingTechnicalInformation' | translate }}"
        [selected]="true"
      >
        <app-technical-information-form
          [formGroup]="verificationFormGroup"
          formName="verifyingTechnicalInformation"
          [options]="userInformationDropdownOption"
        ></app-technical-information-form>
      </p-accordionTab>

      <p-accordionTab
        header="{{ 'reception.titles.receivingTechnicianInformation' | translate }}"
        [selected]="true"
      >
        <app-technical-information-form
          [formGroup]="verificationFormGroup"
          [options]="userInformationDropdownOption"
        ></app-technical-information-form>
      </p-accordionTab>
      <p-accordionTab
        header="{{ 'validation.tabs.sampleInformation' | translate }}"
        [selected]="true"
      >
        <div class="row">
          <app-self-service-to-validation-table
            [formGroup]="verificationFormGroup"
            [validationTable]="true"
            [childTable]="true"
          ></app-self-service-to-validation-table>
        </div>
      </p-accordionTab>

      <app-transportation-information
        [parentGroup]="verificationFormGroup"
        [required]="true"
      ></app-transportation-information>

      <app-additional-information
        [parentGroup]="verificationFormGroup"
      ></app-additional-information>
    </form>
  </p-accordion>
</div>

<div class="flex">
  <div *ngIf="selectedDeliveryId" class="cursor-pointer w-max mr-4" (click)="printDelivery()">
    <i class="pi pi-print mb-4 mt-4" style="color: green"></i>
    <span class="text-green-800 ml-1">{{ 'button.printDeliveryInformation' | translate }}</span>
  </div>

  <p-splitButton
    *ngIf="selectedDeliveryId"
    #export
    label="{{ 'button.exportReceipt' | translate }}"
    icon="pi pi-download"
    [model]="this.exportReceiptList"
    styleClass="p-button-text p-button-success mr-2 mb-2"
    (onClick)="export.onDropdownButtonClick($event)"
  ></p-splitButton>
</div>

<p-dialog
  *ngIf="deliveryTableData.length"
  header="{{ 'deliveryTable.search' | translate }}"
  [closable]="true"
  [modal]="true"
  [(visible)]="visiblePopup"
  [style]="{ width: '90vw', height: '90vh' }"
>
  <app-delivery-table-search-uwi-contract
    [tableData]="deliveryTableData"
    (selectedDeliveryId)="onSelectedDeliveryId($event)"
  ></app-delivery-table-search-uwi-contract>
</p-dialog>
