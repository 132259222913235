export enum SampleType {
  ZH_ZS_DAT = 'ZhZsDat',
  CORE = 'Core',
  PRESERVED = 'Preserved',
  PLUG = 'Plug',
  SWC = 'Swc',
  FLUID = 'Fluid',
  RESIDUE = 'Residue',
  PETROGRAPHIC_PLATE = 'PetrographicPlate',
  BIOSTRATIGRAPHIC = 'Biostratigraphic',
  SURFACE_SAMPLE = 'SurfaceSample',
  PISTON_CORE = 'PistonCore',
  CRUDE = 'Crude',
}
