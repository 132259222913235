import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from '../../core/api.consts';
import { EntityCacheKey } from '../../core/enums/entity-cache-key.enum';
import { ProjectState } from '../../core/models/project-state.model';
import { CacheService } from '../cache.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectStateService {
  private url = `${SGC_API}/ProjectStates`;

  constructor(private httpClient: HttpClient, private cacheService: CacheService) {}

  getProjectStates(): Observable<ProjectState[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.PROJECT_STATES,
      this.httpClient.get<ProjectState[]>(this.url),
    );
  }
}
