import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PASS_ERROR_HEADER, SGC_API } from '../../core/api.consts';
import { ComapanyInformation } from '../../core/models/drop-down-option.model';
import { DataAccessServiceModel } from '../../features/data-access/models/data-access.model';
import { ServiceSearch, ServicesShape } from '../../features/service/models/service.model';

@Injectable({
  providedIn: 'root',
})
export class SearchServiceService {
  private url = SGC_API;
  public serviceSearchUrl = `${this.url}/Service/search`;
  public serviceInformationUrl = `${this.url}/Service/informations`;
  public scheduleUrl = `${this.url}/Service/schedule`;
  public serviceByFilterUrl = `${this.url}/Service/servicesByFilter`;
  public serviceScheduleNoteURL = `${this.url}/Service/serviceSchedule/note`;
  public serviceScheduleCompletedDateURl = `${this.url}/Service/serviceSchedule/completedDate`;

  constructor(private httpClient: HttpClient) {}

  create(ServiceSchedulingFormData: ServicesShape): Observable<ServicesShape> {
    return this.httpClient.post<ServicesShape>(`${this.scheduleUrl}`, ServiceSchedulingFormData, {
      headers: { PassError: '' },
    });
  }

  getAllBy(upiUwi: string): Observable<ServiceSearch[]> {
    return this.httpClient.get<ServiceSearch[]>(`${this.serviceSearchUrl}?uwiOrUpi=${upiUwi}`, {
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }

  getInformation(): Observable<ComapanyInformation[]> {
    return this.httpClient.get<ComapanyInformation[]>(`${this.serviceInformationUrl}`);
  }

  getServicesByFilter(params: HttpParams): Observable<DataAccessServiceModel> {
    return this.httpClient.get<DataAccessServiceModel>(`${this.serviceByFilterUrl}`, { params });
  }

  updateNote(serviceScheduleId: string, note: string): Observable<null> {
    const params = new HttpParams().set('serviceScheduleId', serviceScheduleId).set('note', note);
    return this.httpClient.put<null>(`${this.serviceScheduleNoteURL}`, null, { params });
  }

  updateCompletedDate(serviceScheduleId: string, completedDate: string): Observable<null> {
    const params = new HttpParams()
      .set('serviceScheduleId', serviceScheduleId)
      .set('completedDate', completedDate);
    return this.httpClient.put<null>(`${this.serviceScheduleCompletedDateURl}`, null, { params });
  }
}
