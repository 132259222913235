import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { Tooltip, TooltipModule } from 'primeng/tooltip';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';

import { TableValidationModel } from 'src/app/core/models/table-validation.model';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';
import { SampleDetailInformation } from 'src/app/features/verification/models/sample-details.model';
import { PrintLabelService } from 'src/app/services/api/print-label.service';

import { SampleInformation } from '../../core/models/delivery-full.model';
import { DropDownOption } from '../../core/models/drop-down-option.model';
import {
  CHILD_TABLE_NAME,
  INPUT_FIELDS_FLOAT,
  INPUT_FIELDS_NUMBER,
  SAMPLE_INFO_TABLE_DROPDOWN_OPTION,
  TABLE_DATE_FIELDS,
  TABLE_NAME,
  TABLE_SUMMARY,
} from '../../core/table-consts';
import { ChildTableComponent } from '../../features/verification/child-table/child-table.component';
import { BulkDataUploadService } from '../../services/api/bulk-data-upload.service';
import { LocaleService } from '../../services/locale.service';
import { ToastService } from '../../services/toast.service';
import { DeliveryTotalNumber } from '../delivery-table-search-uwi-contract/models/delivery-total-number.model';
import { DefaultCalendarDateFormatDirective } from '../directives/default-calendar-date-format.directive';
import { DropdownEditableComponent } from '../dropdown-editable/dropdown-editable.component';
import { DropdownPackagingTypeComponent } from '../dropdown-packaging-type/dropdown-packaging-type.component';
import { DropdownSearchPopupComponent } from '../dropdown-search-popup/dropdown-search-popup.component';
import { DropDownPopupOption } from '../dropdown-search-popup/models/dropdown-search-popup.model';
import { selfServiceToValidationTable } from './mocks/self-service-to-validation.mock';
import { SelfServiceToValidationTable, TabInfo } from './models/self-service-to-validation.model';

@Component({
  selector: 'app-self-service-to-validation-table',
  standalone: true,
  imports: [
    CommonModule,
    TabViewModule,
    TableModule,
    FormsModule,
    InputTextModule,
    TranslateModule,
    InputNumberModule,
    CalendarModule,
    DropdownEditableComponent,
    DropdownModule,
    ChildTableComponent,
    CheckboxModule,
    DialogModule,
    DropdownSearchPopupComponent,
    TooltipModule,
    DropdownPackagingTypeComponent,
    DefaultCalendarDateFormatDirective,
    FileUploadModule,
    ProgressSpinnerModule,
  ],
  templateUrl: './self-service-to-validation-table.component.html',
  styleUrls: ['./self-service-to-validation-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfServiceToValidationTableComponent extends Destroyable(Object) implements OnInit {
  @ViewChildren('dropdownSearchPopupComponent')
  dropdownSearchPopupComponent!: QueryList<DropdownSearchPopupComponent>;

  @ViewChildren('containerDropDown') containerDropDown!: QueryList<Dropdown>;

  @ViewChild(Tooltip) tooltip!: Tooltip;
  @ViewChild(ChildTableComponent) childTableComponent!: ChildTableComponent;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  @Input() selfServiceTable = false;
  @Input() childTable = false;
  @Input() validationTable = false;
  @Input() verificationTable = false;
  @Input() notDisplayField: string[] = [];
  @Input() formGroup!: FormGroup;

  tableDateFields = TABLE_DATE_FIELDS;
  inputFieldsNumber = INPUT_FIELDS_NUMBER;
  inputFieldsFloat = INPUT_FIELDS_FLOAT;
  tableSummary = TABLE_SUMMARY;
  sampleInfoTableDropdownOption = SAMPLE_INFO_TABLE_DROPDOWN_OPTION;
  tabs: TabInfo[] = JSON.parse(JSON.stringify(selfServiceToValidationTable));
  activeTabIndex = 0;
  parentTableName = TABLE_NAME;
  childTableName = CHILD_TABLE_NAME;
  totalNumber: DeliveryTotalNumber = { totalCount: 0, sumOfValue: 0 };

  visibleContainerPopup = false;
  visiblePalletPopup = false;
  visibleLocationPopup = false;
  bulkUploadCompleted = false;
  spinnerShow = false;
  selectedDropDownRowIndex: number | null = null;
  selectedDropDownColumnName = '';
  mainTableValidationStatus: TableValidationModel = {
    isValid: true,
    fieldName: '',
  };

  childTableValidationStatus: TableValidationModel = {
    isValid: true,
    fieldName: '',
  };

  childTableWeightValidationStatus: TableValidationModel = {
    isValid: true,
    fieldName: '',
  };

  constructor(
    private localeService: LocaleService,
    private cd: ChangeDetectorRef,
    private toastService: ToastService,
    private translate: TranslateService,
    private printLabelService: PrintLabelService,
    private bulkDataUploadService: BulkDataUploadService,
  ) {
    super();
  }

  get wellName(): string {
    return this.formGroup.get('wellInformation.wellName')?.value;
  }

  get uwi(): string {
    return this.formGroup.get('wellInformation.uwi')?.value;
  }

  get defaultLocale(): string {
    return this.localeService.getDefaultLocale();
  }

  ngOnInit(): void {
    this.initTable();
  }

  integerInput(value: string): boolean {
    return this.inputFieldsNumber.includes(value);
  }

  floatInput(value: string): boolean {
    return this.inputFieldsFloat.includes(value);
  }

  dateInput(value: string): boolean {
    return this.tableDateFields.includes(value);
  }

  getTableValues(): TabInfo[] {
    return this.removeEmptyRowsFromAllTabs();
  }

  loadTabData(event: any): void {
    this.activeTabIndex = event.index;
  }

  removeEmptyRowsFromAllTabs(exportReceipt = false): TabInfo[] {
    const tabData: TabInfo[] = JSON.parse(JSON.stringify(this.tabs));
    const updatedTabs = tabData.map((tab, tabIndex) => {
      let updatedData = tab.data;

      if (this.childTable) {
        updatedData = updatedData.map((parentRow) => {
          const updatedChildData = parentRow[this.childTableName[tabIndex]].filter(
            (childRow: any) => !this.isEmptyObject(childRow),
          );
          return updatedChildData.length
            ? { ...parentRow, [this.childTableName[tabIndex]]: updatedChildData }
            : (delete parentRow[this.childTableName[tabIndex]], parentRow);
        });
      }

      updatedData = updatedData.filter(
        (row) =>
          (!exportReceipt && !this.isEmptyObject(row, true)) ||
          (exportReceipt && this.childTable && !this.isEmptyObject(row, true) && row['verified']) ||
          (exportReceipt && !this.childTable && !this.isEmptyObject(row, true)),
      );

      return { ...tab, data: updatedData };
    });
    return updatedTabs;
  }

  rowValueChanged(tab: TabInfo): void {
    const anyRowEmpty = tab.data.some((row) => this.isEmptyObject(row));
    this.updateTableSummary(tab.data, this.activeTabIndex);
    this.validateDepthRanges(this.getTableValues());
    this.validateChildTableDepthRanges(this.getTableValues());
    this.validateChildTableWeightRanges(this.getTableValues());

    if (!anyRowEmpty) {
      this.addEmptyRows(this.activeTabIndex, 5);
    }
  }

  updateTableData(tablesData: any): void {
    this.tabs.forEach((tab, tabIndex) => {
      tab.data = tablesData[tab.tableName];
      let remainingRows = 5;

      if (tab.data) remainingRows = 5 - (tab.data.length % 5);
      else tab.data = [];

      this.addEmptyRows(tabIndex, remainingRows);

      if (this.childTable) {
        tab.data.forEach((row, parentRowIndex) => {
          const remainingRowsChildTable = 5 - (row[this.childTableName[tabIndex]].length % 5);
          this.addChildEmptyRows(tabIndex, parentRowIndex, remainingRowsChildTable);
        });
        tab.data = this.childTableAddActionAttribute(tab.data);
      }
      if (tab.tableName === 'pistonCoreBoxes') {
        tab.data.forEach((row) => {
          if (row.shotDate) {
            row.shotDate = new Date(row.shotDate);
          }
        });
      }
      this.updateTableSummary(tab.data, tabIndex);
    });
    this.cd.detectChanges();
  }

  resetChildTable(): void {
    this.tabs.forEach((tab, tabIndex) => {
      tab.data.forEach((row, parentRowIndex) => {
        tab.data[parentRowIndex][this.childTableName[tabIndex]] = [];
        this.addChildEmptyRows(tabIndex, parentRowIndex, 5);
      });
      tab.data = this.childTableAddActionAttribute(tab.data);
    });
    this.bulkUploadCompleted = false;
  }

  resetTable(): void {
    this.tabs = JSON.parse(JSON.stringify(selfServiceToValidationTable));
    this.initTable();
    this.tabs.forEach((tab, tabIndex) => {
      this.updateTableSummary(tab.data, tabIndex);
    });
    this.activeTabIndex = 0;
    this.bulkUploadCompleted = false;
    this.cd.detectChanges();
  }

  toggleChildTable(tab: TabInfo, rowData: any): void {
    rowData.actions.expanded = !rowData.actions.expanded;
  }

  showDialog(index: number, columnName: string): void {
    this.selectedDropDownRowIndex = index;
    this.selectedDropDownColumnName = columnName;

    switch (columnName) {
      case 'containerId':
        this.visibleContainerPopup = true;
        break;
      case 'palletId':
        this.visiblePalletPopup = true;
        break;
      case 'locationId':
        this.visibleLocationPopup = true;
        break;
      default:
        break;
    }
  }

  onSelectedDropDownOption(selectedDropDownOption: DropDownPopupOption): void {
    if (this.dropdownSearchPopupComponent)
      this.dropdownSearchPopupComponent.forEach((component: DropdownSearchPopupComponent) => {
        component.resetPage();
      });
    this.visibleContainerPopup = false;
    this.visiblePalletPopup = false;
    this.visibleLocationPopup = false;
    const index = this.sampleInfoTableDropdownOption['containerId'].findIndex(
      (item) => item.id === selectedDropDownOption['id'],
    );

    if (index === -1) {
      this.sampleInfoTableDropdownOption['containerId'].push(
        selectedDropDownOption as DropDownOption,
      );
    }
    this.tabs[this.activeTabIndex].data[this.selectedDropDownRowIndex!][
      this.selectedDropDownColumnName
    ] = selectedDropDownOption['id'];
  }

  customSort(event: SortEvent): void {
    event.data?.sort((data1, data2) => {
      const value1 = data1.boxTopDepth;
      const value2 = data2.boxTopDepth;

      if (!value1 || value1 === '') {
        return 1;
      }
      if (!value2 || value2 === '') {
        return -1;
      }

      if (value1 < value2) {
        return -1;
      }
      if (value1 > value2) {
        return 1;
      }

      const bottomDepth1 = data1.boxBottomDepth;
      const bottomDepth2 = data2.boxBottomDepth;

      if (!bottomDepth1 || bottomDepth1 === '') {
        return 1;
      }
      if (!bottomDepth2 || bottomDepth2 === '') {
        return -1;
      }

      if (bottomDepth1 < bottomDepth2) {
        return -1;
      }
      if (bottomDepth1 > bottomDepth2) {
        return 1;
      }
      return 0;
    });
  }

  printContainer(dropdown: Dropdown): void {
    if (!dropdown.label) {
      this.toastService.toastError(this.getTranslationText('errorMessage.ContainerCodeRequired'));
      return;
    }

    this.printLabelService
      .getContainerLabel(dropdown.label)
      .pipe(this.takeUntilDestroyed())
      .subscribe((response) => {
        this.print(response.body);
      });
  }

  addRowToChildTable(tabIndex: number, parentRowIndex: number): void {
    this.addChildEmptyRows(tabIndex, parentRowIndex, 1);
    this.childTableComponent.markForCheck();
  }

  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files && inputElement.files[0];

    const fileNameParts: string[] = file!.name.split('.');
    const fileExtension: string = fileNameParts[fileNameParts.length - 1];

    if ((file && fileExtension === 'xls') || (file && fileExtension === 'xlsx')) {
      this.uploadFile(file);
    } else this.toastService.toastError('Application accepts only xls and xlsx file formats');

    this.clearFileInput();
  }

  downloadTemplate(): void {
    this.spinnerShow = true;
    if (!this.childTable) this.downloadTemplateBox();
    else this.downloadTemplateSample();
  }

  private clearFileInput(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  private downloadTemplateBox(): void {
    this.bulkDataUploadService
      .downloadTemplateBox()
      .pipe(this.takeUntilDestroyed())
      .subscribe((file) => {
        this.downloadFile(file, 'Box_template', '.xlsx');
        this.spinnerShow = false;
        this.cd.detectChanges();
      });
  }

  private downloadTemplateSample(): void {
    this.bulkDataUploadService
      .downloadTemplateSample()
      .pipe(this.takeUntilDestroyed())
      .subscribe((file) => {
        this.downloadFile(file, 'Sample_template', '.xlsx');
        this.spinnerShow = false;
        this.cd.detectChanges();
      });
  }

  private uploadFile(file: File): void {
    this.spinnerShow = true;
    if (!this.childTable) this.bulkUploadBox(file);
    else this.bulkUploadSample(file);
  }

  private bulkUploadSample(file: File): void {
    this.bulkDataUploadService.bulkUploadSample(file).subscribe({
      next: (res) => {
        const unavailableBoxNumbers = this.getUnavailableBoxNumbers(res);
        if (Object.keys(unavailableBoxNumbers).length) {
          const boxNumbers = Object.entries(unavailableBoxNumbers)
            .map(([key, value]) => `${key}: '${value}'`)
            .join(', ');
          this.toastService.toastError(
            `Sample bulk upload ${boxNumbers} box number not available in boxes`,
          );
          this.spinnerShow = false;
          this.cd.detectChanges();
        } else {
          this.resetChildTable();
          this.updateChildTable(res);
          this.bulkUploadCompleted = true;
          this.spinnerShow = false;
          this.cd.detectChanges();
        }
      },
      error: (_d) => {
        this.spinnerShow = false;
        this.cd.detectChanges();
      },
      complete: () => {
        this.spinnerShow = false;
        this.cd.detectChanges();
      },
    });
  }

  private updateChildTable(uploadData: SampleInformation): void {
    this.parentTableName.forEach((boxName, index) => {
      if (Object.prototype.hasOwnProperty.call(uploadData, boxName) && uploadData[boxName]) {
        uploadData[boxName].forEach((uploadBox: SampleInformation) => {
          const { boxNumber } = uploadBox;
          const boxIndex = this.tabs[index].data.findIndex(
            (originalBox) => originalBox.boxNumber === boxNumber,
          );

          if (boxIndex !== -1) {
            const sampleName = this.childTableName[index];

            if (Object.prototype.hasOwnProperty.call(uploadBox, sampleName)) {
              this.tabs[index].data[boxIndex][sampleName] = uploadBox[sampleName];
            }
          }
        });
      }
    });
  }

  private getUnavailableBoxNumbers(uploadData: SampleInformation): Record<string, string> {
    const missingBoxNames: Record<string, string> = {};
    this.parentTableName.forEach((boxName, index) => {
      if (boxName === 'crudeBoxes') return;

      if (Object.prototype.hasOwnProperty.call(uploadData, boxName) && uploadData[boxName]) {
        uploadData[boxName].forEach((uploadBox: any) => {
          const { boxNumber } = uploadBox;
          if (
            !this.tabs[index].data.some((originalBox: any) => originalBox.boxNumber === boxNumber)
          ) {
            if (!Object.prototype.hasOwnProperty.call(missingBoxNames, boxName)) {
              missingBoxNames[boxName] = `${boxNumber}`;
            } else {
              missingBoxNames[boxName] += `,${boxNumber}`;
            }
          }
        });
      } else if (uploadData[boxName]) {
        missingBoxNames[boxName] = '';
      }
    });
    return missingBoxNames;
  }

  private bulkUploadBox(file: File): void {
    this.bulkDataUploadService.bulkUploadBox(file).subscribe({
      next: (uploadData: SampleInformation) => {
        this.resetTable();
        this.updateTableData(uploadData);
        this.bulkUploadCompleted = true;
        this.spinnerShow = false;
        this.cd.detectChanges();
      },
      error: (_d) => {
        this.spinnerShow = false;
        this.cd.detectChanges();
      },
      complete: () => {
        this.spinnerShow = false;
        this.cd.detectChanges();
      },
    });
  }

  private downloadFile(fileData: ArrayBuffer, fileName: string, fileExtension: string): void {
    const blob = new Blob([fileData], { type: 'application/octet-stream' });

    const link = document.createElement('a');

    link.download = `${fileName}${fileExtension}`;
    link.href = window.URL.createObjectURL(blob);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  private print(body: Blob): void {
    const fileUrl = URL.createObjectURL(body);
    const printWindow = window.open(fileUrl, '_blank', 'resizable=yes');
    printWindow?.addEventListener('load', () => {
      printWindow?.print();
      URL.revokeObjectURL(fileUrl);
    });
  }

  private initTable(): void {
    this.tabs.forEach((tab, tabIndex) => {
      this.addEmptyRows(tabIndex, 5);
      if (this.childTable) tab.data = this.childTableAddActionAttribute(tab.data);
    });
  }

  private addEmptyRows(tabIndex: number, remainingRows: number): void {
    for (let i = 0; i < remainingRows; i += 1) {
      if (this.childTable)
        this.tabs[tabIndex].data.push({
          [this.childTableName[tabIndex]]: [...Array(5)].map(() => ({})),
        });
      else this.tabs[tabIndex].data.push({});
    }
    this.tabs[tabIndex].data = [...this.tabs[tabIndex].data];
  }

  private addChildEmptyRows(tabIndex: number, parentRowIndex: number, remainingRows: number): void {
    for (let i = 0; i < remainingRows; i += 1) {
      this.tabs[tabIndex].data[parentRowIndex][this.childTableName[tabIndex]].push({});
    }
    this.tabs[tabIndex].data = [...this.tabs[tabIndex].data];
  }

  private childTableAddActionAttribute(
    data: SelfServiceToValidationTable[],
  ): SelfServiceToValidationTable[] {
    return data.map((item) => {
      return {
        ...item,
        actions: {
          expanded: false,
        },
      };
    });
  }

  private validateDepthRanges(tableValues: TabInfo[]): void {
    let isDepthValid = true;
    let fieldWithError = '';

    tableValues.some((t) => {
      const isBottomDepthLess = t.data.find((obj) =>
        (obj.boxBottomDepth || obj.boxBottomDepth === 0) &&
        (obj.boxTopDepth || obj.boxTopDepth === 0)
          ? obj.boxBottomDepth < obj.boxTopDepth
          : false,
      );

      if (isBottomDepthLess) {
        isDepthValid = false;
        fieldWithError = t.tableName;
        this.tooltip.activate();

        return true;
      }

      return false;
    });

    this.mainTableValidationStatus = {
      isValid: isDepthValid,
      fieldName: fieldWithError,
    };
  }

  private validateChildTableDepthRanges(tableValues: TabInfo[]): void {
    let isChildDepthValid = true;
    let fieldWithError = '';
    tableValues.forEach((item) => {
      this.childTableName.forEach((key) => {
        const samples = item.data.flatMap((dataItem) => {
          const sampleArray = dataItem[key];
          return sampleArray ? [].concat(sampleArray) : ([] as SampleDetailInformation);
        });

        if (samples.length > 0) {
          const isValid = samples.every((sample) => {
            let sampleTopDepth;
            let sampleBottomDepth;
            if (sample.preservedBoxId && sample.topPreservedDepth && sample.botomPreservedDepth) {
              sampleTopDepth = parseFloat(sample.topPreservedDepth);
              sampleBottomDepth = parseFloat(sample.botomPreservedDepth);
              return sampleTopDepth <= sampleBottomDepth;
            }

            if (
              sample.coreBoxId &&
              sample.sampleTopDepth &&
              sample.sampleBottomDepth &&
              sample.topPreservedDepth &&
              sample.botomPreservedDepth
            ) {
              sampleTopDepth = parseFloat(sample.sampleTopDepth);
              sampleBottomDepth = parseFloat(sample.sampleBottomDepth);
              const preservedTopDepth = parseFloat(sample.topPreservedDepth);
              const preservedBottomDepth = parseFloat(sample.botomPreservedDepth);

              return sampleTopDepth <= sampleBottomDepth
                ? preservedTopDepth <= preservedBottomDepth
                : sampleTopDepth <= sampleBottomDepth;
            }

            if (sample.botomPreservedDepth && sample.topPreservedDepth) {
              sampleTopDepth = parseFloat(sample.topPreservedDepth);
              sampleBottomDepth = parseFloat(sample.botomPreservedDepth);

              return sampleTopDepth <= sampleBottomDepth;
            }

            if (sample.sampleTopDepth && sample.sampleBottomDepth) {
              sampleTopDepth = parseFloat(sample.sampleTopDepth);
              sampleBottomDepth = parseFloat(sample.sampleBottomDepth);

              return sampleTopDepth <= sampleBottomDepth;
            }

            return true;
          });

          if (!isValid) {
            isChildDepthValid = false;
            fieldWithError = key;
          }
        }
      });
    });

    this.childTableValidationStatus = {
      isValid: isChildDepthValid,
      fieldName: fieldWithError,
    };
  }

  private validateChildTableWeightRanges(tableValues: TabInfo[]): void {
    let isChildDepthValid = true;
    const item = tableValues[0];
    const key = 'zhZsDatSamples';
    const samples = item.data.flatMap((dataItem) => {
      const sampleArray = dataItem[key];
      return sampleArray ? [].concat(sampleArray) : ([] as SampleDetailInformation);
    });

    if (samples.length > 0) {
      const isValid = samples.every((sample) => {
        let minimumWeight;
        let remainingWeight;

        if (sample.remainingWeight || sample.minimumWeight) {
          minimumWeight = parseFloat(sample.minimumWeight || 0);
          remainingWeight = parseFloat(sample.remainingWeight);

          return minimumWeight <= remainingWeight;
        }

        return true;
      });

      if (!isValid) {
        isChildDepthValid = false;
      }
    }

    this.childTableWeightValidationStatus = {
      isValid: isChildDepthValid,
      fieldName: key,
    };
  }

  private updateTableSummary(
    currentTable: SelfServiceToValidationTable[],
    tableIndex: number,
  ): void {
    let totalCount = 0;
    let sumOfValue = 0;
    let verifiedSum = 0;
    let validatedSum = 0;
    let samplesTotal = 0;
    const key = this.tableSummary.key[tableIndex];

    currentTable.forEach((f) => {
      if (f[key]) {
        totalCount += 1;
        sumOfValue += f[key];
        if (this.verificationTable) {
          verifiedSum += f.verified ? 1 : 0;
        }

        if (this.validationTable) {
          samplesTotal += f[this.childTableName[tableIndex]].filter(
            (x: { validated: boolean }) => x.validated !== null && !this.isEmptyObject(x),
          ).length;
          validatedSum += f[this.childTableName[tableIndex]].filter(
            (x: { validated: boolean }) => x.validated && !this.isEmptyObject(x),
          ).length;
        }
      }
    });
    this.tableSummary.value[tableIndex].totalCount = totalCount;
    this.tableSummary.value[tableIndex].sumOfValue = sumOfValue;

    const totalSums = this.calculateTotalSums();
    this.totalNumber = { ...totalSums };

    if (this.verificationTable) {
      this.tableSummary.value[tableIndex].verifiedCount = verifiedSum;
    }
    if (this.validationTable) {
      this.tableSummary.value[tableIndex].validatedCount = validatedSum;
      this.tableSummary.value[tableIndex].samplesTotal = samplesTotal;
    }
  }

  private calculateTotalSums(): DeliveryTotalNumber {
    return this.tableSummary.value.reduce(
      (acc, { totalCount, sumOfValue }) => {
        acc.totalCount += totalCount;
        acc.sumOfValue += sumOfValue;
        return acc;
      },
      { totalCount: 0, sumOfValue: 0 },
    );
  }

  private isEmptyObject(obj: SelfServiceToValidationTable, deleteActions = false): boolean {
    if (this.childTable && deleteActions && Object.prototype.hasOwnProperty.call(obj, 'actions')) {
      delete obj['actions'];
    }

    if (Object.keys(obj).length === 0) {
      return true;
    }

    if (
      obj['validated'] !== undefined &&
      obj['validated'] !== null &&
      Object.keys(obj).length === 1
    ) {
      return true;
    }

    const hasNonNullValue = Object.keys(obj).some(
      (key) => obj[key] !== null && !this.childTableName.includes(key),
    );

    return !hasNonNullValue;
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }
}
