import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DropDownOption } from 'src/app/core/models/drop-down-option.model';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';

@Component({
  selector: 'app-options-table',
  standalone: true,
  providers: [ConfirmationService],
  imports: [
    CommonModule,
    TableModule,
    TranslateModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    ConfirmPopupModule,
  ],
  templateUrl: './options-table.component.html',
  styleUrls: ['./options-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsTableComponent extends Destroyable(Object) {
  @Input() title = 'test';
  @Input() entities = new Array<DropDownOption>();
  @Output() save = new EventEmitter<Array<DropDownOption>>();
  @Output() cancel = new EventEmitter<void>();
  @Output() add = new EventEmitter<{ endpointParam: string; name: string }>();
  @Output() delete = new EventEmitter<{ endpointParam: string; name: string }>();

  isAddModalVisible = false;
  newEntityName = '';

  constructor(
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
  ) {
    super();
  }

  onDelete(entity: DropDownOption): void {
    this.delete.emit({ endpointParam: entity.id, name: entity.name ?? '' });
  }

  showDialog(): void {
    this.isAddModalVisible = true;
  }

  onSave(): void {
    this.add.emit({ endpointParam: this.newEntityName, name: this.newEntityName });
    this.closeDialog();
    this.newEntityName = '';
  }

  onCancelModal(): void {
    this.newEntityName = '';
    this.closeDialog();
  }

  confirm(event: Event, entity: DropDownOption) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.getTranslationText('confirmation.message'),
      acceptButtonStyleClass: 'btn-secondary',
      rejectButtonStyleClass: 'btn-primary',
      acceptLabel: this.getTranslationText('confirmation.confirm_btn'),
      rejectLabel: this.getTranslationText('confirmation.cancel_btn'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDelete(entity);
      },
    });
  }

  private getTranslationText(translationKey: string): string {
    let translationText = '';
    this.translate
      .stream(translationKey)
      .pipe(this.takeUntilDestroyed())
      .subscribe((msg) => {
        translationText = msg;
      });
    return translationText;
  }

  private closeDialog(): void {
    this.isAddModalVisible = false;
  }
}
