import { ServiceChartFilters } from '../models/service-chart-filters.model';

export const INITIAL_SERVICE_CHART_STATE: ServiceChartFilters = {
  sampleTypesIds: [],
  display: {
    day: false,
    week: false,
    month: true,
  },
  duration: {
    startDate: '',
    endDate: '',
  },
};
