<ng-container [formGroup]="parentGroup">
  <p-accordionTab
    header="{{ 'selfServices.serviceProvider' | translate }}"
    formGroupName="serviceProvider"
    [selected]="isExpanded"
  >
    <div class="row input-group-50">
      <span class="p-float-label">
        <p-dropdown
          *ngIf="!isWellManagementDialog"
          id="companyName-input"
          [options]="serviceProviders"
          optionLabel="companyName"
          optionValue="id"
          [filter]="true"
          filterBy="companyName"
          formControlName="companyName"
          [showClear]="true"
          placeholder="{{ 'selfServices.serviceCompanyName' | translate }}"
        >
        </p-dropdown>
        <p-dropdown
          *ngIf="isWellManagementDialog"
          id="companyName-input"
          [options]="serviceProviders"
          optionLabel="companyName"
          optionValue="id"
          [filter]="true"
          filterBy="companyName,contactName"
          formControlName="companyName"
          [showClear]="true"
          placeholder="{{ 'selfServices.serviceCompanyName' | translate }}"
        >
          <ng-template let-serviceProvider pTemplate="item">
            <p style="font-weight: bold">{{ serviceProvider.companyName }}</p>
            <span>{{ serviceProvider.contactName }}</span>
          </ng-template>
        </p-dropdown>
        <label for="companyName-input"
          >{{ 'selfServices.serviceCompanyName' | translate }}<span *ngIf="required">*</span></label
        >
        <small
          *ngIf="checkFormError('serviceProvider.companyName')['required']"
          class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>

      <span class="p-float-label">
        <input
          maxlength="256"
          (input)="onFieldInput()"
          id="contactName-input"
          type="text"
          pInputText
          formControlName="contactName"
        />
        <label for="contactName-input">{{ 'selfServices.contactName' | translate }}</label>
        <small
          *ngIf="checkFormError('selfServices.contactName')['required']"
          class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>
    </div>

    <div class="row input-group-50">
      <span class="p-float-label">
        <input
          (input)="onFieldInput()"
          id="contactNumber-input"
          type="text"
          pInputText
          formControlName="contactNumber"
        />
        <label for="contactNumber-input">{{ 'selfServices.contactNumber' | translate }}</label>
        <small
          *ngIf="checkFormError('serviceProvider.contactNumber')['required']"
          class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>

      <span class="p-float-label">
        <input id="email-input" type="text" pInputText formControlName="email" />
        <label for="email-input"
          >{{ 'selfServices.email' | translate }}<span *ngIf="required">*</span></label
        >
        <small *ngIf="checkFormError('email')['required']" class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>
    </div>
  </p-accordionTab>
</ng-container>
