<p-dialog
  header="{{ 'service.serviceSearch' | translate }}"
  [closable]="false"
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '80vw', height: '50vw' }"
>
  <ng-template pTemplate="header">
    <div class="custom-header">
      <span class="p-dialog-title">{{ 'service.serviceSearch' | translate }}</span>
      <div class="header-buttons">
        <div class="actions">
          <button
            pButton
            pRipple
            type="button"
            label="{{ 'button.cancel' | translate }}"
            (click)="resetForm()"
            class="btn-primary"
          ></button>
          <button
            pButton
            pRipple
            type="button"
            label="{{ 'button.select' | translate }}"
            class="btn-secondary"
            [disabled]="!dataTable.selection.length"
            (click)="select()"
          ></button>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="content">
    <app-well-search-form
      (searchEvent)="onSearch($event)"
      [formGroup]="serviceSearchFormGroup"
      searchValue="UWI/UPI"
    ></app-well-search-form>
    <div *ngIf="spinnerShow" class="card spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <p-table
      #dataTable
      [value]="searchValues"
      dataKey="dataKey"
      [(selection)]="selectedItems"
      [paginator]="true"
      [rows]="5"
      [selectionMode]="'multiple'"
      [resizableColumns]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th>{{ 'service.table.type' | translate }}</th>
          <th>{{ 'service.table.uwiupi' | translate }}</th>
          <th>{{ 'service.table.sampleType' | translate }}</th>
          <th>{{ 'service.table.totalBoxes' | translate }}</th>
          <th>{{ 'service.table.totalSamples' | translate }}</th>
          <th>{{ 'service.table.topDepth' | translate }}</th>
          <th>{{ 'service.table.bottomDepth' | translate }}</th>
          <th>{{ 'service.table.depthUnit' | translate }}</th>
          <th>{{ 'service.table.sampleLocations' | translate }}</th>
          <th>{{ 'service.table.boxLocations' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-value>
        <tr>
          <td>
            <p-tableCheckbox [value]="value"></p-tableCheckbox>
          </td>
          <td>{{ value.type }}</td>
          <td>{{ value.uwiOrUpi }}</td>
          <td>{{ value.sampleType }}</td>
          <td>{{ value.totalBoxes }}</td>
          <td>{{ value.totalSamples }}</td>
          <td>{{ value.topDepth }}</td>
          <td>{{ value.bottomDepth }}</td>
          <td>
            <p-dropdown
              placeholder=" "
              optionLabel="name"
              [options]="depthUnitOption"
              [showClear]="true"
              appendTo="body"
              [editable]="false"
              optionValue="id"
              [(ngModel)]="value.depthUnitId"
              [disabled]="true"
            ></p-dropdown>
          </td>
          <td>{{ value.sampleLocations }}</td>
          <td>{{ value.boxLocations }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="5">{{ 'storage.table.empty-cells' | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
