import { Pipe, PipeTransform } from '@angular/core';

import { logServiceDisplayName } from '../consts/log-service-display-name.const';
import { LogServiceEnum } from '../enums/log-service.enum';

@Pipe({
  name: 'appLogServiceDisplay',
  standalone: true,
})
export class LogServiceDisplayPipe implements PipeTransform {
  transform(logService: LogServiceEnum): string {
    return logServiceDisplayName[logService];
  }
}
