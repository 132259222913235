import { CalendarEvent } from 'calendar-utils';

import { DropDownOption } from '../../../core/models/drop-down-option.model';

export interface Film {
  id: number;
  title: string;
  release_date: string;
}

export interface CalendarModel {
  activity: string;
  date: string;
  technician: string;
  state: string;
  uwiOrUpi: string;
  name: string;
  sampleType: string | null;
  totalBoxes: number | null;
  totalSamples: number | null;
  companyName: string | null;
  contractNumber: string;
  note: string;
  attachedFiles: {
    id: string;
    name: string;
  };
}

export interface CalendarFilterModel {
  month: number;
  year: number;
  states: string[] | null;
  activities: string[] | null;
}

export interface CalendarEventExtended extends CalendarEvent {
  activity: string;
}

export const Activities: DropDownOption[] = [
  {
    id: 'Assignment',
    name: 'calendar.assignment',
  },
  {
    id: 'Service',
    name: 'calendar.service',
  },
];

export const CalendarTitle: { [key: string]: string } = {
  technician: 'Technician',
  state: 'State',
  uwiOrUpi: 'UWI/UPI',
  name: 'Name',
  sampleType: 'Sample type',
  totalBoxes: 'Total boxes',
  totalSamples: 'Total samples',
  companyName: 'Company name',
  contractNumber: 'Contract number',
  note: 'Note',
};
