import { map, Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from '../../core/api.consts';
import { PaginationResponse } from '../../core/models/pagination-response.model';
import { Responsibility } from '../../core/models/responsibility.model';

@Injectable({
  providedIn: 'root',
})
export class ResponsibilityService {
  private url = `${SGC_API}/Responsibilities`;

  constructor(private httpClient: HttpClient) {}

  create(responsibility: Responsibility): Observable<null> {
    return this.httpClient.post<null>(this.url, responsibility);
  }

  edit(responsibility: Responsibility): Observable<null> {
    return this.httpClient.put<null>(`${this.url}/${responsibility.id}`, responsibility);
  }

  getBy(page: number, pageSize: number): Observable<PaginationResponse<Responsibility>> {
    const params = new HttpParams({
      fromObject: { page, pageSize },
    });

    return this.httpClient.get<PaginationResponse<Responsibility>>(this.url, { params });
  }

  getAll(): Observable<Responsibility[]> {
    const params = new HttpParams({ fromObject: { pageSize: -1 } });

    return this.httpClient
      .get<PaginationResponse<Responsibility>>(this.url, { params })
      .pipe(map((response) => response.data));
  }
}
