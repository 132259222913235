import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { DEFAULT_DATE_FORMAT } from '../../core/consts';

@Pipe({
  name: 'appDefaultDateFormat',
  standalone: true,
})
export class DefaultDateFormatPipe implements PipeTransform {
  transform(value: string | number | Date, withTime = false): string {
    const format = withTime ? DEFAULT_DATE_FORMAT.dateTimePipe : DEFAULT_DATE_FORMAT.datePipe;

    return value ? formatDate(value, format, 'en-US') : '';
  }
}
