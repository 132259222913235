<div class="header">
  <div class="title">{{ title }}</div>
</div>
<p-table
  [value]="providers"
  [paginator]="true"
  [rows]="5"
  [showCurrentPageReport]="true"
  [tableStyle]="{ 'min-width': '250px' }"
  currentPageReportTemplate=""
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 30%">{{ 'adminSettings.titles.table.name' | translate }}</th>
      <th style="width: 20%">{{ 'adminSettings.titles.table.contactName' | translate }}</th>
      <th style="width: 20%">{{ 'adminSettings.titles.table.contactNumber' | translate }}</th>
      <th style="width: 20%">{{ 'adminSettings.titles.table.email' | translate }}</th>
      <th style="width: 10%"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-entity>
    <tr>
      <td>{{ entity.companyName }}</td>
      <td>{{ entity.contactName }}</td>
      <td>{{ entity.contactNumber }}</td>
      <td>{{ entity.email }}</td>
      <td>
        <button
          pButton
          type="button"
          class="edit-btn btn"
          (click)="onEdit(entity)"
          icon="pi pi-pencil"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <p-button
      type="button"
      icon="pi pi-plus"
      (click)="showDialog()"
      styleClass="btn-secondary"
    ></p-button>
    <p-dialog
      header="Header"
      [(visible)]="isModalVisible"
      [modal]="true"
      [style]="{ width: '400px' }"
      [draggable]="false"
      [resizable]="false"
      [closable]="false"
    >
      <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{ mode }} {{ type }}</span>
      </ng-template>
      <div class="container add_pallet">
        <span class="p-float-label">
          <input pInputText id="companyName" [(ngModel)]="provider.companyName" />
          <label htmlFor="companyName">{{
            'adminSettings.providers.companyName' | translate
          }}</label>
        </span>
        <span class="p-float-label">
          <input pInputText id="contactName" [(ngModel)]="provider.contactName" />
          <label htmlFor="contactName">{{
            'adminSettings.providers.contactName' | translate
          }}</label>
        </span>
        <span class="p-float-label">
          <input pInputText id="contactNumber" [(ngModel)]="provider.contactNumber" />
          <label htmlFor="contactNumber">{{
            'adminSettings.providers.contactNumber' | translate
          }}</label>
        </span>
        <span class="p-float-label">
          <input pInputText id="email" [(ngModel)]="provider.email" />
          <label htmlFor="email">{{ 'adminSettings.providers.email' | translate }}</label>
        </span>
      </div>
      <ng-template pTemplate="footer">
        <p-button (click)="onCancelModal()" label="Cancel" styleClass="btn-primary"></p-button>
        <p-button
          [disabled]="!provider.companyName || isSavingInProgress"
          (onClick)="onSave()"
          label="Save"
          styleClass="btn-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>
  </ng-template>
</p-table>
