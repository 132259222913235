import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';

import { WellData } from '../models/well-data.model';

@Injectable({
  providedIn: 'root',
})
export class GisMockService {
  mockData: WellData[] = [
    {
      id: '65e85be0f01b97c232d9439a',
      basinId: '93c198fb-dabb-4218-8385-cf7cdc5a1232',
      wellTypeId: '3e0fedb2-5e66-4932-bc27-196bbee31a5e',
      wellName: 'well 3231',
      latitude: 4.6097,
      longitude: -74.0817,
      north: 9,
      east: 7,
      origin: 1,
      uwiUpiCreatedInEpis: false,
      deliveries: [
        {
          contractNumber: 'contact144533',
          uwi: 'UWI 123',
          wellName: 'well 3231',
          samples: [
            { id: '65e85be03b1c1979a82d6708', type: 'Fluid', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be03b1c1979a82d6708', type: 'Core', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be03b1c1979a82d6708', type: 'Crud', topDepth: '11', bottomDepth: '200' },
          ],
        },
        {
          contractNumber: 'contac1413',
          uwi: 'UWI 123',
          wellName: 'well 3231',
          samples: [
            { id: '65e85be03b1c1979a82d6708', type: 'f', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be03b1c1979a82d6708', type: 'c', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be03b1c1979a82d6708', type: 'Crud', topDepth: '11', bottomDepth: '200' },
          ],
        },
      ],
    },
    {
      id: '65e85be0f9eb51a044976106',
      basinId: '694eed85-363c-4db5-b06d-f47783559695',
      wellTypeId: '23c7c17e-2cbb-4b8e-85b1-375e9d9c0ea6',
      latitude: 6.2442,
      longitude: -75.5812,
      north: 9,
      east: 8,
      origin: 7,
      wellName: 'well 3231',
      uwiUpiCreatedInEpis: true,
      deliveries: [
        {
          contractNumber: 'contact113',
          uwi: 'UWI 123',
          wellName: 'well 3231',
          samples: [
            { id: '65e85be03b1c1979a82d6708', type: 'a', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be0sdfa82d6708', type: 'Core', topDepth: '11', bottomDepth: '200' },
            { id: '65e85besdf979a82d6708', type: 'Crud', topDepth: '11', bottomDepth: '200' },
          ],
        },
        {
          contractNumber: 'contact234113',
          uwi: 'UWI 123',
          wellName: 'well 3231',
          samples: [
            { id: '65e85be03dsd82d6708', type: 'c', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be0dsf9a82d6708', type: 'Core', topDepth: '11', bottomDepth: '200' },
            { id: '65e85besdfd6708', type: 'Crud', topDepth: '11', bottomDepth: '200' },
          ],
        },
      ],
    },
    {
      id: '65e85be06f071f87994fdce1',
      basinId: '2d95b04c-4583-47eb-82d1-8ab4bc68029f',
      wellTypeId: '3eedd1b3-a172-4ade-b62f-7c643c5f3a2f',
      wellName: 'well 3231',
      latitude: 11.0041,
      longitude: -74.8065,
      north: 0,
      east: 1,
      origin: 4,
      uwiUpiCreatedInEpis: true,
      deliveries: [
        {
          contractNumber: 'contact14 3',
          uwi: 'UWI 123',
          wellName: 'well 3231',
          samples: [
            { id: '65e85be03b1c1979a82d6708', type: 'p', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be03b1c1979a82d6708', type: 'Core', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be03b1c1979a82d6708', type: 'Crud', topDepth: '11', bottomDepth: '200' },
          ],
        },
        {
          contractNumber: 'contact14 3',
          uwi: 'UWI 123',
          wellName: 'well 3231',
          samples: [
            { id: '65e85be03b1c1979a82d6708', type: 'ps', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be03b1c1979a82d6708', type: 'Core', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be03b1c1979a82d6708', type: 'Crud', topDepth: '11', bottomDepth: '200' },
          ],
        },
      ],
    },
    {
      id: '65e85be0b5b011538eb9b62f',
      basinId: '0633e9a5-04d4-451a-b06d-7af89fdd1edd',
      wellTypeId: '059f1425-e0e7-4f24-9300-3830ecf7f15a',
      wellName: 'well 3231',
      latitude: 3.4216,
      longitude: -76.5205,
      north: 7,
      east: 1,
      origin: 2,
      uwiUpiCreatedInEpis: false,
      deliveries: [
        {
          contractNumber: 'contact 1421133',
          uwi: 'UWI 123',
          wellName: 'well 3231',
          samples: [
            { id: '65e85be03b1c1979a82d6708', type: 'Fluid', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be03b1c1979a82d6708', type: 'Core', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be03b1c1979a82d6708', type: 'Crud', topDepth: '11', bottomDepth: '200' },
          ],
        },
      ],
    },
    {
      id: '65e85be03b1c1979a82d6708',
      basinId: '8f69584d-2de9-40c2-a798-ca86cf3fcfe1',
      wellTypeId: '248f1b86-c2ae-493a-9936-76a8157d2d74',
      wellName: 'well 3231',
      latitude: 7.133,
      longitude: -73.1259,
      north: 7,
      east: 6,
      origin: 5,
      uwiUpiCreatedInEpis: true,
      deliveries: [
        {
          contractNumber: 'contact 233',
          uwi: 'UWI 123',
          wellName: 'well 3231',
          samples: [
            { id: '65e85be03b1c1979a82d6708', type: 'Fluid', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be03b1c1979a82d6708', type: 'Core', topDepth: '11', bottomDepth: '200' },
            { id: '65e85be03b1c1979a82d6708', type: 'Crud', topDepth: '11', bottomDepth: '200' },
          ],
        },
      ],
    },
  ];

  getMockData(): Observable<WellData[]> {
    return of(this.mockData);
  }

  getMockDataByName(name: string): Observable<WellData[]> {
    const filteredData = this.mockData.filter((mock) =>
      mock.deliveries.some((delivery) => delivery.contractNumber === name),
    );

    return of(filteredData);
  }
}
