<div class="header">
  <h2 class="title">{{ title | translate }}</h2>

  <div class="actions">
    <button
      pButton
      pRipple
      type="button"
      label="{{ 'button.clear' | translate }}"
      (click)="resetForm(true)"
      class="btn btn-primary"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      label="{{ 'button.submit' | translate }}"
      class="btn btn-secondary"
      [disabled]="!this.selectedDeliveryId"
      (click)="save()"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      label="{{ 'button.submitAndPrint' | translate }}"
      class="btn btn-secondary ml-1"
      [disabled]="!this.selectedDeliveryId"
      (click)="saveAndPrint()"
    ></button>
  </div>
</div>

<div class="content">
  <p-accordion [multiple]="true">
    <div class="row input-group-50 mb-3">
      <span class="p-input-icon-left p-float-label">
        <i class="pi pi-search"></i>
        <input
          class="search-input"
          id="search-template-input"
          type="text"
          pInputText
          autocomplete="off"
          [readonly]="true"
          (click)="openPopup()"
        />
        <label for="search-template-input">{{ 'button.search' | translate }}</label>
      </span>
    </div>

    <div *ngIf="spinnerShow" class="card spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>

    <form
      *ngIf="selfServicesFormGroup && selectedDeliveryId"
      [formGroup]="selfServicesFormGroup"
      class="h-full"
    >
      <app-contract-information [parentGroup]="selfServicesFormGroup"></app-contract-information>

      <app-operator [required]="false" [parentGroup]="selfServicesFormGroup"></app-operator>

      <app-service-provider
        [required]="false"
        [parentGroup]="selfServicesFormGroup"
      ></app-service-provider>

      <app-well-information
        [required]="false"
        [parentGroup]="selfServicesFormGroup"
      ></app-well-information>

      <app-project-information [parentGroup]="selfServicesFormGroup"></app-project-information>

      <p-accordionTab
        [selected]="isExpanded"
        header="{{ 'selfServices.sampleInformation' | translate }}"
      >
        <div class="row">
          <app-self-service-to-validation-table
            [notDisplayField]="notDisplayField"
            [selfServiceTable]="true"
          ></app-self-service-to-validation-table>
        </div>
      </p-accordionTab>

      <app-transportation-information
        [parentGroup]="selfServicesFormGroup"
        [required]="true"
      ></app-transportation-information>

      <app-additional-information
        [parentGroup]="selfServicesFormGroup"
      ></app-additional-information>
    </form>
  </p-accordion>
</div>

<p-dialog
  header="{{ 'deliveryTable.search' | translate }}"
  [closable]="true"
  [modal]="true"
  [(visible)]="visiblePopup"
  [style]="{ width: '80vw', height: 'fit-content', maxHeight: '90vh' }"
  (onHide)="clearTemplatesIfNothingSelected()"
>
  <div *ngIf="deliveryTemplateSearchSpinner" class="card spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <div class="flex justify-content-between align-items-center">
    <div class="w-6 mt-4 mb-4">
      <span class="p-input-icon-left p-float-label w-full">
        <i class="pi pi-search"></i>
        <input
          class="w-full"
          id="float-input"
          type="text"
          pInputText
          autocomplete="off"
          [formControl]="searchFormControl"
        />
        <label for="float-input">{{
          'adminSettings.wellManagement.searchLabel' | translate
        }}</label>
      </span>
    </div>

    <div class="actions">
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'button.clear' | translate }}"
        (click)="onClearTemplateDeliverySearch()"
        class="btn btn-primary"
      ></button>

      <button
        pButton
        pRipple
        type="button"
        label="{{ 'button.select' | translate }}"
        class="btn btn-secondary"
        [disabled]="!selectedRow"
        (click)="onSelectedDeliveryId(selectedRow!.id)"
      ></button>
    </div>
  </div>

  <p-table
    [value]="deliveryTemplates"
    [paginator]="false"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '250px' }"
    currentPageReportTemplate=""
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: auto">{{ tableHeaderKeysPrefix + 'contractNumber' | translate }}</th>
        <th style="width: auto">{{ tableHeaderKeysPrefix + 'uwi' | translate }}</th>
        <th style="width: auto">{{ tableHeaderKeysPrefix + 'wellName' | translate }}</th>
        <th style="width: auto">{{ tableHeaderKeysPrefix + 'upi' | translate }}</th>
        <th style="width: auto">{{ tableHeaderKeysPrefix + 'projectName' | translate }}</th>
        <th style="width: auto">{{ tableHeaderKeysPrefix + 'longitude' | translate }}</th>
        <th style="width: auto">{{ tableHeaderKeysPrefix + 'latitude' | translate }}</th>
        <th style="width: auto">{{ tableHeaderKeysPrefix + 'serviceProviderName' | translate }}</th>
        <th style="width: auto">{{ tableHeaderKeysPrefix + 'operatorName' | translate }}</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row let-deliveryTemplate>
      <tr class="body-row" [class.selected]="selectedRow === row" (click)="onRowSelect(row)">
        <td>{{ deliveryTemplate.contractNumber }}</td>
        <td>{{ deliveryTemplate.uwi }}</td>
        <td>{{ deliveryTemplate.wellName }}</td>
        <td>{{ deliveryTemplate.upi }}</td>
        <td>{{ deliveryTemplate.projectName }}</td>
        <td>{{ deliveryTemplate.longitude }}</td>
        <td>{{ deliveryTemplate.latitude }}</td>
        <td>{{ deliveryTemplate.serviceProviderCompanyName }}</td>
        <td>{{ deliveryTemplate.operatorCompanyName }}</td>
      </tr>
    </ng-template>
  </p-table>

  <div class="flex justify-content-between align-items-center">
    <button
      pButton
      class="btn btn-primary"
      [label]="'selfServices.requestWell.requestWellBtnTitle' | translate"
      (click)="onRequestWellBtnClicked()"
    ></button>

    <p-paginator
      (onPageChange)="onPageChanged($event)"
      [rows]="paginatorOptions.pageSize"
      [totalRecords]="paginatorOptions.count"
      [rowsPerPageOptions]="[10, 20, 30]"
      [dropdownAppendTo]="'body'"
    ></p-paginator>
  </div>
</p-dialog>

<p-dialog
  header="{{ 'selfServices.requestWell.requestWellPopupTitle' | translate }}"
  [closable]="true"
  [modal]="true"
  [(visible)]="visibleRequestWellPopup"
  [style]="{ width: '700px', height: 'fit-content', maxHeight: '90vh' }"
>
  <div *ngIf="sendWellRequestSpinner" class="card spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <form class="flex flex-column gap-4 mt-4" [formGroup]="wellRequestFormGroup">
    <span class="p-float-label">
      <input
        class="w-full"
        type="text"
        id="request-well-title"
        pInputText
        formControlName="title"
      />
      <label for="request-well-title">{{
        'selfServices.requestWell.controlTitles.title' | translate
      }}</label>
    </span>

    <span class="p-float-label">
      <p-multiSelect
        id="request-well-to"
        formControlName="recipientIds"
        [styleClass]="'w-full'"
        [options]="recipients"
        [readonly]="true"
        [dropdownIcon]="'none'"
        (click)="onRequestWellToFieldClicked()"
        optionValue="id"
        optionLabel="email"
        appendTo="body"
      ></p-multiSelect>

      <label htmlFor="request-well-to">
        {{ 'selfServices.requestWell.controlTitles.to' | translate }}
      </label>
    </span>

    <span class="p-float-label">
      <textarea
        id="request-well-message"
        pInputTextarea
        class="w-full"
        formControlName="message"
      ></textarea>

      <label for="request-well-message">{{
        'selfServices.requestWell.controlTitles.message' | translate
      }}</label>
    </span>
  </form>

  <ng-template pTemplate="footer">
    <button
      pButton
      class="btn btn-primary"
      [label]="'button.clear' | translate"
      (click)="onClearRequestWellBtnClicked()"
    ></button>

    <button
      pButton
      class="btn btn-secondary"
      [label]="'button.send' | translate"
      [disabled]="isSendWellRequestBtnDisabled"
      (click)="onSendRequestWellClicked()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  header="{{ 'selfServices.requestWell.selectRecipientsPopup.title' | translate }}"
  [closable]="true"
  [modal]="true"
  [(visible)]="visibleRequestWellRecipientsPopup"
  [style]="{ width: '1000px', height: 'fit-content', maxHeight: '90vh', maxWidth: '90vw' }"
>
  <div class="mt-4 mb-4">
    <span class="p-input-icon-left p-float-label w-full">
      <i class="pi pi-search"></i>
      <input
        class="w-full"
        id="search-recipients"
        type="text"
        pInputText
        autocomplete="off"
        [formControl]="searchRecipientsFormControl"
      />
      <label for="search-recipients">{{
        'selfServices.requestWell.selectRecipientsPopup.searchRecipientsLabel' | translate
      }}</label>
    </span>
  </div>

  <p-table
    [value]="filteredRecipients"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
    [paginator]="true"
    [paginatorDropdownAppendTo]="'body'"
    [rows]="5"
    [(selection)]="selectedRecipients"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th class="w-auto" pSortableColumn="fullName" field="fullName">
          {{ 'selfServices.requestWell.selectRecipientsPopup.tableTitles.fullName' | translate }}
        </th>
        <th class="w-auto" pSortableColumn="organizationName" field="organizationName">
          {{
            'selfServices.requestWell.selectRecipientsPopup.tableTitles.organizationName'
              | translate
          }}
        </th>
        <th class="w-auto" pSortableColumn="responsibilityNames" field="responsibilityNames">
          {{
            'selfServices.requestWell.selectRecipientsPopup.tableTitles.responsibilityNames'
              | translate
          }}
        </th>
        <th class="w-auto" pSortableColumn="roleName" field="roleName">
          {{ 'selfServices.requestWell.selectRecipientsPopup.tableTitles.roleName' | translate }}
        </th>
        <th class="w-auto" pSortableColumn="email" field="email">
          {{ 'selfServices.requestWell.selectRecipientsPopup.tableTitles.email' | translate }}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-recipient>
      <tr>
        <td>
          <p-tableCheckbox [value]="recipient"></p-tableCheckbox>
        </td>
        <td>{{ recipient.fullName }}</td>
        <td>{{ recipient.organizationName }}</td>
        <td>{{ recipient.responsibilityNames }}</td>
        <td>
          {{ recipient.roleName }}
          <p-tag
            *ngIf="recipient.isAdmin"
            severity="success"
            [value]="'tag.admin' | translate"
            [rounded]="true"
          ></p-tag>
        </td>
        <td>{{ recipient.email }}</td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template pTemplate="footer">
    <button
      pButton
      class="btn btn-primary"
      [label]="'button.clear' | translate"
      (click)="onClearRequestWellRecipientsSearchBtnClicked()"
    ></button>

    <button
      pButton
      class="btn btn-secondary"
      [label]="'button.select' | translate"
      [disabled]="!selectedRecipients.length"
      (click)="onSelectRequestWellRecipientsClicked()"
    ></button>
  </ng-template>
</p-dialog>
