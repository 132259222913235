import { MessageService } from 'primeng/api';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  toastSuccess(content: string): void {
    this.toast('success', 'Success', content);
  }

  toastError(content: string): void {
    this.toast('error', 'Error', content);
  }

  toastWarn(content: string): void {
    this.toast('warn', 'Warn', content);
  }

  toastInfo(content: string): void {
    this.toast('info', 'Info', content);
  }

  toast(type: string, title: string, content: string): void {
    this.messageService.add({
      severity: type,
      summary: title,
      detail: content,
    });
  }
}
