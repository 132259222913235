<div class="header">
  <div class="title">{{ title }}</div>
</div>

<p-table
  [lazy]="true"
  [value]="entities"
  [paginator]="false"
  [rows]="filterData.pageSize"
  editMode="row"
  dataKey="id"
  [showCurrentPageReport]="true"
  [tableStyle]="{ 'min-width': '250px' }"
  currentPageReportTemplate=""
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 50%">{{ 'adminSettings.titles.table.name' | translate }}</th>
      <th style="width: 30%">{{ 'adminSettings.titles.table.count' | translate }}</th>
      <th style="width: 20%"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-entity let-editing="editing" let-ri="rowIndex">
    <tr [pEditableRow]="entity">
      <td>{{ entity.name }}</td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-inputNumber [locale]="defaultLocale" [(ngModel)]="entity.count"></p-inputNumber>
          </ng-template>
          <ng-template pTemplate="output">
            {{ entity.count }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <div class="edit-cell-buttons">
          <button
            *ngIf="!editing"
            pButton
            pRipple
            type="button"
            pInitEditableRow
            icon="pi pi-pencil"
            (click)="duplicateInitialValue(entity)"
            class="edit-btn btn"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pSaveEditableRow
            icon="pi pi-check"
            (click)="onSubmitCountChanges(entity)"
            class="p-button-rounded p-button-text p-button-success mr-2"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pCancelEditableRow
            icon="pi pi-times"
            (click)="onCancelCountChanges(entity)"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
          <p-confirmPopup></p-confirmPopup>
          <button
            pButton
            type="button"
            class="delete-btn btn"
            icon="pi pi-trash"
            (click)="confirm($event, entity.id)"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<div class="footer">
  <div>
    <p-button
      type="button"
      icon="pi pi-plus"
      (click)="showDialog()"
      styleClass="btn-secondary"
    ></p-button>

    <p-dialog
      header="Header"
      [(visible)]="isAddModalVisible"
      [modal]="true"
      [style]="{ width: '400px' }"
      [draggable]="false"
      [resizable]="false"
      [closable]="false"
    >
      <ng-template pTemplate="header">
        <span class="text-xl font-bold"
          >{{ 'adminSettings.titles.actions.new' | translate }} {{ title }}</span
        >
      </ng-template>
      <div class="container add_pallet">
        <span class="p-float-label">
          <input pInputText id="name" [(ngModel)]="entity.name" />
          <label htmlFor="name">{{ 'adminSettings.titles.table.name' | translate }}</label>
        </span>

        <span class="p-float-label">
          <p-inputNumber
            id="count"
            [locale]="defaultLocale"
            [(ngModel)]="entity.count"
          ></p-inputNumber>
          <label htmlFor="count">{{ 'adminSettings.titles.table.count' | translate }}</label>
        </span>
      </div>
      <ng-template pTemplate="footer">
        <p-button (onClick)="onCancelModal()" label="Cancel" styleClass="btn-primary"></p-button>
        <p-button
          [disabled]="!entity.name || isSavingInProgress"
          (onClick)="onSave()"
          label="Save"
          styleClass="btn-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>
  </div>

  <div>
    <p-paginator
      (onPageChange)="onPageChanged($event)"
      [rows]="filterData.pageSize"
      [totalRecords]="filterData.count"
    ></p-paginator>
  </div>
</div>
