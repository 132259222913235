<div class="content">
  <h3 class="checkbox-name">Area</h3>
  <div
    *ngIf="areaFormGroup && areaOptions?.length && areaFormArray"
    [formGroup]="areaFormGroup"
    class="field-wrapper"
  >
    <div formArrayName="area">
      <div *ngFor="let area of areaOptions; let i = index" class="field-checkbox">
        <p-checkbox
          [formControlName]="area"
          [value]="area"
          [inputId]="'warehouse-area-' + i"
          [binary]="true"
        ></p-checkbox>
        <label [for]="'warehouse-area-' + i">{{ area }}</label>
      </div>
    </div>
  </div>
</div>
<div class="actions">
  <button
    pButton
    class="btn btn-primary"
    id="sample-types-chart-filter-cancel-btn"
    (click)="cancel()"
  >
    {{ 'button.cancel' | translate }}
  </button>
  <button
    pButton
    class="btn btn-secondary"
    (click)="apply()"
    cdkFocusInitial
    id="sample-types-chart-filter-confirm-btn"
  >
    {{ 'button.apply' | translate }}
  </button>
</div>
