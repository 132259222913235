import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { WellData } from '../models/well-data.model';

@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [CommonModule, AccordionModule, TranslateModule],
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupComponent {
  @Input() well!: WellData;
  @Output() changeTab: EventEmitter<number> = new EventEmitter<number>();

  viewMore(): void {
    this.changeTab.emit(1);
  }
}
