import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeString',
  standalone: true,
})
export class CapitalizeStringPipe implements PipeTransform {
  transform(str: string): string {
    const words = str.split(/(?=[A-Z])|\s+/);

    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return capitalizedWords.join(' ');
  }
}
