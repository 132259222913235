<div class="container">
  <div class="flex-container margin-bottom-20">
    <input
      type="file"
      accept=".raw"
      appearance="fill"
      style="flex: 0.5; font-size: large"
      (change)="onFileSelected($event)"
    />
    <label #upl for="3d-data-upload">
      <button pButton pRipple (click)="this.upl.click()" class="btn btn-secondary custom-upload">
        Upload .raw files<i class="pi pi-upload"></i>
      </button>
    </label>
    <input
      type="file"
      accept=".raw"
      style="flex: 0.5; font-size: large"
      (change)="onFileSelected($event)"
      id="3d-data-upload"
    />
  </div>
  <div class="flex-container margin-bottom-20 padding-top-10 params-container">
    <span class="p-float-label input-container">
      <p-inputNumber
        inputId="nx"
        [(ngModel)]="nx"
        [showButtons]="true"
        mode="decimal"
        [minFractionDigits]="0"
        [maxFractionDigits]="2"
        [locale]="defaultLocale"
        incrementButtonClass="btn-primary"
        decrementButtonClass="btn-primary"
      >
      </p-inputNumber>
      <label htmlFor="number-input">nx</label>
    </span>
    <div style="flex: 0.1"></div>
    <span class="p-float-label input-container">
      <p-inputNumber
        inputId="ny"
        [(ngModel)]="ny"
        [showButtons]="true"
        mode="decimal"
        [minFractionDigits]="0"
        [maxFractionDigits]="2"
        [locale]="defaultLocale"
        incrementButtonClass="btn-primary"
        decrementButtonClass="btn-primary"
      >
      </p-inputNumber>
      <label htmlFor="number-input">ny</label>
    </span>
  </div>
  <div class="flex-container margin-bottom-20 padding-top-10 params-container">
    <span class="p-float-label input-container">
      <p-inputNumber
        inputId="nz"
        [(ngModel)]="nz"
        [showButtons]="true"
        mode="decimal"
        [minFractionDigits]="0"
        [maxFractionDigits]="2"
        [locale]="defaultLocale"
        incrementButtonClass="btn-primary"
        decrementButtonClass="btn-primary"
      >
      </p-inputNumber>
      <label htmlFor="number-input">nz</label>
    </span>
    <div style="flex: 0.1"></div>
    <span class="p-float-label input-container">
      <p-dropdown [(ngModel)]="dataType" [options]="dataTypeList" inputId="data-type"></p-dropdown>
      <label for="data-type">{{ 'visualization.dataType' | translate }}</label>
    </span>
  </div>
  <div class="flex-container margin-bottom-20">
    <button
      pButton
      pRipple
      class="btn btn-secondary"
      label="{{ 'visualization.open' | translate }}"
      (click)="onOpenClick()"
    ></button>
  </div>
  <div *ngIf="dataArray !== null" class="container">
    <h2>{{ 'visualization.instructions' | translate }}</h2>
    <div class="flex-container margin-bottom-20 margin-top-20 padding-top-10">
      <span class="p-float-label">
        <p-inputNumber
          inputId="max-value"
          [(ngModel)]="maxValue"
          [showButtons]="true"
          mode="decimal"
          [minFractionDigits]="0"
          [maxFractionDigits]="2"
          [locale]="defaultLocale"
          incrementButtonClass="btn-primary"
          decrementButtonClass="btn-primary"
        >
        </p-inputNumber>
        <label htmlFor="number-input">{{ 'visualization.maxValue' | translate }}</label>
      </span>
      <div style="flex: 0.1"></div>
      <span class="p-float-label">
        <p-inputNumber
          inputId="min-value"
          [(ngModel)]="minValue"
          [showButtons]="true"
          mode="decimal"
          [minFractionDigits]="0"
          [maxFractionDigits]="2"
          [locale]="defaultLocale"
          incrementButtonClass="btn-primary"
          decrementButtonClass="btn-primary"
        >
        </p-inputNumber>
        <label htmlFor="number-input">{{ 'visualization.minValue' | translate }}</label>
      </span>
      <div style="flex: 0.1"></div>
      <app-color-maps
        (selectedColormapChange)="handleSelectedColormapChange($event)"
      ></app-color-maps>
    </div>
    <div>
      <app-opacity
        (opacityArrayChange)="handleOpacityArrayChange($event)"
        [maxValue]="maxValue"
        [minValue]="minValue"
        *ngIf="dataArray"
      ></app-opacity>
    </div>
    <!-- color map and opacity apply buttons -->
    <div class="margin-top-20 margin-bottom-20 flex-container">
      <button
        pButton
        pRipple
        class="btn btn-secondary"
        label="{{ 'visualization.changeImageColor' | translate }}"
        (click)="applyColorMap()"
      ></button>

      <button
        pButton
        pRipple
        class="btn btn-secondary"
        label="{{ 'visualization.changeVolumeColor' | translate }}"
        (click)="applyColorMapOnVolume()"
      ></button>

      <button
        pButton
        pRipple
        class="btn btn-secondary"
        label="{{ 'visualization.changeOpacity' | translate }}"
        (click)="applyOpacityOnVolume()"
      ></button>
    </div>
    <div class="example-box">
      <app-image-view
        #imageViewRef
        [inputData]="dataArray"
        [maxValue]="maxValue"
        [minValue]="minValue"
        [maxZ]="max_z"
        *ngIf="dataArray"
      ></app-image-view>
      <div class="spacer"></div>
      <app-volume-view
        #volumeViewRef
        [inputData]="dataArray"
        [maxValue]="maxValue"
        [minValue]="minValue"
        *ngIf="dataArray"
      ></app-volume-view>
    </div>
  </div>
</div>
