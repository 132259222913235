import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RadioButtonModule } from 'primeng/radiobutton';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CapitalizeStringPipe } from 'src/app/common/pipes/capitalize-string.pipe';
import { Destroyable } from 'src/app/core/utils/mixins/destroyable.mixin';

import { WarehouseWidgetService } from '../services/warehouse-widget.service';
import { WarehouseChartFilters } from './models/warehouse-chart-filter.model';

@Component({
  selector: 'app-warehouse-chart-filter',
  standalone: true,
  imports: [
    DialogModule,
    CheckboxModule,
    CommonModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    TranslateModule,
    CapitalizeStringPipe,
  ],
  templateUrl: './warehouse-chart-filter.component.html',
  styleUrls: ['./warehouse-chart-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarehouseChartFilterComponent extends Destroyable(Object) implements OnInit {
  dataObject!: WarehouseChartFilters;
  areaFormGroup!: FormGroup;
  areaOptions!: string[];

  get areaFormArray(): FormGroup {
    return this.areaFormGroup.get('area') as FormGroup;
  }

  constructor(
    public dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private warehouseWidgetService: WarehouseWidgetService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initData();
  }

  initOptions(): void {
    this.warehouseWidgetService
      .getAreas()
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.areaOptions = data;
        this.initAreasFormGroup();
        this.cd.detectChanges();
      });
  }

  apply(): void {
    const areaFormGroupValue = this.areaFormArray.getRawValue();

    const areaIds = Object.entries(areaFormGroupValue)
      .filter(([key, value]) => value === true)
      .map(([key]) => {
        return key;
      });

    const newDataObject: WarehouseChartFilters = {
      areaIds,
    };

    this.dialogRef.close(newDataObject);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  private initData(): void {
    this.dataObject = this.dialogConfig.data;
    this.initOptions();
  }

  private initAreasFormGroup(): void {
    const selectedAreas = this.dataObject.areaIds ? [...this.dataObject.areaIds] : [];

    const formControls = new FormGroup(
      this.areaOptions.reduce((acc, item) => {
        return { ...acc, [item]: new FormControl(selectedAreas.includes(item)) };
      }, {}),
    );

    this.areaFormGroup = new FormGroup({
      area: formControls,
    });
  }
}
