import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { OptionsTableEntityKey } from '../core/enums/options-table-entity-key.enum';
import { IdName } from '../core/models/id-name.model';
import { ProjectOptionsService } from './api/project-options.service';
import { ProjectStateService } from './api/project-state.service';
import { WellInfoService } from './api/well-info.service';

@Injectable({
  providedIn: 'root',
})
export class OptionsTableEntityKeyToActionMapService {
  constructor(
    private projectOptionsService: ProjectOptionsService,
    private projectStateService: ProjectStateService,
    private wellInfoService: WellInfoService,
  ) {}

  doAction(
    action: 'post' | 'delete',
    key: OptionsTableEntityKey,
    endpointParam: string,
  ): Observable<IdName | null> {
    return action === 'post'
      ? this.createEntity(key, endpointParam)
      : this.deleteEntity(key, endpointParam);
  }

  private createEntity(key: OptionsTableEntityKey, name: string): Observable<IdName> {
    const config: Record<OptionsTableEntityKey, (...args: string[]) => Observable<IdName>> = {
      [OptionsTableEntityKey.basinTypes]: this.wellInfoService.createBasinType.bind(
        this.wellInfoService,
      ),
      [OptionsTableEntityKey.biostratigraphicSampleTypes]:
        this.projectOptionsService.createBiostratigraphicSampleType.bind(
          this.projectOptionsService,
        ),
      [OptionsTableEntityKey.boxTypes]: this.projectOptionsService.createBoxType.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.depthUnits]: this.projectOptionsService.createDepthUnit.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.endCupFractionInfos]:
        this.projectOptionsService.createEndCupFractionInfo.bind(this.projectOptionsService),
      [OptionsTableEntityKey.extractionPositionInfos]:
        this.projectOptionsService.createExtractionPositionInfo.bind(this.projectOptionsService),
      [OptionsTableEntityKey.fluidTypes]: this.projectOptionsService.createFluidType.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.lengthUnits]: this.projectOptionsService.createLengthUnit.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.mediumDeliveryInfos]:
        this.projectOptionsService.createMediumDeliveryInfo.bind(this.projectOptionsService),
      [OptionsTableEntityKey.orientationTypes]:
        this.projectOptionsService.createOrientationType.bind(this.projectOptionsService),
      [OptionsTableEntityKey.physicalStatuses]:
        this.projectOptionsService.createPhysicalStatus.bind(this.projectOptionsService),
      [OptionsTableEntityKey.plateTypes]: this.projectOptionsService.createPlateType.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.preparationTypes]:
        this.projectOptionsService.createPreparationType.bind(this.projectOptionsService),
      [OptionsTableEntityKey.preservationTypes]:
        this.projectOptionsService.createPreservationType.bind(this.projectOptionsService),
      [OptionsTableEntityKey.sampleOrigins]: this.projectOptionsService.createSampleOrigin.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.sampleStatuses]: this.projectOptionsService.createSampleStatus.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.samplingMethods]: this.projectOptionsService.createSamplingMethod.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.sectionTypes]: this.projectOptionsService.createSectionType.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.surfaceSampleTypes]:
        this.projectOptionsService.createSurfaceSampleType.bind(this.projectOptionsService),
      [OptionsTableEntityKey.technicalInfoses]: this.projectOptionsService.createTechnicalInfo.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.wellTypes]: this.wellInfoService.createWellType.bind(
        this.wellInfoService,
      ),
      [OptionsTableEntityKey.weightUnits]: this.projectOptionsService.createWeightUnit.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.zhZsDatObjectiveSets]:
        this.projectOptionsService.createZhZsDatObjectiveSet.bind(this.projectOptionsService),
      [OptionsTableEntityKey.zhZsDatSampleTypes]:
        this.projectOptionsService.createZhZsDatSampleType.bind(this.projectOptionsService),
      [OptionsTableEntityKey.volumeUnits]: this.projectOptionsService.createVolumeUnit.bind(
        this.projectOptionsService,
      ),
    };

    return config[key]!(name);
  }

  private deleteEntity(key: OptionsTableEntityKey, id: string): Observable<null> {
    const config: Record<OptionsTableEntityKey, (...args: string[]) => Observable<null>> = {
      [OptionsTableEntityKey.basinTypes]: this.wellInfoService.deleteBasinType.bind(
        this.wellInfoService,
      ),
      [OptionsTableEntityKey.biostratigraphicSampleTypes]:
        this.projectOptionsService.deleteBiostratigraphicSampleType.bind(
          this.projectOptionsService,
        ),
      [OptionsTableEntityKey.boxTypes]: this.projectOptionsService.deleteBoxType.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.depthUnits]: this.projectOptionsService.deleteDepthUnit.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.endCupFractionInfos]:
        this.projectOptionsService.deleteEndCupFractionInfo.bind(this.projectOptionsService),
      [OptionsTableEntityKey.extractionPositionInfos]:
        this.projectOptionsService.deleteExtractionPositionInfo.bind(this.projectOptionsService),
      [OptionsTableEntityKey.fluidTypes]: this.projectOptionsService.deleteFluidType.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.lengthUnits]: this.projectOptionsService.deleteLengthUnit.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.mediumDeliveryInfos]:
        this.projectOptionsService.deleteMediumDeliveryInfo.bind(this.projectOptionsService),
      [OptionsTableEntityKey.orientationTypes]:
        this.projectOptionsService.deleteOrientationType.bind(this.projectOptionsService),
      [OptionsTableEntityKey.physicalStatuses]:
        this.projectOptionsService.deletePhysicalStatus.bind(this.projectOptionsService),
      [OptionsTableEntityKey.plateTypes]: this.projectOptionsService.deletePlateType.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.preparationTypes]:
        this.projectOptionsService.deletePreparationType.bind(this.projectOptionsService),
      [OptionsTableEntityKey.preservationTypes]:
        this.projectOptionsService.deletePreservationType.bind(this.projectOptionsService),
      [OptionsTableEntityKey.sampleOrigins]: this.projectOptionsService.deleteSampleOrigin.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.sampleStatuses]: this.projectOptionsService.deleteSampleStatus.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.samplingMethods]: this.projectOptionsService.deleteSamplingMethod.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.sectionTypes]: this.projectOptionsService.deleteSectionType.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.surfaceSampleTypes]:
        this.projectOptionsService.deleteSurfaceSampleType.bind(this.projectOptionsService),
      [OptionsTableEntityKey.technicalInfoses]: this.projectOptionsService.deleteTechnicalInfo.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.wellTypes]: this.wellInfoService.deleteWellType.bind(
        this.wellInfoService,
      ),
      [OptionsTableEntityKey.weightUnits]: this.projectOptionsService.deleteWeightUnit.bind(
        this.projectOptionsService,
      ),
      [OptionsTableEntityKey.zhZsDatObjectiveSets]:
        this.projectOptionsService.deleteZhZsDatObjectiveSet.bind(this.projectOptionsService),
      [OptionsTableEntityKey.zhZsDatSampleTypes]:
        this.projectOptionsService.deleteZhZsDatSampleType.bind(this.projectOptionsService),
      [OptionsTableEntityKey.volumeUnits]: this.projectOptionsService.deleteVolumeUnit.bind(
        this.projectOptionsService,
      ),
    };

    return config[key]!(id);
  }
}
