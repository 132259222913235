import { map, Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from 'src/app/core/api.consts';

import { SEPARATOR_BETWEEN_PALLET_LABEL_AND_CONTAINER_COUNT } from '../../../core/consts';
import { EntityCacheKey } from '../../../core/enums/entity-cache-key.enum';
import { ContainerModel } from '../../../core/models/container.model';
import { LocationModel } from '../../../core/models/location.model';
import { PalletModel } from '../../../core/models/pallet.model';
import { CacheService } from '../../../services/cache.service';
import { Localize } from '../models/localize.model';
import { PalletStorageModel } from '../models/pallet-search.model';
import { Palletize } from '../models/palletize.model';
import { SampleModel } from '../models/sample.model';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private url = `${SGC_API}/storage`;
  private samplesUrl = `${this.url}/samples`;
  private palletsUrl = `${this.url}/pallets`;
  private containersUrl = `${this.url}/containers`;
  private locationsUrl = `${this.url}/locations`;

  private palletizeUrl = `${this.url}/palletize`;
  private depalletizeUrl = `${this.url}/depalletize`;
  private localizeUrl = `${this.url}/locallize`;

  private createDeletePalletUrl = `${this.url}/pallet`;
  private createDeleteContainerUrl = `${this.url}/container`;
  private createDeleteLocationUrl = `${this.url}/location`;

  private newPalletLabel = `${this.url}/newPalletLabel`;
  private newContainerLabel = `${this.url}/newContainerLabel`;

  constructor(private httpClient: HttpClient, private cacheService: CacheService) {}

  getAllPallets(): Observable<PalletStorageModel[]> {
    const params = new HttpParams().set('pageSize', -1);

    return this.cacheService.getCachedObservable(
      EntityCacheKey.PALLETS,
      this.httpClient.get<PalletStorageModel[]>(this.palletsUrl, { params }).pipe(
        map((pallets) =>
          pallets.map((pallet) => {
            return {
              ...pallet,
              palletLabel: `${pallet.palletLabel}${SEPARATOR_BETWEEN_PALLET_LABEL_AND_CONTAINER_COUNT}${pallet.containerCount}`,
              originalPalletLabel: pallet.palletLabel,
            };
          }),
        ),
      ),
    );
  }

  getAllContainers(): Observable<ContainerModel[]> {
    const params = new HttpParams().set('pageSize', -1);

    return this.cacheService.getCachedObservable(
      EntityCacheKey.CONTAINERS,
      this.httpClient.get<ContainerModel[]>(this.containersUrl, { params }),
    );
  }

  getAllLocations(): Observable<LocationModel[]> {
    const params = new HttpParams().set('pageSize', -1);

    return this.cacheService.getCachedObservable(
      EntityCacheKey.LOCATIONS,
      this.httpClient.get<LocationModel[]>(this.locationsUrl, { params }),
    );
  }

  searchProjects(searchText: string, filter: string): Observable<SampleModel[]> {
    const params = { SampleFilter: filter, SearchQuery: searchText };
    return this.httpClient.get<SampleModel[]>(`${this.samplesUrl}/filter`, { params });
  }

  searchPallets(palletOrLocation: string, filter: string): Observable<PalletStorageModel[]> {
    const params = { PalletFilter: filter, SearchQuery: palletOrLocation };
    return this.httpClient.get<PalletStorageModel[]>(`${this.palletsUrl}/filter`, { params });
  }

  palletize(pallet: Palletize): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PALLETS,
      this.httpClient.post<null>(this.palletizeUrl, pallet),
    );
  }

  depalletize(pallet: Palletize): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PALLETS,
      this.httpClient.post<null>(this.depalletizeUrl, pallet),
    );
  }

  localize(location: Localize): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.LOCATIONS,
      this.httpClient.post<null>(this.localizeUrl, location),
    );
  }

  createPallet(pallet: PalletModel): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PALLETS,
      this.httpClient.post<null>(`${this.createDeletePalletUrl}`, pallet),
    );
  }

  createContainer(container: ContainerModel): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.CONTAINERS,
      this.httpClient.post<null>(`${this.createDeleteContainerUrl}`, container),
    );
  }

  createLocation(location: LocationModel): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.LOCATIONS,
      this.httpClient.post<null>(`${this.createDeleteLocationUrl}`, location),
    );
  }

  deletePallet(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PALLETS,
      this.httpClient.delete<null>(`${this.createDeletePalletUrl}/${id}`),
    );
  }

  deleteContainer(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.CONTAINERS,
      this.httpClient.delete<null>(`${this.createDeletePalletUrl}/${id}`),
    );
  }

  deleteLocation(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.LOCATIONS,
      this.httpClient.delete<null>(`${this.createDeleteLocationUrl}/${id}`),
    );
  }

  generateNewPalletLabel(): Observable<string> {
    return this.httpClient.get(this.newPalletLabel, { responseType: 'text' });
  }

  generateNewContainerLabel(): Observable<string> {
    return this.httpClient.get(this.newContainerLabel, { responseType: 'text' });
  }
}
