import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from 'src/app/core/api.consts';

import { DashboardFilterResult } from '../sample-types-chart-filter/models/dashboard-filter-result.model';
import { SampleTypesFilter } from '../sample-types-chart-filter/models/sample-types-filter.model';

@Injectable({
  providedIn: 'root',
})
export class SampleTypesService {
  private filterUrl = `${SGC_API}/dashboards/filter`;
  constructor(private http: HttpClient) {}

  getFilteredSamples(filteredData: SampleTypesFilter): Observable<DashboardFilterResult[]> {
    const parameters = new HttpParams()
      .set('StartDate', filteredData.startDate)
      .set('EndDate', filteredData.endDate)
      .set('Display', filteredData.display)
      .set('TimeZoneIana', filteredData.timeZoneIana)
      .appendAll(filteredData.sampleTypes);

    return this.http.get<Array<DashboardFilterResult>>(`${this.filterUrl}`, {
      params: parameters,
    });
  }
}
