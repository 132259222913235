import { DeliveryTotalNumber } from '../../../common/delivery-table-search-uwi-contract/models/delivery-total-number.model';
import { TabInfo } from '../../../common/self-service-to-validation-table/models/self-service-to-validation.model';
import { User } from '../../admin-settings/user-table/user.model';
import {
  Verification,
  VerificationSampleInformation,
  VerificationShape,
} from '../../verification/models/verification-sample-information.model';

export class ValidationShapeImpl implements VerificationShape {
  sampleTypeId?: string;
  contractId: string;
  searchSample?: string;
  contractNumber: string;
  yearOfContract: string;
  operatorId: string | null;
  operatorContactName: string | null;
  operatorContactNumber: string | null;
  operatorEmail: string | null;
  serviceProviderId: string | null;
  serviceProviderContactName: string | null;
  serviceProviderContactNumber: string | null;
  serviceProviderEmail: string | null;
  transportationProviderId: string | null;
  uwiUpiCreatedInEpis: boolean;
  upi: string;
  projectName: string;
  notes: string;
  receivingTechnicianId: string;
  verificationTechnicianId: string;
  validationTechnicianId: string;
  totalNumberOfBoxes: number;
  totalNumberOfSamples: number;
  zhZsDatBoxes: VerificationSampleInformation[];
  coreBoxes: VerificationSampleInformation[];
  preservedBoxes: VerificationSampleInformation[];
  plugBoxes: VerificationSampleInformation[];
  swcBoxes: VerificationSampleInformation[];
  fluidBoxes: VerificationSampleInformation[];
  residueBoxes: VerificationSampleInformation[];
  petrographicPlatesBoxes: VerificationSampleInformation[];
  biostratigraphicBoxes: VerificationSampleInformation[];
  surfaceSampleBoxes: VerificationSampleInformation[];
  pistonCoreBoxes: VerificationSampleInformation[];
  crudeBoxes: VerificationSampleInformation[];
  deliveryId?: string;
  wellInformation: any;

  constructor(
    deliveryId: string,
    contractId: string,
    userOption: User[],
    totalNumber: DeliveryTotalNumber,
    ss?: Verification,
    table?: TabInfo[],
  ) {
    this.deliveryId = deliveryId;
    this.contractId = contractId;
    this.searchSample = '';
    this.contractNumber = ss?.contractInformation?.contractNumber ?? '';
    this.yearOfContract = ss!.contractInformation?.contractYear;
    this.operatorId = ss?.operator?.companyName ?? null;
    this.operatorContactName = ss?.operator?.contactName ?? null;
    this.operatorContactNumber = ss?.operator?.contactNumber ?? null;
    this.operatorEmail = ss?.operator?.email ?? null;
    this.serviceProviderId = ss?.serviceProvider?.companyName ?? null;
    this.serviceProviderContactName = ss?.serviceProvider?.contactName ?? null;
    this.serviceProviderContactNumber = ss?.serviceProvider?.contactNumber ?? null;
    this.serviceProviderEmail = ss?.serviceProvider?.email ?? null;
    this.transportationProviderId = ss?.transportationInformation?.companyName ?? null;
    this.wellInformation = {
      id: ss?.wellInformation?.id || null,
      basinId: ss?.wellInformation?.basin || null,
      uwi: ss?.wellInformation?.uwi ?? '',
      wellName: ss?.wellInformation?.wellName ?? '',
      wellTypeId: ss?.wellInformation?.wellType || null,
      latitude: ss?.wellInformation?.latitude ?? null,
      longitude: ss?.wellInformation?.longitude ?? null,
      north: ss?.wellInformation?.north ?? null,
      east: ss?.wellInformation?.east ?? null,
      origin: ss?.wellInformation?.origin ?? '',
      uwiUpiCreatedInEpis: ss?.wellInformation.uwiUpiCreatedInEpis ?? false,
    } as any;
    this.upi = ss?.projectInformation?.upi ?? '';
    this.projectName = ss?.projectInformation?.projectName ?? '';
    this.uwiUpiCreatedInEpis = ss?.projectInformation.uwiUpiCreatedInEpis ?? false;
    this.notes = ss?.additionalInformation?.notes ?? '';
    this.validationTechnicianId = ss?.validationTechnicalInformation.name ?? '';
    this.receivingTechnicianId = ss?.technicalInformation.name ?? '';
    this.verificationTechnicianId = ss?.verifyingTechnicalInformation.name ?? '';
    this.totalNumberOfBoxes = totalNumber.totalCount;
    this.totalNumberOfSamples = totalNumber.sumOfValue;
    this.zhZsDatBoxes = table![0].data ?? [];
    this.coreBoxes = table![1].data ?? [];
    this.preservedBoxes = table![2].data ?? [];
    this.plugBoxes = table![3].data ?? [];
    this.swcBoxes = table![4].data ?? [];
    this.fluidBoxes = table![5].data ?? [];
    this.residueBoxes = table![6].data ?? [];
    this.petrographicPlatesBoxes = table![7].data ?? [];
    this.biostratigraphicBoxes = table![8].data ?? [];
    this.surfaceSampleBoxes = table![9].data ?? [];
    this.pistonCoreBoxes = table![10].data ?? [];
    this.crudeBoxes = table![11].data ?? [];
  }
}
