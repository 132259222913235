import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { debounceTime } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DefaultDateFormatPipe } from '../../../../common/pipes/default-date-format.pipe';
import { PageEvent } from '../../../../core/models/page-event.model';
import { PaginatorOptions } from '../../../../core/models/paginator-options.model';
import { Destroyable } from '../../../../core/utils/mixins/destroyable.mixin';
import { GisService } from '../../../../services/api/gis.service';
import { WellData } from '../models/well-data.model';

@Component({
  selector: 'app-gis-table',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TableModule,
    DefaultDateFormatPipe,
    ButtonModule,
    RippleModule,
    InputTextModule,
    PaginatorModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
  ],
  templateUrl: './gis-table.component.html',
  styleUrls: ['./gis-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GisTableComponent extends Destroyable(Object) {
  @Input() tableDataCount!: number;
  @Output() selectedWell: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeWellData: EventEmitter<{ data: WellData[]; resetMap: boolean }> =
    new EventEmitter<{
      data: WellData[];
      resetMap: boolean;
    }>();

  tableHeaders: string[] = [
    'deliveryTable.contractNumber',
    'deliveryTable.wellName',
    'deliveryTable.uwi',
    'deliveryTable.latitude',
    'deliveryTable.longitude',
    'deliveryTable.north',
    'deliveryTable.origin',
    'deliveryTable.sampleType',
  ];

  tableData: WellData[] = [];
  spinnerShow = false;

  selectedRow: WellData | null = null;
  searchText!: string;
  searchInputControl = new FormControl('');
  paginatorOptions: PaginatorOptions = {
    page: 0,
    pageSize: 100,
    count: 0,
  };

  constructor(private gisService: GisService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.getWellData(0, 200);
    this.inputSearch();
    this.cd.markForCheck();
  }

  onRowSelect(row: WellData): void {
    this.selectedRow = row;
  }

  resetPage(): void {
    this.searchText = '';
    this.selectedRow = null;
    this.getWellData(0, 200, true);
  }

  select(): void {
    this.selectedWell.emit(this.selectedRow);
  }

  getWellData(page: number, pageSize: number, resetMap = false): void {
    this.spinnerShow = true;
    this.cd.detectChanges();
    this.gisService
      .getGisBy(page, pageSize, this.searchInputControl.value! || '')
      .pipe(this.takeUntilDestroyed())
      .subscribe((resp) => {
        this.paginatorOptions.count = resp.count;
        const dataArr = Array.from(resp.data);
        this.tableData = dataArr;
        this.changeWellData.emit({ data: dataArr, resetMap });
        this.spinnerShow = false;
        this.cd.markForCheck();
      });
  }

  onPageChanged(pageEvent: PageEvent): void {
    this.paginatorOptions = {
      ...this.paginatorOptions,
      page: pageEvent.page,
      pageSize: pageEvent.rows,
    };

    this.getWellData(this.paginatorOptions.page + 1, this.paginatorOptions.pageSize, true);
  }

  private inputSearch(): void {
    this.searchInputControl.valueChanges.pipe(debounceTime(450)).subscribe((value) => {
      this.getWellData(this.paginatorOptions.page + 1, this.paginatorOptions.pageSize, true);
    });
  }
}
