import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { LangOption } from 'src/app/core/models/lang-option.model';

@Component({
  selector: 'app-translation',
  standalone: true,
  imports: [CommonModule, FormsModule, SelectButtonModule, TooltipModule, ToolbarModule],
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationComponent {
  readonly langLocalStorageKey = 'language';
  readonly langOptions: LangOption[] = [
    { tooltip: 'English', icon: 'fi fi-us', code: 'en' },
    { tooltip: 'Español', icon: 'fi fi-co', code: 'es' },
  ];

  lang = '';

  constructor(private translate: TranslateService, private primeNGConfig: PrimeNGConfig) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');
    this.updateLang(localStorage.getItem(this.langLocalStorageKey));
  }

  changeLang(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem(this.langLocalStorageKey, lang);
  }

  private updateLang(localStoreLang: string | null): void {
    const browserLang = this.translate.getBrowserLang() ?? '';
    this.lang = browserLang.match(/en|es/) ? browserLang : 'en';
    this.lang = localStoreLang !== null ? localStoreLang : this.lang;
    this.changeLang(this.lang);
  }
}
