import { User } from '../../features/admin-settings/user-table/user.model';
import { Responsibility } from './responsibility.model';
import { Role } from './role.model';

export class RecipientTableRow {
  id: string;
  fullName: string;
  organizationName: string;
  responsibilityNames: string;
  roleName: string;
  isAdmin: boolean;
  email: string;

  constructor(user: User, roles: Role[], responsibilities: Responsibility[]) {
    this.id = user.id!;
    this.fullName = (user.firstName ?? '') + (user.lastName ? ` ${user.lastName}` : '');
    this.organizationName = user.organizationName;
    this.responsibilityNames = responsibilities
      .filter((responsibility) => user.responsibilityIds.includes(responsibility.id!))
      .map((responsibility) => responsibility.name)
      .join('');
    this.roleName = roles.find((role) => role.id === user.roleId)?.name ?? '';
    this.isAdmin = user.isAdmin!;
    this.email = user.email;
  }
}
