<div class="header">
  <div class="title">{{ title }}</div>
</div>

<p-table
  [value]="entities"
  [paginator]="true"
  [rows]="5"
  [showCurrentPageReport]="true"
  [tableStyle]="{ 'min-width': '250px' }"
  currentPageReportTemplate=""
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 30%">{{ 'adminSettings.titles.table.name' | translate }}</th>
      <th style="width: 30%">{{ 'adminSettings.titles.table.driverName' | translate }}</th>
      <th style="width: 30%">
        {{ 'adminSettings.titles.table.vehiclesLicensePlate' | translate }}
      </th>
      <th style="width: 10%"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-entity>
    <tr>
      <td>{{ entity.companyName }}</td>
      <td>{{ entity.driverName }}</td>
      <td>{{ entity.licensePlate }}</td>
      <td>
        <button
          pButton
          type="button"
          class="edit-btn btn"
          (click)="onEdit(entity)"
          icon="pi pi-pencil"
        ></button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <p-button
      type="button"
      icon="pi pi-plus"
      (click)="onCreate()"
      styleClass="btn-secondary"
    ></p-button>

    <p-dialog
      header="Header"
      [(visible)]="isModalVisible"
      [modal]="true"
      [style]="{ width: '400px' }"
      [draggable]="false"
      [resizable]="false"
      [closable]="false"
    >
      <ng-template pTemplate="header">
        <span class="text-xl font-bold"
          >{{ modeKey | translate }}
          {{ 'adminSettings.titles.entities.transportations' | translate }}</span
        >
      </ng-template>

      <div class="container add_pallet">
        <span class="p-float-label">
          <input pInputText id="companyName" [(ngModel)]="entity.companyName" />
          <label htmlFor="companyName">{{
            'adminSettings.titles.table.companyName' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input pInputText id="contactNumber" [(ngModel)]="entity.contactNumber" />
          <label htmlFor="contactNumber">{{
            'adminSettings.titles.table.contactNumber' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input pInputText id="driverName" [(ngModel)]="entity.driverName" />
          <label htmlFor="driverName">{{
            'adminSettings.titles.table.driverName' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input pInputText id="driverDocument" [(ngModel)]="entity.driverDocument" />
          <label htmlFor="driverDocument">{{
            'adminSettings.titles.table.driverDocument' | translate
          }}</label>
        </span>

        <span class="p-float-label">
          <input pInputText id="licensePlate" [(ngModel)]="entity.licensePlate" />
          <label htmlFor="licensePlate">{{
            'adminSettings.titles.table.vehiclesLicensePlate' | translate
          }}</label>
        </span>
      </div>

      <ng-template pTemplate="footer">
        <p-button
          (click)="onCancelModal()"
          [label]="'button.cancel' | translate"
          styleClass="btn-primary"
        ></p-button>

        <p-button
          [disabled]="!entity.companyName || isSavingInProgress"
          (onClick)="onSave()"
          [label]="'button.save' | translate"
          styleClass="btn-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>
  </ng-template>
</p-table>
