import { ChartModule } from 'angular-highcharts';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';

import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-plot',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
    NgChartsModule,
    DropdownModule,
    RippleModule,
    ButtonModule,
  ],
  templateUrl: './plot.component.html',
  styleUrls: ['./plot.component.scss', './../upload-styles.scss'],
})
export class PlotComponent {
  txtContent: any = null;
  colors: any = ['red', 'green', 'yellow', 'purple', 'blue', 'pink', 'brown'];

  plot_x_select = 'None';
  plot_y_select = 'None';
  plot_column_list: any = null;
  plot_show: any[] = [];

  plot_x_select_2 = 'None';
  plot_y_select_2 = 'None';
  plot_show_2: any = [];

  public plotOption: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        grid: {
          lineWidth: 0,
        },
        title: {
          display: true,
          text: 'X-Axis Label',
        },
      },
      y: {
        display: true,
        grid: {
          lineWidth: 0,
        },
        title: {
          display: true,
          text: 'X-Axis Label',
        },
        min: 4028,
        max: 4032,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  public plotOptions: ChartOptions[] = [];

  plotData: any = {
    datasets: [
      {
        data: [
          { x: 5, y: 9 },
          { x: 3, y: 10 },
          { x: 1, y: 6 },
        ],
        backgroundColor: 'rgba(0, 255, 0, 0.8)',
        borderColor: 'rgba(0, 255, 0, 1)',
        borderWidth: 1,
        pointRadius: 0,
        showLine: true,
        fill: false,
      },
    ],
  };

  public plotChartDatas: ChartData<'line'>[] = [];
  public plotChartType: ChartType = 'scatter';

  public plotOption_2: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        display: true,
        grid: {
          lineWidth: 0,
        },
        title: {
          display: true,
          text: 'X-Axis Label',
        },
      },
      y: {
        display: true,
        grid: {
          lineWidth: 0,
        },
        title: {
          display: true,
          text: 'X-Axis Label',
        },
        min: 4028,
        max: 4032,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  public plotOptions_2: ChartOptions[] = [];

  plotData_2: any = {
    datasets: [
      {
        data: [
          { x: 5, y: 9 },
          { x: 3, y: 10 },
          { x: 1, y: 6 },
        ],
        backgroundColor: 'rgba(0, 255, 0, 0.8)',
        borderColor: 'rgba(0, 255, 0, 1)',
        borderWidth: 1,
        pointRadius: 5,
        fill: false,
      },
    ],
  };

  public plotChartDatas_2: ChartData<'line'>[] = [];
  public plotChartType_2: ChartType = 'scatter';

  constructor(private cd: ChangeDetectorRef) {}

  onTxtFileSelected(event: any) {
    const { files } = event.target;
    this.txtContent = null;
    this.plot_column_list = [];
    this.plotChartDatas = [];
    this.plotOptions = [];
    this.plot_show = [];
    this.plotChartDatas_2 = [];
    this.plotOptions_2 = [];
    this.plot_show_2 = [];

    this.plot_x_select = 'None';
    this.plot_y_select = 'None';
    this.plot_x_select_2 = 'None';
    this.plot_y_select_2 = 'None';

    if (files.length === 0) {
      this.txtContent = null;
      return;
    }

    const file = event.target.files[0];

    if (file.name.endsWith('.txt')) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const result = reader.result as string;
        this.txtContent = result;

        const rows = this.txtContent.split('\n');

        const headers = rows[0].trim().split(' ');

        let isAllString = true;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < headers.length; i++) {
          if (!Number.isNaN(headers[i])) {
            isAllString = false;
            break;
          }
        }

        if (!isAllString) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < headers.length; i++) {
            headers[i] = `column_${i}`;
          }
        }

        this.plot_column_list = headers;
        const data: { [key: string]: any[] } = {};

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < rows.length; i++) {
          const row = rows[i].trim().split(' ');
          if (row[0] !== '') {
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < headers.length; j++) {
              if (!data[headers[j]]) {
                data[headers[j]] = [];
              }
              if (row[j] === -999) {
                row[j] = null;
              }

              data[headers[j]].push(parseFloat(row[j]));
            }
          }
        }

        this.txtContent = data;
        this.plot_column_list.unshift('None');
        this.cd.detectChanges();
      };
    } else {
      alert('only txt files are supported');
    }
  }

  onAddPlot() {
    const dd = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.txtContent[this.plot_x_select].length; i++) {
      const x = this.txtContent[this.plot_x_select][i];
      const y = this.txtContent[this.plot_y_select][i];
      dd.push({ x, y });
    }

    const minValue = Math.min(...this.txtContent[this.plot_y_select]);
    const maxValue = Math.max(...this.txtContent[this.plot_y_select]);

    this.plotChartDatas.push(JSON.parse(JSON.stringify(this.plotData)));
    this.plotChartDatas[this.plotChartDatas.length - 1].datasets[0].data = dd;
    this.plot_show.push(false);

    const option = JSON.parse(JSON.stringify(this.plotOption));
    const moreSpace = (maxValue - minValue) * 0.05;
    option.scales.y.min = minValue - moreSpace;
    option.scales.y.max = maxValue + moreSpace;
    option.scales.y.title.text = this.plot_y_select;
    option.scales.x.title.text = this.plot_x_select;
    this.plotOptions.push(JSON.parse(JSON.stringify(option)));

    setTimeout(() => {
      this.plot_show[this.plot_show.length - 1] = true;
      this.cd.detectChanges();
    }, 10);
  }

  onClearPlot() {
    this.plotChartDatas = [];
    this.plotOptions = [];
    this.plot_show = [];
  }

  onAddScatterPlot() {
    const dd = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.txtContent[this.plot_x_select_2].length; i++) {
      const x = this.txtContent[this.plot_x_select_2][i];
      const y = this.txtContent[this.plot_y_select_2][i];
      dd.push({ x, y });
    }

    const minValue = Math.min(...this.txtContent[this.plot_y_select_2]);
    const maxValue = Math.max(...this.txtContent[this.plot_y_select_2]);

    this.plotChartDatas_2.push(JSON.parse(JSON.stringify(this.plotData_2)));
    this.plotChartDatas_2[this.plotChartDatas_2.length - 1].datasets[0].data = dd;
    this.plot_show_2.push(false);

    const option = JSON.parse(JSON.stringify(this.plotOption_2));
    option.scales.y.min = minValue;
    option.scales.y.max = maxValue;
    option.scales.y.title.text = this.plot_y_select_2;
    option.scales.x.title.text = this.plot_x_select_2;
    this.plotOptions_2.push(JSON.parse(JSON.stringify(option)));

    setTimeout(() => {
      this.plot_show_2[this.plot_show_2.length - 1] = true;
      this.cd.detectChanges();
    }, 10);
  }

  onClearScatterPlot() {
    this.plotChartDatas_2 = [];
    this.plotOptions_2 = [];
    this.plot_show_2 = [];
  }
}
