<div class="header">
  <div class="title">{{ title }}</div>
</div>

<p-table
  [value]="entities"
  [paginator]="true"
  [rows]="5"
  [showCurrentPageReport]="true"
  [tableStyle]="{ 'min-width': '250px' }"
  currentPageReportTemplate=""
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 25%">{{ 'adminSettings.titles.table.palletLabel' | translate }}</th>
      <th style="width: 25%"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-entity>
    <tr>
      <td>{{ entity.palletLabel }}</td>
      <td>
        <p-confirmPopup></p-confirmPopup>
        <button
          pButton
          type="button"
          class="delete-btn btn btn-secondary"
          icon="pi pi-trash"
          (click)="confirm($event, entity)"
        ></button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <p-button
      type="button"
      icon="pi pi-plus"
      (click)="showDialog()"
      styleClass="btn-secondary"
    ></p-button>

    <p-dialog
      header="Header"
      [(visible)]="isAddModalVisible"
      [modal]="true"
      [style]="{ width: '400px' }"
      [draggable]="false"
      [resizable]="false"
      [closable]="false"
    >
      <ng-template pTemplate="header">
        <span class="text-xl font-bold"
          >{{ 'adminSettings.titles.actions.new' | translate }} {{ title }}</span
        >
      </ng-template>
      <div class="container add_pallet">
        <span class="p-float-label">
          <input pInputText id="palletLabel" [(ngModel)]="entity.palletLabel" [disabled]="true" />
          <label htmlFor="palletLabel">{{
            'adminSettings.titles.table.palletLabel' | translate
          }}</label>
        </span>
      </div>
      <ng-template pTemplate="footer">
        <p-button (click)="onCancelModal()" label="Cancel" styleClass="btn-primary"></p-button>
        <p-button
          [disabled]="!entity.palletLabel || isSavingInProgress"
          (onClick)="onSave()"
          label="Save"
          styleClass="btn-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>
  </ng-template>
</p-table>
