<p-splitter styleClass="mb-5" layout="vertical" [gutterSize]="10">
  <ng-template pTemplate="">
    <div class="panel">
      <app-gis-table
        [tableDataCount]="tableDataCount"
        (selectedWell)="onSelectedWell($event)"
        (changeWellData)="onchangeWellData($event)"
      ></app-gis-table>
    </div>
  </ng-template>
  <ng-template pTemplate="">
    <div class="content">
      <div class="map-container">
        <div class="map-frame">
          <div id="map"></div>
        </div>
      </div>
      <p-sidebar position="right" [(visible)]="isSidebarVisible" (onHide)="setDefaultMarkerIcon()">
        <h3 class="sidebar-header">Deliveries for {{ selectedWell.wellName }}</h3>
        <app-popup [well]="selectedWell" (changeTab)="onTabChange(0)"></app-popup>
      </p-sidebar>
    </div>
  </ng-template>
</p-splitter>

<div *ngIf="spinnerShow" class="card spinner">
  <p-progressSpinner></p-progressSpinner>
</div>
