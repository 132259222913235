import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  getLicenseData(): Observable<{ expirationDate: number }> {
    const numberOfDaysToAdd = 31;
    const date = new Date();
    const newDate = date.setDate(date.getDate() + numberOfDaysToAdd);

    return of({
      expirationDate: newDate,
    });
  }

  async isLicenseExpired(): Promise<boolean> {
    const orgData = await this.getLicenseData().toPromise();
    return orgData!.expirationDate > Date.now();
  }

  async getDaysToLicenseExpiration(): Promise<number> {
    const millisecondsInDay = 1000 * 60 * 60 * 24;
    const dateNow = Date.now();
    const orgData = await this.getLicenseData().toPromise();
    return Math.floor((orgData!.expirationDate - dateNow) / millisecondsInDay);
  }
}
