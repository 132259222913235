import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-player',
  standalone: true,
  imports: [CommonModule, ButtonModule, RippleModule],
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss', './../upload-styles.scss'],
})
export class VideoPlayerComponent {
  videoSrc: any = null;

  constructor(private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}
  onFileSelected(event: any) {
    this.videoSrc = null;

    const { files } = event.target;
    if (files.length === 0) {
      this.videoSrc = null;
      return;
    }

    const file = event.target.files[0];

    if (file.name.endsWith('.mp4')) {
      const reader = new FileReader();
      reader.onload = () => {
        const blobUrl = URL.createObjectURL(
          new Blob([reader.result as BlobPart], { type: file.type }),
        );
        this.videoSrc = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
        this.cd.detectChanges();
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert('only mp4 vides is supported now.');
    }
  }
}
