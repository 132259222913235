import { TranslateModule } from '@ngx-translate/core';
import {
  interpolateBrBG,
  interpolateCool,
  interpolateGreys,
  interpolateRainbow,
  interpolateSpectral,
  interpolateWarm,
} from 'd3-scale-chromatic';
import { DropdownModule } from 'primeng/dropdown';

import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-color-maps',
  standalone: true,
  imports: [CommonModule, DropdownModule, FormsModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './color-maps.component.html',
  styleUrls: ['./color-maps.component.scss'],
})
export class ColorMapsComponent {
  colormaps = [
    { name: 'Gray', interpolate: interpolateGreys },
    { name: 'Rainbow', interpolate: interpolateRainbow },
    { name: 'Coolwarm', interpolate: interpolateCool },
    { name: 'Hot', interpolate: interpolateWarm },
    { name: 'BrBG', interpolate: interpolateBrBG },
    { name: 'Spectral', interpolate: interpolateSpectral },
  ];

  selectedColormap: any;

  @Output() selectedColormapChange = new EventEmitter<any>();

  constructor(private cd: ChangeDetectorRef) {}

  handleSelectedColormapChange(colormap: any): void {
    this.selectedColormap = colormap;

    const colorCount = 30;
    const colors = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < colorCount; i++) {
      const color = this.selectedColormap.interpolate((colorCount - 1 - i) / (colorCount - 1));
      colors.push(color);
    }

    this.selectedColormapChange.emit(colors);
    this.cd.detectChanges();
  }

  generateColormapColors(interpolate: any): string[] {
    const colorCount = 30;
    const colors = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < colorCount; i++) {
      const color = interpolate((colorCount - 1 - i) / (colorCount - 1));
      colors.push(color);
    }

    return colors;
  }
}
