import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AccessTableComponent } from '../../common/access-table/access-table.component';
import { GisMapComponent } from './gis-map/gis-map.component';
import {
  AssignmentAccessHeader,
  DataAccessHeader,
  ServiceAccessHeader,
} from './models/data-access.model';

@Component({
  selector: 'app-data-access',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    TableModule,
    AccessTableComponent,
    TabViewModule,
    GisMapComponent,
  ],
  templateUrl: './data-access.component.html',
  styleUrls: ['./data-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataAccessComponent implements OnInit {
  @ViewChild('gisMapComponent') gisMapComponent!: GisMapComponent;
  @Output() changeTab: EventEmitter<number> = new EventEmitter<number>();

  dataAccessHeader: string[] = DataAccessHeader;
  serviceAccessHeader: string[] = ServiceAccessHeader;
  assignmentAccessHeader: string[] = AssignmentAccessHeader;
  deliveryFilterOption = { status: true, deliveryCreated: true, receptionDate: true };
  serviceFilterOption = { dateService: true };
  assignmentFilterOption = { state: true, date: true, technicianIds: true };
  tabIndex = 0;
  searchText = '';
  isGisHidden = true;

  tabChanged(tabIndex: number): void {
    this.tabIndex = tabIndex;
  }

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['id']) this.searchText = params['id'];

      if (params['activity'] === 'Assignment') {
        this.tabIndex = 2;
      } else if (params['activity'] === 'Service') this.tabIndex = 1;

      this.clearQueryParams();
    });
  }

  clearQueryParams(): void {
    const urlWithoutParams = this.router.url.split('?')[0];
    this.router.navigateByUrl(urlWithoutParams);
  }

  resizeMap(): void {
    this.gisMapComponent.resizeMap();
  }
}
