import { TranslateModule } from '@ngx-translate/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { StyleClassModule } from 'primeng/styleclass';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { filter } from 'rxjs/operators';

import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule, Scroll } from '@angular/router';

import { FOOTER_TEXT } from 'src/app/core/consts';

import { TranslationComponent } from '../common/translation/translation.component';
import { ROUTES } from '../core/menu-routes';
import { MenuItem } from '../core/models/menu-item.model';
import { RoutePath } from '../core/route-paths.enum';
import { USE_OKTA_AUTH_TOKEN } from '../core/tokens/use-okta-auth.token';
import { Destroyable } from '../core/utils/mixins/destroyable.mixin';
import { AuthenticationService } from '../services/api/authentication.service';
import { HealthCheckService } from '../services/api/health-check.service';
import { LicenseService } from '../services/api/license.service';
import { TokenStorageService } from '../services/api/token-storage.service';
import { UserManagementService } from '../services/user-management.service';
import { ContentComponent } from './content/content.component';
import { ServiceStatus } from './models/service-status.model';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    StyleClassModule,
    ButtonModule,
    TooltipModule,
    CommonModule,
    ToolbarModule,
    SelectButtonModule,
    FormsModule,
    TranslateModule,
    RippleModule,
    TranslationComponent,
    ContentComponent,
    OverlayPanelModule,
  ],
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ width: '299px' })),
      state('false', style({ width: '80px' })),
      transition('false => true', animate(100)),
      transition('true => false', animate(100)),
    ]),
  ],
})
export class LayoutComponent extends Destroyable(Object) implements OnInit {
  @ViewChild('op') op!: OverlayPanel;

  readonly footerText = FOOTER_TEXT;
  readonly logoImage = 'assets/images/SGC_sidebar_logo_big.png';
  readonly logoImageSmall = 'assets/images/SGC_sidebar_logo_small.png';

  routeLinks = RoutePath;
  sidenavOpenedAnimation = true;
  sidenavOpened = true;
  sidebarMenuItems!: MenuItem[];
  isTechnician!: boolean;
  isCustomer!: boolean;
  isAdmin!: boolean;
  serviceDown = false;
  isHovered = false;
  serviceStatusArray!: ServiceStatus[];
  showLicenseExpirationWarning = false;
  daysRemainingForLicenseExpiration = +Infinity;

  constructor(
    public primeNGConfig: PrimeNGConfig,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private userManagementService: UserManagementService,
    private cd: ChangeDetectorRef,
    private authService: AuthenticationService,
    private healthCheckService: HealthCheckService,
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private licenseService: LicenseService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isTechnician = this.userManagementService.isTechnician;
    this.isCustomer = this.userManagementService.isCustomer;
    this.isAdmin = this.userManagementService.isAdmin;
    this.authService.loginSubject.subscribe(() => {
      // this.initializeAllowedRoutesByRole();
      // this.filterMenuItems();
    });

    this.setLocationHighlight();
    // this.initializeAllowedRoutesByRole();
    this.filterMenuItems();
    this.statusUpdate();
    // this.initializeLicenseData();
  }

  filterMenuItems(): void {
    this.sidebarMenuItems = this.filterByRole(this.filterHiddenItems(ROUTES));
    const storedActiveMenuItem = localStorage.getItem('sidebarMenu');
    if (storedActiveMenuItem) {
      const data = JSON.parse(storedActiveMenuItem);
      const dataObject: { [key: string]: MenuItem } = {};
      data.forEach((storeMenu: MenuItem) => {
        dataObject[storeMenu.labelKey] = storeMenu;
      });
      this.sidebarMenuItems.forEach((menu) => {
        const activeMenu = dataObject[menu.labelKey];
        if (activeMenu) {
          menu.isActive = activeMenu.isActive;
        }
      });
    }
    this.cd.markForCheck();
  }

  onAnimationEvent(event: AnimationEvent): void {
    if (event.phaseName === 'done' && event.toState) {
      this.sidenavOpenedAnimation = this.sidenavOpened;
    }
    if (event.phaseName === 'start' && !event.toState) {
      this.sidenavOpenedAnimation = this.sidenavOpened;
    }
  }

  logout(): void {
    if (this.useOktaAuth) {
      this.oktaAuth.signOut();
    } else {
      this.tokenStorageService.removeTokens();
      this.userManagementService.removeUserInfo();
      this.router.navigate([this.routeLinks.LOGIN]);
    }
  }

  onOverlayHide(): void {
    this.isHovered = false;
  }

  private statusUpdate(): void {
    this.healthCheckService
      .fetchStandByStatusRegularly()
      .pipe(this.takeUntilDestroyed())
      .subscribe((serviceStatus) => {
        this.serviceStatusArray = serviceStatus;
        this.serviceDown = this.serviceStatusArray.some((service) => !service.alive);
        this.cd.markForCheck();
      });
  }

  private setLocationHighlight(): void {
    this.router.events
      .pipe(filter((event: any | Scroll) => event instanceof Scroll))
      .subscribe((event: Scroll) => {
        this.sidebarMenuItems.forEach((item: MenuItem) => {
          item.isActive = item.url === event.routerEvent.url;
        });
        localStorage.setItem('sidebarMenu', JSON.stringify(this.sidebarMenuItems));
      });
  }

  private filterHiddenItems(routes: MenuItem[]): MenuItem[] {
    return routes.filter((route) => !route.hide);
  }

  private filterByRole(routes: MenuItem[]): MenuItem[] {
    if (this.isTechnician) {
      return routes.filter(
        (route) =>
          ![RoutePath.ASSIGNMENT, RoutePath.SERVICE, RoutePath.ADMIN_SETTINGS].includes(route.url),
      );
    }

    if (this.isCustomer) {
      return routes.filter(
        (route) =>
          ![
            RoutePath.ASSIGNMENT,
            RoutePath.SERVICE,
            RoutePath.ADMIN_SETTINGS,
            RoutePath.DASHBOARD,
            RoutePath.DATA_ACCESS,
            RoutePath.CALENDAR,
            RoutePath.RECEPTION,
            RoutePath.VERIFICATION,
            RoutePath.VALIDATION,
            RoutePath.STORAGE,
          ].includes(route.url),
      );
    }

    return routes;
  }

  private async initializeLicenseData(): Promise<void> {
    const upperDaysLimitToShowWarning = 30;
    const numberOfDaysForLicenseRemaining = await this.licenseService.getDaysToLicenseExpiration();

    if (numberOfDaysForLicenseRemaining <= upperDaysLimitToShowWarning) {
      this.showLicenseExpirationWarning = true;
      this.daysRemainingForLicenseExpiration = numberOfDaysForLicenseRemaining;
    }
  }
}
