import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SGC_API } from 'src/app/core/api.consts';

import { EntityCacheKey } from '../../core/enums/entity-cache-key.enum';
import { IdName } from '../../core/models/id-name.model';
import { CacheService } from '../cache.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectOptionsService {
  private url = `${SGC_API}/ProjectOptions`;

  private getBiostratigraphicSampleTypesUrl = `${this.url}/BiostratigraphicSampleTypes`;
  private getBoxTypesUrl = `${this.url}/BoxTypes`;
  private getDepthUnitsUrl = `${this.url}/DepthUnits`;
  private getEndCupFractionInfosUrl = `${this.url}/EndCupFractionInfos`;
  private getExtractionPositionInfosUrl = `${this.url}/ExtractionPositionInfos`;
  private getFluidTypesUrl = `${this.url}/FluidTypes`;
  private getLengthUnitsUrl = `${this.url}/LentghUnits`;
  private getMediumDeliveryInfosUrl = `${this.url}/MediumDeliveryInfos`;
  private getOrientationTypesUrl = `${this.url}/OrientationTypes`;
  private getPhysicalStatusesUrl = `${this.url}/PhysicalStatuses`;
  private getPlateTypesUrl = `${this.url}/PlateTypes`;
  private getPreparationTypesUrl = `${this.url}/PreparationTypes`;
  private getPreservationTypesUrl = `${this.url}/PreservationTypes`;
  private getSampleOriginsUrl = `${this.url}/SampleOrigins`;
  private getSampleStatusesUrl = `${this.url}/SampleStatuses`;
  private getSamplingMethodsUrl = `${this.url}/SamplingMethods`;
  private getSectionTypesUrl = `${this.url}/SectionTypes`;
  private getSurfaceSampleTypesUrl = `${this.url}/SurfaceSampleTypes`;
  private getTechnicalInfosUrl = `${this.url}/TechnicalInfoses`;
  private getWeightUnitsUrl = `${this.url}/WeightUnits`;
  private getZhZsDatObjectiveSetsUrl = `${this.url}/ZhZsDatObjectiveSets`;
  private getZhZsDatSampleTypesUrl = `${this.url}/ZhZsDatSampleTypes`;
  private getVolumeUnitsUrl = `${this.url}/VolumeUnits`;

  private createDeleteBiostratigraphicSampleTypeUrl = `${this.url}/BiostratigraphicSampleType`;
  private createDeleteBoxTypeUrl = `${this.url}/BoxType`;
  private createDeleteDepthUnitUrl = `${this.url}/DepthUnit`;
  private createDeleteEndCupFractionInfoUrl = `${this.url}/EndCupFractionInfo`;
  private createDeleteExtractionPositionInfoUrl = `${this.url}/ExtractionPositionInfo`;
  private createDeleteFluidTypeUrl = `${this.url}/FluidType`;
  private createDeleteLengthUnitUrl = `${this.url}/LentghUnit`;
  private createDeleteMediumDeliveryInfoUrl = `${this.url}/MediumDeliveryInfo`;
  private createDeleteOrientationTypeUrl = `${this.url}/OrientationType`;
  private createDeletePhysicalStatusUrl = `${this.url}/PhysicalStatus`;
  private createDeletePlateTypeUrl = `${this.url}/PlateType`;
  private createDeletePreparationTypeUrl = `${this.url}/PreparationType`;
  private createDeletePreservationTypeUrl = `${this.url}/PreservationType`;
  private createDeleteSampleOriginUrl = `${this.url}/SampleOrigin`;
  private createDeleteSampleStatusUrl = `${this.url}/SampleStatus`;
  private createDeleteSamplingMethodUrl = `${this.url}/SamplingMethod`;
  private createDeleteSectionTypeUrl = `${this.url}/SectionType`;
  private createDeleteSurfaceSampleTypeUrl = `${this.url}/SurfaceSampleType`;
  private createDeleteTechnicalInfoUrl = `${this.url}/TechnicalInfo`;
  private createDeleteWeightUnitUrl = `${this.url}/WeightUnit`;
  private createDeleteZhZsDatObjectiveSetUrl = `${this.url}/ZhZsDatObjectiveSet`;
  private createDeleteZhZsDatSampleTypeUrl = `${this.url}/ZhZsDatSampleType`;
  private createDeleteVolumeUnitUrl = `${this.url}/VolumeUnit`;

  constructor(private httpClient: HttpClient, private cacheService: CacheService) {}

  // #region GET

  getZhZsDatObjectiveSets(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.ZH_ZS_DAT_OBJECTIVE_SETS,
      this.httpClient.get<IdName[]>(this.getZhZsDatObjectiveSetsUrl),
    );
  }

  getBoxTypes(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.BOX_TYPES,
      this.httpClient.get<IdName[]>(this.getBoxTypesUrl),
    );
  }

  getDepthUnits(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.DEPTH_UNITS,
      this.httpClient.get<IdName[]>(this.getDepthUnitsUrl),
    );
  }

  getLengthUnits(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.LENGTH_UNITS,
      this.httpClient.get<IdName[]>(this.getLengthUnitsUrl),
    );
  }

  getWeightUnits(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.WEIGHT_UNITS,
      this.httpClient.get<IdName[]>(this.getWeightUnitsUrl),
    );
  }

  getSectionTypes(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.SECTION_TYPES,
      this.httpClient.get<IdName[]>(this.getSectionTypesUrl),
    );
  }

  getTechnicalInfoses(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.TECHNICAL_INFOSES,
      this.httpClient.get<IdName[]>(this.getTechnicalInfosUrl),
    );
  }

  getMediumDeliveryInfos(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.MEDIUM_DELIVERY_INFOS,
      this.httpClient.get<IdName[]>(this.getMediumDeliveryInfosUrl),
    );
  }

  getFluidTypes(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.FLUID_TYPES,
      this.httpClient.get<IdName[]>(this.getFluidTypesUrl),
    );
  }

  getVolumeUnits(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.VOLUME_UNITS,
      this.httpClient.get<IdName[]>(this.getVolumeUnitsUrl),
    );
  }

  getBiostratigraphicSampleTypes(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.BIOSTRATIGRAPHIC_SAMPLE_TYPES,
      this.httpClient.get<IdName[]>(this.getBiostratigraphicSampleTypesUrl),
    );
  }

  getEndCupFractionInfos(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.END_CUP_FRACTION_INFOS,
      this.httpClient.get<IdName[]>(this.getEndCupFractionInfosUrl),
    );
  }

  getExtractionPositionInfos(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.EXTRACTION_POSITION_INFOS,
      this.httpClient.get<IdName[]>(this.getExtractionPositionInfosUrl),
    );
  }

  getOrientationTypes(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.ORIENTATION_TYPES,
      this.httpClient.get<IdName[]>(this.getOrientationTypesUrl),
    );
  }

  getPhysicalStatuses(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.PHYSICAL_STATUSES,
      this.httpClient.get<IdName[]>(this.getPhysicalStatusesUrl),
    );
  }

  getPlateTypes(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.PLATE_TYPES,
      this.httpClient.get<IdName[]>(this.getPlateTypesUrl),
    );
  }

  getPreparationTypes(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.PREPARATION_TYPES,
      this.httpClient.get<IdName[]>(this.getPreparationTypesUrl),
    );
  }

  getPreservationTypes(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.PRESERVATION_TYPES,
      this.httpClient.get<IdName[]>(this.getPreservationTypesUrl),
    );
  }

  getSampleOrigins(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.SAMPLE_ORIGINS,
      this.httpClient.get<IdName[]>(this.getSampleOriginsUrl),
    );
  }

  getSampleStatuses(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.SAMPLE_STATUSES,
      this.httpClient.get<IdName[]>(this.getSampleStatusesUrl),
    );
  }

  getSamplingMethods(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.SAMPLING_METHODS,
      this.httpClient.get<IdName[]>(this.getSamplingMethodsUrl),
    );
  }

  getSurfaceSampleTypes(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.SURFACE_SAMPLE_TYPES,
      this.httpClient.get<IdName[]>(this.getSurfaceSampleTypesUrl),
    );
  }

  getZhZsDatSampleTypes(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      EntityCacheKey.ZH_ZS_DAT_SAMPLE_TYPES,
      this.httpClient.get<IdName[]>(this.getZhZsDatSampleTypesUrl),
    );
  }

  // #endregion GET
  // #region POST

  createBoxType(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.BOX_TYPES,
      this.httpClient.post<IdName>(`${this.createDeleteBoxTypeUrl}?option=${name}`, {}),
    );
  }

  createDepthUnit(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.DEPTH_UNITS,
      this.httpClient.post<IdName>(`${this.createDeleteDepthUnitUrl}?option=${name}`, {}),
    );
  }

  createLengthUnit(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.LENGTH_UNITS,
      this.httpClient.post<IdName>(`${this.createDeleteLengthUnitUrl}?option=${name}`, {}),
    );
  }

  createWeightUnit(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.WEIGHT_UNITS,
      this.httpClient.post<IdName>(`${this.createDeleteWeightUnitUrl}?option=${name}`, {}),
    );
  }

  createSectionType(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.SECTION_TYPES,
      this.httpClient.post<IdName>(`${this.createDeleteSectionTypeUrl}?option=${name}`, {}),
    );
  }

  createTechnicalInfo(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.TECHNICAL_INFOSES,
      this.httpClient.post<IdName>(`${this.createDeleteTechnicalInfoUrl}?option=${name}`, {}),
    );
  }

  createMediumDeliveryInfo(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.MEDIUM_DELIVERY_INFOS,
      this.httpClient.post<IdName>(`${this.createDeleteMediumDeliveryInfoUrl}?option=${name}`, {}),
    );
  }

  createFluidType(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.FLUID_TYPES,
      this.httpClient.post<IdName>(`${this.createDeleteFluidTypeUrl}?option=${name}`, {}),
    );
  }

  createVolumeUnit(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.VOLUME_UNITS,
      this.httpClient.post<IdName>(`${this.createDeleteVolumeUnitUrl}?name=${name}`, {}),
    );
  }

  createBiostratigraphicSampleType(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.BIOSTRATIGRAPHIC_SAMPLE_TYPES,
      this.httpClient.post<IdName>(
        `${this.createDeleteBiostratigraphicSampleTypeUrl}?option=${name}`,
        {},
      ),
    );
  }

  createEndCupFractionInfo(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.END_CUP_FRACTION_INFOS,
      this.httpClient.post<IdName>(`${this.createDeleteEndCupFractionInfoUrl}?option=${name}`, {}),
    );
  }

  createExtractionPositionInfo(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.EXTRACTION_POSITION_INFOS,
      this.httpClient.post<IdName>(
        `${this.createDeleteExtractionPositionInfoUrl}?option=${name}`,
        {},
      ),
    );
  }

  createOrientationType(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.ORIENTATION_TYPES,
      this.httpClient.post<IdName>(`${this.createDeleteOrientationTypeUrl}?option=${name}`, {}),
    );
  }

  createPhysicalStatus(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PHYSICAL_STATUSES,
      this.httpClient.post<IdName>(`${this.createDeletePhysicalStatusUrl}?option=${name}`, {}),
    );
  }

  createPlateType(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PLATE_TYPES,
      this.httpClient.post<IdName>(`${this.createDeletePlateTypeUrl}?option=${name}`, {}),
    );
  }

  createPreparationType(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PREPARATION_TYPES,
      this.httpClient.post<IdName>(`${this.createDeletePreparationTypeUrl}?option=${name}`, {}),
    );
  }

  createPreservationType(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PRESERVATION_TYPES,
      this.httpClient.post<IdName>(`${this.createDeletePreservationTypeUrl}?option=${name}`, {}),
    );
  }

  createSampleOrigin(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.SAMPLE_ORIGINS,
      this.httpClient.post<IdName>(`${this.createDeleteSampleOriginUrl}?option=${name}`, {}),
    );
  }

  createSampleStatus(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.SAMPLE_STATUSES,
      this.httpClient.post<IdName>(`${this.createDeleteSampleStatusUrl}?option=${name}`, {}),
    );
  }

  createSamplingMethod(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.SAMPLING_METHODS,
      this.httpClient.post<IdName>(`${this.createDeleteSamplingMethodUrl}?option=${name}`, {}),
    );
  }

  createSurfaceSampleType(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.SURFACE_SAMPLE_TYPES,
      this.httpClient.post<IdName>(`${this.createDeleteSurfaceSampleTypeUrl}?option=${name}`, {}),
    );
  }

  createZhZsDatObjectiveSet(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.ZH_ZS_DAT_OBJECTIVE_SETS,
      this.httpClient.post<IdName>(`${this.createDeleteZhZsDatObjectiveSetUrl}?option=${name}`, {}),
    );
  }

  createZhZsDatSampleType(name: string): Observable<IdName> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.ZH_ZS_DAT_SAMPLE_TYPES,
      this.httpClient.post<IdName>(`${this.createDeleteZhZsDatSampleTypeUrl}?option=${name}`, {}),
    );
  }

  // #endregion POST
  // #region DELETE

  deleteBoxType(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.BOX_TYPES,
      this.httpClient.delete<null>(`${this.createDeleteBoxTypeUrl}/${id}`),
    );
  }

  deleteDepthUnit(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.DEPTH_UNITS,
      this.httpClient.delete<null>(`${this.createDeleteDepthUnitUrl}/${id}`),
    );
  }

  deleteLengthUnit(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.LENGTH_UNITS,
      this.httpClient.delete<null>(`${this.createDeleteLengthUnitUrl}/${id}`),
    );
  }

  deleteWeightUnit(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.WEIGHT_UNITS,
      this.httpClient.delete<null>(`${this.createDeleteWeightUnitUrl}/${id}`),
    );
  }

  deleteSectionType(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.SECTION_TYPES,
      this.httpClient.delete<null>(`${this.createDeleteSectionTypeUrl}/${id}`),
    );
  }

  deleteTechnicalInfo(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.TECHNICAL_INFOSES,
      this.httpClient.delete<null>(`${this.createDeleteTechnicalInfoUrl}/${id}`),
    );
  }

  deleteMediumDeliveryInfo(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.MEDIUM_DELIVERY_INFOS,
      this.httpClient.delete<null>(`${this.createDeleteMediumDeliveryInfoUrl}/${id}`),
    );
  }

  deleteFluidType(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.FLUID_TYPES,
      this.httpClient.delete<null>(`${this.createDeleteFluidTypeUrl}/${id}`),
    );
  }

  deleteVolumeUnit(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.VOLUME_UNITS,
      this.httpClient.delete<null>(`${this.createDeleteVolumeUnitUrl}/${id}`),
    );
  }

  deleteBiostratigraphicSampleType(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.BIOSTRATIGRAPHIC_SAMPLE_TYPES,
      this.httpClient.delete<null>(`${this.createDeleteBiostratigraphicSampleTypeUrl}/${id}`),
    );
  }

  deleteEndCupFractionInfo(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.END_CUP_FRACTION_INFOS,
      this.httpClient.delete<null>(`${this.createDeleteEndCupFractionInfoUrl}/${id}`),
    );
  }

  deleteExtractionPositionInfo(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.EXTRACTION_POSITION_INFOS,
      this.httpClient.delete<null>(`${this.createDeleteExtractionPositionInfoUrl}/${id}`),
    );
  }

  deleteOrientationType(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.ORIENTATION_TYPES,
      this.httpClient.delete<null>(`${this.createDeleteOrientationTypeUrl}/${id}`),
    );
  }

  deletePhysicalStatus(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PHYSICAL_STATUSES,
      this.httpClient.delete<null>(`${this.createDeletePhysicalStatusUrl}/${id}`),
    );
  }

  deletePlateType(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PLATE_TYPES,
      this.httpClient.delete<null>(`${this.createDeletePlateTypeUrl}/${id}`),
    );
  }

  deletePreparationType(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PREPARATION_TYPES,
      this.httpClient.delete<null>(`${this.createDeletePreparationTypeUrl}/${id}`),
    );
  }

  deletePreservationType(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.PRESERVATION_TYPES,
      this.httpClient.delete<null>(`${this.createDeletePreservationTypeUrl}/${id}`),
    );
  }

  deleteSampleOrigin(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.SAMPLE_ORIGINS,
      this.httpClient.delete<null>(`${this.createDeleteSampleOriginUrl}/${id}`),
    );
  }

  deleteSampleStatus(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.SAMPLE_STATUSES,
      this.httpClient.delete<null>(`${this.createDeleteSampleStatusUrl}/${id}`),
    );
  }

  deleteSamplingMethod(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.SAMPLING_METHODS,
      this.httpClient.delete<null>(`${this.createDeleteSamplingMethodUrl}/${id}`),
    );
  }

  deleteSurfaceSampleType(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.SURFACE_SAMPLE_TYPES,
      this.httpClient.delete<null>(`${this.createDeleteSurfaceSampleTypeUrl}/${id}`),
    );
  }

  deleteZhZsDatObjectiveSet(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.ZH_ZS_DAT_OBJECTIVE_SETS,
      this.httpClient.delete<null>(`${this.createDeleteZhZsDatObjectiveSetUrl}/${id}`),
    );
  }

  deleteZhZsDatSampleType(id: string): Observable<null> {
    return this.cacheService.updateCacheAfterActionFor(
      EntityCacheKey.ZH_ZS_DAT_SAMPLE_TYPES,
      this.httpClient.delete<null>(`${this.createDeleteZhZsDatSampleTypeUrl}/${id}`),
    );
  }

  // #endregion DELETE
}
