import { VerificationSampleInformation } from '../../features/verification/models/verification-sample-information.model';

export class PrintDeliveryTable {
  Type: string;
  BoxNumber: string;
  TopDepth: string;
  BottomDepth: string;
  SamplePerBox: string;
  PackagingType: string;

  constructor(packagingType: string, type: string, t?: VerificationSampleInformation) {
    this.Type = type;
    this.BoxNumber = t!.boxNumber ? t!.boxNumber!.toString() : '';
    this.TopDepth = t?.boxTopDepth ? t!.boxTopDepth!.toString() : '';
    this.BottomDepth = t?.boxBottomDepth ? t!.boxBottomDepth!.toString() : '';
    this.SamplePerBox =
      t?.numberSamplesPerBox?.toString() ||
      t?.tubesPerBox?.toString() ||
      t?.samplesPerBox?.toString() ||
      t?.numberOfBottles?.toString() ||
      t?.bottleNumber?.toString() ||
      '';
    this.PackagingType = packagingType ?? '';
  }
}
