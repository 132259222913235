<ng-container [formGroup]="parentGroup">
  <p-accordionTab
    header="{{ 'selfServices.contractInformation' | translate }}"
    formGroupName="contractInformation"
    [selected]="isExpanded"
  >
    <div class="row input-group-50">
      <span class="p-float-label">
        <input id="float-input" type="text" pInputText formControlName="contractNumber" />
        <label for="float-input">{{ 'selfServices.contractNumber' | translate }}*</label>
        <small
          *ngIf="checkFormError('contractInformation.contractNumber')"
          class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>
      <span class="p-float-label">
        <p-calendar
          view="year"
          dateFormat="yy"
          inputId="yearpicker"
          id="contract-date"
          [showIcon]="true"
          formControlName="contractYear"
        ></p-calendar>
        <label for="float-input">{{ 'selfServices.contractYear' | translate }}</label>
        <small *ngIf="checkFormError('contractInformation.contractYear')" class="p-error p-invalid"
          >{{ 'errorMessage.fieldIsRequired' | translate }}
        </small>
      </span>
    </div>
  </p-accordionTab>
</ng-container>
