import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { concatMap, delay, from, Observable } from 'rxjs';

import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

import { PrintLabelType } from '../../../../core/enums/print-label-type.enum';
import { PrintableSampleLabel } from '../../../../core/models/printable-labels.model';
import { SampleLabelPayload } from '../../../../core/models/sample-label-payload.model';
import { Destroyable } from '../../../../core/utils/mixins/destroyable.mixin';
import { PrintLabelService } from '../../../../services/api/print-label.service';

@Component({
  selector: 'app-print-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NgOptimizedImage, ProgressSpinnerModule],
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.scss'],
})
export class PrintLayoutComponent extends Destroyable(Object) implements OnInit {
  type: string | null = '';
  labels: string | null = '';
  observableArray: Observable<any>[] | undefined;
  imgSrcs: string[] = [];
  showSpinner = true;
  constructor(
    private readonly route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private readonly printLabelService: PrintLabelService,
  ) {
    super();
    this.type = localStorage.getItem('type');
  }

  ngOnInit(): void {
    const localData = localStorage.getItem('data');
    if (localData != null) {
      switch (this.type) {
        case PrintLabelType.sample:
          this.observableArray = JSON.parse(localData).map((entity: PrintableSampleLabel) => {
            const sampleLabelPayload = new SampleLabelPayload(
              entity.label,
              entity.wellName,
              entity.uwi,
              entity.sampleType,
              entity.unitNumber,
              entity.depth,
            );

            return this.printLabelService.getSampleLabel(sampleLabelPayload);
          });
          break;
        case PrintLabelType.container:
          this.observableArray = localData
            .split(',')
            .filter((item) => !!item)
            .map((label) => {
              return this.printLabelService.getContainerLabel(label);
            });
          break;
        case PrintLabelType.pallet:
          this.observableArray = localData
            .split(',')
            .filter((item) => !!item)
            .map((label) => {
              return this.printLabelService.getPalletLabel(label);
            });
          break;
        case PrintLabelType.location:
          this.observableArray = JSON.parse(localData)
            .filter((entity: { label: string }) => {
              return !!entity.label;
            })
            .map((entity: { label: string; bay: string; position: string }) => {
              return this.printLabelService.getLocationLabel(
                entity.label,
                entity.bay,
                entity.position,
              );
            });
          break;
        default:
          return;
      }
    }

    if (this.observableArray) {
      this.observableArray = this.observableArray.map((item) => item.pipe(delay(100)));
      from(this.observableArray)
        .pipe(concatMap((item) => item))
        .subscribe((item) => {
          this.print(item.body);
        });
    }
  }

  print(body: Blob): void {
    this.imgSrcs.push(URL.createObjectURL(body));
    if (this.imgSrcs.length === this.observableArray?.length) {
      this.showSpinner = false;
      setTimeout(() => {
        localStorage.removeItem('type');
        localStorage.removeItem('data');
        window.print();
      }, 100);
    }
    this.cd.detectChanges();
  }
}
