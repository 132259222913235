<form [formGroup]="formGroup">
  <div class="row input-group-50 mt-3 mb-5 gap-3">
    <span class="p-float-label">
      <p-multiSelect
        id="state-dropdown"
        [options]="stateOption"
        [filter]="false"
        filterBy="name"
        optionValue="id"
        optionLabel="name"
        formControlName="states"
        placeholder="{{ 'calendar.selectState' | translate }}"
        [showClear]="true"
        [styleClass]="'w-full'"
      ></p-multiSelect>
      <label for="state-dropdown">{{ 'calendar.state' | translate }}</label>
    </span>

    <span class="p-float-label">
      <p-multiSelect
        id="activity-dropdown"
        [options]="activities"
        [filter]="false"
        formControlName="activities"
        optionValue="id"
        optionLabel="name"
        placeholder="{{ 'calendar.selectActivity' | translate }}"
        [showClear]="true"
        [styleClass]="'w-full'"
      ></p-multiSelect>
      <label for="activity-dropdown">{{ 'calendar.activity' | translate }}</label>
    </span>

    <button
      pButton
      type="button"
      class="btn btn-secondary btn-apply gap-2"
      [disabled]="
        formGroup.get('states')?.value?.length < 1 || formGroup.get('activities')?.value?.length < 1
      "
      (click)="apply()"
      label="{{ 'button.apply' | translate }}"
    ></button>
  </div>
</form>

<div *ngIf="spinnerShow" class="card spinner">
  <p-progressSpinner></p-progressSpinner>
</div>

<div class="row text-center">
  <div class="pf-md-4">
    <div class="btn-group">
      <div
        class="btn btn-primary"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay($event)"
      >
        {{ 'button.previous' | translate }}
      </div>
      <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
        {{ 'button.today' | translate }}
      </div>
      <div
        class="btn btn-primary"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay($event)"
      >
        {{ 'button.next' | translate }}
      </div>
    </div>
  </div>
  <div class="pf-md-4 capitalize">
    <h3>{{ viewDate | calendarDate : view + 'ViewTitle' : calendarLocale }}</h3>
  </div>
</div>
<br />

<div class="calendar-container" [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="'month'"
    [viewDate]="viewDate"
    [events]="events"
    [locale]="calendarLocale"
    [activeDayIsOpen]="activeDayIsOpen"
    (dayClicked)="dayClicked($event.day)"
    [eventTitleTemplate]="customEventTitleTemplate"
  >
  </mwl-calendar-month-view>
</div>

<ng-template #customEventTitleTemplate let-event="event">
  <span class="custom-event-title cal-event-title">
    <span (click)="handleTitleClick(event)">{{ event.title }}</span>
    <ng-container *ngIf="event['activity'] === 'Service'">
      <span>, Attached files: </span>
      <span class="fileUrl" (click)="handleUrlClick(event?.name, event?.fileId)">
        {{ event.name || 'No files' }}
      </span>
    </ng-container>
  </span>
</ng-template>
