import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';

import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LocaleService } from '../../../../services/locale.service';

@Component({
  selector: 'app-opacity',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputNumberModule,
    ButtonModule,
    RippleModule,
    TranslateModule,
  ],
  templateUrl: './opacity.component.html',
  styleUrls: ['./opacity.component.scss'],
})
export class OpacityComponent {
  @Input() minValue = 0;
  @Input() maxValue = 0;
  @Output() opacityArrayChange = new EventEmitter<any>();

  displayedColumns: string[] = ['value', 'opacity'];
  dataSource: any[] = [];
  intensity!: number;
  opacity!: number;

  count = 0;
  display = true;

  get defaultLocale(): string {
    return this.localeService.getDefaultLocale();
  }

  constructor(private localeService: LocaleService, private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.dataSource = [
      { value: this.minValue, opacity: 0.0 },
      { value: this.maxValue, opacity: 1.0 },
    ];
    this.opacityArrayChange.emit(this.dataSource);
    this.cd.detectChanges();
  }

  addToTable() {
    this.display = false;

    const x = { value: this.intensity, opacity: this.opacity };
    const index = this.dataSource.findIndex((obj) => obj.value === x.value);

    if (index !== -1) {
      this.dataSource[index] = x;
    } else {
      this.dataSource.push(x);

      this.dataSource.sort((a: any, b: any) => a.value - b.value);
    }

    this.opacityArrayChange.emit(this.dataSource);

    this.count += 1;
    setTimeout(() => {
      this.display = true;
      this.cd.detectChanges();
    }, 10);
  }

  removeFromTable() {
    this.display = false;

    const index = this.dataSource.findIndex((obj) => obj.value === this.intensity);

    if (index !== -1) {
      this.dataSource.splice(index, 1);
      this.opacityArrayChange.emit(this.dataSource);
    }

    this.count += 1;
    setTimeout(() => {
      this.display = true;
      this.cd.detectChanges();
    }, 10);
  }
}
