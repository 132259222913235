import { Pipe, PipeTransform } from '@angular/core';

import { ShortTransportationModel } from '../../../core/models/transportation.model';

@Pipe({
  name: 'buildTransportationName',
  standalone: true,
})
export class BuildTransportationNamePipe implements PipeTransform {
  transform(transportation: ShortTransportationModel): string {
    return [transportation.companyName, transportation.driverName, transportation.licensePlate]
      .filter((transportationProp) => !!transportationProp)
      .join('-');
  }
}
